import { lazy } from "react";
import { Redirect } from "react-router-dom";

/* Sameer Test Pages & Routes */

// Dashboard
const Dashboard = lazy(() => import("../Views/User/Dashboard")); // Need work on To Do List
const Listing = lazy(() => import("../Views/User/Listing"));
const Photos = lazy(() => import("../Views/User/Photos")); // Need Sort of Images
const Reviews = lazy(() => import("../Views/User/Reviews"));
const Products = lazy(() => import("../Views/User/Products")); // Need Adjustments
const Employees = lazy(() => import("../Views/User/Employees"));
const Events = lazy(() => import("../Views/User/Events"));
// const Profile = lazy(() => import("../Views/User/Profile"));
const QA = lazy(() => import("../Views/User/QA")); // Untested
const Jobs = lazy(() => import("../Views/User/Jobs"));
const Inbox = lazy(() => import("../Views/User/Inbox")); // Not Working
const Statistics = lazy(() => import("../Views/User/Statistics")); // Not Working
// const Share = lazy(() => import("../Views/User/Share")); // Not Finished
const Account = lazy(() => import("../Views/User/Account")); // Missing Checkboxes Backend
const Upgrade = lazy(() => import("../Views/User/Upgrade")); // Not Working

// Add
const SubmitEvent = lazy(() => import("../Views/Add/Event")); // Edit Missing
const SubmitListing = lazy(() => import("../Views/Add/Listing")); // Edit Missing & Event List Page Missing
const EditListing = lazy(() => import("../Views/Add/Listing/Edit/")); // Edit Missing & Event List Page Missing
const EditEvent = lazy(() => import("../Views/Add/Event/Edit/")); // Edit Missing & Event List Page Missing
const Pricing = lazy(() => import("../Views/Add/Listing/Pricing/"));
const Social = lazy(() => import("../Views/Add/Listing/SocialMedia/"));
const Coupon = lazy(() => import("../Views/Add/Listing/Coupons/"));

/* Sameer Test Pages & Routes */

const ProtectedRoutes = () => [
  {
    exact: true,
    name: "UserDashboard",
    path: "/user/dashboard",
    desktopModule: Dashboard,
    mobileModule: false,
  },
  {
    exact: false,
    name: "UserListing",
    path: "/user/listing/",
    desktopModule: Listing,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserEvents",
    path: "/user/events",
    desktopModule: Events,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserPhotos",
    path: "/user/photos",
    desktopModule: Photos,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserReviews",
    path: "/user/reviews",
    desktopModule: Reviews,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserProducts",
    path: "/user/products",
    desktopModule: Products,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserEmployees",
    path: "/user/employees",
    desktopModule: Employees,
    mobileModule: false,
    statusVerfied: true,
  },
  // {
  //   exact: true,
  //   name: "UserProfile",
  //   path: "/user/profile",
  //   desktopModule: Profile,
  //   mobileModule: false,
  //   statusVerfied: true,
  // },
  {
    exact: true,
    name: "UserQA",
    path: "/user/qa",
    desktopModule: QA,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserJobs",
    path: "/user/jobs",
    desktopModule: Jobs,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserInbox",
    path: "/user/inbox",
    desktopModule: Inbox,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserStatistics",
    path: "/user/statistics",
    desktopModule: Statistics,
    mobileModule: false,
    statusVerfied: true,
  },
  // {
  //   exact: true,
  //   name: "UserShare",
  //   path: "/user/share",
  //   desktopModule: Share,
  //   mobileModule: false,
  //   statusVerfied: true,
  // },
  {
    exact: true,
    name: "UserAccount",
    path: "/user/account",
    desktopModule: Account,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserUpgrade",
    path: "/user/upgrade",
    desktopModule: Upgrade,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserSocial",
    path: "/user/social",
    desktopModule: Social,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserCoupon",
    path: "/user/coupon",
    desktopModule: Coupon,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "UserPricing",
    path: "/user/pricing",
    desktopModule: Pricing,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "SubmitListing",
    path: "/submit-listing",
    desktopModule: SubmitListing,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "EditListing",
    path: "/edit-listing",
    desktopModule: EditListing,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "SubmitEvent",
    path: "/submit-event",
    desktopModule: SubmitEvent,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: true,
    name: "EditEvent",
    path: "/edit-event",
    desktopModule: EditEvent,
    mobileModule: false,
    statusVerfied: true,
  },
  {
    exact: false,
    name: "NotFound",
    path: "*",
    desktopModule: ToHome,
    mobileModule: ToHome,
  },
];

const ToHome = () => {
  return <Redirect to="/" />;
};

export default ProtectedRoutes;
