import React, { createContext, useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";

const AuthContext = createContext({});

const AuthProvider = (props) => {
  const [token, setToken] = useState("");
  const [location, setLocation] = useState({});
  let value = localStorage.getItem("refreshCycle") ? localStorage.getItem("refreshCycle") : false;
  const [isAuthenticated, setIsAuthenticated] = useState(value);

  /* Verify if token is present or not */
  useEffect(() => {
    getLocation();

    if (token) {
      verifyToken(token);
    } else {
      if (localStorage.getItem("token")) {
        setToken(localStorage.getItem("token"));
        verifyToken(localStorage.getItem("token"));
      } else {
        setIsAuthenticated(false);
      }
    }
  }, []);

  /* Used to set token */
  const activateToken = (value) => {
    setToken(value);
    return;
  };

  const getLocation = () => {
    const location = localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")) : "";
    const locationDate = new Date(location.date);
    const lessDate = new Date().setDate(new Date().getDate() - 3);

    if (!location || locationDate < lessDate) {
      console.log("refetch");
      Axios.get(`https://geolocation-db.com/json/`).then((res) => {
        const { data } = res;
        if (data.country_code == "PK") {
          data.date = moment();
          localStorage.setItem("location", JSON.stringify(data));
          setLocation(data);
        }
      });
    } else {
      setLocation(location);
    }
  };

  const changeCity = (city) => {
    const location = localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")) : "";
    if (location) {
      location.city = city;
      localStorage.setItem("location", JSON.stringify(location));
      setLocation(location);
    }
  };

  /* Used to set authentication */
  const activateAuthentication = (value) => {
    setIsAuthenticated(value);
    return;
  };

  /* Function to verify if token is valid or not */
  const verifyToken = async (token) => {
    let result = await Axios.get(`${process.env.REACT_APP_API_URL}auth/verify-token`, { headers: { "x-access-token": token } });
    if (result.data != false) {
      setIsAuthenticated(!result.data.error);
      localStorage.setItem("user", JSON.stringify(result.data.user));
      localStorage.setItem("favListing", JSON.stringify(result.data.user.favListing));
      localStorage.setItem("favNames", JSON.stringify(result.data.user.favNames));
      localStorage.setItem("refreshCycle", !result.data.error);
    } else {
      setIsAuthenticated(false);
      localStorage.removeItem("user");
      localStorage.removeItem("refreshCycle");
      localStorage.removeItem("favListing");
      localStorage.removeItem("favNames");
    }
  };

  const authContextValue = {
    activateToken,
    activateAuthentication,
    isAuthenticated,
    location,
    changeCity,
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

const Auth = () => React.useContext(AuthContext);

export { AuthProvider, Auth };
