import React from "react";
import "./Main.scss";
import { Link } from "react-router-dom";
import { AiOutlineTwitter, AiFillFacebook, AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { BsEnvelope, BsPinterest, BsLinkedin } from "react-icons/bs";
import { config } from "../../../config/social.config";

const FooterMobile = () => {
  return (
    <div id="footer-mobile" className="mt-n5">
      <div className="container">
        <h5>Newsletter</h5>
        <div className="d-flex">
          <input className="form-control footer-input" placeholder="name@citybook.pk" />
          <button className="btn sub-button">Subscribe</button>
        </div>
        <h5 className="mt-4">Social Media</h5>
        <div className="d-flex">
          <a href={config.link.tt} target="_blank" className="social-icon text-primary">
            <AiOutlineTwitter />
          </a>
          <a href={config.link.fb} target="_blank" className="social-icon text-primary">
            <AiFillFacebook />
          </a>
          <a href={config.link.ig} target="_blank" className="social-icon text-danger">
            <AiFillInstagram />
          </a>
          <a href={config.link.yt} target="_blank" className="social-icon text-danger">
            <AiFillYoutube />
          </a>
          <a href={config.link.pt} target="_blank" className="social-icon text-danger">
            <BsPinterest />
          </a>
          <a href={config.link.in} target="_blank" className="social-icon text-danger">
            <BsLinkedin />
          </a>
        </div>
        <div className="row mt-3">
          <div className="col-6 pr-1">
            <img className="stores-img" src="../../../../google-play-badge.2a304fb4.png" />
          </div>
          <div className="col-6 pl-1">
            <img className="stores-img" src="../../../../Download_on_the_App_Store_Badge.svg (1).4c04ac2b.webp" />
          </div>
        </div>
        <div className="d-flex">
          <h6 className="mb-0 align-self-center">Get in Touch : </h6>
          <a href="mailto:citybookpk@gmail.com" className="text-muted mb-0 ">
            &nbsp; <BsEnvelope /> citybookpk@gmail.com
          </a>
        </div>
      </div>
      <hr />
      <div className="container">
        <div>
          <p className="text-center">Copyright © 2003 - 2022 Citybook (Pvt) Ltd. - All Rights Reserved.</p>
          <div className="d-flex justify-content-center">
            <Link to="/terms-condition" className="text-muted">
              Terms of Service{" "}
            </Link>
            <span className="text-muted">&nbsp; | &nbsp;</span>
            <Link className="text-muted" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <a href="https://azi.solutions/" target="_blank" className="d-flex mt-3 justify-content-center text-decoration-none text-dark">
            <p className="align-self-center mb-0">Developed by AZI Solutions : </p>
            <img className="brand-logo" src="../../../../azi solutions.png" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
