import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Form, Image } from "react-bootstrap";
import { FiChevronsLeft } from "react-icons/fi";
import Sidebar from "../LeftSidebar";
import SearchBar from "./SearchBar";
import "./styles.scss";
import "../Main.scss";

const favicon = "/favicon.png";

function Header() {
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const [menu, setMenu] = useState(false);

  return (
    <div className="header w-100">
      <Sidebar open={menu} setOpen={setMenu} />

      {pathname == "/" && (
        <div className="bars" onClick={() => setMenu(!menu)}>
          <div className="dot"></div>
          <div className="bar-1"> </div>
          <div className="bar-2"></div>
          <div className="bar-3"></div>
        </div>
      )}
      {pathname != "/" && (
        <div className="mt-2 mx-0">
          <FiChevronsLeft size="30" onClick={() => goBack()} />
        </div>
      )}

      <Form>
        <Form.Group className="mb-0">
          <div className="d-flex search">
            <Image style={{ height: "25px", alignSelf: "center" }} fluid src={favicon} />
            <SearchBar />
          </div>
        </Form.Group>
      </Form>

      {pathname != "/" && (
        <div className="bars" onClick={() => setMenu(!menu)}>
          <div className="dot"></div>
          <div className="bar-1"> </div>
          <div className="bar-2"></div>
          <div className="bar-3"></div>
        </div>
      )}
    </div>
  );
}

export default Header;
