const oldLinks = [
  { from: "/all-about-uzma-khan-actress-you-need-to-know/" },
  { from: "/how-to-make-a-business-website/" },
  { from: "/petrol-prices-going-to-decrease-again-in-june-2020/" },
  { from: "/top-10-android-games-are-worth-playing/" },
  { from: "/salman-khan-girlfriends-list-all-the-time/" },
  { from: "/top-10-companies-in-pakistan-you-must-have-to-apply/" },
  { from: "/5-steps-to-select-high-quality-it-providers/" },
  { from: "/toyota-fortuner-price-in-pakistan-specifications-and-pictures/" },
  { from: "/must-try-top-10-exercises-for-weight-loss-at-home/" },
  { from: "/top-5-business-listing-websites-in-pakistan/" },
  { from: "/50-places-to-visit-in-pakistan/" },
  { from: "/suzuki-swift-price-in-pakistan-with-specs-and-pictures/" },
  { from: "/top-10-tiktokers-in-pakistan-who-are-celebrities-now/" },
  { from: "/top-7-questions-science-cant-answer/" },
  { from: "/seo-is-a-technical-skill-with-good-earning-potential/" },
  { from: "/top-10-expensive-cars-in-the-world-2020-who-owns-it/" },
  { from: "/suzuki-ciaz-price-in-pakistan/" },
  { from: "/details-pictures-of-miss-pakistan-from-last-10-years/" },
  { from: "/list-of-mahira-khan-movies-of-all-time/" },
  { from: "/top-10-online-shopping-sites-in-pakistan/" },
  { from: "/suzuki-cultus-price-in-pakistan-with-specs/" },
  { from: "/pakistan-richest-man-2020-shahid-khan/" },
  { from: "/audi-car-prices-in-pakistan/" },
  { from: "/range-rover-car-prices-in-pakistan/" },
  { from: "/top-fawad-khan-movies-list-all-the-time/" },
  { from: "/honda-city-price-in-pakistan-with-specifications/" },
  { from: "/love-sayings-in-urdu/" },
  { from: "/romantic-poetry-sms-in-urdu/" },
  { from: "/islamic-poetry-sms-in-urdu/" },
  { from: "/top-10-seasons-on-netflix-you-havent-watched-yet/" },
  { from: "/honda-civic-car-price-in-pakistan-specifications/" },
  { from: "/suzuki-wagon-r-price-in-pakistan-with-specs-pictures/" },
  { from: "/9-benefits-of-oranges-what-it-is-for-and-properties/" },
  { from: "/10-pakistani-celebrities-who-left-showbiz-for-islam/" },
  { from: "/kia-picanto-pakistan-price-specification-and-review/" },
  { from: "/suzuki-alto-price-specification-and-review/" },
  { from: "/royalty-free-nature-images-nature-stock-videos/" },
  { from: "/sana-javed-pakistani-actress-bio-and-pictures/" },
  { from: "/most-beautiful-couples-of-pakistani-showbiz-industry/" },
  { from: "/5-top-stocks-to-invest-in-2020/" },
  { from: "/learn-how-to-make-money-online-in-pakistan/" },
  { from: "/pakistani-actress-ramsha-khan-best-dramas-list/" },
  { from: "/top-10-pakistani-celebrities-instagram-you-must-follow/" },
  { from: "/ramadan-calendar-2020-pakistan-sehr-o-iftar-timetable/" },
  { from: "/10-bad-driving-habits-that-ruin-your-car-engine/" },
  { from: "/job-interview-preparation/" },
  { from: "/best-google-chrome-seo-extensions-can-help-in-seo/" },
  { from: "/toyota-yaris-price-in-pakistan-with-book-and-specs/" },
  { from: "/how-to-make-hand-sanitizer-at-home-easily/" },
  { from: "/how-corona-spread-so-easily-between-people/" },
  { from: "/pakistani-actress-yumna-zaidi-top-dramas-list/" },
  { from: "/pakistani-actress-aiman-khan-best-dramas/" },
  { from: "/coronavirus-shuts-all-pakistan-limits-flights-closes-borders-schools-and-universities/" },
  { from: "/coronavirus-symptoms-and-treatment-in-pakistan/" },
  { from: "/how-road-trip-enlighten-your-mind/" },
  { from: "/pakistans-most-loved-couples-sajal-ali-and-ahad-raza-mir-get-married-in-uae/" },
  { from: "/pakistani-actress-hania-amir-best-dramas-list/" },
  { from: "/top-10-richest-man-in-pakistan-2020/" },
  { from: "/new-upcoming-pakistani-dramas-releasing-in-2020/" },
  { from: "/top-5-highest-grossing-pakistani-movies-2019/" },
  { from: "/upcoming-pakistani-movies-announced-for-release-in-2020/" },
  { from: "/why-purple-is-a-international-womens-day-color/" },
  { from: "/excellent-ideas-how-to-celebrate-international-womens-day/" },
  { from: "/international-womens-day-history-and-celebrations/" },
  { from: "/how-to-book-tickets-for-psl-2020-in-pakistan-and-overseas/" },
  { from: "/psl-2020-schedule-venues-timetable-and-results/" },
  { from: "/top-psl-highest-paid-player-in-2020-league/" },
  { from: "/most-searched-2019-best-movies-and-tv-shows-of-all-time/" },
  { from: "/10-most-searched-person-on-google-2019-from-pakistan/" },
  { from: "/best-inverter-ac-in-pakistan/" },
  { from: "/pakistan-most-successful-twenty20-captain-all-the-time/" },
  { from: "/pakistan-most-successful-odi-captain-all-the-time/" },
  { from: "/the-greatest-pakistani-test-captains-of-all-time/" },
  { from: "/how-can-traveling-increase-your-skills/" },
  { from: "/top-10-pakistani-twenty20-bowlers-of-all-time/" },
  { from: "/top-10-pakistani-twenty20-batsmen-of-all-time/" },
  { from: "/best-pakistani-all-rounder-of-all-time/" },
  { from: "/top-10-pakistani-bowlers-of-all-time/" },
  { from: "/best-pakistani-dramas-based-on-true-stories/" },
  { from: "/6-exciting-places-to-visit-in-mexico/" },
  { from: "/top-10-pakistani-test-batsmen-of-all-time/" },
  { from: "/top-10-pakistani-cricketers-of-all-time/" },
  { from: "/top-rated-5-pakistani-movies-list/" },
  { from: "/pakistani-actor-shehzad-sheikh-best-dramas/" },
  { from: "/pakistani-actor-imran-ashraf-top-dramas/" },
  { from: "/pakistani-actress-ushna-shah-best-dramas/" },
  { from: "/pakistani-actress-iqra-aziz-top-dramas/" },
  { from: "/pakistani-actor-bilal-abbas-best-dramas/" },
  { from: "/best-places-to-visit-in-malaysia-2020-travel-guide/" },
  { from: "/pakistani-actor-humayun-saeed-top-dramas/" },
  { from: "/pakistani-actress-hira-mani-best-dramas/" },
  { from: "/pakistani-actor-sami-khan-dramas/" },
  { from: "/pakistani-actress-mawra-hocane-dramas/" },
  { from: "/top-10-womens-best-boutiques-in-islamabad/" },
  { from: "/pakistani-actor-ahad-raza-mir-best-dramas/" },
  { from: "/pakistani-actress-sania-saeed-dramas/" },
  { from: "/pakistani-actress-maya-ali-dramas/" },
  { from: "/pakistani-actress-ayesha-omer-dramas-list/" },
  { from: "/pakistani-actor-ahsan-khan-top-5-dramas/" },
  { from: "/pakistani-actor-ahmed-ali-akbar-top-dramas/" },
  { from: "/low-budget-new-cars-in-pakistan-that-are-available-now/" },
  { from: "/pakistani-actor-osman-khalid-butt-best-dramas/" },
  { from: "/pakistan-new-cheapest-car-prince-pearl-2020-review-specs-features-prices/" },
  { from: "/pakistani-actress-neelam-muneer-best-dramas/" },
  { from: "/top-10-business-in-pakistan-you-can-start-in-under-10k-budget/" },
  { from: "/pakistani-actress-alizeh-shah-top-dramas/" },
  { from: "/pakistani-actress-sanam-baloch-dramas/" },
  { from: "/pemra-banned-repeat-telecast-of-ishqiya-pyar-ke-sadqay/" },
  { from: "/pakistan-govt-decided-to-reopen-schools-from-15-september/" },
  { from: "/how-to-use-tax-asaan-app-for-tax-return/" },
  { from: "/top-10-agriculture-apps-for-your-farm-in-pakistan/" },
  { from: "/coronavirus-22-educational-institutions-closed-across-country-in-48-hours/" },
  { from: "/pakistani-actress-areeba-habib-dramas-list/" },
  { from: "/pakistani-actress-minal-khan-dramas-list/" },
  { from: "/apple-iphone-ringtone-download-complete-guide-step-by-step/" },
  { from: "/govt-decides-no-change-in-petrol-prices-for-next-15-days/" },
  { from: "/corona-virus-cases-again-increased-in-pakistan/" },
  { from: "/pakistan-has-banned-the-chinese-social-media-app-tiktok/" },
  { from: "/training-strategies-for-microsoft-az-500-exam/" },
  { from: "/landscaping-basics-7-elements-to-consider-in-landscape-design/" },
  { from: "/5-tips-to-create-an-impactful-brand-experience-for-your-audience/" },
  { from: "/real-estate-is-still-the-best-investment-today/" },
  { from: "/15-business-tips-that-will-grow-your-local-market/" },
  { from: "/top-10-wedding-planners-in-islamabad/" },
  { from: "/top-10-best-lawyers-in-lahore/" },
  { from: "/best-top-10-lawyers-in-islamabad/" },
  { from: "/pakistani-actress-ayesha-khan-dramas/" },
  { from: "/pakistani-actress-kubra-khan-top-5-dramas/" },
  { from: "/5-authenticated-ways-for-conducting-online-assessment-test/" },
  { from: "/pakistani-actor-noman-ijaz-best-dramas/" },
  { from: "/pakistani-actress-sajal-ali-top-5-dramas/" },
  { from: "/top-10-best-ranked-lawyers-in-karachi-city/" },
  { from: "/pakistani-actress-sana-javed-top-dramas/" },
  { from: "/pakistani-actor-mikaal-zulfiqar-top-dramas/" },
  { from: "/what-are-the-best-ways-to-download-songs-from-youtube/" },
  { from: "/pakistani-actress-sanam-saeed-top-5-dramas/" },
  { from: "/123movies-is-it-legal-or-safe-to-use/" },
  { from: "/pakistani-actress-saba-qamar-top-dramas-list/" },
  { from: "/three-telltale-signs-youre-in-the-wrong-career/" },
  { from: "/image-seo-marketing/" },
  { from: "/pakistani-actress-mahira-khan-best-dramas-list/" },
  { from: "/telenor-internet-packages/" },
  { from: "/top-tips-to-consider-before-starting-a-business-in-dubai/" },
  { from: "/all-about-prize-bond-results-in-pakistan/" },
  { from: "/why-is-it-important-to-use-solar-energy-in-pakistan/" },
  { from: "/a-beginners-guide-to-specialty-coffee/" },
  { from: "/pakistani-actress-ayeza-khan-top-5-dramas-list/" },
  { from: "/proton-prices-in-pakistan/" },
  { from: "/proton-saga-price-in-pakistan-with-specs-and-pictures/" },
  { from: "/5-basic-steps-in-maintaining-proper-oral-care/" },
  { from: "/changan-alsvin-price-in-pakistan-with-specs-and-pictures/" },
  { from: "/what-did-you-know-about-auction-sheet-verification/" },
  { from: "/how-to-improve-your-website-ranking-in-2021/" },
  { from: "/10-incredible-tips-for-online-shopping-in-pakistan/" },
  { from: "/famous-and-cute-pakistani-child-actors-in-dramas/" },
  { from: "/recover-from-pain-through-hijama-therapy/" },
  { from: "/how-to-buy-ladies-fashion-footwear-online-in-pakistan/" },
  { from: "/how-to-build-a-blog-on-wordpress-in-15-minutes-step-by-step-guide/" },
  { from: "/how-do-i-make-my-house-look-professionally-decorated/" },
  { from: "/how-to-start-learning-seo-in-2021/" },
  { from: "/6-challenges-of-public-speaking-and-how-to-overcome-them/" },
  { from: "/6-elements-to-consider-before-purchasing-plastic-pipes/" },
  { from: "/3-tips-for-travelers-during-covid-19/" },
  { from: "/top-21-best-hiking-shoes-for-women-in-2021/" },
  { from: "/how-to-prevent-eye-injuries-in-construction-sites/" },
  { from: "/top-10-latest-kurta-design-ideas-for-women-2021/" },
  { from: "/summer-collection-for-women-2021/" },
  { from: "/5-mistakes-to-avoid-when-preparing-for-a-move-abroad/" },
  { from: "/pmi-pmp-certbolt-certification-is-it-worth-the-hype-in-the-industry/" },
  { from: "/everything-you-need-to-know-about-the-ielts-test/" },
  { from: "/5-most-expensive-mobiles-in-pakistan/" },
  { from: "/how-businesses-are-struggling-with-covid-19-in-pakistan/" },
  { from: "/future-of-automobile-industry-in-pakistan/" },
  { from: "/health-and-fitness-travel-8-tips-to-make-the-most-of-it/" },
  { from: "/5-reasons-why-natural-attar-is-better-than-perfumes/" },
  { from: "/why-is-it-better-to-hire-a-construction-company-in-pakistan/" },
  { from: "/how-can-you-start-your-own-clothing-business/" },
  { from: "/different-variants-of-natural-liquid-hand-wash-available-online/" },
  { from: "/telemedicine-time-to-catch-up-with-the-rest-of-the-world/" },
  { from: "/how-is-pakistan-managing-to-survive-covid-19/" },
  { from: "/pakistani-actor-hamza-ali-abbasi-best-dramas-list/" },
  { from: "/pakistani-actor-adnan-siddiqui-top-drama-list/" },
  { from: "/best-pakistani-actress-of-the-year-2019/" },
  { from: "/top-rated-5-pakistani-dramas-list/" },
  { from: "/pakistani-actor-feroze-khan-top-dramas-list/" },
  { from: "/pakistani-actor-fawad-khan-dramas-list/" },
  { from: "/best-pakistani-actors-of-the-year-2019/" },
  { from: "/latest-technology-trends-in-automotive-lighting/" },
  { from: "/best-local-marketing-ideas-for-2020/" },
  { from: "/how-hina-altaf-celebrate-his-birthday/" },
  { from: "/traveling-to-pakistan-here-is-what-you-should-know/" },
  { from: "/cars-under-10-lakh-available-in-pakistan/" },
  { from: "/how-to-avoid-problems-with-online-shopping-in-pakistan-2020/" },
  { from: "/bring-amazon-ebay-and-paypal-to-pakistan/" },
  { from: "/how-to-apply-for-government-jobs-ppsc-paper-guidelines/" },
  { from: "/kia-picanto-2019-prices-in-pakistan/" },
  { from: "/top-ten-tallest-buildings-we-have-today-in-pakistan/" },
  { from: "/top-5-shopping-malls-of-lahore-worth-shopping/" },
  { from: "/top-6-e-commerce-shopping-websites-in-pakistan/" },
  { from: "/top-10-historical-places-in-pakistan-you-should-visit/" },
  { from: "/10-most-stunning-beaches-you-wont-believe-are-in-balochistan/" },
  { from: "/top-10-inspirational-photographers-to-hire-in-pakistan/" },
  { from: "/top-10-famous-fashion-designers-of-pakistan/" },
  { from: "/what-is-cryptocurrency-and-how-it-works/" },
  { from: "/top-10-wedding-event-planners-in-lahore/" },
  { from: "/delve-into-karachis-nine-most-amazing-places/" },
  { from: "/traveling-to-pakistan-a-beginners-guide-for-tourists/" },
  { from: "/how-to-use-technology-for-your-small-business-health/" },
  { from: "/tour-of-bahria-town-lahore/" },
  { from: "/how-to-plan-an-event-on-a-tight-budget/" },
  { from: "/6-eating-habits-that-can-help-you-live-longer/" },
  { from: "/no-to-polythene-bags-in-islamabad-from-independence-day/" },
  { from: "/india-dismissed-the-special-status-of-kashmir/" },
  { from: "/most-effective-ways-to-overcome-job-interviews-problem/" },
  { from: "/reasons-to-build-a-positive-work-environment-for-employees/" },
  { from: "/top-8-qualities-of-a-great-event-planner/" },
  { from: "/blocking-of-non-verified-accounts-by-pakistani-banks/" },
  { from: "/how-to-prevent-dehydration-in-this-humid-weather/" },
  { from: "/essential-tips-to-shoot-your-next-big-event/" },
  { from: "/6-insightful-ways-to-boost-events-tickets-selling-in-2019/" },
  { from: "/10-smarter-ways-to-increase-your-productivity-at-work/" },
  { from: "/how-tourism-industry-is-evolving-in-pakistan/" },
  { from: "/5-benefits-of-listing-business-services-in-pakistan/" },
  { from: "/how-to-start-a-successful-event-planning-company/" },
  { from: "/why-you-should-invest-in-event-planning-business/" },
  { from: "/how-to-travel-on-a-low-budget-in-pakistan/" },
  { from: "/why-it-is-important-to-build-an-online-reputation-of-businesses/" },
  { from: "/how-to-make-your-lifestyle-a-healthy-living/" },
  { from: "/7-phenomenal-health-benefits-of-drinking-water/" },
  { from: "/brand-new-cars-under-10-lakh-in-pakistan-in-2020/" },
  { from: "/brand-new-heavy-bike-under-3-lakh-in-pakistan/" },
  { from: "/top-notch-email-marketing-campaign-for-2020/" },
  { from: "/travel-to-dubai-from-pakistan-for-dubai-expo-2020/" },
  { from: "/eid-ul-adha-things-to-do-in-2019/" },
  { from: "/how-to-lose-belly-fat-within-30-days/" },
  { from: "/how-to-open-a-bank-account-in-pakistan/" },
  { from: "/huawei-to-set-up-their-technology-center-in-pakistan/" },
  { from: "/seven-hidden-wonders-of-pakistan/" },
  { from: "/lets-dive-deep-into-citybook-pk/" },
  { from: "/did-you-know-interesting-facts-and-figures/" },
  { from: "/how-to-apply-for-kenya-visa-from-pakistan/" },
  { from: "/huawei-is-going-to-launch-new-operating-system-like-ios-and-android/" },
  { from: "/how-to-avoid-pregnancy-home-remedies-tablets/" },
  { from: "/iran-and-us-war-how-the-war-will-start/" },
  { from: "/ppp-and-n-league-going-to-protest-after-eid-against-pti/" },
  { from: "/google-and-android-system-start-to-cut-ties-with-huawei/" },
  { from: "/10-things-you-can-do-on-this-eid-event/" },
  { from: "/how-to-apply-for-study-aboard/" },
  { from: "/how-to-make-beautiful-content-using-a-smartphone-for-vlog/" },
  { from: "/lahore-board-matric-model-papers/" },
  { from: "/top-10-universities-in-pakistan-ranked-in-2020/" },
  { from: "/top-10-insurance-companies-in-pakistan/" },
  { from: "/strange-oasis-and-lakes-in-deserts/" },
  { from: "/how-to-do-your-medi-pedi-at-home/" },
  { from: "/how-boys-fight-with-hair-fall/" },
  { from: "/mothers-day-special/" },
  { from: "/five-coolest-places-in-pakistan-for-summer-vacations/" },
  { from: "/beauty-tips-that-every-teen-should-know/" },
  { from: "/perfect-makeup-for-teens/" },
  { from: "/16-zero-calorie-foods-for-weight-loss/" },
  { from: "/home-beauty-hacks-for-summer/" },
  { from: "/mars-mission-human-settlement-on-mars-plan-and-history/" },
  { from: "/pakistan-beautiful-and-most-popular-female-politicians/" },
  { from: "/5-tips-to-get-rid-of-oily-skin-in-summer/" },
  { from: "/online-shopping-guide-for-beginners/" },
  { from: "/why-is-accounting-important-for-an-e-commerce-business/" },
  { from: "/historical-places-of-lahore/" },
  { from: "/guide-on-how-to-ace-certbolt-cisco-200-201-cbrops-exam-with-practice-tests-at-the-first-attempt/" },
  { from: "/beautify-life-with-colors/" },
  { from: "/summers-and-fashion-of-pakistan/" },
  { from: "/hair-loss-in-children-what-you-need-to-know/" },
  { from: "/heart-of-pakistan-lahore-population-area-and-famous-places/" },
  { from: "/how-to-have-the-perfect-family-day-at-a-water-park/" },
  { from: "/online-quran-learning/" },
  { from: "/5-affordable-home-decor-ideas-using-bean-bags-in-pakistan/" },
  { from: "/how-to-preserve-companys-documentations-in-pakistan/" },
  { from: "/badshahi-mosque-history-and-whats-inside-mosque/" },
  { from: "/respray-vs-full-car-wrap-complete-guide/" },
  { from: "/latest-fashion-trends-in-pakistan/" },
  { from: "/how-to-ring-into-new-years-event-party/" },
  { from: "/how-to-cure-back-pain-fast-easiest-ways-to-try-at-home/" },
  { from: "/18-things-you-can-do-to-serve-the-christian-audience-better/" },
  { from: "/setup-online-cosmetic-store-in-pakistan/" },
  { from: "/best-tips-to-treat-acne-home/" },
  { from: "/best-seo-tips/" },
  { from: "/fastest-growing-city-bahria-town-karachi/" },
  { from: "/benefits-of-records-management/" },
  { from: "/how-cable-tray-manufacturing-industry-growing-in-pakistan/" },
  { from: "/reasons-for-professional-estimating-service/" },
  { from: "/web-design-vs-seo/" },
  { from: "/capital-valley-islamabad-installment-plans/" },
  { from: "/tools-to-convert-image-to-text/" },
  { from: "/customer-feedback-tools-for-small-businesses-strong/" },
  { from: "/jinbei-car-prices/" },
  { from: "/mushtaq-car-prices/" },
  { from: "/mg-cars-prices/" },
  { from: "/zotye-car-prices/" },
  { from: "/top-10-tiktok-famous-girls-in-pakistan/" },
  { from: "/list-of-tiktok-famous-boys-all-the-time-in-pakistan/" },
  { from: "/red-velvet-pancakes-perfect-breakfast-food-for-christmas/" },
  { from: "/bollywood-model-and-actor-varun-dhawan-girlfriends/" },
  { from: "/pia-special-flights/" },
  { from: "/bollywood-actor-tiger-shroff-girlfriends-list/" },
  { from: "/bollywood-star-and-actor-ranbir-kapoor-girlfriends/" },
  { from: "/bollywood-film-star-actor-arjun-kapoor-girlfriends/" },
  { from: "/learn-how-to-install-windows-10-in-just-5-minutes/" },
  { from: "/5-fun-activities-that-teach-young-kids-stem/" },
  { from: "/upcoming-latest-computer-technology-that-will-blow-your-mind/" },
  { from: "/hide-applications-dual-apps-with-complete-privacy-and-security/" },
  { from: "/how-to-use-duplicate-apps-without-new-installations/" },
  { from: "/ringtone-downloads/" },
  { from: "/amrood-waly-ka-interview-lesson-for-lazy-persons/" },
  { from: "/eucalyptus-gum-tree-why-safeda-tree-banned-or-restrict-to-plant/" },
  { from: "/how-to-connect-mobile-bluetooth-with-suzuki-wagonr/" },
  { from: "/how-to-clear-fog-on-windscreen-suzuki-wagonr/" },
  { from: "/how-to-adjust-clock-time-in-suzuki-wagonr/" },
  { from: "/traveling-through-villages-beautiful-roads-and-greenery/" },
  { from: "/best-beauty-tips-for-face-that-make-you-look-gorgeous/" },
  { from: "/10-effective-ways-to-get-your-dream-job-faster/" },
  { from: "/why-ants-walk-in-a-straight-line/" },
  { from: "/3-major-elements-of-a-successful-online-short-courses/" },
  { from: "/anniversary-sms/" },
  { from: "/easy-and-simple-cookie-recipe-to-make-at-home/" },
  { from: "/how-and-where-french-fries-were-invented/" },
  { from: "/5-high-value-investments-entrepreneurs-need-to-make-in-themselves/" },
  { from: "/united-bike-prices-in-pakistan/" },
  { from: "/zxmco-bike-prices-in-pakistan/" },
  { from: "/zontes-bike-prices-in-pakistan/" },
  { from: "/zongshen-bike-prices-in-pakistan/" },
  { from: "/yamaha-bike-prices-in-pakistan/" },
  { from: "/vespa-bike-prices-in-pakistan/" },
  { from: "/unique-bike-prices-in-pakistan/" },
  { from: "/u-m-bike-prices-in-pakistan/" },
  { from: "/how-to-japanese-auction-sheet-verification-online/" },
  { from: "/natural-remedies-for-stress-and-anxiety-during-pregnancy/" },
  { from: "/suzuki-bike-prices-in-pakistan/" },
  { from: "/super-power-bike-prices-in-pakistan/" },
  { from: "/road-prince-bike-prices-in-pakistan/" },
  { from: "/ravi-bike-prices-in-pakistan/" },
  { from: "/power-bike-prices-in-pakistan/" },
  { from: "/pak-hero-bike-prices-in-pakistan/" },
  { from: "/overdrive-bike-prices-in-pakistan/" },
  { from: "/osaka-bike-prices-in-pakistan/" },
  { from: "/metro-bike-prices-in-pakistan/" },
  { from: "/lifan-bike-prices-in-pakistan/" },
  { from: "/keeway-bike-prices-in-pakistan/" },
  { from: "/honda-bike-prices-in-pakistan/" },
  { from: "/hi-speed-bike-prices-in-pakistan/" },
  { from: "/hero-bike-prices-in-pakistan/" },
  { from: "/ghani-bike-prices-in-pakistan/" },
  { from: "/fuego-bike-prices-in-pakistan/" },
  { from: "/derbi-bike-prices-in-pakistan/" },
  { from: "/bml-bike-prices-in-pakistan/" },
  { from: "/chines-bike-prices-in-pakistan/" },
  { from: "/crown-bike-prices-in-pakistan/" },
  { from: "/benelli-bike-prices-in-pakistan/" },
  { from: "/aprilia-bike-prices-in-pakistan/" },
  { from: "/10-easy-and-quick-simple-pasta-recipes-to-make-at-home/" },
  { from: "/all-natural-homemade-face-moisturizer-easy-recipe/" },
  { from: "/digital-marketing-vs-traditional-marketing-whats-the-best-among-both/" },
  { from: "/how-to-make-carrot-cake-with-pineapple-at-home/" },
  { from: "/visit-the-natural-wonders-near-toronto-with-cheap-flights/" },
  { from: "/easy-and-delicious-pakistani-chicken-biryani-recipe/" },
  { from: "/10-travel-accessories-for-women-every-woman-must-have/" },
  { from: "/how-to-use-100-online-therapy/" },
  { from: "/psychotherapy-during-a-pandemic/" },
  { from: "/how-do-hormones-work/" },
  { from: "/15-travel-accessories-for-men-every-traveller-must-have/" },
  { from: "/india-pakistan-cricket-match-history-and-results/" },
  { from: "/list-of-live-pakistan-sports-channel/" },
  { from: "/mercedes-benz-car-prices-in-pakistan/" },
  { from: "/complete-namaz-ka-tarika-in-urdu/" },
  { from: "/pakistan-dialing-codes-city-code-of-pakistan/" },
  { from: "/government-of-pakistan-increase-petrol-prices-again/" },
  { from: "/top-10-most-famous-people-in-pakistan/" },
  { from: "/how-to-make-vanilla-cupcakes-easy-recipe/" },
  { from: "/6-kalma-of-islam-with-urdu-translate/" },
  { from: "/what-is-doping-in-sports-history-and-doping-epidemic/" },
  { from: "/9-helpful-strategies-for-restaurants-during-economic-slowdowns/" },
  { from: "/best-dua-after-fajar-namaz/" },
  { from: "/trump-tulsa-rally-is-dangerous-move-in-coronavirus/" },
  { from: "/trump-fires-top-us-prosecutor-geoffrey-berman-who-refused-to-quit/" },
  { from: "/pakistan-upcoming-cricket-series-till-2023-world-cup/" },
  { from: "/top-10-most-beautiful-places-to-visit-in-islamabad/" },
  { from: "/national-and-international-sports-in-pakistan/" },
  { from: "/top-10-most-beautiful-places-to-visit-in-lahore/" },
  { from: "/how-to-make-your-own-makeup-at-home/" },
  { from: "/easy-and-delicious-pakistani-chicken-recipes/" },
  { from: "/pakistani-celebrities-diet-plan-for-weight-loss/" },
  { from: "/easy-and-quick-pakistani-food-recipes/" },
  { from: "/10-natural-homemade-beauty-products-that-you-must-try/" },
  { from: "/literacy-rate-in-pakistan-all-you-need-to-know-about/" },
  { from: "/amazing-facts-about-the-blue-mosque-in-turkey/" },
  { from: "/types-of-education-in-pakistan/" },
  { from: "/online-shopping-in-pakistan-with-free-home-delivery/" },
  { from: "/traditional-dresses-and-national-dress-of-pakistan/" },
  { from: "/10-homemade-beauty-tips-for-girls-that-must-works/" },
  { from: "/explore-pakistani-culture-with-traditions-and-people/" },
  { from: "/find-and-apply-for-best-jobs-in-pakistan/" },
  { from: "/best-toddler-breakfast-ideas-easy-and-healthy/" },
  { from: "/education-in-karachi-all-you-need-to-know-about/" },
  { from: "/must-try-skin-care-tips-for-all-seasons/" },
  { from: "/top-10-best-food-in-pakistan/" },
  { from: "/latest-mobiles-in-pakistan-with-prices/" },
  { from: "/sad-ringtones-download", to: "/ringtones/sad-ringtones-download_2" },
  { from: "/most-downloaded-ringtones-for-mobile", to: "/ringtones/most-downloaded-ringotones-for-mobile_3" },
  { from: "/romantic-ringtones", to: "/ringtones/romantic-ringtones_4" },
  { from: "/latest-ringtones", to: "/ringtones/latest-ringtones-download_5" },
  { from: "/english-ringtones", to: "/ringtones/english-ringtones-download_6" },
  { from: "/sms-ringtones", to: "/ringtones/sms-ringtones-download_7" },
  { from: "/instrumental-ringtones", to: "/ringtones/instrumental-ringtones_8" },
  { from: "/romantic-ringtones", to: "/ringtones/romantic-ringtones_9" },
  { from: "/popular-ringtones", to: "/ringtones/popular-ringtones_10" },
  { from: "/bollywood-ringtones", to: "/ringtones/bollywood-ringtones_11" },

  { from: "/listing/khalid-electric-store-2", to: "/listing/electrician/khalid-electric-store-3411" },
  { from: "/listing/azad-plumbing-electro-mechanical-services", to: "/listing/plumbers/azad-plumbing-&amp;-electro-mechanical-services-3496" },
  { from: "/listing/easy-solution-lahore", to: "/listing/plumbers/easy-solution-lahore-3502" },
  { from: "/listing/fixthis-pk", to: "/listing/plumbers/fixthis.pk-3509" },
  { from: "/listing/zubair-sanitary-services", to: "/listing/plumbers/zubair-sanitary-services-3514" },
  { from: "/listing/fixit-services", to: "/listing/electrician/fixit-services-3522" },
  { from: "/listing/hamza-sanitary-store", to: "/listing/plumbers/hamza-sanitary-store-3523" },
  { from: "/listing/easy-solution-lahore-2", to: "/listing/electrician/easy-solution-lahore-3530" },
  { from: "/listing/azad-boring-house", to: "/listing/plumbers/azad-boring-house-3535" },
  { from: "/listing/ammar-sanitary-store-plumber", to: "/listing/plumbers/ammar-sanitary-store-(plumber)-3543" },
  { from: "/listing/shafiq-electrics-mobile-repairing-center-electrician", to: "/listing/electrician/shafiq-electrics-&-mobile-repairing-center-(electrician)-3544" },
  { from: "/listing/mian-electric-store-electrician", to: "/listing/electrician/mian-electric-store-(electrician)-3549" },
  { from: "/listing/merit-cables-electrician", to: "/listing/electrician/merit-cables-(electrician)-3557" },
  { from: "/listing/fixit-services-plumber", to: "/listing/plumbers/fixit-services-(plumber)-3562" },
  { from: "/listing/polyphase-piping-system-plumber", to: "/listing/plumbers/polyphase-piping-system-(plumber)-3567" },
  { from: "/listing/fine-traders-electric-store-electrician", to: "/listing/electrician/fine-traders-electric-store-(electrician)-3568" },
  { from: "/listing/maf-plastics-plumber", to: "/listing/plumbers/maf-plastics-(plumber)-3576" },
  { from: "/listing/zaheer-electric-works-electrician", to: "/listing/electrician/zaheer-electric-works-(electrician)-3577" },
  { from: "/listing/al-faisal-electric-electrician", to: "/listing/electrician/al-faisal-electric-(electrician)-3586" },
  { from: "/listing/arshad-electric-repairing-centre-electrician", to: "/listing/electrician/arshad-electric-&-repairing-centre-(electrician)-3592" },
  { from: "/listing/residential-plumber", to: "/listing/plumbers/residential-plumber-3595" },
  { from: "/listing/plumber-homes-and-offices", to: "/listing/plumbers/plumber-homes-and-offices-3604" },
  { from: "/listing/water-sui-gase-sevraj-plumber", to: "/listing/plumbers/water-&amp;-sui-gase-&amp;-sevraj-(plumber)-3611" },
  { from: "/listing/technosolution-engineering-electrician", to: "/listing/electrician/technosolution-engineering-(electrician)-3612" },
  { from: "/listing/al-habib-electric-store-electrician", to: "/listing/electrician/al-habib-electric-store-(electrician)-3620" },
  { from: "/listing/plumber-service-at-your-door-step", to: "/listing/plumbers/plumber-service-at-your-door-step-3623" },
  { from: "/listing/h-a-wood-works-carpenter", to: "/listing/carpenter/h.a-wood-works-(carpenter)-3631" },
  { from: "/listing/superman-plumber", to: "/listing/plumbers/superman-(plumber)-3632" },
  { from: "/listing/ali-cooling-electrical-home-services-electrician", to: "/listing/electrician/ali-cooling-&-electrical-home-services-(electrician)-3637" },
  { from: "/listing/hashmi-electronics-electric-works-electrician", to: "/listing/electrician/hashmi-electronics-&-electric-works-(electrician)-3642" },
  { from: "/listing/liberty-cables-electrician", to: "/listing/electrician/liberty-cables-(electrician)-3647" },
  { from: "/listing/mian-muhammad-zahid-bashir-electrician", to: "/listing/electrician/mian-muhammad-zahid-bashir-(electrician)-3653" },
  { from: "/listing/bhai-bhai-wood-house-carpenter", to: "/listing/carpenter/bhai-bhai-wood-house-(carpenter)-3661" },
  { from: "/listing/nawaz-carpenter-shop", to: "/listing/carpenter/nawaz-carpenter-shop-3665" },
  { from: "/listing/khalil-wood-works-carpenter", to: "/listing/carpenter/khalil-wood-works-(carpenter)-3669" },
  { from: "/listing/waseem-carpenter", to: "/listing/carpenter/waseem-carpenter-3673" },
  { from: "/listing/najam-woodwork-company-carpenter", to: "/listing/carpenter/najam-woodwork-company-(carpenter)-3677" },
  { from: "/listing/rug-sense-carpenter", to: "/listing/carpenter/rug-sense-(carpenter)-3681" },
  { from: "/listing/bashir-ahmed-wood-works-carpenter", to: "/listing/carpenter/bashir-ahmed-wood-works-(carpenter)-3685" },
  { from: "/listing/arsalan-wood-works-carpenter", to: "/listing/carpenter/arsalan-wood-works-(carpenter)-3689" },
  { from: "/listing/hayats-furniture-messrs-sharif-hayat-carpenter", to: "/listing/carpenter/hayat's-furniture,-messrs-sharif-hayat-(carpenter)-3693" },
  { from: "/listing/carpenter-wood-care", to: "/listing/carpenter/carpenter-wood-care-3697" },
  { from: "/listing/naushahi-carpenter", to: "/listing/carpenter/naushahi-carpenter-3701" },
  { from: "/listing/structure-health-fitness-gulberg-lahore", to: "/listing/gym/structure-health-&amp;-fitness-gulberg-lahore-3749" },
  { from: "/listing/eleven-gym-lahore", to: "/listing/gym/eleven-gym-lahore-3754" },
  { from: "/listing/structure-health-fitness-dha-lahore", to: "/listing/gym/structure-health-&amp;-fitness-dha-lahore-3760" },
  { from: "/listing/indus-yoga-health-club-for-ladies-lahore", to: "/listing/gym/indus-yoga-health-club-for-ladies-lahore-3765" },
  { from: "/listing/pakistan-gym-club", to: "/listing/gym/pakistan-gym-club-3771" },
  { from: "/listing/bodyzone-gym-liberty", to: "/listing/gym/bodyzone-gym-liberty-3775" },
  { from: "/listing/bodytalk-gym-garden-town", to: "/listing/gym/bodytalk-gym-garden-town-3780" },
  { from: "/listing/yahya-fitness-center", to: "/listing/gym/yahya-fitness-center-3785" },
  { from: "/listing/malik-gym", to: "/listing/gym/malik-gym-3790" },
  { from: "/listing/bodytalk-fitness-gym-suppliers", to: "/listing/gym/bodytalk-fitness-&-gym-suppliers-3794" },
  { from: "/listing/eleven-gym-lahore-2", to: "/listing/gym/eleven-gym-lahore-3798" },
  { from: "/listing/bodytalk-gym-dha-lahore", to: "/listing/gym/bodytalk-gym-dha-lahore-3802" },
  { from: "/listing/pumphouse-gym", to: "/listing/gym/pumphouse-gym-3806" },
  { from: "/listing/body-strong-fitness-club", to: "/listing/gym/body-strong-fitness-club-3810" },
  { from: "/listing/sams-skin-analysis-health-beauty-medical-spa-gym", to: "/listing/gym/sams-(skin-analysis-health-&-beauty-medical-spa-&-gym)-3814" },
  { from: "/listing/abs-wellness-club", to: "/listing/gym/abs-wellness-club-3818" },
  { from: "/listing/imtiaz-fitness", to: "/listing/gym/imtiaz-fitness-3822" },
  { from: "/listing/azhar-fitness-center", to: "/listing/gym/azhar-fitness-center-3827" },
  { from: "/listing/shapes-dha", to: "/listing/gym/shapes-dha-3831" },
  { from: "/listing/home-tutor-provider", to: "/listing/tutoring/home-tutor-provider-3835" },
  { from: "/listing/pakistantutor", to: "/listing/tutoring/pakistantutor-3839" },
  { from: "/listing/java-hts-tutors", to: "/listing/tutoring/java-hts-tutors-3843" },
  { from: "/listing/home-tutor", to: "/listing/tutoring/home-tutor-3847" },
  { from: "/listing/tutor-at-home", to: "/listing/tutoring/tutor-at-home-3851" },
  { from: "/listing/mytutor", to: "/listing/tutoring/mytutor-3855" },
  { from: "/listing/home-tutor-in-lahore-tutor-at-home", to: "/listing/tutoring/home-tutor-in-lahore-|-tutor-at-home-3859" },
  { from: "/listing/classic-home-tuition", to: "/listing/tutoring/classic-home-tuition-3871" },
  { from: "/listing/lahore-beacon-tutors-academy", to: "/listing/tutoring/lahore-beacon-tutors-academy-3875" },
  { from: "/listing/lahore-tutors", to: "/listing/tutoring/lahore-tutors-3879" },
  { from: "/listing/home-tutor-provider-in-lahore", to: "/listing/tutoring/home-tutor-provider-in-lahore-3883" },
  { from: "/listing/get-smart", to: "/listing/gym/get-smart-3913" },
  { from: "/listing/dream-body-gym", to: "/listing/gym/dream-body-gym-3918" },
  { from: "/listing/fitness-cult", to: "/listing/gym/fitness-cult-3923" },
  { from: "/listing/evovle-gym", to: "/listing/gym/evovle-gym-3928" },
  { from: "/listing/fight-club-fitness-training", to: "/listing/gym/fight-club-fitness-&amp;-training-3933" },
  { from: "/listing/shapeup-gym", to: "/listing/gym/shapeup-gym-3938" },
  { from: "/listing/body-shaper-gym", to: "/listing/gym/body-shaper-gym-3943" },
  { from: "/listing/i-club", to: "/listing/gym/i-club-3948" },
  { from: "/listing/times-fitness-gym", to: "/listing/gym/times-fitness-gym-3953" },
  { from: "/listing/synergy-fitness-mma", to: "/listing/gym/synergy-fitness-&amp;-mma-3958" },
  { from: "/listing/fitmark-gym-and-spa", to: "/listing/gym/fitmark-gym-and-spa-3963" },
  { from: "/listing/saf-rent-a-car", to: "/listing/car-rental/saf-rent-a-car-4007" },
  { from: "/listing/lahore-rent-a-car", to: "/listing/car-rental/lahore-rent-a-car-4009" },
  { from: "/listing/car-rental-services", to: "/listing/car-rental/car-rental-services-4011" },
  { from: "/listing/habib-construction-services", to: "/listing/construction-services/habib-construction-services-4057" },
  { from: "/listing/abdullah-rent-a-car", to: "/listing/car-rental/abdullah-rent-a-car-4060" },
  { from: "/listing/bismillah-rent-a-car", to: "/listing/car-rental/bismillah-rent-a-car-4063" },
  { from: "/listing/uk-rent-a-car", to: "/listing/car-rental/uk-rent-a-car-4195" },
  { from: "/listing/speed-rent-a-car", to: "/listing/car-rental/speed-rent-a-car-4198" },
  { from: "/listing/master-services-rent-a-car", to: "/listing/car-rental/master-services-rent-a-car-4200" },
  { from: "/listing/usman-rent-a-car", to: "/listing/car-rental/usman-rent-a-car-4202" },
  { from: "/listing/travel-fast-visa-services", to: "/listing/visa-agencies/travel-fast-(visa-services)-4206" },
  { from: "/listing/atlas-consultants-pvt-ltd-visa-services", to: "/listing/visa-agencies/atlas-consultants-pvt.-ltd.-(visa-services)-4208" },
  { from: "/listing/umrah-service-visa-services", to: "/listing/visa-agencies/umrah-service-(visa-services)-4210" },
  { from: "/listing/travel-channel-visa-services", to: "/listing/visa-agencies/travel-channel-(visa-services)-4212" },
  { from: "/listing/bluesky-consultancy-study-in-australia-uk-europe-visa-services", to: "/listing/visa-agencies/bluesky-consultancy-study-in-australia,-uk,-europe-(visa-services)-4214" },
  { from: "/listing/university-of-engineering-and-technology-lahore", to: "/listing/universities/university-of-engineering-and-technology,-lahore-4216" },
  { from: "/listing/university-of-the-punjab", to: "/listing/universities/university-of-the-punjab-4269" },
  { from: "/listing/the-university-of-lahore", to: "/listing/universities/the-university-of-lahore-4309" },
  { from: "/listing/university-of-health-sciences", to: "/listing/universities/university-of-health-sciences-4315" },
  { from: "/listing/lahore-university-of-management-sciences", to: "/listing/universities/lahore-university-of-management-sciences-4318" },
  { from: "/listing/government-college-university", to: "/listing/universities/government-college-university-4321" },
  { from: "/listing/university-of-management-technology", to: "/listing/universities/university-of-management-&-technology-4324" },
  { from: "/listing/university-of-central-punjab", to: "/listing/universities/university-of-central-punjab-4328" },
  { from: "/listing/forman-christian-college-a-chartered-university", to: "/listing/universities/forman-christian-college-(a-chartered-university)-4331" },
  { from: "/listing/university-of-south-asia", to: "/listing/universities/university-of-south-asia-4334" },
  { from: "/listing/national-college-of-arts", to: "/listing/universities/national-college-of-arts-4338" },
  { from: "/listing/king-edward-medical-university", to: "/listing/universities/king-edward-medical-university-4343" },
  { from: "/listing/university-of-veterinary-and-animal-sciences", to: "/listing/universities/university-of-veterinary-and-animal-sciences-4346" },
  { from: "/listing/allama-iqbal-medical-college", to: "/listing/universities/allama-iqbal-medical-college-4349" },
  { from: "/listing/beaconhouse-national-university-tarogil-campus", to: "/listing/universities/beaconhouse-national-university-tarogil-campus-4352" },
  { from: "/listing/university-of-education", to: "/listing/universities/university-of-education-4356" },
  { from: "/listing/lahore-college-for-women-university", to: "/listing/universities/lahore-college-for-women-university-4359" },
  { from: "/listing/virtual-university-of-pakistan", to: "/listing/universities/virtual-university-of-pakistan-4362" },
  { from: "/listing/kinnaird-college-for-women-university", to: "/listing/universities/kinnaird-college-for-women-university-4372" },
  { from: "/listing/hajvery-university", to: "/listing/universities/hajvery-university-4378" },
  { from: "/listing/minhaj-university-lahore", to: "/listing/universities/minhaj-university-lahore-4383" },
  { from: "/listing/lahore-garrison-university", to: "/listing/universities/lahore-garrison-university-4394" },
  { from: "/listing/information-technology-university", to: "/listing/universities/information-technology-university-4398" },
  { from: "/listing/lahore-leads-university", to: "/listing/universities/lahore-leads-university-4401" },
  { from: "/listing/pakistan-institute-of-fashion-and-design", to: "/listing/universities/pakistan-institute-of-fashion-and-design-4404" },
  { from: "/listing/lahore-medical-dental-college", to: "/listing/universities/lahore-medical-&-dental-college-4407" },
  { from: "/listing/shalamar-medical-dental-college", to: "/listing/universities/shalamar-medical-&-dental-college-4410" },
  { from: "/listing/times-consultant-study-visa", to: "/listing/visa-agencies/times-consultant-(study-visa)-4413" },
  { from: "/listing/gerrys-international-visa-services", to: "/listing/visa-agencies/gerry's-international-(visa-services)-4417" },
  { from: "/listing/liverpool-visa-consultants", to: "/listing/visa-agencies/liverpool-visa-consultants-4423" },
  { from: "/listing/stallion-immigration-pvt-ltd", to: "/listing/visa-agencies/stallion-immigration-(pvt)-ltd-4426" },
  { from: "/listing/kind-travel-hajj-umrah-visa-services", to: "/listing/visa-agencies/kind-travel-&-hajj-umrah-visa-services-4429" },
  { from: "/listing/aura-solutions-visa-services", to: "/listing/visa-agencies/aura-solutions-(visa-services)-4431" },
  { from: "/listing/mehmood-carpenter", to: "/listing/carpenter/mehmood-carpenter-in-faisalabad-4434" },
  { from: "/listing/khalqi-wood-works-carpenter", to: "/listing/carpenter/khalqi-wood-works-(carpenter)-4437" },
  { from: "/listing/wood-works-carpenter", to: "/listing/carpenter/wood-works-(carpenter)-4440" },
  { from: "/listing/riphah-international-university-lahore", to: "/listing/universities/riphah-international-university-lahore-4477" },
  { from: "/listing/national-university-of-computer-and-emerging-sciences-lahore", to: "/listing/universities/national-university-of-computer-and-emerging-sciences,-lahore-4480" },
  { from: "/listing/fmh-college-of-medicine-and-dentistry", to: "/listing/universities/fmh-college-of-medicine-and-dentistry-4483" },
  { from: "/listing/jamia-ashrafia", to: "/listing/universities/jamia-ashrafia-4486" },
  { from: "/listing/services-institute-of-medical-sciences", to: "/listing/universities/services-institute-of-medical-sciences-4489" },
  { from: "/listing/allah-tawakkal-rent-a-car-service", to: "/listing/car-rental/allah-tawakkal-rent-a-car-service-4495" },
  { from: "/listing/central-park-medical-college", to: "/listing/universities/central-park-medical-college-4497" },
  { from: "/listing/avicenna-medical-college", to: "/listing/universities/avicenna-medical-college-4500" },
  { from: "/listing/azra-naheed-medical-college", to: "/listing/universities/azra-naheed-medical-college-4520" },
  { from: "/listing/continental-medical-college", to: "/listing/universities/continental-medical-college-4526" },
  { from: "/listing/pakistan-college-of-law", to: "/listing/universities/pakistan-college-of-law-4536" },
  { from: "/listing/al-madina-rent-a-car-2", to: "/listing/car-rental/al-madina-rent-a-car-4569" },
  { from: "/listing/nn-printing-services", to: "/listing/printing/nn-printing-services-4576" },
  { from: "/listing/haseeb-printers", to: "/listing/printing/haseeb-printers-4579" },
  { from: "/listing/al-madina-printers", to: "/listing/printing/al-madina-printers-4582" },
  { from: "/listing/sheikh-rent-a-car", to: "/listing/car-rental/sheikh-rent-a-car-4585" },
  { from: "/listing/faisalabad-rent-a-car", to: "/listing/car-rental/faisalabad-rent-a-car-4587" },
  { from: "/listing/jutt-rent-a-car", to: "/listing/car-rental/jutt-rent-a-car-4590" },
  { from: "/listing/al-fateh-rent-a-car", to: "/listing/car-rental/al-fateh-rent-a-car-4592" },
  { from: "/listing/bismillah-wood-works-carpenter", to: "/listing/carpenter/bismillah-wood-works-(carpenter)-4596" },
  { from: "/listing/mirza-maqbool-sons-wood-works-carpenter", to: "/listing/carpenter/mirza-maqbool-&-sons-wood-works-(carpenter)-4598" },
  { from: "/listing/qadri-wood-works-carpenter", to: "/listing/carpenter/qadri-wood-works-(carpenter)-4602" },
  { from: "/listing/kaleem-wood-works-carpenter", to: "/listing/carpenter/kaleem-wood-works-(carpenter)-4604" },
  { from: "/listing/wedding-cards-in-lahore", to: "/listing/printing/wedding-cards-in-lahore-4607" },
  { from: "/listing/click2print", to: "/listing/printing/click2print-4609" },
  { from: "/listing/print-house", to: "/listing/printing/print-house-4612" },
  { from: "/listing/mugs-printing", to: "/listing/printing/mugs-printing-4615" },
  { from: "/listing/dotprint-pvt-ltd", to: "/listing/printing/dotprint-pvt-ltd-4617" },
  { from: "/listing/online-printing-press-in-lahore-pakistan", to: "/listing/printing/online-printing-press-in-lahore-pakistan-4620" },
  { from: "/listing/rn-digital-print", to: "/listing/printing/rn-digital-print-4671" },
  { from: "/listing/galaxy-printers", to: "/listing/printing/galaxy-printers-4674" },
  { from: "/listing/awan-printing-and-packaging-services", to: "/listing/printing/awan-printing-and-packaging-services-4677" },
  { from: "/listing/millat-digital-printing", to: "/listing/printing/millat-digital-printing-4680" },
  { from: "/listing/laser-cut-lahore-pakistan", to: "/listing/printing/laser-cut-lahore-pakistan-4683" },
  { from: "/listing/wedding-card-experts", to: "/listing/printing/wedding-card-experts-4685" },
  { from: "/listing/nisar-art-press-pvt-limited", to: "/listing/printing/nisar-art-press-(pvt)-limited-4688" },
  { from: "/listing/soleja-brothers", to: "/listing/printing/soleja-brothers-4691" },
  { from: "/listing/yadgar-wedding-cards", to: "/listing/printing/yadgar-wedding-cards-4694" },
  { from: "/listing/zafar-art-printers", to: "/listing/printing/zafar-art-printers-4697" },
  { from: "/listing/cartech-auto-electronics-electrician", to: "/listing/electrician/cartech-auto-electronics-(electrician)-4702" },
  { from: "/listing/al-makkah-car-ac-electrician", to: "/listing/electrician/al-makkah-car-ac-&-electrician-4704" },
  { from: "/listing/ashfaq-auto-electronics-workshop-electrician", to: "/listing/electrician/ashfaq-auto-electronics-workshop-(electrician)-4706" },
  { from: "/listing/bismillah-auto-electrician-spare-parts", to: "/listing/electrician/bismillah-auto-electrician-&-spare-parts-4708" },
  { from: "/listing/pak-electronics-shop-electrician", to: "/listing/electrician/pak-electronics-shop-(electrician)-4710" },
  { from: "/listing/shahid-auto-electrician", to: "/listing/electrician/shahid-auto-electrician-4712" },
  { from: "/listing/the-zainab-engineering-workshop-electrician", to: "/listing/electrician/the-zainab-engineering-workshop-(electrician)-4714" },
  { from: "/listing/mashallah-auto-electrician-car-ac-service", to: "/listing/electrician/mashallah-auto-electrician-&-car-ac-service-4715" },
  { from: "/listing/khalid-electrician-car-ac-service", to: "/listing/electrician/khalid-electrician-&-car-ac-service-4720" },
  { from: "/listing/instaprint", to: "/listing/printing/instaprint-4726" },
  { from: "/listing/mohsin-shaheen-construction-company", to: "/listing/construction-services/mohsin-shaheen-construction-company-4729" },
  { from: "/listing/orange-line-hcs-site-office", to: "/listing/construction-services/orange-line-hcs-site-office-4732" },
  { from: "/listing/airrs-associates-pvt-ltd", to: "/listing/construction-services/airrs-associates-(pvt)-ltd-4735" },
  { from: "/listing/builders-associates-pvt-ltd", to: "/listing/construction-services/builders-associates-pvt-ltd-4738" },
  { from: "/listing/reco-pre-engineered-steel-buildings", to: "/listing/construction-services/reco-pre-engineered-steel-buildings-4741" },
  { from: "/listing/mukhtar-sons", to: "/listing/construction-services/mukhtar-sons-4746" },
  { from: "/listing/flowtronix-pvt-limited", to: "/listing/construction-services/flowtronix-(pvt)-limited-4749" },
  { from: "/listing/nishan-engineers", to: "/listing/construction-services/nishan-engineers-4752" },
  { from: "/listing/punjab-group-of-constructions", to: "/listing/construction-services/punjab-group-of-constructions-4755" },
  { from: "/listing/ittefaq-group", to: "/listing/construction-services/ittefaq-group-4758" },
  { from: "/listing/farooqs-home-construction-building-solutions", to: "/listing/construction-services/farooqs-home-construction-building-solutions-4761" },
  { from: "/listing/bajwa-sahib-estate-advisor", to: "/listing/construction-services/bajwa-sahib-estate-advisor-4764" },
  { from: "/listing/dascon-construction-company", to: "/listing/construction-services/dascon-construction-company-4767" },
  { from: "/listing/izhar-group-of-companies", to: "/listing/construction-services/izhar-group-of-companies-4770" },
  { from: "/listing/ahmed-construction-pvt-ltd", to: "/listing/construction-services/ahmed-construction-pvt-ltd-4808" },
  { from: "/listing/maaher-builders", to: "/listing/construction-services/maaher-builders-4811" },
  { from: "/listing/miran-builders", to: "/listing/construction-services/miran-builders-4814" },
  { from: "/listing/mas-group", to: "/listing/construction-services/mas-group-4817" },
  { from: "/listing/winds-international", to: "/listing/construction-services/winds-international-4820" },
  { from: "/listing/shaikh-zayed-hospital", to: "/listing/hospitals/shaikh-zayed-hospital-4876" },
  { from: "/listing/ittefaq-hospital", to: "/listing/hospitals/ittefaq-hospital-4879" },
  { from: "/listing/mid-city-hospital", to: "/listing/hospitals/mid-city-hospital-4883" },
  { from: "/listing/gulab-devi-chest-hospital", to: "/listing/hospitals/gulab-devi-chest-hospital-4886" },
  { from: "/listing/surgimed-hospital", to: "/listing/hospitals/surgimed-hospital-4889" },
  { from: "/listing/punjab-social-security-hospital", to: "/listing/hospitals/punjab-social-security-hospital-4893" },
  { from: "/listing/the-childrens-hospital-lahore", to: "/listing/hospitals/the-children's-hospital-|-lahore-4896" },
  { from: "/listing/gul-construction-company", to: "/listing/construction-services/gul-construction-company-4897" },
  { from: "/listing/elcon-associates-faisalabad", to: "/listing/construction-services/elcon-associates-faisalabad-4902" },
  { from: "/listing/adam-ismail-construction-company", to: "/listing/construction-services/adam-ismail-construction-company-4907" },
  { from: "/listing/fauji-foundation-hospital-lahore", to: "/listing/hospitals/fauji-foundation-hospital-lahore-4909" },
  { from: "/listing/national-hospital-medical-center", to: "/listing/hospitals/national-hospital-&-medical-center-4915" },
  { from: "/listing/doctors-hospital-medical-center", to: "/listing/hospitals/doctors-hospital-&-medical-center-4921" },
  { from: "/listing/zainab-associates", to: "/listing/construction-services/zainab-associates-4963" },
  { from: "/listing/al-hadi-associates-and-builders", to: "/listing/construction-services/al-hadi-associates-and-builders-4965" },
  { from: "/listing/younis-adnan-construction", to: "/listing/construction-services/younis-adnan-construction-4968" },
  { from: "/listing/sadiq-gill-pakistan-ltd", to: "/listing/construction-services/sadiq-gill-pakistan-ltd-4970" },
  { from: "/listing/yukon-builders-developers", to: "/listing/construction-services/yukon-builders-&-developers-4975" },
  { from: "/listing/lyallpur-concrete-pvt-ltd", to: "/listing/construction-services/lyallpur-concrete-pvt-ltd-4977" },
  { from: "/listing/haxn-rent-a-car", to: "/listing/car-rental/haxn-rent-a-car-4988" },
  { from: "/listing/pakistan-rent-a-car", to: "/listing/car-rental/pakistan-rent-a-car-4991" },
  { from: "/listing/waris-ali-mughal-carpenter", to: "/listing/carpenter/waris-ali-mughal-carpenter-4994" },
  { from: "/listing/bhatti-wood-works", to: "/listing/carpenter/bhatti-wood-works-4997" },
  { from: "/listing/nicon-group-of-colleges", to: "/listing/colleges/nicon-group-of-colleges-5001" },
  { from: "/listing/falcon-center-point", to: "/listing/construction-services/falcon-center-point-5004" },
  { from: "/listing/khanial-homes", to: "/listing/construction-services/khanial-homes-5007" },
  { from: "/listing/the-best-group", to: "/listing/construction-services/the-best-group-5010" },
  { from: "/listing/haryali-store", to: "/listing/restaurants/haryali-store-5019" },
  { from: "/listing/hadiq-foods-fast-food-pizza", to: "/listing/restaurants/hadiq-foods---fast-food-&-pizza-5022" },
  { from: "/listing/kfc", to: "/listing/restaurants/kfc-5025" },
  { from: "/listing/malik-rent-a-car", to: "/listing/car-rental/malik-rent-a-car-ahmadpur-east-5043" },
  { from: "/listing/vocational-training-institute-ahmed-pur-east", to: "/listing/colleges/vocational-training-institute-ahmed-pur-east-5045" },
  { from: "/listing/smarts-health-fitness-club", to: "/listing/gym/smarts-health-&-fitness-club-faisalabad-5055" },
  { from: "/listing/al-fahd-spa-fitness-club", to: "/listing/gym/al-fahd-spa-&-fitness-faisalabad-5060" },
  { from: "/listing/golds-gym-faisalabad", to: "/listing/gym/gold's-gym-faisalabad-5064" },
  { from: "/listing/muscles-gym-and-fitness-and-beauty-aesthetic-center", to: "/listing/gym/muscles-gym-and-fitness-and-beauty-aesthetic-center-5131" },
  { from: "/listing/lions-gold-gym-fitness-centre-faisalabad", to: "/listing/gym/lions-gold-gym-&-fitness-centre-faisalabad-5134" },
  { from: "/listing/model-town-gym-faisalabad", to: "/listing/gym/model-town-gym-faisalabad-5138" },
  { from: "/listing/ladies-gym-faisalabad", to: "/listing/gym/ladies-gym-faisalabad-5141" },
  { from: "/listing/lyallpur-gym-and-fitness-center-faisalabad", to: "/listing/gym/lyallpur-gym-and-fitness-center-faisalabad-5145" },
  { from: "/listing/world-gym-faisalabad", to: "/listing/gym/world-gym-faisalabad-5148" },
  { from: "/listing/mian-gym-faisalabad", to: "/listing/gym/mian-gym-faisalabad-5153" },
  { from: "/listing/exellence-electric-lab", to: "/listing/electrician/exellence-electric-lab-5221" },
  { from: "/listing/khokhar-electric-shop-electrician-rawalpindi", to: "/listing/electrician/khokhar-electric-shop-|-electrician-|-rawalpindi-5226" },
  { from: "/listing/azhar-ajji-electric-hardware-store", to: "/listing/electrician/azhar-ajji-electric-&-hardware-store-|-electrician-|-rawalpindi-5228" },
  { from: "/listing/nawab-tikka-house-food-rawalpindi", to: "/listing/restaurants/nawab-tikka-house-|-food-|-rawalpindi-5260" },
  { from: "/listing/nisaar-charsi-tikka-food-rawalpindi", to: "/listing/restaurants/nisaar-charsi-tikka-|-food-|-rawalpindi-5294" },
  { from: "/listing/mastana-restaurant-food-rawalpindi", to: "/listing/restaurants/mastana-restaurant-|-food-|-rawalpindi-5299" },
  { from: "/listing/city-gym-and-fitness-clubgents-and-ladies-food-rawalpindi", to: "/listing/gym/city-gym-and-fitness-club(gents-and-ladies)-|-food-|-rawalpindi-5301" },
  { from: "/listing/fitness-zone-ladies-gym-food-rawalpindi", to: "/listing/gym/fitness-zone-ladies-gym-|-food-|-rawalpindi-5303" },
  { from: "/listing/ladies-yoga-studio-food-rawalpindi", to: "/listing/gym/ladies-yoga-studio---|-food-|-rawalpindi-5306" },
  { from: "/listing/mehboob-hospital-food-rawalpindi", to: "/listing/hospitals/mehboob-hospital-|-rawalpindi-5308" },
  { from: "/listing/city-hospital-hospilal-rawalpindi", to: "/listing/hospitals/city-hospital-|-hospilal--|-rawalpindi-5310" },
  { from: "/listing/autodeals-pk-car-dealer-lahore", to: "/listing/car-dealer/autodeals.pk-|-car-dealer-|-lahore-5315" },
  { from: "/listing/advocate-supreme-court-of-pakistan-lawyer-rawalpindi", to: "/listing/lawyers/advocate-supreme-court-of-pakistan-|-lawyer-|-rawalpindi-5348" },
  { from: "/listing/azeem-m-qazi-law-office-lawyer-rawalpindi", to: "/listing/lawyers/azeem-m.-qazi-law-office--|-lawyer-|-rawalpindi-5351" },
  { from: "/listing/sheikh-law-associates-lawyers-rawalpindi", to: "/listing/lawyers/sheikh-law-associates-|-lawyers-|-rawalpindi-5353" },
  { from: "/listing/cosmetique-pakistan-clinic-lahore", to: "/listing/doctors-and-clinics/cosmetique-pakistan-|-clinic-|-lahore-5355" },
  { from: "/listing/hafiz-printers-rawalpindi-rawalpindi", to: "/listing/printing/hafiz-printers-rawalpindi-|-rawalpindi-5356" },
  { from: "/listing/sigma-press-printring-rawalpindi", to: "/listing/printing/sigma-press-|-printring--|-rawalpindi-5359" },
  { from: "/listing/print-world-printring-rawalpindi", to: "/listing/printing/print-world-|-printring--|-rawalpindi-5362" },
  { from: "/listing/pakistan-property-property-rawalpindi", to: "/listing/property-development/pakistan-property-|-property-|-rawalpindi-5365" },
  { from: "/listing/cosmoderma-fue-hair-transplant-clinic-lahore", to: "/listing/doctors-and-clinics/cosmoderma-fue-hair-transplant-clinic-lahore-5368" },
  { from: "/listing/e-state-affair-property-dealer-property-rawalpindi", to: "/listing/property-development/e-state-affair-property-dealer-|-property-|-rawalpindi-5372" },
  { from: "/listing/makkah-mukarma-property-advisor-property-rawalpindi", to: "/listing/property-development/makkah-mukarma-property-advisor-|-property-|-rawalpindi-5378" },
  { from: "/listing/advanced-pain-center-clinic-lahore", to: "/listing/doctors-and-clinics/advanced-pain-center-|-clinic-|-lahore-5381" },
  { from: "/listing/iqbal-clinics-lahore", to: "/listing/doctors-and-clinics/iqbal-clinics-|-lahore-5385" },
  { from: "/listing/petsmall-clinic-lahore", to: "/listing/doctors-and-clinics/petsmall-&-clinic-|-lahore-5388" },
  { from: "/listing/hamdard-clinic-lahore", to: "/listing/doctors-and-clinics/hamdard-clinic-|-lahore-5391" },
  { from: "/listing/hamdard-matab-lahore", to: "/listing/doctors-and-clinics/hamdard-matab-|-lahore-5394" },
  { from: "/listing/city-clinics-medical-dental-consultants-lahore", to: "/listing/doctors-and-clinics/city-clinics---medical-&-dental-consultants-|-lahore-5397" },
  { from: "/listing/dental-smile-specialist-clinic-lahore", to: "/listing/doctors-and-clinics/dental-smile-specialist-clinic-lahore-5401" },
  { from: "/listing/dr-waris-anwar-aesthetics-clinic-lahore", to: "/listing/doctors-and-clinics/dr-waris-anwar-aesthetics-|-clinic-|-lahore-5404" },
  { from: "/listing/abdullah-diagnostic-ultrasound-clinic-lahore", to: "/listing/doctors-and-clinics/abdullah-diagnostic-ultrasound-clinic-|-lahore-5407" },
  { from: "/listing/al-noor-clinic-lahore", to: "/listing/doctors-and-clinics/al-noor-clinic-|-lahore-5410" },
  { from: "/listing/allied-hospital-medical-center-faisalabad", to: "/listing/hospitals/allied-hospital-&-medical-center-faisalabad-5413" },
  { from: "/listing/aziz-fatima-hospital-medical-center-faisalabad", to: "/listing/hospitals/aziz-fatima-hospital-&-medical-center-faisalabad-5417" },
  { from: "/listing/shifa-international-hospital-ltd-faisalabad", to: "/listing/hospitals/shifa-international-hospital-ltd.-faisalabad-5421" },
  { from: "/listing/the-national-hospital-medical-center", to: "/listing/hospitals/the-national-hospital-&-medical-center-faisalabad-5425" },
  { from: "/listing/children-hospital-institute-of-child-health-faisalabad", to: "/listing/hospitals/children-hospital-&-institute-of-child-health-faisalabad-5430" },
  { from: "/listing/saahil-hospital-medical-center-faisalabad", to: "/listing/hospitals/saahil-hospital-&-medical-center-faisalabad-5433" },
  { from: "/listing/kardar-specialist-hospital-faisalabad", to: "/listing/hospitals/kardar-specialist-hospital-faisalabad-5435" },
  { from: "/listing/rathore-hospital-medical-surgical-complex-faisalabad", to: "/listing/hospitals/rathore-hospital-medical-&-surgical-complex-faisalabad-5437" },
  { from: "/listing/district-headquarter-hospital-faisalabad", to: "/listing/hospitals/district-headquarter-hospital-faisalabad-5440" },
  { from: "/listing/faisal-hospital-medical-center-faisalabad", to: "/listing/hospitals/faisal-hospital-&-medical-center-faisalabad-5443" },
  { from: "/listing/government-general-hospital-faisalabad", to: "/listing/hospitals/government-general-hospital-faisalabad-5445" },
  { from: "/listing/punjab-social-security-hospital-faisalabad", to: "/listing/hospitals/punjab-social-security-hospital-faisalabad-5449" },
  { from: "/listing/madinah-teaching-hospital-faisalabad", to: "/listing/hospitals/madinah-teaching-hospital-faisalabad-5453" },
  { from: "/listing/al-rehman-hospital-medical-center-faisalabad", to: "/listing/hospitals/al-rehman-hospital-&-medical-center-faisalabad-5457" },
  { from: "/listing/hilal-e-ahmar-maternity-hospital-faisalabad", to: "/listing/hospitals/hilal-e-ahmar-maternity-hospital-faisalabad-5459" },
  { from: "/listing/dr-obt-clinic-lahore", to: "/listing/doctors-and-clinics/dr.obt-|-clinic-|-lahore-5461" },
  { from: "/listing/laser-praxis-cosmetics-clinic-lahore", to: "/listing/doctors-and-clinics/laser-praxis-cosmetics-|-clinic-|-lahore-5464" },
  { from: "/listing/aestheticare-skin-laser-clinic-lahore", to: "/listing/doctors-and-clinics/aestheticare-skin-&-laser-clinic-|-lahore-5467" },
  { from: "/listing/zee-o-clinic-lahore", to: "/listing/doctors-and-clinics/zee-o-clinic-|-lahore-5470" },
  { from: "/listing/dr-aamirs-specialist-homeopathic-online-clinic-lahore", to: "/listing/doctors-and-clinics/dr-aamir's-specialist-homeopathic-online-clinic-|-lahore-5474" },
  { from: "/listing/dr-farahs-skin-clinic", to: "/listing/doctors-and-clinics/dr.-farah’s-skin-clinic-5475" },
  { from: "/listing/bari-clinic-hospital-lahore", to: "/listing/doctors-and-clinics/bari-clinic-&-hospital-|-lahore-5478" },
  { from: "/listing/hearing-clinic-lahore", to: "/listing/doctors-and-clinics/hearing-clinic-|-lahore-5480" },
  { from: "/listing/skin-clinic-by-dr-haroon-nabi-dermatologist-lahore", to: "/listing/doctors-and-clinics/skin-clinic-by-dr-haroon-nabi-dermatologist-|-lahore-5483" },
  { from: "/listing/friends-environmental-solutions", to: "/listing/pest-control/friends-environmental-solutions-5488" },
  { from: "/listing/sigma-motors-car-dealer-lahore", to: "/listing/car-dealer/sigma-motors-|-car-dealer-|-lahore-5492" },
  { from: "/listing/samaa-motors-car-dealer-lahore", to: "/listing/car-dealer/samaa-motors-|-car-dealer-|-lahore-5495" },
  { from: "/listing/a-k-motors-car-dealer-lahore", to: "/listing/car-dealer/a.k-motors-|-car-dealer-|-lahore-5498" },
  { from: "/listing/honda-gateway-car-dealer-lahore", to: "/listing/car-dealer/honda-gateway-|-car-dealer-|-lahore-5501" },
  { from: "/listing/toyota-shaheen-motors-car-dealer-lahore", to: "/listing/car-dealer/toyota-shaheen-motors-|-car-dealer-|-lahore-5504" },
  { from: "/listing/audi-lahore-car-dealer", to: "/listing/car-dealer/audi-lahore-|-car-dealer-5623" },
  { from: "/listing/japan-car-point-car-dealer-lahore", to: "/listing/car-dealer/japan-car-point-|-car-dealer-|-lahore-5627" },
  { from: "/listing/wada-clinic-rawalpindi", to: "/listing/doctors-and-clinics/wada-clinic-|-rawalpindi-5633" },
  { from: "/listing/skinz-laser-clinic-rawalpindi", to: "/listing/doctors-and-clinics/skinz-laser-clinic-|-rawalpindi-5636" },
  { from: "/listing/abdullah-clinic-rawalpindi", to: "/listing/doctors-and-clinics/abdullah-clinic-|-rawalpindi-5639" },
  { from: "/listing/suzuki-madni-motors-jail-road-car-dealer-lahore", to: "/listing/car-dealer/suzuki-madni-motors-jail-road-|-car-dealer-|-lahore-5642" },
  { from: "/listing/baig-motors-car-dealer-lahore", to: "/listing/car-dealer/baig-motors-|-car-dealer-|-lahore-5645" },
  { from: "/listing/moon-motors-car-dealer-lahore", to: "/listing/car-dealer/moon-motors-|-car-dealer-|-lahore-5648" },
  { from: "/listing/lahore-motors-car-dealer-lahore", to: "/listing/car-dealer/lahore-motors-|-car-dealer-|-lahore-5650" },
  { from: "/listing/auto-motives-car-dealer-lahore", to: "/listing/car-dealer/auto-motives-|-car-dealer-|-lahore-5652" },
  { from: "/listing/rameen-motors-car-dealer-lahore", to: "/listing/car-dealer/rameen-motors-|-car-dealer-|-lahore-5654" },
  { from: "/listing/haq-motors-car-dealer-lahore", to: "/listing/car-dealer/haq-motors-|-car-dealer-|-lahore-5656" },
  { from: "/listing/best-buy-motors-car-dealer-lahore", to: "/listing/car-dealer/best-buy-motors-|-car-dealer-|-lahore-5658" },
  { from: "/listing/autozxpress-car-dealer-lahore", to: "/listing/car-dealer/autozxpress-|-car-dealer-|-lahore-5660" },
  { from: "/listing/carmudi-pakistan-car-dealer-lahore", to: "/listing/car-dealer/carmudi-pakistan-|-car-dealer-|-lahore-5663" },
  { from: "/listing/first-choice-motors-car-dealer-lahore", to: "/listing/car-dealer/first-choice-motors-|-car-dealer-|-lahore-5666" },
  { from: "/listing/autolook-pakistan-car-dealer-lahore", to: "/listing/car-dealer/autolook-pakistan-|-car-dealer-|-lahore-5668" },
  { from: "/listing/family-lawyer-faisalabad", to: "/listing/lawyers/family-lawyer-faisalabad-5723" },
  { from: "/listing/shahnawaz-law-associates-faisalabad", to: "/listing/lawyers/shahnawaz-law-associates-|-faisalabad-5725" },
  { from: "/listing/the-solutions-lawyer-faisalabad", to: "/listing/lawyers/the-solutions-|-lawyer-|-faisalabad-5727" },
  { from: "/listing/mian-law-chambers-sales-tax-income-tax-advisor-faisalabad", to: "/listing/lawyers/mian-law-chambers-(sales-tax-income-tax-advisor)-|-faisalabad-5728" },
  { from: "/listing/tahir-mahmood-advocate-lawyer-faisalabad", to: "/listing/lawyers/tahir-mahmood-advocate-|-lawyer-|-faisalabad-5731" },
  { from: "/listing/muzammil-raza-advocate-lawyer-faisalabad", to: "/listing/lawyers/muzammil-raza-advocate-|-lawyer-|-faisalabad-5733" },
  { from: "/listing/asma-tanveer-randhawa-advocate-lawyer-faisalabad", to: "/listing/lawyers/asma-tanveer-randhawa-advocate-|-lawyer-|-faisalabad-5736" },
  { from: "/listing/anis-law-associates-faisalabad", to: "/listing/lawyers/anis-law-associates-|-faisalabad-5739" },
  { from: "/listing/mohammad-yasin-advocate-high-court-lawyer-faisalabad", to: "/listing/lawyers/mohammad-yasin-advocate-high-court-|-lawyer-|-faisalabad-5742" },
  { from: "/listing/doce-foods-head-office-lahore", to: "/listing/restaurants/doce-foods-head-office-|-lahore-5767" },
  { from: "/listing/desi-home-cooked-delicious-food-lahore", to: "/listing/restaurants/desi-home-cooked-delicious-food-|-lahore-5770" },
  { from: "/listing/arabic-shawarma-fast-food-lahore", to: "/listing/restaurants/arabic-shawarma-&-fast-food-|-lahore-5773" },
  { from: "/listing/mr-alladin-fast-food-lahore", to: "/listing/restaurants/mr.alladin-fast-food-|-lahore-5780" },
  { from: "/listing/sufi-barkat-ali-law-chambers-lawyer-faisalabad", to: "/listing/lawyers/sufi-barkat-ali-law-chambers-|-lawyer-|-faisalabad-5781" },
  { from: "/listing/raja-babu-master-foods-lahore", to: "/listing/restaurants/raja-babu-master-foods-|-lahore-5791" },
  { from: "/listing/chiniot-food-catering-lahore", to: "/listing/restaurants/chiniot-food-catering-|-lahore-5794" },
  { from: "/listing/big-bird-foods-lahore", to: "/listing/restaurants/big-bird-foods-|-lahore-5804" },
  { from: "/listing/united-fast-food-restaurant-lahore", to: "/listing/restaurants/united-fast-food-restaurant-|-lahore-5807" },
  { from: "/listing/naeem-sardar-law-chamber-faisalabad", to: "/listing/lawyers/naeem-sardar-law-chamber-|-faisalabad-5838" },
  { from: "/listing/gerrys-visa-drop-box-faisalabad", to: "/listing/visa-agencies/gerry's-visa-drop-box-|-faisalabad-5841" },
  { from: "/listing/times-consultant-visa-services-faisalabad-2", to: "/listing/visa-agencies/times-consultant-|-visa-services-|-faisalabad-5843" },
  { from: "/listing/routes-consultants-visa-services-faisalabad", to: "/listing/visa-agencies/routes-consultants-|-visa-services-|-faisalabad-5848" },
  { from: "/listing/pakland-travel-pvt-ltd-visa-services-faisalabad", to: "/listing/visa-agencies/pakland-travel-pvt.-ltd.-|-visa-services-|-faisalabad-5852" },
  { from: "/listing/british-visa-services-faisalabad", to: "/listing/visa-agencies/british-visa-services-|-faisalabad-5855" },
  { from: "/listing/xpertz-solution-ltd-visa-services-faisalabad", to: "/listing/visa-agencies/xpertz-solution-ltd-|-visa-services-|-faisalabad-5860" },
  { from: "/listing/uni-guide-consultancy-services-pvt-ltd-visa-services-faisalabad", to: "/listing/visa-agencies/uni-guide-consultancy-services-(pvt.)-ltd-|-visa-services-|-faisalabad-5863" },
  { from: "/listing/hs-consultants-pvt-ltd-visa-services-faisalabad", to: "/listing/visa-agencies/hs-consultants-pvt-ltd-|-visa-services-|-faisalabad-5866" },
  { from: "/listing/continental-linkers-visa-services-faisalabad", to: "/listing/visa-agencies/continental-linkers-|-visa-services-|-faisalabad-5872" },
  { from: "/listing/bicas-consultancy-uk-limited-visa-services-faisalabad", to: "/listing/visa-agencies/bicas-consultancy-uk-limited-|-visa-services-|-faisalabad-5881" },
  { from: "/listing/hr-consultants-pvt-ltd-visa-services-faisalabad", to: "/listing/visa-agencies/hr-consultants-pvt-ltd-|-visa-services-|-faisalabad-5882" },
  { from: "/listing/mian-property-dealer-faisalabad", to: "/listing/property-development/mian-property-dealer-|-faisalabad-5893" },
  { from: "/listing/qasim-property-dealers-faisalabad", to: "/listing/property-development/qasim-property-dealers-|-faisalabad-5894" },
  { from: "/listing/anwaar-property-dealer-faisalabad", to: "/listing/property-development/anwaar-property-dealer-|-faisalabad-5896" },
  { from: "/listing/pakistan-property-dealer-faisalabad", to: "/listing/property-development/pakistan-property-dealer-|-faisalabad-5902" },
  { from: "/listing/property-dealers-faisalabad", to: "/listing/property-development/property-dealers-|-faisalabad-5904" },
  { from: "/listing/akhtar-property-dealers-faisalabad", to: "/listing/property-development/akhtar-property-dealers-|-faisalabad-5907" },
  { from: "/listing/azeem-estate-agency-faisalabad", to: "/listing/property-development/azeem-estate-agency-|-faisalabad-5911" },
  { from: "/listing/shah-jee-property-dealer-faisalabad", to: "/listing/property-development/shah-jee-property-dealer-|-faisalabad-5915" },
  { from: "/listing/loyal-estate-property-dealer-faisalabad", to: "/listing/property-development/loyal-estate-property-dealer-|-faisalabad-5920" },
  { from: "/listing/al-jannat-property-dealer-faisalabad", to: "/listing/property-development/al-jannat-property-dealer-|-faisalabad-5922" },
  { from: "/listing/siddeeq-public-school-rawalpindi", to: "/listing/driving-schools/siddeeq-public-school-|-rawalpindi-5925" },
  { from: "/listing/presentation-convent-girls-high-school-rawalpindi", to: "/listing/driving-schools/presentation-convent-girls-high-school-|-rawalpindi-5928" },
  { from: "/listing/sls-montessori-school-rawalpindi", to: "/listing/driving-schools/sls-montessori-&-school-|-rawalpindi-5932" },
  { from: "/listing/allied-school-peshawar-road-campus-rawalpindi", to: "/listing/driving-schools/allied-school-peshawar-road-campus-|-rawalpindi-5938" },
  { from: "/listing/silver-oaks-school-rawalpindi", to: "/listing/driving-schools/silver-oaks-school-|-rawalpindi-5941" },
  { from: "/listing/sls-montessori-school-rawalpindi-2", to: "/listing/driving-schools/sls-montessori-&-school-|-rawalpindi-5952" },
  { from: "/listing/roots-school-system-rawalpindi", to: "/listing/driving-schools/roots-school-system-|-rawalpindi-5958" },
  { from: "/listing/al-huda-school-rawalpindi", to: "/listing/driving-schools/al-huda-school-|-rawalpindi-5970" },
  { from: "/listing/jubilee-printing-press-faisalabad", to: "/listing/printing/jubilee-printing-press-|-faisalabad-6003" },
  { from: "/listing/islamia-printers-faisalabad", to: "/listing/printing/islamia-printers-|-faisalabad-6011" },
  { from: "/listing/legend-club-printers-faisalabad", to: "/listing/printing/legend-club-printers-|-faisalabad-6023" },
  { from: "/listing/dot-club-printers-faisalabad", to: "/listing/printing/dot-club-printers-|-faisalabad-6025" },
  { from: "/listing/unicorn-international-printers-faisalabad", to: "/listing/printing/unicorn-international-printers-|-faisalabad-6029" },
  { from: "/listing/printing-point-faisalabad", to: "/listing/printing/printing-point-|-faisalabad-6032" },
  { from: "/listing/kalar-sharif-printing-press-faisalabad", to: "/listing/printing/kalar-sharif-printing-press-|-faisalabad-6035" },
  { from: "/listing/veena-printing-press-faisalabad", to: "/listing/printing/veena-printing-press-|-faisalabad-6037" },
  { from: "/listing/pak-printers-faisalabad", to: "/listing/printing/pak-printers-|-faisalabad-6039" },
  { from: "/listing/usman-printers-faisalabad", to: "/listing/printing/usman-printers-|-faisalabad-6041" },
  { from: "/listing/urban-pest-management-faisalabad", to: "/listing/pest-control/urban-pest-management-|-faisalabad-6045" },
  { from: "/listing/peart-agro-chemicals-pest-control-faisalabad", to: "/listing/pest-control/peart-agro-chemicals-|-pest-control-|-faisalabad-6048" },
  { from: "/listing/the-mentor-school-rawalpindi", to: "/listing/driving-schools/the-mentor-school-|-rawalpindi-6178" },
  { from: "/listing/green-leads-school-system-rawalpindi", to: "/listing/driving-schools/green-leads-school-system-|-rawalpindi-6181" },
  { from: "/listing/al-muqaddim-travel-tours-rawalpindi", to: "/listing/travel-agents/al-muqaddim-travel-&-tours-|-rawalpindi-6185" },
  { from: "/listing/hafiz-iftikhar-international-travel-tours-pvt-ltd-rawalpindi", to: "/listing/travel-agents/hafiz-iftikhar-international-travel-&-tours-pvt-ltd-|-rawalpindi-6187" },
  { from: "/listing/k-m-travels-rawalpindi", to: "/listing/travel-agents/k-m-travels-|-rawalpindi-6190" },
  { from: "/listing/yasoob-travel-rawalpindi", to: "/listing/travel-agents/yasoob-travel-|-rawalpindi-6193" },
  { from: "/listing/green-hill-travel-rawalpindi", to: "/listing/travel-agents/green-hill-travel-|-rawalpindi-6196" },
  { from: "/listing/tutors-link-rawalpindi", to: "/listing/tutoring/tutors-link-|-rawalpindi-6199" },
  { from: "/listing/creative-home-tutors-rawalpindi", to: "/listing/tutoring/creative-home-tutors-|-rawalpindi-6201" },
  { from: "/listing/home-tutors-rawalpindi", to: "/listing/tutoring/home-tutors-|-rawalpindi-6204" },
  { from: "/listing/lions-home-tutors-rawalpindi", to: "/listing/tutoring/lions-home-tutors-|-rawalpindi-6207" },
  { from: "/listing/medicare-hospital", to: "/listing/hospitals/medicare-hospital-6216" },
  { from: "/listing/suzuki-central-motors-rawalpindi", to: "/listing/car-dealer/suzuki-central-motors-|-rawalpindi-6310" },
  { from: "/listing/11th-way-cars-rawalpindi", to: "/listing/car-dealer/11th-way-cars-|-rawalpindi-6313" },
  { from: "/listing/pak-drive-rawalpindi", to: "/listing/car-dealer/pak-drive-|-rawalpindi-6316" },
  { from: "/listing/aaban-motors-rawalpindi", to: "/listing/car-dealer/aaban-motors-|-rawalpindi-6319" },
  { from: "/listing/about-cars-rawalpindi", to: "/listing/car-dealer/about-cars-|-rawalpindi-6322" },
  { from: "/listing/car-junction-rawalpindi", to: "/listing/car-dealer/car-junction-|-rawalpindi-6325" },
  { from: "/listing/baig-motors-rawalpindi", to: "/listing/car-dealer/baig-motors-|-rawalpindi-6328" },
  { from: "/listing/rehman-motors-rawalpindi", to: "/listing/car-dealer/rehman-motors-|-rawalpindi-6332" },
  { from: "/listing/auto-company-rawalpindi", to: "/listing/car-dealer/auto-company-|-rawalpindi-6335" },
  { from: "/listing/uzair-motors-rawalpindi", to: "/listing/car-dealer/uzair-motors-|-rawalpindi-6338" },
  { from: "/listing/suzuki-central-motors-rawalpindi-2", to: "/listing/car-dealer/suzuki-central-motors-|-rawalpindi-6341" },
  { from: "/listing/baig-motors-real-estate-rawalpindi", to: "/listing/car-dealer/baig-motors-&-real-estate|-rawalpindi-6344" },
  { from: "/listing/shaheen-rent-a-car-rawalpindi", to: "/listing/car-rental/shaheen-rent-a-car-|-rawalpindi-6347" },
  { from: "/listing/nouman-motors-rent-a-car-rawalpindi", to: "/listing/car-rental/nouman-motors-rent-a-car-|-rawalpindi-6350" },
  { from: "/listing/rawalpindi-rent-a-car-rawalpindi", to: "/listing/car-rental/rawalpindi-rent-a-car-|-rawalpindi-6353" },
  { from: "/listing/elite-rent-a-car-tours-rawalpindi", to: "/listing/car-rental/elite-rent-a-car-&-tours-|-rawalpindi-6356" },
  { from: "/listing/ittefaq-rent-a-car-rawalpindi", to: "/listing/car-rental/ittefaq-rent-a-car-in-islamabad-&-rawalpindi-6360" },
  { from: "/listing/3-star-rent-a-car-rawalpindi", to: "/listing/car-rental/3-star-rent-a-car-|-rawalpindi-6363" },
  { from: "/listing/royal-executive-cars-rawalpindi", to: "/listing/car-rental/royal-executive-cars-|-rawalpindi-6366" },
  { from: "/listing/ahsan-moters-rawalpindi", to: "/listing/car-rental/ahsan-moters-|-rawalpindi-6369" },
  { from: "/listing/city-rent-a-car-rawalpindi", to: "/listing/car-rental/city-rent-a-car-|-rawalpindi-6372" },
  { from: "/listing/imran-tours-rent-a-car-rawalpindi", to: "/listing/car-rental/imran-tours-&-rent-a-car-|-rawalpindi-6375" },
  { from: "/listing/toyota-faisalabad-motors", to: "/listing/car-dealer/toyota-faisalabad-motors-6384" },
  { from: "/listing/new-unique-pest-control-service-faisalabad", to: "/listing/pest-control/new-unique-pest-control-service-|-faisalabad-6390" },
  { from: "/listing/united-pest-control-service-faisalabad", to: "/listing/pest-control/united-pest-control-service-|-faisalabad-6392" },
  { from: "/listing/jazmak-motors-car-dealer-faisalabad", to: "/listing/car-dealer/jazmak-motors-|-car-dealer-|-faisalabad-6396" },
  { from: "/listing/ar-raheem-motors-rent-a-car-islamabad-rawalpindi", to: "/listing/car-rental/ar-raheem-motors-|-rent-a-car-islamabad-rawalpindi-6399" },
  { from: "/listing/suzuki-canal-motors-faisalabad", to: "/listing/car-dealer/suzuki-canal-motors-|-faisalabad-6404" },
  { from: "/listing/awan-wood-works-rawalpindi", to: "/listing/carpenter/awan-wood-works-|-rawalpindi-6410" },
  { from: "/listing/madina-plywood-centre-rawalpindi", to: "/listing/carpenter/madina-plywood-centre-|-rawalpindi-6413" },
  { from: "/listing/ghulam-rasul-carpenter-rawalpindi", to: "/listing/carpenter/ghulam-rasul-carpenter-|-rawalpindi-6416" },
  { from: "/listing/alhamdulillah-wood-works-rawalpindi", to: "/listing/carpenter/alhamdulillah-wood-works-|-rawalpindi-6419" },
  { from: "/listing/ali-nisar-sound-box-maker-rawalpindi", to: "/listing/carpenter/ali-nisar-sound-box-maker-|-rawalpindi-6422" },
  { from: "/listing/huzaifa-cnc-works-rawalpindi", to: "/listing/carpenter/huzaifa-cnc-works-|-rawalpindi-6426" },
  { from: "/listing/shine-woodwork-shop-rawalpindi", to: "/listing/carpenter/shine-woodwork-shop-|-rawalpindi-6429" },
  { from: "/listing/wood-carpenter-rawalpindi", to: "/listing/carpenter/wood-carpenter-|-rawalpindi-6432" },
  { from: "/listing/batool-clinic-rawalpindi", to: "/listing/doctors-and-clinics/batool-clinic-|-rawalpindi-6435" },
  { from: "/listing/dr-zainab-mubashir-psychiatrist-clinic-rawalpindi", to: "/listing/doctors-and-clinics/dr-zainab-mubashir-psychiatrist-clinic-|-rawalpindi-6438" },
  { from: "/listing/taj-skin-clinic-rawalpindi", to: "/listing/doctors-and-clinics/taj-skin-clinic-|-rawalpindi-6444" },
  { from: "/listing/physiotherapy-health-clinic-rawalpindi", to: "/listing/doctors-and-clinics/physiotherapy-&-health-clinic-|-rawalpindi-6445" },
  { from: "/listing/premium-diagnostics-center-clinic-rawalpindi", to: "/listing/doctors-and-clinics/premium-diagnostics-center-&-clinic-|-rawalpindi-6448" },
  { from: "/listing/cheema-eye-skin-clinic-rawalpindi", to: "/listing/doctors-and-clinics/cheema-eye-&-skin-clinic-|-rawalpindi-6451" },
  { from: "/listing/nadeem-clinic-rawalpindi", to: "/listing/doctors-and-clinics/nadeem-clinic-|-rawalpindi-6454" },
  { from: "/listing/aslam-ent-clinic-rawalpindi", to: "/listing/doctors-and-clinics/aslam-ent-clinic-|-rawalpindi-6457" },
  { from: "/listing/race-course-clinic-rawalpindi", to: "/listing/doctors-and-clinics/race-course-clinic-|-rawalpindi-6460" },
  { from: "/listing/yasir-motors-car-dealer-faisalabad", to: "/listing/car-dealer/yasir-motors-|-car-dealer-|-faisalabad-6463" },
  { from: "/listing/faw-lyallpur-motors-faisalabad", to: "/listing/car-dealer/faw-lyallpur-motors-|-faisalabad-6464" },
  { from: "/listing/bilal-motors-car-dealer-faisalabad", to: "/listing/car-dealer/bilal-motors-|-car-dealer-|-faisalabad-6469" },
  { from: "/listing/makkah-motors-car-dealer-faisalabad", to: "/listing/car-dealer/makkah-motors-|-car-dealer-|-faisalabad-6471" },
  { from: "/listing/car-city-car-dealer-faisalabad", to: "/listing/car-dealer/car-city-|-car-dealer-|-faisalabad-6474" },
  { from: "/listing/zaib-motors-car-dealer-faisalabad", to: "/listing/car-dealer/zaib-motors-|-car-dealer-|-faisalabad-6476" },
  { from: "/listing/saeed-autos-car-dealer-faisalabad", to: "/listing/car-dealer/saeed-autos-|-car-dealer-|-faisalabad-6478" },
  { from: "/listing/honda-chenab-green-view-motors-pvt-ltd-faisalabad", to: "/listing/car-dealer/honda-chenab-(green-view-motors-pvt.-ltd.)-|-faisalabad-6483" },
  { from: "/listing/honda-faisalabad-3s-showroom", to: "/listing/car-dealer/honda-faisalabad-3s-showroom-6510" },
  { from: "/listing/saad-motors-car-dealer-faisalabad", to: "/listing/car-dealer/saad-motors-|-car-dealer-|-faisalabad-6514" },
  { from: "/listing/suzuki-haq-motors-car-dealer-faisalabad", to: "/listing/car-dealer/suzuki-haq-motors-|-car-dealer-|-faisalabad-6518" },
  { from: "/listing/abdullah-car-mart-car-dealer-faisalabad", to: "/listing/car-dealer/abdullah-car-mart-|-car-dealer-|-faisalabad-6525" },
  { from: "/listing/wali-mental-health-clinic-faisalabad", to: "/listing/doctors-and-clinics/wali-mental-health-clinic-|-faisalabad-6528" },
  { from: "/listing/pet-care-clinic-faisalabad", to: "/listing/doctors-and-clinics/pet-care-clinic-|-faisalabad-6530" },
  { from: "/listing/jinnah-clinics-faisalabad", to: "/listing/doctors-and-clinics/jinnah-clinics-|-faisalabad-6533" },
  { from: "/listing/farhat-ishtiaq-eye-care-clinic-faisalabad", to: "/listing/doctors-and-clinics/farhat-ishtiaq-eye-care-clinic-|-faisalabad-6535" },
  { from: "/listing/lahore-eye-care-clinic-faisalabad", to: "/listing/doctors-and-clinics/lahore-eye-care-clinic-|-faisalabad-6537" },
  { from: "/listing/pest-control-services-in-lahore-epest", to: "/listing/pest-control/pest-control-services-in-lahore---epest-6539" },
  { from: "/listing/nawab-family-care-clinic-faisalabad", to: "/listing/doctors-and-clinics/nawab-family-care-clinic-|-faisalabad-6560" },
  { from: "/listing/hajra-maternity-and-ultrasound-clinic-faisalabad", to: "/listing/doctors-and-clinics/hajra-maternity-and-ultrasound-clinic-|-faisalabad-6562" },
  { from: "/listing/dr-aslam-farooq-eye-care-clinic-faisalabad", to: "/listing/doctors-and-clinics/dr.-aslam-farooq-eye-care-clinic-|-faisalabad-6565" },
  { from: "/listing/dr-babar-javed-clinic-faisalabad", to: "/listing/doctors-and-clinics/dr.-babar-javed-clinic-|-faisalabad-6568" },
  { from: "/listing/madina-clinic-faisalabad", to: "/listing/doctors-and-clinics/madina-clinic-|-faisalabad-6570" },
  { from: "/listing/hair-transplant-clinic-faisalabad", to: "/listing/doctors-and-clinics/hair-transplant-clinic-|-faisalabad-6573" },
  { from: "/listing/hair-club-hair-transplant-clinic-faisalabad", to: "/listing/doctors-and-clinics/hair-club-|-hair-transplant-clinic-|-faisalabad-6575" },
  { from: "/listing/hair-solution-hair-transplant-clinic-faisalabad", to: "/listing/doctors-and-clinics/hair-solution-|-hair-transplant-clinic-|-faisalabad-6578" },
  { from: "/listing/punjab-college-of-information-technology-rawalpindi", to: "/listing/colleges/punjab-college-of-information-technology-|-rawalpindi-6614" },
  { from: "/listing/askaria-college-rawalpindi", to: "/listing/colleges/askaria-college-|-rawalpindi-6617" },
  { from: "/listing/icmc-college-saddar-rawalpindi-rawalpindi", to: "/listing/colleges/icmc-college-saddar-rawalpindi-6620" },
  { from: "/listing/scholars-cantt-college-of-commerce-rawalpindi", to: "/listing/colleges/scholars-cantt-college-of-commerce|-rawalpindi-6623" },
  { from: "/listing/al-mizan-college-rawalpindi", to: "/listing/colleges/al-mizan-college-|-rawalpindi-6627" },
  { from: "/listing/rawalpindi-cantt-college-of-commerce", to: "/listing/colleges/rawalpindi-cantt-college-of-commerce-6630" },
  { from: "/listing/f-g-sir-syed-college-rawalpindi", to: "/listing/colleges/f.-g.-sir-syed-college-|-rawalpindi-6633" },
  { from: "/listing/askaria-college-of-management-information-technologies-rawalpindi", to: "/listing/colleges/askaria-college-of-management-&-information-technologies-|-rawalpindi-6636" },
  { from: "/listing/national-college-of-arts-rawalpindi", to: "/listing/colleges/national-college-of-arts-|-rawalpindi-6639" },
  { from: "/listing/mashallah-motors-car-dealer-ahmedpur-east", to: "/listing/car-dealer/mashallah-motors-|-car-dealer-|-ahmedpur-east-6672" },
  { from: "/listing/al-gaffar-furniture-house-carpenter-ahmedpur-east", to: "/listing/carpenter/al-gaffar-furniture-house-|-carpenter-|-ahmedpur-east-6674" },
  { from: "/listing/family-health-clinic-fhc-clinic-ahmadpur-east", to: "/listing/doctors-and-clinics/family-health-clinic-(fhc)-|-clinic-|-ahmadpur-east-6676" },
  { from: "/listing/food-club-restaurant-ahmedpur-east", to: "/listing/restaurants/food-club-|-restaurant-|-ahmedpur-east-6679" },
  { from: "/listing/al-jamshed-health-club-gym-ahmedpur-east", to: "/listing/gym/al-jamshed-health-club-|-gym-|-ahmedpur-east-6681" },
  { from: "/listing/tehsil-head-quarter-hospital-ahmedpur-east", to: "/listing/hospitals/tehsil-head-quarter-hospital-|-ahmedpur-east-6683" },
  { from: "/listing/kharwala-law-chamber-lawyer-ahmedpur-east-2", to: "/listing/lawyers/kharwala-law-chamber-|-lawyer-|-ahmedpur-east-6685" },
  { from: "/listing/mk-builders-rawalpindi", to: "/listing/construction-services/mk.builders-|-rawalpindi-6700" },
  { from: "/listing/golf-construction-company-rawalpindi", to: "/listing/construction-services/golf-construction-company-|-rawalpindi-6703" },
  { from: "/listing/space-construction-and-engineering-rawalpindi", to: "/listing/construction-services/space-construction-and-engineering-|-rawalpindi-6706" },
  { from: "/listing/ma-builders-developers-rawalpindi", to: "/listing/construction-services/ma-builders-&-developers-|-rawalpindi-6709" },
  { from: "/listing/skymark-builders-rawalpindi", to: "/listing/construction-services/skymark-builders-|-rawalpindi-6713" },
  { from: "/listing/wave-associates-rawalpindi", to: "/listing/construction-services/wave-associates-|-rawalpindi-6716" },
  { from: "/listing/sohail-builders-rawalpindi", to: "/listing/construction-services/sohail-builders-|-rawalpindi-6719" },
  { from: "/listing/experts-engineering-services-rawalpindi", to: "/listing/construction-services/experts-engineering-services-|-rawalpindi-6722" },
  { from: "/listing/termite-and-pest-control-services-urban-pest-management", to: "/listing/pest-control/termite-and-pest-control-services---urban-pest-management-6735" },
  { from: "/listing/farrukh-electric-works-rawalpindi", to: "/listing/electrician/farrukh-electric-works-|-rawalpindi-6738" },
  { from: "/listing/gc-university-faisalabad-2", to: "/listing/colleges/gc.-university-faisalabad-6763" },
  { from: "/listing/gc-university-faisalabad", to: "/listing/universities/gc.-university-faisalabad-6764" },
  { from: "/listing/university-of-agriculture-faisalabad", to: "/listing/universities/university-of-agriculture-faisalabad-6767" },
  { from: "/listing/the-university-of-faisalabad", to: "/listing/universities/the-university-of-faisalabad-6770" },
  { from: "/listing/national-textile-university-faisalabad", to: "/listing/universities/national-textile-university-faisalabad-6776" },
  { from: "/listing/nfc-institute-of-engineering-and-fertilizer-research-faisalabad", to: "/listing/universities/nfc-institute-of-engineering-and-fertilizer-research-faisalabad-6779" },
  { from: "/listing/government-college-women-university-faisalabad", to: "/listing/colleges/government-college-women-university-faisalabad-6782" },
  { from: "/listing/government-college-women-university-faisalabad-2", to: "/listing/universities/government-college-women-university-faisalabad-6783" },
  { from: "/listing/government-college-women-university-faisalabad-3", to: "/listing/colleges/government-college-women-university-faisalabad-6784" },
  { from: "/listing/punjab-medical-college-faisalabad-medical-university", to: "/listing/universities/punjab-medical-college---faisalabad-medical-university-6788" },
  { from: "/listing/divisional-model-college-faisalabad", to: "/listing/colleges/divisional-model-college-faisalabad-6792" },
  { from: "/listing/government-college-of-technology-faisalabad", to: "/listing/colleges/government-college-of-technology-faisalabad-6795" },
  { from: "/listing/aziz-fatimah-medical-and-dental-college-faisalabad", to: "/listing/colleges/aziz-fatimah-medical-and-dental-college-faisalabad-6798" },
  { from: "/listing/independent-medical-college-faisalabad", to: "/listing/colleges/independent-medical-college-faisalabad-6813" },
  { from: "/listing/independent-medical-college-faisalabad-2", to: "/listing/universities/independent-medical-college-faisalabad-6814" },
  { from: "/listing/punjab-college-of-commerce-faisalabad", to: "/listing/colleges/punjab-college-of-commerce,-faisalabad-6818" },
  { from: "/listing/roots-ivy-international-university-faisalabad", to: "/listing/universities/roots-ivy-international-university-faisalabad-6822" },
  { from: "/listing/lions-home-tutors", to: "/listing/tutoring/lions-home-tutors-6851" },
  { from: "/listing/al-makkah-sentry-store-plumber-gujranwala", to: "/listing/plumbers/al-makkah-sentry-store-|-plumber-|-gujranwala-6860" },
  { from: "/listing/ali-sentry-store-plumber-gujranwala", to: "/listing/plumbers/ali-sentry-store-|-plumber-|-gujranwala-6862" },
  { from: "/listing/sabri-wood-works-carpenter-gujranwala", to: "/listing/carpenter/sabri-wood-works-|-carpenter-|-gujranwala-6868" },
  { from: "/listing/mughal-wood-works-carpenter-gujranwala", to: "/listing/carpenter/mughal-wood-works-|-carpenter-|-gujranwala-6870" },
  { from: "/listing/javed-surgical-steel-furniture-carpenter-gujranwala", to: "/listing/carpenter/javed-surgical-steel-furniture-|-carpenter-|-gujranwala-6872" },
  { from: "/listing/madina-wood-works-carpenter-gujranwala", to: "/listing/carpenter/madina-wood-works-|-carpenter-|-gujranwala-6875" },
  { from: "/listing/al-makkah-wood-works-carpenter-gujranwala", to: "/listing/carpenter/al-makkah-wood-works-|-carpenter-|-gujranwala-6878" },
  { from: "/listing/khokhar-wood-works-carpenter-gujranwala", to: "/listing/carpenter/khokhar--wood-works-|-carpenter-|-gujranwala-6879" },
  { from: "/listing/kashmir-electronics-gujranwala", to: "/listing/electrician/kashmir-electronics-|-gujranwala-6881" },
  { from: "/listing/ehsaan-electronics-gujranwala", to: "/listing/electrician/ehsaan-electronics-|-gujranwala-6884" },
  { from: "/listing/awais-qarni-electrician-gujranwala", to: "/listing/electrician/awais-qarni-|-electrician-|-gujranwala-6887" },
  { from: "/listing/street-loharanwali-electrician-gujranwala", to: "/listing/electrician/street-loharanwali-|-electrician-|-gujranwala-6889" },
  { from: "/listing/chawinda-auto-electrician-gujranwala", to: "/listing/electrician/chawinda-auto-electrician-|-gujranwala-6891" },
  { from: "/listing/tahir-auto-electrician-gujranwala", to: "/listing/electrician/tahir-auto-electrician-|-gujranwala-6893" },
  { from: "/listing/city-rent-a-car-gujranwala", to: "/listing/car-rental/city-rent-a-car-|-gujranwala-6895" },
  { from: "/listing/ali-bhai-rent-a-car-gujranwala", to: "/listing/car-rental/ali-bhai-rent-a-car-|-gujranwala-6900" },
  { from: "/listing/faisal-rent-a-car-gujranwala", to: "/listing/car-rental/faisal-rent-a-car-|-gujranwala-6902" },
  { from: "/listing/butt-rent-a-car-gujranwala", to: "/listing/car-rental/butt-rent-a-car-|-gujranwala-6904" },
  { from: "/listing/umer-rent-a-car-gujranwala", to: "/listing/car-rental/umer-rent-a-car-|-gujranwala-6908" },
  { from: "/listing/babu-rent-a-car-gujranwala", to: "/listing/car-rental/babu-rent-a-car-|-gujranwala-6909" },
  { from: "/listing/decent-rent-a-car-gujranwala", to: "/listing/car-rental/decent-rent-a-car-|-gujranwala-6911" },
  { from: "/listing/hadan-rent-a-car-gujranwala", to: "/listing/car-rental/hadan-rent-a-car-|-gujranwala-6913" },
  { from: "/listing/termite-and-pest-control-services-exterminators", to: "/listing/pest-control/termite-and-pest-control-services---exterminators-6916" },
  { from: "/listing/shiza-salon-gujranwala", to: "/listing/beauty-parlour/shiza-salon-|-gujranwala-6978" },
  { from: "/listing/makeovers-by-amaima-beauty-salon-gujranwala", to: "/listing/beauty-parlour/makeovers-by-amaima-|-beauty-salon-|-gujranwala-6981" },
  { from: "/listing/shades-beauty-salon-gujranwala", to: "/listing/beauty-parlour/shades-|-beauty-salon-|-gujranwala-6984" },
  { from: "/listing/elegance-bridal-salon-gujranwala", to: "/listing/beauty-parlour/elegance-bridal-salon-|-gujranwala-6987" },
  { from: "/listing/depilex-beauty-clinic-beauty-salon-gujranwala", to: "/listing/beauty-parlour/depilex-beauty-clinic-|-beauty-salon-|-gujranwala-6990" },
  { from: "/listing/abid-car-center-real-estate-gujranwala", to: "/listing/car-dealer/abid-car-center-&-real-estate-|-gujranwala-7024" },
  { from: "/listing/shuja-motors-car-dealer-gujranwala", to: "/listing/car-dealer/shuja-motors-|-car-dealer-|-gujranwala-7028" },
  { from: "/listing/car-club-car-dealer-gujranwala", to: "/listing/car-dealer/car-club-|-car-dealer-|-gujranwala-7033" },
  { from: "/listing/al-raza-motors-car-dealer-gujranwala", to: "/listing/car-dealer/al-raza-motors-|-car-dealer-|-gujranwala-7036" },
  { from: "/listing/al-hamd-cars-car-dealer-gujranwala", to: "/listing/car-dealer/al-hamd-cars-|-car-dealer-|-gujranwala-7040" },
  { from: "/listing/dubai-motors-car-dealer-gujranwala", to: "/listing/car-dealer/dubai-motors-|-car-dealer-|-gujranwala-7045" },
  { from: "/listing/city-car-point-car-dealer-gujranwala", to: "/listing/car-dealer/city-car-point-|-car-dealer-|-gujranwala-7048" },
  { from: "/listing/honda-gujranwala-pvt-ltd-car-dealer-gujranwala", to: "/listing/car-dealer/honda-gujranwala-pvt-ltd-|-car-dealer-|-gujranwala-7050" },
  { from: "/listing/salaam-motors-car-dealer-gujranwala", to: "/listing/car-dealer/salaam-motors-|-car-dealer-|-gujranwala-7055" },
  { from: "/listing/subhan-motors-car-dealer-gujranwala", to: "/listing/car-dealer/subhan-motors-|-car-dealer-|-gujranwala-7058" },
  { from: "/listing/suzuki-gujranwala-motors-car-dealer", to: "/listing/car-dealer/suzuki-gujranwala-motors-|-car-dealer-7059" },
  { from: "/listing/face-on-beauty-parlour-for-women-gujranwala", to: "/listing/beauty-parlour/face-on-beauty-parlour-for-women-|-gujranwala-7063" },
  { from: "/listing/iffi-bride-n-beauty-parlour-gujranwala", to: "/listing/beauty-parlour/iffi-bride-n-beauty-parlour-|-gujranwala-7066" },
  { from: "/listing/rose-beauty-parlour-and-training-center-gujranwala", to: "/listing/beauty-parlour/rose-beauty-parlour-and-training-center-|-gujranwala-7068" },
  { from: "/listing/baby-boy-hair-salon-2-gujranwala", to: "/listing/beauty-parlour/baby-&-boy-hair-salon-2-|-gujranwala-7070" },
  { from: "/listing/orange-advertising-advertiser-in-lahore", to: "/listing/advertisers/orange-advertising-|-advertiser-in-lahore-7075" },
  { from: "/listing/adinfinity-group-pvt-ltd-advertiser-in-lahore", to: "/listing/advertisers/adinfinity-group-pvt.-ltd.-|-advertiser-in-lahore-7078" },
  { from: "/listing/azi-solutions-app-developer-in-lahore", to: "/listing/mobile-app-development/azi-solutions-|-app-developer-in-lahore-7081" },
  { from: "/listing/dynamic-online-technologies-app-developer-in-lahore", to: "/listing/mobile-app-development/dynamic-online-technologies-|-app-developer-in-lahore-7085" },
  { from: "/listing/idea-works-marketing-pvt-ltd-rawalpindi", to: "/listing/advertisers/idea-works-marketing-(pvt)-ltd-|-rawalpindi-|-7089" },
  { from: "/listing/80m-advertising-rawalpindi", to: "/listing/advertisers/80m-advertising-|-rawalpindi-|-7092" },
  { from: "/listing/mns-advertising-rawalpindi", to: "/listing/advertisers/mns-advertising-|-rawalpindi-|-7095" },
  { from: "/listing/panaflex-printing-rawalpindi", to: "/listing/printing/panaflex-printing-|-rawalpindi-|-7098" },
  { from: "/listing/al-basit-advertising-rawalpindi", to: "/listing/advertisers/al-basit-advertising-|-rawalpindi-|-7101" },
  { from: "/listing/haxn-rent-a-car-islamabad-rawalpindi-pakistan", to: "/listing/car-rental/haxn-rent-a-car-islamabad-rawalpindi-pakistan-7111" },
  { from: "/listing/ali-welding-house-welder-in-lahore", to: "/listing/welding-services/ali-welding-house-|-welder-in-lahore-7167" },
  { from: "/listing/asif-welding-works-welder-service", to: "/listing/welding-services/asif-welding-works-|-welder-service-7169" },
  { from: "/listing/candid-pakistan-rawalpindi", to: "/listing/advertisers/candid-pakistan-|-rawalpindi-|-7181" },
  { from: "/listing/mss-digital-media-arts-rawalpindi", to: "/listing/advertisers/mss-digital-media-arts-|-rawalpindi-|-7183" },
  { from: "/listing/jeelani-arts-rawalpindi", to: "/listing/advertisers/jeelani-arts-|-rawalpindi-|-7186" },
  { from: "/listing/brandania-adgos-rawalpindi", to: "/listing/advertisers/brandania-adgo's-|-rawalpindi-|-7189" },
  { from: "/listing/astute-pros-rawalpindi", to: "/listing/advertisers/astute-pros-|-rawalpindi-|-7192" },
  { from: "/listing/services-in-rawalpindi", to: "/listing/advertisers/services-in-rawalpindi-7195" },
  { from: "/listing/brand-experts-pvt-ltd-rawalpindi", to: "/listing/advertisers/brand-experts-(pvt.)-ltd-|-rawalpindi-|-7198" },
  { from: "/listing/adsells-advertising-pvt-ltd-rawalpindi", to: "/listing/advertisers/adsells-advertising-(pvt)-ltd.--|-rawalpindi-|-7201" },
  { from: "/listing/nikhar-beauty-saloon-rawalpindi", to: "/listing/beauty-parlour/nikhar-beauty-saloon-|-rawalpindi-|-7204" },
  { from: "/listing/blue-moon-beauty-salon-rawalpindi", to: "/listing/beauty-parlour/blue-moon-beauty-salon-|-rawalpindi-|-7207" },
  { from: "/listing/shuaa-beauty-salon-rawalpindi", to: "/listing/beauty-parlour/shuaa-beauty-salon-|-rawalpindi-|-7210" },
  { from: "/listing/lily-chinese-beauty-parlour-rawalpindi", to: "/listing/beauty-parlour/lily-chinese-beauty-parlour-|-rawalpindi-|-7213" },
  { from: "/listing/isha-beauty-salon-academy-studio-rawalpindi", to: "/listing/beauty-parlour/isha-beauty-salon-academy-&-studio--|-rawalpindi-|-7216" },
  { from: "/listing/hina-beauty-parlour-rawalpindi", to: "/listing/beauty-parlour/hina-beauty-parlour-|-rawalpindi-|-7219" },
  { from: "/listing/musafat-visa-service-gujranwala", to: "/listing/visa-agencies/musafat-|-visa-service-|-gujranwala-7231" },
  { from: "/listing/uni-world-education-consultants-visa-service-gujranwala", to: "/listing/visa-agencies/uni-world-education-consultants-|-visa-service-|-gujranwala-7234" },
  { from: "/listing/sunshine-int-visa-service-gujranwala", to: "/listing/visa-agencies/sunshine-int-visa-service-|-gujranwala-7238" },
  { from: "/listing/student-counseling-services-s-c-s-visa-service-gujranwala", to: "/listing/visa-agencies/student-counseling-services-(s.c.s)-|-visa-service-|-gujranwala-7242" },
  { from: "/listing/orion-travel-tourism-services-gujranwala", to: "/listing/travel-agents/orion-travel-&-tourism-services-|-gujranwala-7249" },
  { from: "/listing/hafiz-muhammad-sons-travel-agency-gujranwala", to: "/listing/travel-agents/hafiz-muhammad-&-sons-|-travel-agency-|-gujranwala-7253" },
  { from: "/listing/koyal-beauty-parlour-rawalpindi", to: "/listing/beauty-parlour/koyal-beauty-parlour-|-rawalpindi-|-7273" },
  { from: "/listing/depilex-men-rawalpindi-rawalpindi", to: "/listing/beauty-parlour/depilex-men-rawalpindi-|-rawalpindi-|-7276" },
  { from: "/listing/obees-beauty-salon-gym-rawalpindi", to: "/listing/beauty-parlour/obee's-beauty-salon-&-gym-|-rawalpindi-|-7279" },
  { from: "/listing/beauty-hut-salon-rawalpindi", to: "/listing/beauty-parlour/beauty-hut-salon-|-rawalpindi-|-7282" },
  { from: "/listing/deep-look-beauty-parlour-training-centre-rawalpindi", to: "/listing/beauty-parlour/deep-look-beauty-parlour-&-training-centre-|-rawalpindi-|-7285" },
  { from: "/listing/al-sadaat-auto-denting-painting-shop-rawalpindi", to: "/listing/car-denting-painting/al-sadaat-auto-denting-&-painting-shop-|-rawalpindi-|-7292" },
  { from: "/listing/auto-denting-painting-mechanical-workshop-rawalpindi", to: "/listing/car-denting-painting/auto-denting-painting-&-mechanical-workshop-|-rawalpindi-|-7295" },
  { from: "/listing/five-friends-auto-denting-painting-accidental-works-rawalpindi", to: "/listing/car-denting-painting/royal-car-painter-in-rawalpindi-7298" },
  { from: "/listing/sheraz-denting-works-rawalpindi", to: "/listing/car-denting-painting/sheraz-denting-works-|-rawalpindi-|-7302" },
  { from: "/listing/zahid-denting-works-rawalpindi", to: "/listing/car-denting-painting/zahid-denting-works-|-rawalpindi-|-7305" },
  { from: "/listing/sanchi-khan-denting-works-rawalpindi", to: "/listing/car-denting-painting/sanchi-khan-denting-works-|-rawalpindi-|-7308" },
  { from: "/listing/arshad-autos-car-paint-rawalpindi", to: "/listing/car-denting-painting/arshad-autos-&-car-paint-|-rawalpindi-|-7311" },
  { from: "/listing/hotel-pearl-resort-hotel-in-ahmedpur-east", to: "/listing/hotels/hotel-pearl-resort-|-hotel-in-ahmedpur-east-7318" },
  { from: "/listing/perfect-car-wash-rawalpindi", to: "/listing/car-washers/perfect-car-wash-|-rawalpindi-|-7364" },
  { from: "/listing/bimillah-car-wash-service-station-rawalpindi", to: "/listing/car-washers/bimillah-car-wash-&-service-station-|-rawalpindi-|-7367" },
  { from: "/listing/nauman-car-wash-rawalpindi", to: "/listing/car-washers/nauman-car-wash-|-rawalpindi-|-7371" },
  { from: "/listing/stash-teck-auto-detailers-rawalpindi", to: "/listing/car-washers/stash-teck-auto-detailers-|-rawalpindi-|-7374" },
  { from: "/listing/shell-khanna-car-wash-rawalpindi", to: "/listing/car-washers/shell-khanna-car-wash-|-rawalpindi-|-7377" },
  { from: "/listing/lash-pash-car-wash-rawalpindi", to: "/listing/car-washers/lash-pash-car-wash-|-rawalpindi-|-7380" },
  { from: "/listing/carbographics-rawalpindi", to: "/listing/car-washers/carbographics-|-rawalpindi-|-7383" },
  { from: "/listing/protecht-auto-detailing-rawalpindi", to: "/listing/car-washers/protecht-auto-detailing-|-rawalpindi-|-7386" },
  { from: "/listing/pindi-oil-service-station-rawalpindi", to: "/listing/car-washers/pindi-oil-&-service-station-|-rawalpindi-|-7389" },
  { from: "/listing/total-car-wash-rawalpindi", to: "/listing/car-washers/total-car-wash-|-rawalpindi-|-7391" },
  { from: "/listing/qaswa-car-wash-cleaners-rawalpindi", to: "/listing/car-washers/qaswa-car-wash-&-cleaners-|-rawalpindi-|-7394" },
  { from: "/listing/cinepax-rawalpindi", to: "/listing/cinemas-and-theaters/cinepax-|-rawalpindi-|-7397" },
  { from: "/listing/ciros-cinema-rawalpindi", to: "/listing/cinemas-and-theaters/ciros-cinema-|-rawalpindi-|-7400" },
  { from: "/listing/rose-cinema-rawalpindi", to: "/listing/cinemas-and-theaters/rose-cinema-|-rawalpindi-|-7403" },
  { from: "/listing/ciros-enterprises-rawalpindi", to: "/listing/cinemas-and-theaters/ciros-enterprises-|-rawalpindi-|-7406" },
  { from: "/listing/new-rocky-music-center-rawalpindi", to: "/listing/cinemas-and-theaters/new-rocky-music-center-|-rawalpindi-|-7409" },
  { from: "/listing/shabistan-cinema-rawalpindi", to: "/listing/cinemas-and-theaters/shabistan-cinema-|-rawalpindi-|-7412" },
  { from: "/listing/odeon-cineplex-rawalpindi", to: "/listing/cinemas-and-theaters/odeon-cineplex-|-rawalpindi-|-7414" },
  { from: "/listing/auditorium-rawalpindi", to: "/listing/cinemas-and-theaters/auditorium|-rawalpindi-|-7417" },
  { from: "/listing/odean-cinepex-rawalpindi", to: "/listing/cinemas-and-theaters/odean-cinepex-|-rawalpindi-|-7420" },
  { from: "/listing/rialto-theater-rawalpindi", to: "/listing/cinemas-and-theaters/rialto-theater-|-rawalpindi-|-7423" },
  { from: "/listing/super-advertising-company-advertiser-in-gujranwala-2", to: "/listing/advertisers/super-advertising-company-|-advertiser-in-gujranwala-7426" },
  { from: "/listing/colorman-advertising-advertiser-in-gujranwala", to: "/listing/advertisers/colorman-advertising-|-advertiser-in-gujranwala-7428" },
  { from: "/listing/colorman-printing-advertising-printing-in-gujranwala", to: "/listing/printing/colorman-printing-&-advertising-|-printing-in-gujranwala-7430" },
  { from: "/listing/fazal-book-depo-book-stores-in-gujranwala", to: "/listing/book-stores/fazal-book-depo-|-book-stores-in-gujranwala-7433" },
  { from: "/listing/rizwan-book-center-and-stationary-shop-book-stores-in-gujranwala", to: "/listing/book-stores/rizwan-book-center-and-stationary-shop-|-book-stores-in-gujranwala-7437" },
  {
    from: "/listing/abdullah-book-center-educational-advisor-gujranwala-abcg-book-stores-in-gujranwala",
    to: "/listing/book-stores/abdullah-book-center-&-educational-advisor-gujranwala-(abcg)-|-book-stores-in-gujranwala-7440",
  },
  { from: "/listing/book-zone-book-stores-in-gujranwala", to: "/listing/book-stores/book-zone-|-book-stores-in-gujranwala-7444" },
  { from: "/listing/allied-book-co-rawalpindi", to: "/listing/book-stores/allied-book-co-|-rawalpindi-|-7451" },
  { from: "/listing/idris-book-bank-rawalpindi", to: "/listing/book-stores/idris-book-bank-|-rawalpindi-|-7455" },
  { from: "/listing/the-good-book-shop-rawalpindi", to: "/listing/book-stores/the-good-book-shop-|-rawalpindi-|-7458" },
  { from: "/listing/malik-old-book-shop-rawalpindi", to: "/listing/book-stores/malik-old-book-shop-|-rawalpindi-|-7461" },
  { from: "/listing/prince-books-rawalpindi", to: "/listing/book-stores/prince-books-|-rawalpindi-|-7464" },
  { from: "/listing/students-book-company-rawalpindi", to: "/listing/book-stores/students-book-company-|-rawalpindi-|-7467" },
  { from: "/listing/majeed-book-store-rawalpindi", to: "/listing/book-stores/majeed-book-store-|-rawalpindi-|-7470" },
  { from: "/listing/book-plus-rawalpindi", to: "/listing/book-stores/book-plus-|-rawalpindi-|-7473" },
  { from: "/listing/ahmed-book-corporation-rawalpindi", to: "/listing/book-stores/ahmed-book-corporation-|-rawalpindi-|-7476" },
  { from: "/listing/al-aziz-book-centre-rawalpindi", to: "/listing/book-stores/al-aziz-book-centre-|-rawalpindi-|-7479" },
  { from: "/listing/public-book-shop-rawalpindi", to: "/listing/book-stores/public-book-shop-|-rawalpindi-|-7482" },
  { from: "/listing/it-solution-laptop-repairing-rawalpindi", to: "/listing/computer-repair/it-solution-laptop-repairing--|-rawalpindi-|-7485" },
  { from: "/listing/umer-computers-rawalpindi", to: "/listing/computer-repair/umer-computers-|-rawalpindi-|-7488" },
  { from: "/listing/dell-authorized-repair-center-warranty-center-rawalpindi", to: "/listing/computer-repair/dell-authorized-repair-center-(warranty-center)-|-rawalpindi-|-7491" },
  { from: "/listing/st-computers-networks-rawalpindi", to: "/listing/computer-repair/st-computers-&-networks-|-rawalpindi-|-7495" },
  { from: "/listing/israr-computers-electronics-rawalpindi", to: "/listing/computer-repair/israr-computers-&-electronics-|-rawalpindi-|-7498" },
  { from: "/listing/laptop-repairing-center-rawalpindi", to: "/listing/computer-repair/laptop-repairing-center-|-rawalpindi-|-7501" },
  { from: "/listing/computer-services-and-support-specialist-rawalpindi", to: "/listing/computer-repair/computer-services-and-support-specialist-|-rawalpindi-|-7504" },
  { from: "/listing/future-tech-rawalpindi", to: "/listing/computer-repair/future-tech-|-rawalpindi-|-7507" },
  { from: "/listing/haier-computer-service-centre-rawalpindi", to: "/listing/computer-repair/haier-computer-service-centre-|-rawalpindi-|-7510" },
  { from: "/listing/d-k-computers-rawalpindi", to: "/listing/computer-repair/d-k-computers-|-rawalpindi-|-7514" },
  { from: "/listing/hussain-computer-rawalpindi", to: "/listing/computer-repair/hussain-computer-|-rawalpindi-|-7517" },
  { from: "/listing/al-basit-computer-rawalpindi", to: "/listing/computer-repair/al-basit-computer-|-rawalpindi-|-7520" },
  { from: "/listing/toyota-denting-painting-in-gujranwala", to: "/listing/car-denting-painting/toyota-denting-&-painting-in-gujranwala-7523" },
  {
    from: "/listing/a-one-industry-muslim-bus-body-makers-denting-painting-in-gujranwala",
    to: "/listing/car-denting-painting/a-one-industry---muslim-bus-body-makers-|-denting-&-painting-in-gujranwala-7526",
  },
  { from: "/listing/platinum-carwash-car-washer-in-gujranwala", to: "/listing/car-washers/platinum-carwash-|-car-washer-in-gujranwala-7528" },
  {
    from: "/listing/professional-auto-spa-car-wash-service-center-car-washer-in-gujranwala",
    to: "/listing/car-washers/professional-auto-spa-car-wash-&-service-center-|-car-washer-in-gujranwala-7532",
  },
  { from: "/listing/swiss-automatic-car-wash-car-washer-in-gujranwala", to: "/listing/car-washers/swiss-automatic-car-wash-|-car-washer-in-gujranwala-7536" },
  { from: "/listing/adfirm-advertising-advertiser-in-faisalabad", to: "/listing/advertisers/adfirm-advertising-|-advertiser-in-faisalabad-7573" },
  { from: "/listing/media-touch-communication-advertiser-in-faisalabad", to: "/listing/advertisers/media-touch-communication-|-advertiser-in-faisalabad-7575" },
  { from: "/listing/klikx-digital-marketing-in-faisalabad", to: "/listing/digital-marketing/klikx-|-digital-marketing-in-faisalabad-7579" },
  { from: "/listing/zia-book-sellers-book-stores-in-faisalabad", to: "/listing/book-stores/zia-book-sellers-|-book-stores-in-faisalabad-7582" },
  { from: "/listing/majeed-book-depot-book-stores-in-faisalabad", to: "/listing/book-stores/majeed-book-depot-|-book-stores-in-faisalabad-7585" },
  { from: "/listing/kitab-markaz-book-stores-in-faisalabad", to: "/listing/book-stores/kitab-markaz-|-book-stores-in-faisalabad-7588" },
  { from: "/listing/book-mart-book-stores-in-faisalabad", to: "/listing/book-stores/book-mart-|-book-stores-in-faisalabad-7590" },
  { from: "/listing/hollywood-beauty-parlor-faisalabad", to: "/listing/beauty-parlour/hollywood-beauty-parlor-faisalabad-7592" },
  { from: "/listing/patola-beauty-parlour-faisalabad", to: "/listing/beauty-parlour/patola-beauty-parlour-faisalabad-7596" },
  { from: "/listing/allenora-annies-signature-beauty-parlour-in-faisalabad", to: "/listing/beauty-parlour/alle'nora-annie's-signature-|-beauty-parlour-in-faisalabad-7599" },
  { from: "/listing/signature-by-eram-beauty-parlour-in-faisalabad", to: "/listing/beauty-parlour/signature-by-eram-|-beauty-parlour-in-faisalabad-7603" },
  { from: "/listing/aroosa-beauty-salon-beauty-parlour-in-faisalabad", to: "/listing/beauty-parlour/aroosa-beauty-salon-|-beauty-parlour-in-faisalabad-7606" },
  { from: "/listing/family-dental-clinic-lahore", to: "/listing/dental/family-dental-clinic-|-lahore-7610" },
  { from: "/listing/dental-smile-specialist-clinic-lahore-2", to: "/listing/dental/dental-smile-specialist-clinic-|-lahore-7612" },
  { from: "/listing/smile-line-specialist-dental-surgery-lahore", to: "/listing/dental/smile-line---specialist-dental-surgery-|-lahore-7614" },
  { from: "/listing/aamirs-dental-surgery-lahore", to: "/listing/dental/aamir's-dental-surgery-|-lahore-7617" },
  { from: "/listing/alina-dental-clinic-lahore", to: "/listing/dental/alina-dental-clinic-|-lahore-7622" },
  { from: "/listing/roxy-cinema-cinemas-and-theaters-in-gujranwala", to: "/listing/cinemas-and-theaters/roxy-cinema-|-cinemas-and-theaters-in-gujranwala-7635" },
  { from: "/listing/cinepax-cinemas-and-theaters-in-gujranwala", to: "/listing/cinemas-and-theaters/cinepax-|-cinemas-and-theaters-in-gujranwala-7637" },
  { from: "/listing/opera-cinema-cinemas-and-theaters-in-gujranwala", to: "/listing/cinemas-and-theaters/the-opera-3d-|-cinemas-and-theaters-in-gujranwala-7642" },
  { from: "/listing/zinco-cine-max-cinemas-and-theaters-in-gujranwala", to: "/listing/cinemas-and-theaters/zinco-cine-max-|-cinemas-and-theaters-in-gujranwala-7645" },
  {
    from: "/listing/umeed-e-nau-clinic-for-drugs-addiction-treatment-and-psychiatric-clinic-in-gujranwala",
    to: "/listing/doctors-and-clinics/umeed-e-nau-clinic-for-drugs-addiction-treatment-and-psychiatric-|-clinic-in-gujranwala-7648",
  },
  { from: "/listing/amina-fatima-clinic-clinics-in-gujranwala", to: "/listing/doctors-and-clinics/amina-fatima-clinic-|-clinics-in-gujranwala-7651" },
  { from: "/listing/hamdard-dental-skin-clinic-clinics-in-gujranwala", to: "/listing/doctors-and-clinics/hamdard-dental-&-skin-clinic-|-clinics-in-gujranwala-7653" },
  { from: "/listing/doctor-rubina-anwar-clinic-clinics-in-gujranwala", to: "/listing/doctors-and-clinics/doctor-rubina-anwar-clinic-|-clinics-in-gujranwala-7657" },
  {
    from: "/listing/major-eye-clinic-dr-irfan-qayyum-malik-assc-professor-of-ophthalmology-clinics-in-gujranwala",
    to: "/listing/doctors-and-clinics/major-eye-clinic-(dr-irfan-qayyum-malik)-assc-professor-of-ophthalmology-|-clinics-in-gujranwala-7659",
  },
  { from: "/listing/seo-services-in-lahore", to: "/listing/digital-marketing/seo-services-in-lahore-7704" },
  { from: "/listing/diana-driving-school-lahore", to: "/listing/driving-schools/diana-driving-school-|-lahore-7772" },
  { from: "/listing/jannat-driving-school-lahore", to: "/listing/driving-schools/jannat-driving-school-|-lahore-7775" },
  { from: "/listing/al-qaim-driving-school-lahore", to: "/listing/driving-schools/al-qaim-driving-school-|-lahore-7778" },
  { from: "/listing/mahnoor-driving-school-lahore", to: "/listing/driving-schools/mahnoor-driving-school-|-lahore-7781" },
  { from: "/listing/punjab-driving-school-lahore", to: "/listing/driving-schools/punjab-driving-school-|-lahore-7784" },
  { from: "/listing/younis-the-best-driving-school-lahore", to: "/listing/driving-schools/younis-the-best-driving-school-|-lahore-7787" },
  { from: "/listing/haseeb-driving-school-lahore", to: "/listing/driving-schools/haseeb-driving-school-|-lahore-7789" },
  { from: "/listing/mahfooz-jewellers-lahore", to: "/listing/jewelers/mahfooz-jewellers-|-lahore-7799" },
  { from: "/listing/neemar-jewelers-lahore", to: "/listing/jewelers/neemar-jewelers-|-lahore-7802" },
  { from: "/listing/haroon-sharif-jewellers-lahore", to: "/listing/jewelers/haroon-sharif-jewellers-|-lahore-7805" },
  { from: "/listing/grace-jewellers-lahore", to: "/listing/jewelers/grace-jewellers-|-lahore-7809" },
  { from: "/listing/al-fazal-jewelers-lahore", to: "/listing/jewelers/al-fazal-jewelers-|-lahore-7812" },
  { from: "/listing/aliyaan-jewellers-lahore", to: "/listing/jewelers/aliyaan-jewellers-|-lahore-7815" },
  { from: "/listing/almas-jewellers-lahore", to: "/listing/jewelers/almas-jewellers-|-lahore-7817" },
  { from: "/listing/areezay-jeweller-lahore", to: "/listing/jewelers/areezay-jeweller-|-lahore-7821" },
  { from: "/listing/university-of-the-punjab-gujranwala-campus-universities-in-gujranwala", to: "/listing/universities/university-of-the-punjab,-gujranwala-campus-|-universities-in-gujranwala-7841" },
  { from: "/listing/janjua-tailors-niaz-ahmed-tailors-in-gujranwala", to: "/listing/tailors-and-alterations/janjua-tailors-niaz-ahmed-|-tailors-in-gujranwala-7845" },
  { from: "/listing/taj-centre-garments-tailors-tailors-in-gujranwala", to: "/listing/tailors-and-alterations/taj-centre-garments-&-tailors-|-tailors-in-gujranwala-7848" },
  { from: "/listing/royal-tailors-and-queen-boutique-tailors-in-gujranwala", to: "/listing/tailors-and-alterations/royal-tailors-and-queen-boutique|-tailors-in-gujranwala-7850" },
  { from: "/listing/oxford-tailors-tailors-in-gujranwala", to: "/listing/tailors-and-alterations/oxford-tailors-|-tailors-in-gujranwala-7853" },
  { from: "/listing/baba-tailors-shop-tailors-in-gujranwala", to: "/listing/tailors-and-alterations/baba-tailors-shop-|-tailors-in-gujranwala-7856" },
  { from: "/listing/karachi-tailors-tailors-in-gujranwala", to: "/listing/tailors-and-alterations/karachi-tailors-|-tailors-in-gujranwala-7858" },
  { from: "/listing/ms-tailors-tailors-in-gujranwala", to: "/listing/tailors-and-alterations/ms-tailors-|-tailors-in-gujranwala-7860" },
  { from: "/listing/gift-university-universities-in-gujranwala", to: "/listing/universities/gift-university-|-universities-in-gujranwala-7865" },
  { from: "/listing/gujranwala-medical-college-colleges-in-gujranwala", to: "/listing/colleges/gujranwala-medical-college-|-colleges-in-gujranwala-7869" },
  { from: "/listing/uet-rachna-college-of-engineering-technology-colleges-in-gujranwala", to: "/listing/colleges/uet-rachna-college-of-engineering-&-technology-|-colleges-in-gujranwala-7873" },
  { from: "/listing/government-college-gujranwala-colleges-in-gujranwala", to: "/listing/colleges/government-college-gujranwala-|-colleges-in-gujranwala-7876" },
  { from: "/listing/punjab-college-of-information-technology-colleges-in-gujranwala", to: "/listing/colleges/punjab-college-of-information-technology-|-colleges-in-gujranwala-7879" },
  { from: "/listing/islamia-college-gujranwala-colleges-in-gujranwala", to: "/listing/colleges/islamia-college-gujranwala-|-colleges-in-gujranwala-7883" },
  { from: "/listing/punjab-college-of-commerce-gujranwala-colleges-in-gujranwala", to: "/listing/colleges/punjab-college-of-commerce-gujranwala-|-colleges-in-gujranwala-7887" },
  { from: "/listing/sherwani-estate-property-dealers-in-gujranwala", to: "/listing/property-development/sherwani-estate-|-property-dealers-in-gujranwala-7889" },
  { from: "/listing/al-haram-real-estate-property-dealers-in-gujranwala", to: "/listing/property-development/al-haram-real-estate-|-property-dealers-in-gujranwala-7892" },
  { from: "/listing/gateway-estate-builders-property-dealers-in-gujranwala", to: "/listing/property-development/gateway-estate-&-builders-|-property-dealers-in-gujranwala-7894" },
  { from: "/listing/palm-city-gujranwala-property-dealers-in-gujranwala", to: "/listing/property-development/palm-city-gujranwala-|-property-dealers-in-gujranwala-7898" },
  { from: "/listing/kings-real-estate-gujranwala-property-dealers-in-gujranwala", to: "/listing/property-development/kings-real-estate-gujranwala-|-property-dealers-in-gujranwala-7900" },
  { from: "/listing/new-british-home-property-dealer-property-dealers-in-gujranwala", to: "/listing/property-development/new-british-home-&-property-dealer-|-property-dealers-in-gujranwala-7903" },
  { from: "/listing/rawalpindi-institute-of-art-design", to: "/listing/designer/rawalpindi-institute-of-art-&-design-|-rawalpindi-|-7910" },
  { from: "/listing/gmt-design-studio-rawalpindi", to: "/listing/designer/gmt-design-studio-|-rawalpindi-|-7915" },
  { from: "/listing/hareer-designer-wear-rawalpindi", to: "/listing/designer/hareer-designer-wear-|-rawalpindi-|-7919" },
  { from: "/listing/web-design-company-rawalpindi", to: "/listing/designer/web-design-company-rawalpindi-7922" },
  { from: "/listing/ailtires-design-studio-rawalpindi", to: "/listing/designer/ailtire’s-design-studio-|-rawalpindi-|-7925" },
  { from: "/listing/oasis-international-high-school-schools-in-gujranwala", to: "/listing/driving-schools/oasis-international-high-school-|-schools-in-gujranwala-7928" },
  { from: "/listing/jadeed-dastgir-ideal-high-school-schools-in-gujranwala", to: "/listing/driving-schools/jadeed-dastgir-ideal-high-school-|-schools-in-gujranwala-7931" },
  { from: "/listing/lahore-grammar-school-schools-in-gujranwala", to: "/listing/driving-schools/lahore-grammar-school-|-schools-in-gujranwala-7934" },
  { from: "/listing/the-city-nursery-school-schools-in-gujranwala", to: "/listing/driving-schools/the-city-nursery-school-|-schools-in-gujranwala-7937" },
  { from: "/listing/american-lyceum-international-school-schools-in-gujranwala", to: "/listing/driving-schools/american-lyceum-international-school-|-schools-in-gujranwala-7941" },
  { from: "/listing/dar-e-arqam-school-schools-in-gujranwala", to: "/listing/driving-schools/dar-e-arqam-school-|-schools-in-gujranwala-7944" },
  { from: "/listing/abdullah-motor-car-driving-centre-driving-schools-in-gujranwala", to: "/listing/driving-schools/abdullah-motor-car-driving-centre-|-driving-schools-in-gujranwala-7947" },
  { from: "/listing/city-driving-centre-driving-schools-in-gujranwala", to: "/listing/driving-schools/city-driving-centre-|-driving-schools-in-gujranwala-7949" },
  { from: "/listing/police-driving-training-school-driving-schools-in-gujranwala", to: "/listing/driving-schools/police-driving-training-school-|-driving-schools-in-gujranwala-7951" },
  { from: "/listing/shahbaz-driving-centre-driving-schools-in-gujranwala", to: "/listing/driving-schools/shahbaz-driving-centre-|-driving-schools-in-gujranwala-7954" },
  { from: "/listing/maira-fashion-designer-bridal-store-rawalpindi", to: "/listing/designer/maira-fashion---designer-bridal-store-|-rawalpindi-|-7957" },
  { from: "/listing/warda-designer-collection-rawalpindi", to: "/listing/designer/warda-designer-collection-|-rawalpindi-|-7960" },
  { from: "/listing/trands-taylors-and-designers-rawalpindi", to: "/listing/designer/trands-taylors-and-designers-|-rawalpindi-|-7963" },
  { from: "/listing/shazia-kiyani-studio-rawalpindi", to: "/listing/designer/shazia-kiyani-studio-|-rawalpindi-|-7966" },
  { from: "/listing/fusion-rawalpindi", to: "/listing/designer/mohsin-dulha-designers-&-stitching-|-rawalpindi-|-7969" },
  { from: "/listing/ramlas-designer-wear-rawalpindi", to: "/listing/designer/ramla's-designer-wear-|-rawalpindi-|-7972" },
  { from: "/listing/sheetal-fashion-gallery-rawalpindi", to: "/listing/designer/sheetal-fashion-gallery-|-rawalpindi-|-7975" },
  { from: "/listing/roopali-designers-rawalpindi", to: "/listing/designer/roopali-designers-|-rawalpindi-|-7978" },
  { from: "/listing/sohni-tailors-dress-designers-rawalpindi", to: "/listing/designer/sohni-tailors-&-dress-designers-|-rawalpindi-|-7981" },
  {
    from: "/listing/digicrawl-website-designing-local-seo-services-digital-marketing-advertising-agency-rawalpindi",
    to: "/listing/digital-marketing/digicrawl---website-designing---local-seo-services---digital-marketing---advertising-agency-|-rawalpindi-|-8028",
  },
  { from: "/listing/digital-marketing-training-rawalpindi", to: "/listing/digital-marketing/digital-marketing-training-|-rawalpindi-|-8031" },
  { from: "/listing/seo-top-comapny-in-islamabad-wikisol-rawalpindi", to: "/listing/digital-marketing/seo-top-comapny-in-islamabad-wikisol-|-rawalpindi-|-8035" },
  { from: "/listing/mss-digital-media-arts-rawalpindi-2", to: "/listing/digital-marketing/mss-digital-media-arts-|-rawalpindi-|-8038" },
  { from: "/listing/catalyst-technologies-rawalpindi", to: "/listing/digital-marketing/catalyst-technologies-|-rawalpindi-|-8041" },
  {
    from: "/listing/web-business-listings-of-pakistan-google-listings-local-seo-digital-marketing-rawalpindi",
    to: "/listing/digital-marketing/web-business-listings-of-pakistan-(-google-listings,-local-seo,-digital-marketing-)-|-rawalpindi-|-8044",
  },
  { from: "/listing/brand-experts-pvt-ltd-rawalpindi-2", to: "/listing/digital-marketing/brand-experts-(pvt.)-ltd-|-rawalpindi-|-8048" },
  { from: "/listing/swis-max-solutions-rawalpindi", to: "/listing/digital-marketing/swis-max-solutions-|-rawalpindi-|-8051" },
  { from: "/listing/digitally-up-rawalpindi", to: "/listing/digital-marketing/digitally-up-|-rawalpindi-|-8054" },
  {
    from: "/listing/seo-services-in-islamabad-seo-company-in-pakistan-brand-promotion-company-in-pakistan-solheight-rawalpindi",
    to: "/listing/digital-marketing/seo-services-in-islamabad,-seo-company-in-pakistan,-brand-promotion-company-in-pakistan-|-solheight-|-rawalpindi-|-8057",
  },
  { from: "/listing/go-digital-rawalpindi", to: "/listing/digital-marketing/go-digital-|-rawalpindi-|-8060" },
  { from: "/listing/md-palace-marquee-wedding-hall-in-gujranwala", to: "/listing/wedding-halls/md-palace-&-marquee-|-wedding-hall-in-gujranwala-8062" },
  { from: "/listing/heaven-castle-wedding-hall-in-gujranwala", to: "/listing/wedding-halls/heaven-castle-|-wedding-hall-in-gujranwala-8067" },
  { from: "/listing/k-event-and-wedding-halls-wedding-hall-in-gujranwala", to: "/listing/wedding-halls/k-event-and-wedding-halls-|-wedding-hall-in-gujranwala-8071" },
  { from: "/listing/muqeem-marriage-hall-wedding-hall-in-gujranwala", to: "/listing/wedding-halls/muqeem-marriage-hall-|-wedding-hall-in-gujranwala-8075" },
  { from: "/listing/punjab-palace-wedding-hall-in-gujranwala", to: "/listing/wedding-halls/punjab-palace-|-wedding-hall-in-gujranwala-8077" },
  { from: "/listing/dream-events-marquee-wedding-planner-in-gujranwala", to: "/listing/weddings-planner/dream-events-marquee-|-wedding-planner-in-gujranwala-8079" },
  { from: "/listing/asia-caterers-wedding-planner-in-gujranwala", to: "/listing/weddings-planner/asia-caterers-|-wedding-planner-in-gujranwala-8083" },
  { from: "/listing/plantastic-events-wedding-events-planner-in-gujranwala", to: "/listing/weddings-planner/plantastic-events-|-wedding-&-events-planner-in-gujranwala-8086" },
  { from: "/listing/leads-science-education-tutors-in-gujranwala", to: "/listing/tutoring/leads-science-education-|-tutors-in-gujranwala-8116" },
  { from: "/listing/sheraz-azhar-tutors-in-gujranwala", to: "/listing/tutoring/sheraz-azhar-|-tutors-in-gujranwala-8118" },
  { from: "/listing/m-nafy-javed-tutors-in-gujranwala", to: "/listing/tutoring/m-nafy-javed-|-tutors-in-gujranwala-8120" },
  { from: "/listing/calco-computer-computer-repair-in-gujranwala", to: "/listing/computer-repair/calco-computer-|-computer-repair-in-gujranwala-8122" },
  { from: "/listing/ask-computers-computer-repairs-in-gujranwala", to: "/listing/computer-repair/ask-computers-|-computer-repairs-in-gujranwala-8125" },
  { from: "/listing/skylo-computers-computer-repairs-in-gujranwala", to: "/listing/computer-repair/skylo-computers-|-computer-repairs-in-gujranwala-8128" },
  { from: "/listing/chishtiya-it-corner-computer-repairs-in-gujranwala", to: "/listing/computer-repair/chishtiya-it-corner-|-computer-repairs-in-gujranwala-8130" },
  { from: "/listing/demaf-access-traders-computer-repairs-in-gujranwala", to: "/listing/computer-repair/demaf-access-traders-|-computer-repairs-in-gujranwala-8132" },
  { from: "/listing/arc-construction-company-constructions-in-gujranwala", to: "/listing/construction-services/arc-construction-company-|-constructions-in-gujranwala-8144" },
  { from: "/listing/roshan-marketing-constructions-companies-in-gujranwala", to: "/listing/construction-services/roshan-marketing-|-constructions-companies-in-gujranwala-8147" },
  { from: "/listing/sia-engineers-contractors-constructions-companies-in-gujranwala", to: "/listing/construction-services/sia-engineers-&-contractors-|-constructions-companies-in-gujranwala-8149" },
  {
    from: "/listing/sheran-wali-kothi-rana-brothers-constructions-companies-in-gujranwala",
    to: "/listing/construction-services/sheran-wali-kothi-rana-brothers-|-constructions-companies-in-gujranwala-8151",
  },
  {
    from: "/listing/dc-colony-engineering-branch-constructions-companies-in-gujranwala",
    to: "/listing/construction-services/dc-colony-engineering-branch-|-constructions-companies-in-gujranwala-8153",
  },
  { from: "/listing/j-a-n-constructions-companies-in-gujranwala", to: "/listing/construction-services/j.a.n-|-constructions-companies-in-gujranwala-8155" },
  { from: "/listing/mughal-mahal-hotel-hotels-in-gujranwala", to: "/listing/hotels/mughal-mahal-hotel-|-hotels-in-gujranwala-8157" },
  { from: "/listing/rachna-pearl-hotel-hotels-in-gujranwala", to: "/listing/hotels/rachna-pearl-hotel-|-hotels-in-gujranwala-8160" },
  { from: "/listing/marian-hotel-hotels-in-gujranwala", to: "/listing/hotels/marian-hotel-|-hotels-in-gujranwala-8164" },
  { from: "/listing/aleena-hotel-hotels-in-gujranwala", to: "/listing/hotels/aleena-hotel-|-hotels-in-gujranwala-8167" },
  { from: "/listing/royal-gold-gym-gym-in-gujranwala", to: "/listing/gym/royal-gold-gym-|-gym-in-gujranwala-8179" },
  { from: "/listing/world-gym-gym-in-gujranwala", to: "/listing/gym/world-gym-|-gym-in-gujranwala-8184" },
  { from: "/listing/shapes-gym-gym-in-gujranwala", to: "/listing/gym/shapes-gym-|-gym-in-gujranwala-8187" },
  { from: "/listing/reshape-gym-gym-in-gujranwala", to: "/listing/gym/reshape-gym-|-gym-in-gujranwala-8189" },
  { from: "/listing/citi-fitness-center-gym-in-gujranwala", to: "/listing/gym/citi-fitness-center-|-gym-in-gujranwala-8191" },
  { from: "/listing/depilex-gym-in-gujranwala", to: "/listing/gym/depilex-|-gym-in-gujranwala-8193" },
  { from: "/listing/mnr-design-studio-designer-in-gujranwala", to: "/listing/designer/mnr-design-studio-|-designer-in-gujranwala-8225" },
  { from: "/listing/nashwa-designer-fabrics-designer-in-gujranwala", to: "/listing/designer/nashwa-designer-fabrics-|-designer-in-gujranwala-8230" },
  { from: "/listing/design-bar-designer-in-gujranwala", to: "/listing/designer/design-bar-|-designer-in-gujranwala-8234" },
  { from: "/listing/fas-design-studio-designer-in-gujranwala", to: "/listing/designer/fas-design-studio-|-designer-in-gujranwala-8238" },
  { from: "/listing/khaadi-kings-mall-designer-in-gujranwala", to: "/listing/designer/khaadi---kings-mall-|-designer-in-gujranwala-8243" },
  { from: "/listing/sharjah-swimming-pool-in-gujranwala", to: "/listing/swimming-pools/sharjah-swimming-pool-in-gujranwala-8246" },
  { from: "/listing/al-madinah-swimming-pool-in-gujranwala", to: "/listing/swimming-pools/al-madinah-swimming-pool-in-gujranwala-8248" },
  { from: "/listing/good-life-swimming-pool-in-gujranwala", to: "/listing/swimming-pools/good-life-swimming-pool-in-gujranwala-8250" },
  { from: "/listing/aquatic-club-rahwali-swimming-pool-in-gujranwala", to: "/listing/swimming-pools/aquatic-club-rahwali-|-swimming-pool-in-gujranwala-8253" },
  { from: "/listing/hostel-for-boys-student-hostel-in-gujranwala", to: "/listing/student-hostels/hostel-for-boys-|-student-hostel-in-gujranwala-8255" },
  { from: "/listing/gift-university-boys-hostel-student-hostel-in-gujranwala", to: "/listing/student-hostels/gift-university-boys-hostel-|-student-hostel-in-gujranwala-8257" },
  { from: "/listing/boyz-hostel-chenabianz-student-hostel-in-gujranwala", to: "/listing/student-hostels/boyz-hostel-chenabianz-|-student-hostel-in-gujranwala-8259" },
  { from: "/listing/girls-hostel-student-hostel-in-gujranwala", to: "/listing/student-hostels/girls-hostel-|-student-hostel-in-gujranwala-8262" },
  { from: "/listing/rahat-dental-clinic-dental-in-gujranwala", to: "/listing/dental/rahat-dental-clinic-|-dental-in-gujranwala-8370" },
  { from: "/listing/dental-care-dental-in-gujranwala", to: "/listing/dental/dental-care-|-dental-in-gujranwala-8374" },
  { from: "/listing/smile-dental-care-dental-in-gujranwala", to: "/listing/dental/smile-dental-care-|-dental-in-gujranwala-8376" },
  { from: "/listing/hamdard-dental-skin-clinic-dental-in-gujranwala", to: "/listing/dental/hamdard-dental-&-skin-clinic-|-dental-in-gujranwala-8378" },
  { from: "/listing/asim-qayyum-jewellers-jewellers-in-gujranwala", to: "/listing/jewelers/asim-qayyum-jewellers-|-jewellers-in-gujranwala-8381" },
  { from: "/listing/new-patiala-jewellers-jewellers-in-gujranwala", to: "/listing/jewelers/new-patiala-jewellers-|-jewellers-in-gujranwala-8385" },
  { from: "/listing/aqsa-jewellers-jewellers-in-gujranwala", to: "/listing/jewelers/aqsa-jewellers-|-jewellers-in-gujranwala-8389" },
  { from: "/listing/capital-jewellers-jewellers-in-gujranwala", to: "/listing/jewelers/capital-jewellers-|-jewellers-in-gujranwala-8394" },
  { from: "/listing/hafeez-jewellers-jewellers-in-gujranwala", to: "/listing/jewelers/hafeez-jewellers-|-jewellers-in-gujranwala-8396" },
  {
    from: "/listing/shaukat-khanum-laboratory-collection-centre-laboratories-in-gujranwala",
    to: "/listing/laboratories/shaukat-khanum-laboratory-collection-centre-|-laboratories-in-gujranwala-8409",
  },
  { from: "/listing/chughtai-lab-laboratories-in-gujranwala", to: "/listing/laboratories/chughtai-lab-|-laboratories-in-gujranwala-8413" },
  { from: "/listing/citi-lab-rc-collection-centre-laboratories-in-gujranwala", to: "/listing/laboratories/citi-lab-&-rc-collection-centre-|-laboratories-in-gujranwala-8418" },
  { from: "/listing/excel-labs-collection-point-laboratories-in-gujranwala", to: "/listing/laboratories/excel-labs-collection-point-|-laboratories-in-gujranwala-8421" },
  {
    from: "/listing/aga-khan-university-hospital-laboratory-specimen-collection-unit-laboratories-in-gujranwala",
    to: "/listing/laboratories/aga-khan-university-hospital-laboratory-specimen-collection-unit-|-laboratories-in-gujranwala-8424",
  },
  { from: "/listing/saad-clinical-laboratory-laboratories-in-gujranwala", to: "/listing/laboratories/saad-clinical-laboratory-|-laboratories-in-gujranwala-8427" },
  { from: "/listing/health-laboratories-laboratories-in-gujranwala", to: "/listing/laboratories/health-laboratories-|-laboratories-in-gujranwala-8429" },
  { from: "/listing/tajdar-law-associates-lawyer-in-gujranwala", to: "/listing/lawyers/tajdar-law-associates-|-lawyer-in-gujranwala-8431" },
  { from: "/listing/hassan-driving-school-rawalpindi", to: "/listing/driving-schools/hassan-driving-school|-rawalpindi-|-8501" },
  { from: "/listing/iqra-driving-school-rawalpindi", to: "/listing/driving-schools/iqra-driving-school-|-rawalpindi-|-8504" },
  { from: "/listing/geo-driving-school-rawalpindi", to: "/listing/driving-schools/geo-driving-school-|-rawalpindi-|-8505" },
  { from: "/listing/hassan-driving-training-centre-rawalpindi", to: "/listing/driving-schools/hassan-driving-&-training-centre-|-rawalpindi-|-8508" },
  { from: "/listing/ali-driving-school-rawalpindi", to: "/listing/driving-schools/ali-driving-school-|-rawalpindi-|-8512" },
  { from: "/listing/hassan-driving-school-estate-agency-rawalpindi", to: "/listing/driving-schools/hassan-driving-school-&-estate-agency-|-rawalpindi-|-8514" },
  { from: "/listing/ahmed-ali-shah-driving-school-rawalpindi", to: "/listing/driving-schools/ahmed-ali-shah-driving-school--|-rawalpindi-|-8517" },
  { from: "/listing/arif-driving-school-rawalpindi", to: "/listing/driving-schools/arif-driving-school-|-rawalpindi-|-8520" },
  { from: "/listing/naqeebi-driving-insitute-rawalpindi", to: "/listing/driving-schools/naqeebi-driving-insitute-|-rawalpindi-|-8523" },
  { from: "/listing/al-sheikh-driving-centre-rawalpindi", to: "/listing/driving-schools/al-sheikh-driving-centre-|-rawalpindi-|-8526" },
  { from: "/listing/uzair-driving-school-for-ladies-rawalpindi", to: "/listing/driving-schools/uzair-driving-school-for-ladies-|-rawalpindi-|-8529" },
  { from: "/listing/ahmad-aslam-advocate-lawyer-in-gujranwala", to: "/listing/lawyers/ahmad-aslam-advocate-|-lawyer-in-gujranwala-8533" },
  { from: "/listing/umar-law-associates-lawyer-in-gujranwala", to: "/listing/lawyers/umar-law-associates-|-lawyer-in-gujranwala-8535" },
  { from: "/listing/touseef-ahmad-advocate-lawyer-in-gujranwala", to: "/listing/lawyers/touseef-ahmad-advocate-|-lawyer-in-gujranwala-8539" },
  { from: "/listing/logical-lawyers-international-lawyer-in-gujranwala", to: "/listing/lawyers/logical-lawyers-international-|-lawyer-in-gujranwala-8541" },
  { from: "/listing/jinnah-library-libraries-in-gujranwala", to: "/listing/libraries/jinnah-library-|-libraries-in-gujranwala-8546" },
  { from: "/listing/sheikh-din-muhammad-library-libraries-in-gujranwala", to: "/listing/libraries/sheikh-din-muhammad-library-|-libraries-in-gujranwala-8549" },
  { from: "/listing/murtaza-kamal-library-libraries-in-gujranwala", to: "/listing/libraries/murtaza-kamal-library-|-libraries-in-gujranwala-8551" },
  { from: "/listing/alfanoos-library-libraries-in-gujranwala", to: "/listing/libraries/alfanoos-library-|-libraries-in-gujranwala-8553" },
  { from: "/listing/art-image-advertising-advertisers-in-multan", to: "/listing/advertisers/art-image-advertising-|-advertisers-in-multan-8556" },
  { from: "/listing/amc-advertiser-advertisers-in-multan", to: "/listing/advertisers/amc-advertiser-|-advertisers-in-multan-8559" },
  { from: "/listing/3d-art-advertisers-in-multan", to: "/listing/advertisers/3d-art-|-advertisers-in-multan-8562" },
  { from: "/listing/cosmos-beauty-salon-beauty-parlour-in-multan", to: "/listing/beauty-parlour/cosmos-beauty-salon-|-beauty-parlour-in-multan-8565" },
  { from: "/listing/allenora-annie-signature-salon-beauty-parlour-in-multan", to: "/listing/beauty-parlour/allenora-annie-signature-salon-|-beauty-parlour-in-multan-8568" },
  { from: "/listing/depilex-beauty-parlour-in-multan", to: "/listing/beauty-parlour/depilex-|-beauty-parlour-in-multan-8572" },
  { from: "/listing/haleema-beauty-parlour-beauty-parlour-in-multan", to: "/listing/beauty-parlour/haleema-beauty-parlour-|-beauty-parlour-in-multan-8578" },
  { from: "/listing/mehak-beauty-parlour-beauty-parlour-in-multan", to: "/listing/beauty-parlour/mehak-beauty-parlour-|-beauty-parlour-in-multan-8580" },
  { from: "/listing/mhk-electrical-rawalpindi", to: "/listing/electrician/mhk-electrical-|-rawalpindi-|-8612" },
  { from: "/listing/ashraf-sons-electric-concern-rawalpindi", to: "/listing/electrician/ashraf-&-sons-electric-concern-|-rawalpindi-|-8615" },
  { from: "/listing/iesco-office-kamalabad-rawalpindi", to: "/listing/electrician/iesco-office-kamalabad-|-rawalpindi-|-8618" },
  { from: "/listing/hi-tech-engineers-rawalpindi", to: "/listing/electrician/hi-tech-engineers-|-rawalpindi-|-8621" },
  { from: "/listing/shaeen-engineers-rawalpindi", to: "/listing/electrician/shaeen-engineers-|-rawalpindi-|-8628" },
  { from: "/listing/yasir-electrician-rawalpindi", to: "/listing/electrician/yasir-electrician-|-rawalpindi-|-8631" },
  { from: "/listing/shoukat-auto-efi-electrician-rawalpindi", to: "/listing/electrician/shoukat-auto-efi-electrician-|-rawalpindi-|-8634" },
  { from: "/listing/islamabad-farm-houses-rawalpindi", to: "/listing/farm-houses/islamabad-farm-houses-|-rawalpindi-|-8639" },
  { from: "/listing/royal-farm-houses-rawalpindi", to: "/listing/farm-houses/royal-farm-houses-|-rawalpindi-|-8641" },
  { from: "/listing/tarar-farm-house-rawalpindi", to: "/listing/farm-houses/tarar-farm-house-|-rawalpindi-|-8644" },
  { from: "/listing/chaudhrys-family-farm-rawalpindi", to: "/listing/farm-houses/chaudhrys-family-farm-|-rawalpindi-|-8646" },
  { from: "/listing/aoba-rawalpindi", to: "/listing/farm-houses/aoba-|-rawalpindi-|-8649" },
  { from: "/listing/janjua-farm-house-rawalpindi", to: "/listing/farm-houses/janjua-farm-house-|-rawalpindi-|-8652" },
  { from: "/listing/hair-transplant-in-lahore", to: "/listing/hospitals/hair-transplant-in-lahore-8675" },
  {
    from: "/listing/ijaz-denting-painting-works-auto-electrician-car-denting-painting-in-faisalabad",
    to: "/listing/car-denting-painting/ijaz-denting-painting-works-&-auto-electrician-|-car-denting-painting-in-faisalabad-8678",
  },
  {
    from: "/listing/tariq-mehmood-denting-painting-centre-car-denting-painting-in-faisalabad",
    to: "/listing/car-denting-painting/tariq-mehmood-denting-&-painting-centre-|-car-denting-painting-in-faisalabad-8681",
  },
  {
    from: "/listing/haji-bala-denting-painting-workshop-car-denting-painting-in-faisalabad",
    to: "/listing/car-denting-painting/haji-&-bala-denting-painting-workshop-|-car-denting-painting-in-faisalabad-8684",
  },
  {
    from: "/listing/mac-wash-automatic-car-wash-detailing-centre-car-washer-in-faisalabad",
    to: "/listing/car-washers/mac-wash---automatic-car-wash-&-detailing-centre-|-car-washer-in-faisalabad-8686",
  },
  {
    from: "/listing/auto-chef-automatic-car-wash-detailing-studio-car-washer-in-faisalabad",
    to: "/listing/car-washers/auto-chef---automatic-car-wash-&-detailing-studio-|-car-washer-in-faisalabad-8689",
  },
  { from: "/listing/ah-dogar-car-wash-car-washer-in-faisalabad", to: "/listing/car-washers/ah-dogar-car-wash-|-car-washer-in-faisalabad-8691" },
  { from: "/listing/quick-wash-car-washer-in-faisalabad", to: "/listing/car-washers/quick-wash-|-car-washer-in-faisalabad-8693" },
  { from: "/listing/cineone-3d-cinema-cinemas-theaters-in-faisalabad", to: "/listing/cinemas-and-theaters/cineone-3d-cinema-|-cinemas-&-theaters-in-faisalabad-8696" },
  { from: "/listing/minerva-cinema-complex-cinemas-theaters-in-faisalabad", to: "/listing/cinemas-and-theaters/minerva-cinema-complex-|-cinemas-&-theaters-in-faisalabad-8698" },
  { from: "/listing/taj-mahal-multiplex-cinemas-theaters-in-faisalabad", to: "/listing/cinemas-and-theaters/taj-mahal-multiplex-|-cinemas-&-theaters-in-faisalabad-8702" },
  { from: "/listing/nagina-cineplex-4k-3d-cinemas-theaters-in-faisalabad", to: "/listing/cinemas-and-theaters/nagina-cineplex-4k-3d-|-cinemas-&-theaters-in-faisalabad-8705" },
  { from: "/listing/regal-cinema-cinemas-theaters-in-faisalabad", to: "/listing/cinemas-and-theaters/regal-cinema-|-cinemas-&-theaters-in-faisalabad-8709" },
  { from: "/listing/shabnam-cinema-cinemas-theaters-in-faisalabad", to: "/listing/cinemas-and-theaters/shabnam-cinema-|-cinemas-&-theaters-in-faisalabad-8712" },
  { from: "/listing/dr-tariq-javed-dental-clinic-dental-in-faisalabad", to: "/listing/dental/dr-tariq-javed-dental-clinic-|-dental-in-faisalabad-8714" },
  { from: "/listing/azeem-afzal-dental-surgery-centre-dental-in-faisalabad", to: "/listing/dental/azeem-afzal-dental-surgery-centre-|-dental-in-faisalabad-8717" },
  { from: "/listing/fatima-dental-surgery-clinic-dental-in-faisalabad", to: "/listing/dental/fatima-dental-surgery-clinic-|-dental-in-faisalabad-8719" },
  { from: "/listing/shifa-dental-care-dental-in-faisalabad", to: "/listing/dental/shifa-dental-care-|-dental-in-faisalabad-8723" },
  { from: "/listing/dental-solutions-dental-in-faisalabad", to: "/listing/dental/dental-solutions-|-dental-in-faisalabad-8726" },
  { from: "/listing/dr-waqar-jeelani-dental-and-orthodontic-practice-dental-in-faisalabad", to: "/listing/dental/dr.-waqar-jeelani-dental-and-orthodontic-practice-|-dental-in-faisalabad-8729" },
  { from: "/listing/desi-home-cooked-delicious-food-lahore-2", to: "/listing/home-delivery-food/desi-home-cooked-delicious-food-|-lahore-8956" },
  { from: "/listing/lajiao-kitchen-home-delivery-lahore", to: "/listing/home-delivery-food/lajiao-kitchen-home-delivery-|-lahore-8958" },
  { from: "/listing/bundu-khan-lahore", to: "/listing/home-delivery-food/bundu-khan-|-lahore-8962" },
  { from: "/listing/mei-kong-faisal-town-lahore", to: "/listing/home-delivery-food/mei-kong-faisal-town-|-lahore-8966" },
  { from: "/listing/homemade-cuisine-lahore", to: "/listing/home-delivery-food/homemade-cuisine-|-lahore-8969" },
  { from: "/listing/tabaq-restaurant-lahore", to: "/listing/home-delivery-food/tabaq-restaurant-|-lahore-8972" },
  { from: "/listing/salt-n-pepper-mall-road-lahore", to: "/listing/home-delivery-food/salt'-n-pepper-mall-road-|-lahore-8975" },
  { from: "/listing/kfc-lahore", to: "/listing/home-delivery-food/kfc-|-lahore-8979" },
  { from: "/listing/bar-b-q-tonight-lahore", to: "/listing/home-delivery-food/bar.b.q-tonight-|-lahore-8982" },
  { from: "/listing/tariq-cars-car-dealer-in-multan", to: "/listing/car-dealer/tariq-cars-|-car-dealer-in-multan-9104" },
  { from: "/listing/kazmi-motors-car-dealer-in-multan", to: "/listing/car-dealer/kazmi-motors-|-car-dealer-in-multan-9106" },
  { from: "/listing/suzuki-multan-motors-car-dealer-in-multan", to: "/listing/car-dealer/suzuki-multan-motors-|-car-dealer-in-multan-9110" },
  { from: "/listing/toyota-city-motors-car-dealer-in-multan", to: "/listing/car-dealer/toyota-city-motors-|-car-dealer-in-multan-9117" },
  { from: "/listing/faw-motors-car-dealer-in-multan", to: "/listing/car-dealer/faw-motors-|-car-dealer-in-multan-9121" },
  {
    from: "/listing/7-speed-auto-detailing-glass-coating-car-denting-painting-in-multan",
    to: "/listing/car-denting-painting/7-speed-auto-detailing-&-glass-coating-|-car-denting-painting-in-multan-9124",
  },
  { from: "/listing/wash-x-car-washer-in-multan", to: "/listing/car-washers/wash-x-|-car-washer-in-multan-9136" },
  { from: "/listing/detailing-studio-car-washer-in-multan", to: "/listing/car-washers/detailing-studio-|-car-washer-in-multan-9139" },
  { from: "/listing/sherwani-rent-a-car-car-rental-in-multan", to: "/listing/car-rental/sherwani-rent-a-car-|-car-rental-in-multan-9141" },
  { from: "/listing/ali-bhai-rent-a-car-car-rental-in-multan", to: "/listing/car-rental/ali-bhai-rent-a-car-|-car-rental-in-multan-9144" },
  { from: "/listing/bisma-travel-rent-a-car-car-rental-in-multan", to: "/listing/car-rental/bisma-travel-(rent-a-car)-|-car-rental-in-multan-9146" },
  { from: "/listing/brothers-rent-a-car-car-rental-in-multan", to: "/listing/car-rental/brothers-rent-a-car-|-car-rental-in-multan-9150" },
  { from: "/listing/panjnad-rent-a-car-car-rental-in-multan", to: "/listing/car-rental/panjnad-rent-a-car-|-car-rental-in-multan-9156" },
  { from: "/listing/rehman-rent-a-car-car-rental-in-multan", to: "/listing/car-rental/rehman-rent-a-car-|-car-rental-in-multan-9162" },
  { from: "/listing/abdul-malik-wood-workshop-carpenter-in-multan", to: "/listing/carpenter/abdul-malik-wood-workshop-|-carpenter-in-multan-9207" },
  { from: "/listing/aamir-furniture-workshop-carpenter-in-multan", to: "/listing/carpenter/aamir-furniture-workshop-|-carpenter-in-multan-9209" },
  { from: "/listing/irfan-workworks-contractor-carpenter-in-multan", to: "/listing/carpenter/irfan-workworks-contractor-|-carpenter-in-multan-9212" },
  { from: "/listing/pakistan-woodworks-carpenter-in-multan", to: "/listing/carpenter/pakistan-woodworks-|-carpenter-in-multan-9215" },
  { from: "/listing/lucky-steel-wood-works-carpenter-in-multan", to: "/listing/carpenter/lucky-steel-&-wood-works-|-carpenter-in-multan-9217" },
  { from: "/listing/lahore-choice-wood-works-carpenter-in-multan", to: "/listing/carpenter/lahore-choice-wood-works-|-carpenter-in-multan-9219" },
  { from: "/listing/universal-cinemas-the-grand-cinemas-and-theaters-in-multan", to: "/listing/cinemas-and-theaters/universal-cinemas---the-grand-|-cinemas-and-theaters-in-multan-9221" },
  { from: "/listing/dynamic-online-technologies", to: "/listing/software-company/dynamic-online-technologies-9247" },
  { from: "/listing/cine-star-cinemas-and-theaters-in-multan", to: "/listing/cinemas-and-theaters/cine-star-|-cinemas-and-theaters-in-multan-9303" },
  { from: "/listing/rex-cinema-cinemas-and-theaters-in-multan", to: "/listing/cinemas-and-theaters/rex-cinema-|-cinemas-and-theaters-in-multan-9316" },
  { from: "/listing/dreamland-cinema-cinemas-and-theaters-in-multan", to: "/listing/cinemas-and-theaters/dreamland-cinema-|-cinemas-and-theaters-in-multan-9319" },
  { from: "/listing/hashmat-mahal-cinema-cinemas-and-theaters-in-multan", to: "/listing/cinemas-and-theaters/hashmat-mahal-cinema-|-cinemas-and-theaters-in-multan-9322" },
  { from: "/listing/starlite-cinema-cinemas-and-theaters-in-multan", to: "/listing/cinemas-and-theaters/starlite-cinema-|-cinemas-and-theaters-in-multan-9324" },
  { from: "/listing/gulshan-e-iqbal-park-lahore", to: "/listing/parks/gulshan-e-iqbal-park-|-lahore-9490" },
  { from: "/listing/sozo-water-park-lahore", to: "/listing/parks/sozo-water-park-|-lahore-9500" },
  { from: "/listing/modern-pets-hospital-lahore", to: "/listing/pets-hospitals/modern-pets-hospital-|-lahore-9578" },
  { from: "/listing/jamshed-pets-hospital-lahore", to: "/listing/pets-hospitals/jamshed-pets-hospital-|-lahore-9581" },
  { from: "/listing/general-veterinary-hospital-lahore", to: "/listing/pets-hospitals/general-veterinary-hospital-|-lahore-9585" },
  { from: "/listing/asim-pets-clinic-lahore", to: "/listing/pets-hospitals/asim-pets-clinic-|-lahore-9588" },
  { from: "/listing/spring-clinic-medical-clinic-in-multan", to: "/listing/doctors-and-clinics/spring-clinic-|-medical-clinic-in-multan-9621" },
  { from: "/listing/behtar-zindagi-center-medical-clinic-in-multan", to: "/listing/doctors-and-clinics/behtar-zindagi-center-|-medical-clinic-in-multan-9627" },
  { from: "/listing/marie-stopes-society-clinics-medical-clinic-in-multan", to: "/listing/doctors-and-clinics/marie-stopes-society-clinics-|-medical-clinic-in-multan-9630" },
  { from: "/listing/multan-cancer-clinic-medical-clinic-in-multan", to: "/listing/doctors-and-clinics/multan-cancer-clinic-|-medical-clinic-in-multan-9651" },
  { from: "/listing/dr-ejaz-hussain-malik-clinic-medical-clinic-in-multan", to: "/listing/doctors-and-clinics/dr.-ejaz-hussain-malik-clinic-|-medical-clinic-in-multan-9653" },
  { from: "/listing/nishat-college-of-science-colleges-in-multan", to: "/listing/colleges/nishat-college-of-science-|-colleges-in-multan-9655" },
  { from: "/listing/punjab-college-of-commerce-colleges-in-multan", to: "/listing/colleges/punjab-college-of-commerce-|-colleges-in-multan-9658" },
  { from: "/listing/government-college-of-technology-colleges-in-multan", to: "/listing/colleges/government-college-of-technology-|-colleges-in-multan-9661" },
  { from: "/listing/admire-college-of-science-and-commerce-colleges-in-multan", to: "/listing/colleges/admire-college-of-science-and-commerce-|-colleges-in-multan-9663" },
  { from: "/listing/govt-degree-college-colleges-in-multan", to: "/listing/colleges/govt-degree-college-|-colleges-in-multan-9666" },
  { from: "/listing/prince-computers-computer-repairs-in-multan", to: "/listing/computer-repair/prince-computers-|-computer-repairs-in-multan-9669" },
  { from: "/listing/al-rehman-computers-laptops-computer-repairs-in-multan", to: "/listing/computer-repair/al-rehman-computers-&-laptops-|-computer-repairs-in-multan-9671" },
  { from: "/listing/millennium-computer-computer-repairs-in-multan", to: "/listing/computer-repair/millennium-computer-|-computer-repairs-in-multan-9674" },
  { from: "/listing/qureshi-computers-laptops-computer-repairs-in-multan", to: "/listing/computer-repair/qureshi-computers-&-laptops-|-computer-repairs-in-multan-9677" },
  { from: "/listing/computer-forum-computer-repairs-in-multan", to: "/listing/computer-repair/computer-forum-|-computer-repairs-in-multan-9680" },
  { from: "/listing/ghulam-rasool-company-construction-in-multan", to: "/listing/construction-services/ghulam-rasool-&-company-|-construction-in-multan-9913" },
  { from: "/listing/thaheem-construction-company-construction-in-multan", to: "/listing/construction-services/thaheem-construction-company-|-construction-in-multan-9915" },
  { from: "/listing/sheikh-abdur-razzaq-company-construction-in-multan", to: "/listing/construction-services/sheikh-abdur-razzaq-&-company-|-construction-in-multan-9919" },
  { from: "/listing/iftikhar-construction-company-construction-in-multan", to: "/listing/construction-services/iftikhar-construction-company-|-construction-in-multan-9923" },
  { from: "/listing/grc-company-shahdi-khail-construction-in-multan", to: "/listing/construction-services/grc-company-shahdi-khail-|-construction-in-multan-9925" },
  { from: "/listing/dental-pearl-clinic-dental-in-multan", to: "/listing/dental/dental-pearl-clinic-|-dental-in-multan-9928" },
  { from: "/listing/darkroom-clicks-lahore-3", to: "/listing/photographer/darkroom-clicks-|-lahore-9940" },
  { from: "/listing/maaz-studio-lahore-3", to: "/listing/photographer/maaz-studio-|-lahore-9943" },
  { from: "/listing/aliza-waqar-photography-studio-lahore-2", to: "/listing/photographer/aliza-waqar-photography-studio-|-lahore-9946" },
  { from: "/listing/momentography-studios-lahore-3", to: "/listing/photographer/momentography-studios-|-lahore-9949" },
  { from: "/listing/xpressions-photography-lahore", to: "/listing/photographer/xpressions-photography-|-lahore-9952" },
  { from: "/listing/qureshi-dental-clinic-dental-in-multan", to: "/listing/dental/qureshi-dental-clinic-|-dental-in-multan-9975" },
  { from: "/listing/raheela-memorial-dental-clinic-dental-in-multan", to: "/listing/dental/raheela-memorial-dental-clinic-|-dental-in-multan-9978" },
  { from: "/listing/align-dental-care-dental-in-multan", to: "/listing/dental/align-dental-care-|-dental-in-multan-9980" },
  { from: "/listing/gentle-dental-care-dental-in-multan", to: "/listing/dental/gentle-dental-care-|-dental-in-multan-9983" },
  { from: "/listing/detailing-studio-car-denting-painting-in-multan", to: "/listing/car-denting-painting/detailing-studio-|-car-denting-painting-in-multan-9988" },
  { from: "/listing/m3-glamorus-international-beauty-salon-lahore-3", to: "/listing/salons/m3-glamorus-international-beauty-salon-|-lahore-10114" },
  { from: "/listing/cosmo-salon-lahore-3", to: "/listing/salons/cosmo-salon-|-lahore-10165" },
  { from: "/listing/shumailas-beauty-london-lahore-2", to: "/listing/salons/shumaila’s-beauty-london-|-lahore-10168" },
  { from: "/listing/amina-z-salon-make-up-studio-lahore-3", to: "/listing/salons/amina-z-salon-&amp;-make-up-studio-|-lahore-10170" },
  { from: "/listing/blazon-salon-lahore-3", to: "/listing/salons/blazon-salon-|-lahore-10175" },
  { from: "/listing/promoters-advertising-in-islamabad-5", to: "/listing/advertisers/promoters-advertising-in-islamabad-10236" },
  { from: "/listing/head-to-toe-beauty-salon-in-islamabad", to: "/listing/beauty-parlour/head-to-toe-|-beauty-salon-in-islamabad-10241" },
  { from: "/listing/bravo-security-services-lahore-3", to: "/listing/security-guard/bravo-security-services-|-lahore-10307" },
  { from: "/listing/security-guard-services-company-lahore-2", to: "/listing/security-guard/security-guard-services-company-|-lahore-10312" },
  { from: "/listing/blink-security-services-lahore-3", to: "/listing/security-guard/blink-security-services-|-lahore-10315" },
  { from: "/listing/universe-security-lahore-3", to: "/listing/security-guard/universe-security-|-lahore-10318" },
  { from: "/listing/emporium-mall-by-nishat-group-lahore-3", to: "/listing/shopping-malls/emporium-mall-by-nishat-group-|-lahore-10325" },
  { from: "/listing/mall-of-lahore-lahore-3", to: "/listing/shopping-malls/mall-of-lahore-|-lahore-10328" },
  { from: "/listing/packages-mall-lahore-3", to: "/listing/shopping-malls/packages-mall-|-lahore-10331" },
  { from: "/listing/fortress-square-mall-lahore-3", to: "/listing/shopping-malls/fortress-square-mall-|-lahore-10336" },
  { from: "/listing/studio-3-designer-in-multan", to: "/listing/designer/studio-3-|-designer-in-multan-10413" },
  { from: "/listing/fashion-pakistan-lounge-designer-in-multan", to: "/listing/designer/fashion-pakistan-lounge-|-designer-in-multan-10417" },
  { from: "/listing/leera-latta-designer-in-multan", to: "/listing/designer/leera-latta-|-designer-in-multan-10420" },
  { from: "/listing/shan-e-alam-designer-in-multan", to: "/listing/designer/shan-e-alam-|-designer-in-multan-10423" },
  { from: "/listing/paracha-interiors-collection-designer-in-multan", to: "/listing/designer/paracha-interiors-collection-|-designer-in-multan-10426" },
  { from: "/listing/shoutify-me-digital-marketing-in-multan", to: "/listing/digital-marketing/shoutify-me-|-digital-marketing-in-multan-10428" },
  { from: "/listing/black-matter-digital-marketing-in-multan", to: "/listing/digital-marketing/black-matter-|-digital-marketing-in-multan-10431" },
  { from: "/listing/orm-digital-solutions-digital-marketing-in-multan", to: "/listing/digital-marketing/orm-digital-solutions-|-digital-marketing-in-multan-10434" },
  { from: "/listing/digital-media-communication-digital-marketing-in-multan", to: "/listing/digital-marketing/digital-media-&-communication-|-digital-marketing-in-multan-10439" },
  { from: "/listing/nielsen-digital-marketing-in-multan", to: "/listing/digital-marketing/nielsen-|-digital-marketing-in-multan-10442" },
  { from: "/listing/perfect-car-driving-school-driving-schools-in-multan", to: "/listing/driving-schools/perfect-car-driving-school-|-driving-schools-in-multan-10486" },
  { from: "/listing/city-traffic-police-driving-school-driving-schools-in-multan", to: "/listing/driving-schools/city-traffic-police-driving-school-|-driving-schools-in-multan-10490" },
  { from: "/listing/highway-driving-institute-driving-schools-in-multan", to: "/listing/driving-schools/highway-driving-institute-|-driving-schools-in-multan-10491" },
  { from: "/listing/geo-car-driving-school-driving-schools-in-multan", to: "/listing/driving-schools/geo-car-driving-school-|-driving-schools-in-multan-10493" },
  { from: "/listing/faheem-driving-school-driving-schools-in-multan", to: "/listing/driving-schools/faheem-driving-school-|-driving-schools-in-multan-10496" },
  { from: "/listing/ustad-online-electricians-in-multan", to: "/listing/electrician/ustad-online-|-electricians-in-multan-10499" },
  { from: "/listing/umarsgroup-electricians-in-multan", to: "/listing/electrician/umarsgroup-|-electricians-in-multan-10503" },
  { from: "/listing/rab-nawaz-auto-electrician-electricians-in-multan", to: "/listing/electrician/rab-nawaz-auto-electrician-|-electricians-in-multan-10506" },
  { from: "/listing/auction-sheet-verification", to: "/listing/verification-services/auction-sheet-verification-10784" },
  { from: "/listing/five-flags-family-farmhouse-farmhouse-in-multan", to: "/listing/farm-houses/five-flags-family-farmhouse-|-farmhouse-in-multan-10853" },
  { from: "/listing/buppi-farmhouse-chah-jummay-wala-farmhouse-in-multan", to: "/listing/farm-houses/buppi-farmhouse-(chah-jummay-wala)-|-farmhouse-in-multan-10855" },
  { from: "/listing/ener-gym-gym-in-multan", to: "/listing/gym/ener-gym-|-gym-in-multan-10857" },
  { from: "/listing/cross-fit-gym-fitness-center-gym-in-multan", to: "/listing/gym/cross-fit-gym-&-fitness-center-|-gym-in-multan-10860" },
  { from: "/listing/evolve-health-fitness-centre-gym-in-multan", to: "/listing/gym/evolve-health-&-fitness-centre-|-gym-in-multan-10863" },
  { from: "/listing/saltn-pepper-home-delivery-food-in-multan", to: "/listing/home-delivery-food/salt'n-pepper-|-home-delivery-food-in-multan-10866" },
  { from: "/listing/saltn-pepper-restaurants-in-multan", to: "/listing/restaurants/salt'n-pepper-|-restaurants-in-multan-10869" },
  { from: "/listing/almaida-pizza-garden-home-delivery-food-in-multan", to: "/listing/home-delivery-food/almaida-pizza-garden-|-home-delivery-food-in-multan-10874" },
  { from: "/listing/almaida-pizza-garden-restaurant-in-multan", to: "/listing/restaurants/almaida-pizza-garden-|-restaurant-in-multan-10875" },
  { from: "/listing/lasani-restaurant-chargha-house-restaurant-in-multan", to: "/listing/restaurants/lasani-restaurant-&-chargha-house-|-restaurant-in-multan-10879" },
  { from: "/listing/lasani-restaurant-chargha-house-home-delivery-food-in-multan", to: "/listing/home-delivery-food/lasani-restaurant-&-chargha-house-|-home-delivery-food-in-multan-10880" },
  { from: "/listing/azeem-hospital-hospital-in-multan", to: "/listing/hospitals/azeem-hospital-|-hospital-in-multan-10883" },
  { from: "/listing/bakhtawar-amin-memorial-hospital-hospital-in-multan", to: "/listing/hospitals/bakhtawar-amin-memorial-hospital-|-hospital-in-multan-10885" },
  {
    from: "/listing/the-childrens-hospital-the-institute-of-child-health-hospital-in-multan",
    to: "/listing/hospitals/the-children's-hospital-&-the-institute-of-child-health-|-hospital-in-multan-10889",
  },
  { from: "/listing/ramada-multan-hotels-in-multan", to: "/listing/hotels/ramada-multan-|-hotels-in-multan-11204" },
  { from: "/listing/hotel-one-hotels-in-multan", to: "/listing/hotels/hotel-one-|-hotels-in-multan-11209" },
  { from: "/listing/raywall-executive-suites-hotels-in-multan", to: "/listing/hotels/raywall-executive-suites-|-hotels-in-multan-11212" },
  { from: "/listing/wahid-jeweller-jewellers-in-multan", to: "/listing/jewelers/wahid-jeweller-|-jewellers-in-multan-11214" },
  { from: "/listing/al-khadim-jeweller-jewellers-in-multan", to: "/listing/jewelers/al-khadim-jeweller-|-jewellers-in-multan-11217" },
  { from: "/listing/chishtiah-jewellers-jewellers-in-multan", to: "/listing/jewelers/chishtiah-jewellers-|-jewellers-in-multan-11219" },
  { from: "/listing/agha-khan-lab-laboratories-in-multan", to: "/listing/laboratories/agha-khan-lab-|-laboratories-in-multan-11221" },
  { from: "/listing/chughtai-lab-laboratories-in-multan", to: "/listing/laboratories/chughtai-lab-|-laboratories-in-multan-11228" },
  { from: "/listing/shaukat-khanum-laboratory-collection-centre-laboratories-in-multan", to: "/listing/laboratories/shaukat-khanum-laboratory-collection-centre-|-laboratories-in-multan-11234" },
  { from: "/listing/libra-law-associates-lawyers-in-multan", to: "/listing/lawyers/libra-law-associates-|-lawyers-in-multan-11236" },
  { from: "/listing/advocate-moulvi-qadeer-lawyers-in-multan", to: "/listing/lawyers/advocate-moulvi-qadeer-|-lawyers-in-multan-11239" },
  { from: "/listing/al-rehman-law-associates-lawyers-in-multan", to: "/listing/lawyers/al-rehman-law-associates-|-lawyers-in-multan-11246" },
  { from: "/listing/garrison-public-library-libraries-in-multan", to: "/listing/libraries/garrison-public-library-|-libraries-in-multan-11249" },
  { from: "/listing/angels-beauty-salon-islamabad-3", to: "/listing/salons/angels-beauty-salon-|-islamabad-11256" },
  { from: "/listing/mitsubishi-islamabad-motors-faw-motors-3", to: "/listing/car-dealer/mitsubishi-islamabad-motors---faw-motors-11355" },
  { from: "/listing/madina-motors-islamabad-2", to: "/listing/car-dealer/madina-motors-|-islamabad-11359" },
  { from: "/listing/car-ambassador-islamabad-2", to: "/listing/car-dealer/car-ambassador-islamabad-11361" },
  { from: "/listing/multi-line-book-store-islamabad", to: "/listing/book-stores/multi-line-book-store-|-islamabad-11363" },
  { from: "/listing/vanguard-books-islamabad-2", to: "/listing/book-stores/vanguard-books-|-islamabad-11365" },
  { from: "/listing/new-book-fair-islamabad-2", to: "/listing/book-stores/new-book-fair-|-islamabad-11367" },
  { from: "/listing/five-friends-auto-denting-painting-accidental-works-islamabad-2", to: "/listing/car-denting-painting/five-friends-auto-denting-painting-accidental-works-|-islamabad-11621" },
  { from: "/listing/cantt-library-libraries-in-multan", to: "/listing/libraries/cantt-library-|-libraries-in-multan-11623" },
  { from: "/listing/central-library-libraries-in-multan", to: "/listing/libraries/central-library-|-libraries-in-multan-11626" },
  { from: "/listing/taiba-creations-mobile-app-development-in-multan", to: "/listing/mobile-app-development/taiba-creations-|-mobile-app-development-in-multan-11629" },
  { from: "/listing/taiba-creations-website-development-in-multan", to: "/listing/website-development/taiba-creations-|-website-development-in-multan-11635" },
  { from: "/listing/jinnah-park-park-in-multan", to: "/listing/parks/jinnah-park-|-park-in-multan-11636" },
  { from: "/listing/chaman-zar-e-multan-parks-in-multan", to: "/listing/parks/chaman-zar-e-multan-|-parks-in-multan-11640" },
  { from: "/listing/allama-iqbal-park-parks-in-multan", to: "/listing/parks/allama-iqbal-park-|-parks-in-multan-11643" },
  { from: "/listing/solex-chemical-pvt-ltd-pest-control-in-multan", to: "/listing/pest-control/solex-chemical-(pvt)-ltd.-|-pest-control-in-multan-11645" },
  { from: "/listing/new-agri-care-pest-control-in-multan", to: "/listing/pest-control/new-agri-care-|-pest-control-in-multan-11648" },
  { from: "/listing/suncrop-industries-pvt-ltd-pest-control-in-multan", to: "/listing/pest-control/suncrop-industries-pvt.-ltd.-|-pest-control-in-multan-11652" },
  { from: "/listing/multan-pets-clinic-pets-hospitals-in-multan", to: "/listing/pets-hospitals/multan-pets-clinic-|-pets-hospitals-in-multan-11656" },
  { from: "/listing/khan-pets-veterinary-clinic-pets-hospitals-in-multan", to: "/listing/pets-hospitals/khan-pets-&-veterinary-clinic-|-pets-hospitals-in-multan-11659" },
  { from: "/listing/fahad-pets-clinic-pets-hospitals-in-multan", to: "/listing/pets-hospitals/fahad-pets-clinic-|-pets-hospitals-in-multan-11661" },
  { from: "/listing/waqas-sh-photography-photographers-in-multan", to: "/listing/photographer/waqas-sh-photography-|-photographers-in-multan-11664" },
  { from: "/listing/nabeel-photographer-photographers-in-multan", to: "/listing/photographer/nabeel-photographer-|-photographers-in-multan-11669" },
  { from: "/listing/imagehouse-studio-photographers-in-multan", to: "/listing/photographer/imagehouse-studio-|-photographers-in-multan-11672" },
  {
    from: "/listing/dmotorsports-automotive-workshop-ac-workshop-paint-denting-workshop-islamabad-2",
    to: "/listing/car-denting-painting/dmotorsports-automotive-workshop-&-ac-workshop-&-paint-denting-workshop-|-islamabad-11685",
  },
  { from: "/listing/car-denting-service-islamabad-2", to: "/listing/car-denting-painting/car-denting-service-|-islamabad-11687" },
  { from: "/listing/islamabad-rent-a-car-2", to: "/listing/car-rental/islamabad-rent-a-car-11689" },
  { from: "/listing/haris-rent-a-car-islamabad-3", to: "/listing/car-rental/haris-rent-a-car-|-islamabad-11691" },
  { from: "/listing/afridi-rent-a-car-islamabad", to: "/listing/car-rental/afridi-rent-a-car-islamabad-11694" },
  { from: "/listing/bismillah-goods-transport-company-pickups-and-trucks-in-multan", to: "/listing/pickups-and-trucks/bismillah-goods-transport-company-|-pickups-and-trucks-in-multan-11970" },
  { from: "/listing/talal-goods-transport-company-pickups-and-trucks-in-multan", to: "/listing/pickups-and-trucks/talal-goods-transport-company-|-pickups-and-trucks-in-multan-11972" },
  { from: "/listing/plumber-ustad-online-plumber-in-multan", to: "/listing/plumbers/plumber-ustad.online-|-plumber-in-multan-11974" },
  { from: "/listing/abbas-sanitary-store-plumber-in-multan", to: "/listing/plumbers/abbas-sanitary-store-|-plumber-in-multan-11976" },
  { from: "/listing/universal-traders-plumbers-in-multan", to: "/listing/plumbers/universal-traders-|-plumbers-in-multan-11978" },
  { from: "/listing/noor-printers-printers-in-multan", to: "/listing/printing/noor-printers-|-printers-in-multan-11982" },
  { from: "/listing/rainbow-printing-solutions-printing-in-multan", to: "/listing/printing/rainbow-printing-solutions-|-printing-in-multan-11984" },
  { from: "/listing/akhtar-printing-press-printing-in-multan", to: "/listing/printing/akhtar-printing-press-|-printing-in-multan-11988" },
  { from: "/listing/cordoba-school-lahore", to: "/listing/driving-schools/cordoba-school-|-lahore-12026" },
  { from: "/listing/noor-property-dealer-property-in-multan", to: "/listing/property-development/noor-property-dealer-|-property-in-multan-12038" },
  {
    from: "/listing/fatima-jinnah-property-marketing-property-dealers-property-in-multan",
    to: "/listing/property-development/fatima-jinnah-property-marketing-&-property-dealers-|-property-in-multan-12041",
  },
  { from: "/listing/khan-property-dealer-property-in-multan", to: "/listing/property-development/khan-property-dealer-|-property-in-multan-12046" },
  { from: "/listing/cosmos-beauty-salon-salons-in-multan", to: "/listing/salons/cosmos-beauty-salon-|-salons-in-multan-12048" },
  { from: "/listing/choppers-men-salon-salons-in-multan", to: "/listing/salons/choppers-men-salon-|-salons-in-multan-12050" },
  { from: "/listing/fashion-craze-mens-salon-salons-in-multan", to: "/listing/salons/fashion-craze-men's-salon-|-salons-in-multan-12054" },
  { from: "/listing/lahore-grammar-school-schools-in-multan", to: "/listing/driving-schools/lahore-grammar-school-|-schools-in-multan-12057" },
  { from: "/listing/nishat-high-school-schools-in-multan", to: "/listing/driving-schools/nishat-high-school-|-schools-in-multan-12060" },
  { from: "/listing/learners-castle-school-schools-in-multan", to: "/listing/driving-schools/learner's-castle-school-|-schools-in-multan-12067" },
  { from: "/listing/blink-security-services-security-guards-in-multan", to: "/listing/security-guard/blink-security-services-|-security-guards-in-multan-12079" },
  {
    from: "/listing/sos-pakistan-pvt-ltd-security-organizing-system-pakistan-security-guards-in-multan",
    to: "/listing/security-guard/sos-pakistan-pvt.-ltd-(security-organizing-system-pakistan)-|-security-guards-in-multan-12082",
  },
  { from: "/listing/ar-raheem-security-services-security-guard-in-multan", to: "/listing/security-guard/ar-raheem-security-services-|-security-guard-in-multan-12085" },
  { from: "/listing/mall-of-multan-shopping-mall-in-multan", to: "/listing/shopping-malls/mall-of-multan-|-shopping-mall-in-multan-12088" },
  { from: "/listing/united-mall-gulgasht-shopping-mall-in-multan", to: "/listing/shopping-malls/united-mall-gulgasht-|-shopping-mall-in-multan-12091" },
  { from: "/listing/hafiz-brothers-advertisers-in-karachi", to: "/listing/advertisers/hafiz-brothers-advertisers-in-karachi-12293" },
  { from: "/listing/adcity-advertisers", to: "/listing/advertisers/adcity-advertisers-in-karachi-12303" },
  { from: "/listing/najlas-beauty-parlor-in-karachi-2", to: "/listing/beauty-parlour/najla's-beauty-parlor-in-karachi-12310" },
  { from: "/listing/toniguy-in-karachi", to: "/listing/beauty-parlour/toni&amp;guy--in-karachi-12347" },
  { from: "/listing/rukhsar-beauty-parlor-in-karachi-2", to: "/listing/beauty-parlour/rukhsar-beauty-parlor-in-karachi-12349" },
  { from: "/listing/nursery-advertisers-in-karachi-2", to: "/listing/advertisers/nursery-advertisers-in-karachi-12367" },
  { from: "/listing/cardeals-in-karachi", to: "/listing/car-dealer/cardeals-in-karachi-12455" },
  { from: "/listing/suzuki-north-motors-in-karachi", to: "/listing/car-dealer/suzuki-north-motors-in-karachi-12460" },
  { from: "/listing/jp-cars-in-karachi", to: "/listing/car-dealer/jp-cars-|-cars-dealers-in-karachi-12463" },
  { from: "/listing/shaikh-book-store-stationers-in-karachi", to: "/listing/book-stores/shaikh-book-store-&amp;-stationers-in-karachi-12474" },
  { from: "/listing/asif-book-uniforms-in-karachi-2", to: "/listing/book-stores/asif-book-&amp;-uniforms-in-karachi-12571" },
  { from: "/listing/aroma-rent-a-car-in-karachi", to: "/listing/car-rental/aroma-rent-a-car-in-karachi-12700" },
  { from: "/listing/rehan-rent-a-car-in-karachi-2", to: "/listing/car-rental/rehan-rent-a-car-in-karachi-12704" },
  { from: "/listing/detail-pro-karachi-in-karachi", to: "/listing/car-washers/detail-pro-karachi-in-karachi-12708" },
  { from: "/listing/neelam-beauty-salon-islamabad-2", to: "/listing/beauty-parlour/neelam-beauty-salon-|-islamabad-12713" },
  { from: "/listing/erina-beauty-salon-islamabad-3", to: "/listing/beauty-parlour/erina-beauty-salon-|-islamabad-12716" },
  { from: "/listing/ehsan-mustafa-wood-works-islamabad-2", to: "/listing/carpenter/ehsan-mustafa-wood-works-|-islamabad-12720" },
  { from: "/listing/the-handyman-pvt-limited-islamabad-3", to: "/listing/carpenter/the-handyman-pvt-limited-|-islamabad-12724" },
  { from: "/listing/lakarhara-islamabad-3", to: "/listing/carpenter/lakarhara-|-islamabad-12727" },
  { from: "/listing/sajjad-doors-and-wood-supplier-karachi", to: "/listing/carpenter/sajjad-doors-and-wood-supplier-karachi-12870" },
  { from: "/listing/easyfix-in-karachi", to: "/listing/carpenter/easyfix-in-karachi-12872" },
  { from: "/listing/capri-cinema-in-karachi-3", to: "/listing/cinemas-and-theaters/capri-cinema-in-karachi-12874" },
  { from: "/listing/mega-multiplex-cinema-millenium-mall-in-karachi", to: "/listing/cinemas-and-theaters/mega-multiplex-cinema---millenium-mall-in-karachi-12879" },
  { from: "/listing/jfc-cineplex-islamabad", to: "/listing/cinemas-and-theaters/jfc-cineplex-|-islamabad-12922" },
  { from: "/listing/infinity-eye-care-center-in-karachi", to: "/listing/doctors-and-clinics/infinity-eye-care-center-in-karachi-12924" },
  { from: "/listing/dosani-clinic-in-karachi", to: "/listing/doctors-and-clinics/dosani-clinic-in-karachi-12929" },
  { from: "/listing/hayat-computers-in-karachi", to: "/listing/computer-repair/hayat-computers-in-karachi-12933" },
  { from: "/listing/the-arena-islamabad-3", to: "/listing/cinemas-and-theaters/the-arena-|-islamabad-12938" },
  { from: "/listing/creative-hands-producation-islamabad-2", to: "/listing/cinemas-and-theaters/creative-hands-producation-|-islamabad-12941" },
  { from: "/listing/dr-muzaffar-latif-gill-clinic-islamabad-2", to: "/listing/doctors-and-clinics/dr-muzaffar-latif-gill-clinic-|-islamabad-12948" },
  { from: "/listing/laptop-doctor-in-karachi", to: "/listing/computer-repair/laptop-doctor-in-karachi-12953" },
  { from: "/listing/dr-wajahat-aziz-clinic-islamabad-2", to: "/listing/doctors-and-clinics/dr-wajahat-aziz-clinic-|-islamabad-12955" },
  { from: "/listing/imranz-medical-center-islamabad-2", to: "/listing/doctors-and-clinics/imranz-medical-center-|-islamabad-12957" },
  { from: "/listing/marcom-islamabad-3", to: "/listing/advertisers/marcom-|-islamabad-13057" },
  { from: "/listing/soon-advertising-islamabad-3", to: "/listing/advertisers/soon-advertising-|-islamabad-13063" },
  { from: "/listing/techno-wash-islamabad-2", to: "/listing/car-washers/techno-wash-|-islamabad-13071" },
  { from: "/listing/car-interior-wash-islamabad-2", to: "/listing/car-washers/car-interior-wash-|-islamabad-13073" },
  { from: "/listing/wash-corporation-islamabad-3", to: "/listing/car-washers/wash-corporation-|-islamabad-13075" },
  { from: "/listing/se-software-technologies-software-company-in-multan", to: "/listing/software-company/se-software-technologies-|-software-company-in-multan-13147" },
  { from: "/listing/edusoft-system-solutions-software-company-in-multan", to: "/listing/software-company/edusoft-system-solutions-|-software-company-in-multan-13151" },
  { from: "/listing/queper-technologies-software-company-in-multan", to: "/listing/software-company/queper-technologies-|-software-company-in-multan-13155" },
  { from: "/listing/pride-boys-hostel-student-hostels-in-multan", to: "/listing/student-hostels/pride-boys-hostel-|-student-hostels-in-multan-13158" },
  { from: "/listing/royal-boys-hostel-student-hostels-in-multan", to: "/listing/student-hostels/royal-boys-hostel-|-student-hostels-in-multan-13162" },
  { from: "/listing/cool-pool-party-swimming-pool-swimming-pools-in-multan", to: "/listing/swimming-pools/cool-pool-party-swimming-pool-|-swimming-pools-in-multan-13181" },
  { from: "/listing/rajab-ali-swimming-pool-swimming-pools-in-multan", to: "/listing/swimming-pools/rajab-ali-swimming-pool-|-swimming-pools-in-multan-13183" },
  { from: "/listing/nishtar-swimming-pool-swimming-pools-in-multan", to: "/listing/swimming-pools/nishtar-swimming-pool-|-swimming-pools-in-multan-13186" },
  { from: "/listing/al-farid-fabrics-tailors-tailors-in-multan", to: "/listing/tailors-and-alterations/al-farid-fabrics-&-tailors-|-tailors-in-multan-13189" },
  { from: "/listing/mm-alam-tailors-fabrics-tailors-in-multan", to: "/listing/tailors-and-alterations/mm-alam-tailors-&-fabrics-|-tailors-in-multan-13192" },
  { from: "/listing/prince-tailors-tailors-in-multan", to: "/listing/tailors-and-alterations/prince-tailors-|-tailors-in-multan-13194" },
  { from: "/listing/f-h-motors-in-karachi", to: "/listing/car-dealer/f-&-h-motors-in-karachi-13306" },
  { from: "/listing/the-palm-in-karachi-2", to: "/listing/weddings-planner/the-palm-in-karachi-13367" },
  { from: "/listing/shape-wedding-in-karachi", to: "/listing/weddings-planner/shape-wedding-in-karachi-13379" },
  { from: "/listing/saima-banquet-hall-in-karachi", to: "/listing/wedding-halls/saima-banquet-hall-in-karachi-13426" },
  { from: "/listing/the-clifton-marquee-in-karachi", to: "/listing/wedding-halls/the-clifton-marquee-in-karachi-13431" },
  { from: "/listing/bhutta-travels-pvt-ltd-travel-agency-in-multan", to: "/listing/travel-agents/bhutta-travels-pvt-ltd-|-travel-agency-in-multan-13466" },
  { from: "/listing/sethi-travels-tours-travel-agency-in-multan", to: "/listing/travel-agents/sethi-travels-&-tours-|-travel-agency-in-multan-13469" },
  { from: "/listing/bukhari-travel-tourism-travel-agency-in-multan", to: "/listing/travel-agents/bukhari-travel-&-tourism-|-travel-agency-in-multan-13476" },
  { from: "/listing/lgs-tutors-academy-tutor-in-multan", to: "/listing/tutoring/lgs-tutors-academy-|-tutor-in-multan-13480" },
  { from: "/listing/naqvi-academy-of-sciences-and-linguistics-tutor-in-multan", to: "/listing/tutoring/naqvi-academy-of-sciences-and-linguistics-|-tutor-in-multan-13483" },
  { from: "/listing/saadat-house-academy-tutor-in-multan", to: "/listing/tutoring/saadat-house-academy-|-tutor-in-multan-13485" },
  { from: "/listing/bahauddin-zakariya-university-universities-in-multan", to: "/listing/universities/bahauddin-zakariya-university-|-universities-in-multan-13487" },
  {
    from: "/listing/muhammad-nawaz-shareef-university-of-agriculture-universities-in-multan",
    to: "/listing/universities/muhammad-nawaz-shareef-university-of-agriculture-|-universities-in-multan-13491",
  },
  { from: "/listing/a2z-creatorz-in-karachi", to: "/listing/website-development/a2z-creatorz-in-karachi-13526" },
  { from: "/listing/vtech-in-karachi", to: "/listing/website-development/vtech-in-karachi-13529" },
  { from: "/listing/women-university-universities-in-multan", to: "/listing/universities/women-university-|-universities-in-multan-13618" },
  { from: "/listing/gerrys-visa-dropbox-visa-in-multan", to: "/listing/visa-agencies/gerry's-visa-dropbox-|-visa-in-multan-13622" },
  { from: "/listing/etimad-biometric-centre-visa-in-multan", to: "/listing/visa-agencies/etimad-biometric-centre-|-visa-in-multan-13625" },
  { from: "/listing/oz-consultancy-visa-in-multan", to: "/listing/visa-agencies/oz-consultancy-|-visa-in-multan-13628" },
  {
    from: "/listing/devmet-web-design-development-company-website-development-in-multan",
    to: "/listing/website-development/devmet-web-design-&-development-company-|-website-development-in-multan-13633",
  },
  { from: "/listing/se-software-technologies-website-development-in-multan", to: "/listing/website-development/se-software-technologies-|-website-development-in-multan-13636" },
  { from: "/listing/shah-jahan-royal-banquet-hall-wedding-hall-in-multan", to: "/listing/wedding-halls/shah-jahan-royal-banquet-hall-|-wedding-hall-in-multan-13639" },
  { from: "/listing/shahtaj-banquet-marriage-hall-wedding-hall-in-multan", to: "/listing/wedding-halls/shahtaj-banquet-&-marriage-hall-|-wedding-hall-in-multan-13642" },
  { from: "/listing/noor-mahal-marriage-hall-wedding-hall-in-multan", to: "/listing/wedding-halls/noor-mahal-marriage-hall-|-wedding-hall-in-multan-13645" },
  { from: "/listing/royal-events-wedding-planner-in-multan", to: "/listing/weddings-planner/royal-events-|-wedding-planner-in-multan-13649" },
  { from: "/listing/amir-rajput-catering-services-wedding-services-in-multan", to: "/listing/welding-services/amir-rajput-catering-services-|-wedding-services-in-multan-13653" },
  { from: "/listing/taj-mahal-marriage-club-wedding-services-in-multan", to: "/listing/welding-services/taj-mahal-marriage-club-|-wedding-services-in-multan-13656" },
  { from: "/listing/aroma-travel-in-karachi-2", to: "/listing/visa-agencies/aroma-travel-in-karachi-13749" },
  { from: "/listing/aj-express-in-karachi-2", to: "/listing/visa-agencies/aj-express-in-karachi-13754" },
  { from: "/listing/ahnaf-home-tutor-academy-in-karachi", to: "/listing/tutoring/ahnaf-home-tutor-academy-in-karachi-13771" },
  { from: "/listing/mbit-tutors-in-karachi", to: "/listing/tutoring/mbit-tutors-in-karachi-13812" },
  { from: "/listing/sana-travel-tours-in-karachi", to: "/listing/travel-agents/sana-travel-&amp;-tours-in-karachi-13820" },
  { from: "/listing/shirazi-travels-in-karachi", to: "/listing/travel-agents/shirazi-travels-in-karachi-13823" },
  { from: "/listing/trial-tailors-in-karachi", to: "/listing/tailors-and-alterations/trial-tailors-in-karachi-13897" },
  { from: "/listing/the-larkana-tailors-in-karachi", to: "/listing/tailors-and-alterations/the-larkana-tailors-in-karachi-13901" },
  { from: "/listing/paragon-constructors-pvt-ltd-in-karachi", to: "/listing/construction-services/paragon-constructors-(pvt)-ltd-in-karachi-13919" },
  { from: "/listing/ali-builders-developers-in-karachi-2", to: "/listing/construction-services/ali-builders-&amp;-developers-in-karachi-13924" },
  { from: "/listing/fatima-dental-hospital-in-karachi", to: "/listing/dental/fatima-dental-hospital-in-karachi-13947" },
  { from: "/listing/altamash-dental-clinic-in-karachi", to: "/listing/dental/altamash-dental-clinic-in-karachi-13953" },
  { from: "/listing/magnet-shopping-city-in-karachi-2", to: "/listing/shopping-malls/magnet-shopping-city-in-karachi-14000" },
  { from: "/listing/dolmen-mall-in-karachi-2", to: "/listing/shopping-malls/dolmen-mall-in-karachi-14005" },
  { from: "/listing/nabila-salon-in-karachi", to: "/listing/salons/nabila-salon-in-karachi-14010" },
  { from: "/listing/nadia-hussain-salon-in-karachi", to: "/listing/salons/nadia-hussain-salon-in-karachi-14012" },
  { from: "/listing/sakura-restaurants-in-karachi", to: "/listing/restaurants/sakura-restaurants-in-karachi-14067" },
  { from: "/listing/kolachi-restaurant-in-karachi-2", to: "/listing/restaurants/kolachi-restaurant-in-karachi-14072" },
  { from: "/listing/land-trust-real-estate-in-karachi-2", to: "/listing/property-development/land-trust-real-estate-in-karachi-14076" },
  { from: "/listing/citi-associates-in-karachi-2", to: "/listing/property-development/citi-associates-in-karachi-14080" },
  { from: "/listing/shabbir-motor-workshop-denting-painting-in-karachi", to: "/listing/car-denting-painting/shabbir-motor-workshop-&amp;-denting-painting-in-karachi-14254" },
  { from: "/listing/islamia-science-college-in-karachi", to: "/listing/colleges/islamia-science-college-in-karachi-14258" },
  { from: "/listing/jinnah-medical-dental-college-in-karachi", to: "/listing/colleges/jinnah-medical-&amp;-dental-college-in-karachi-14264" },
  { from: "/listing/global-clicks-in-karachi-2", to: "/listing/content-writers/global-clicks-in-karachi-14269" },
  { from: "/listing/mega-marketing-network-in-karachi-2", to: "/listing/content-writers/mega-marketing-network-in-karachi-14272" },
  { from: "/listing/nomi-ansari-designer-in-karachi-2", to: "/listing/designer/nomi-ansari-designer-in-karachi-14278" },
  { from: "/listing/maestro-designers-in-karachi-2", to: "/listing/designer/maestro-designers-in-karachi-14280" },
  { from: "/listing/digital-marketing-training-institute-in-karachi", to: "/listing/digital-marketing/digital-marketing-training-institute-in-karachi-14284" },
  { from: "/listing/golive-digital-engagement-company-in-karachi", to: "/listing/digital-marketing/golive---digital-engagement-company-in-karachi-14287" },
  { from: "/listing/al-hassan-driving-school-in-karachi-2", to: "/listing/driving-schools/al-hassan-driving-school-in-karachi-14292" },
  { from: "/listing/quick-motor-driving-school-in-karachi", to: "/listing/driving-schools/quick-motor-driving-school-in-karachi-14295" },
  { from: "/listing/saturn-engineering-works-in-karachi", to: "/listing/electrician/saturn-engineering-works-in-karachi-14297" },
  { from: "/listing/pak-electrical-service-in-karachi-2", to: "/listing/electrician/pak-electrical-service-in-karachi-14301" },
  { from: "/listing/shangrila-farm-house-in-karachi", to: "/listing/farm-houses/shangrila-farm-house-in-karachi-14303" },
  { from: "/listing/jb-farm-house-in-karachi", to: "/listing/farm-houses/jb-farm-house-in-karachi-14309" },
  { from: "/listing/power-gym-in-karachi-2", to: "/listing/gym/power-gym-in-karachi-14351" },
  { from: "/listing/ahmed-gym-in-karachi-2", to: "/listing/gym/ahmed-gym-in-karachi-14355" },
  { from: "/listing/home-chef-in-karachi", to: "/listing/home-delivery-food/home-chef-in-karachi-14358" },
  { from: "/listing/student-biryani-in-karachi", to: "/listing/home-delivery-food/student-biryani-in-karachi-14360" },
  { from: "/listing/patel-hospital-in-karachi", to: "/listing/hospitals/patel-hospital-in-karachi-14366" },
  { from: "/listing/pearl-continental-karachi", to: "/listing/hotels/pearl-continental-karachi-14368" },
  { from: "/listing/hotel-second-home-in-karachi-2", to: "/listing/hotels/hotel-second-home-in-karachi-14373" },
  { from: "/listing/qasmia-jewellers-in-karachi-2", to: "/listing/jewelers/qasmia-jewellers-in-karachi-14375" },
  { from: "/listing/ary-jewellers-in-karachi-2", to: "/listing/jewelers/ary-jewellers-in-karachi-14379" },
  { from: "/listing/manpower-employment-services-in-karachi-2", to: "/listing/job-providers/al-mansoor-enterprises-in-karachi-14382" },
  { from: "/listing/dmk-consultancy-international-in-karachi-2", to: "/listing/job-providers/dmk-consultancy-international-in-karachi-14388" },
  { from: "/listing/sindlab-in-karachi", to: "/listing/laboratories/sindlab-in-karachi-14393" },
  { from: "/listing/dow-lab-collection-centre-in-karachi", to: "/listing/laboratories/dow-lab-collection-centre-in-karachi-14403" },
  {
    from: "/listing/irfan-mir-halepota-associates-law-firm-advocates-civil-litigation-corporate-trademark-family-in-karachi",
    to: "/listing/lawyers/irfan-mir-halepota-&amp;-associates-law-firm-advocates-civil-litigation-corporate-trademark-family-in-karachi-14406",
  },
  { from: "/listing/vellani-and-vellani-in-karachi-2", to: "/listing/lawyers/vellani-and-vellani-in-karachi-14408" },
  { from: "/listing/british-council-library-in-karachi", to: "/listing/libraries/british-council-library-in-karachi-14416" },
  { from: "/listing/allama-iqbal-library-in-karachi-2", to: "/listing/libraries/allama-iqbal-library-in-karachi-14418" },
  { from: "/listing/greenland-swimming-pool-in-karachi", to: "/listing/swimming-pools/greenland-swimming-pool-in-karachi-14420" },
  { from: "/listing/shehzad-swimming-pool-in-karachi-2", to: "/listing/swimming-pools/shehzad-swimming-pool-in-karachi-14424" },
  { from: "/listing/karachi-youth-hostel-in-karachi", to: "/listing/student-hostels/karachi-youth-hostel-in-karachi-14430" },
  { from: "/listing/alhaq-boys-hostel-in-karachi", to: "/listing/student-hostels/alhaq-boys-hostel-in-karachi-14451" },
  { from: "/listing/awa-software-house-in-karachi-2", to: "/listing/software-company/awa-software-house-in-karachi-14454" },
  { from: "/listing/valiant-security-services-pvt-ltd-in-karachi", to: "/listing/security-guard/valiant-security-services-(pvt)-ltd-in-karachi-14461" },
  { from: "/listing/askari-guards-pvt-ltd-in-karachi", to: "/listing/security-guard/askari-guards-(pvt)-ltd-in-karachi-14468" },
  { from: "/listing/american-school-in-karachi", to: "/listing/driving-schools/american-school-in-karachi-14474" },
  { from: "/listing/the-c-a-s-school-in-karachi", to: "/listing/driving-schools/the-c.a.s.-school-in-karachi-14476" },
  { from: "/listing/dotline-printing-press-in-karachi", to: "/listing/printing/dotline-printing-press-in-karachi-14481" },
  { from: "/listing/four-star-printers-in-karachi-2", to: "/listing/printing/four-star-printers-in-karachi-14484" },
  { from: "/listing/home-care-roofing-specialist-in-karachi", to: "/listing/plumbers/home-care-roofing-specialist-in-karachi-14486" },
  { from: "/listing/roohan-brothers-and-plumber-in-karachi", to: "/listing/plumbers/roohan-brothers-and-plumber-in-karachi-14491" },
  { from: "/listing/afzal-motors-pvt-ltd-in-karachi", to: "/listing/pickups-and-trucks/afzal-motors-pvt-ltd-in-karachi-14493" },
  { from: "/listing/ghandhara-industries-limited-in-karachi", to: "/listing/pickups-and-trucks/ghandhara-industries-limited-in-karachi-14535" },
  { from: "/listing/ok-production-in-karachi", to: "/listing/photographer/ok-production-in-karachi-14538" },
  { from: "/listing/aj-studio-in-karachi", to: "/listing/photographer/aj-studio-in-karachi-14543" },
  { from: "/listing/animal-care-clinic-in-karachi-2", to: "/listing/pets-hospitals/animal-care-clinic-in-karachi-14548" },
  { from: "/listing/animals-clinic-in-karachi-2", to: "/listing/pets-hospitals/animals-clinic-in-karachi-14550" },
  { from: "/listing/institute-of-business-management-in-karachi-2", to: "/listing/universities/institute-of-business-management-in-karachi-14552" },
  { from: "/listing/greenwich-university-in-karachi", to: "/listing/universities/greenwich-university-in-karachi-14559" },
  { from: "/listing/hilal-park-in-karachi", to: "/listing/parks/hilal-park-in-karachi-14634" },
  { from: "/listing/bagh-ibne-qasim-in-karachi-2", to: "/listing/parks/bagh-ibne-qasim-in-karachi-14638" },
  { from: "/listing/digital-dividend-in-karachi-2", to: "/listing/mobile-app-development/digital-dividend-in-karachi-14643" },
  { from: "/listing/welders-international-in-karachi-2", to: "/listing/welding-services/welders-international-in-karachi-14682" },
  { from: "/listing/al-madina-welding-works-in-karachi-2", to: "/listing/welding-services/al-madina-welding-works-in-karachi-14686" },
  { from: "/listing/perfect-pest-control-services-in-karachi", to: "/listing/pest-control/perfect-pest-control-services-in-karachi-14691" },
  { from: "/listing/eco-services-in-karachi-2", to: "/listing/pest-control/eco-services-in-karachi-14694" },
  { from: "/listing/gexton-security", to: "/listing/security-guard/gexton-security-14968" },
  { from: "/listing/generation-computers-computer-repair-in-islamabad", to: "/listing/computer-repair/generation-computers-|-computer-repair-in-islamabad-15044" },
  { from: "/listing/professional-repairing-center-computer-repair-in-islamabad", to: "/listing/computer-repair/professional-repairing-center-|-computer-repair-in-islamabad-15047" },
  { from: "/listing/royal-computers-internet-cafe-computer-repair-in-islamabad", to: "/listing/computer-repair/royal-computers-&-internet-cafe-|-computer-repair-in-islamabad-15049" },
  { from: "/listing/the-millennium-universal-college-colleges-in-islamabad", to: "/listing/colleges/the-millennium-universal-college-|-colleges-in-islamabad-15051" },
  { from: "/listing/alhuda-college-colleges-in-islamabad", to: "/listing/colleges/alhuda-college-|-colleges-in-islamabad-15054" },
  { from: "/listing/islamabad-college-of-arts-and-sciences-colleges-in-islamabad", to: "/listing/colleges/islamabad-college-of-arts-and-sciences-|-colleges-in-islamabad-15059" },
  { from: "/listing/habib-construction-services-ltd-construction-in-islamabad", to: "/listing/construction-services/habib-construction-services-ltd.-|-construction-in-islamabad-15062" },
  { from: "/listing/banu-mukhtar-construction-company-construction-in-islamabad", to: "/listing/construction-services/banu-mukhtar-construction-company-|-construction-in-islamabad-15066" },
  { from: "/listing/zkb-pvt-ltd-construction-in-islamabad", to: "/listing/construction-services/zkb-(pvt.)-ltd-|-construction-in-islamabad-15069" },
  { from: "/listing/care-n-cure-dental-clinic-dental-in-islamabad", to: "/listing/dental/care-n-cure-dental-clinic-|-dental-in-islamabad-15125" },
  { from: "/listing/z-dental-studio-dental-in-islamabad", to: "/listing/dental/z-dental-studio-|-dental-in-islamabad-15130" },
  { from: "/listing/the-dental-consultants-dental-in-islamabad", to: "/listing/dental/the-dental-consultants-|-dental-in-islamabad-15133" },
  { from: "/listing/shaze-kitchens-interiors-designer-in-islamabad", to: "/listing/designer/shaz'e-kitchens-&-interiors-|-designer-in-islamabad-15136" },
  { from: "/listing/shimla-designer-collection-designer-in-islamabad", to: "/listing/designer/shimla-designer-collection-|-designer-in-islamabad-15139" },
  { from: "/listing/solitaire-designer-jewellery-safa-gold-mall-designer-in-islamabad", to: "/listing/designer/solitaire-designer-jewellery-safa-gold-mall-|-designer-in-islamabad-15142" },
  { from: "/listing/annie-rafael-digital-marketing-in-islamabad", to: "/listing/digital-marketing/annie-rafael-|-digital-marketing-in-islamabad-15145" },
  {
    from: "/listing/khurram-abbasi-digital-marketing-manager-consultant-expert-digital-marketing-in-islamabad",
    to: "/listing/digital-marketing/khurram-abbasi---digital-marketing-manager,-consultant-&-expert-|-digital-marketing-in-islamabad-15147",
  },
  {
    from: "/listing/digital-inception-digital-social-media-marketing-digital-marketing-in-islamabad",
    to: "/listing/digital-marketing/digital-inception-(digital-social-media-marketing)-|-digital-marketing-in-islamabad-15150",
  },
  { from: "/listing/king-driving-school-driving-schools-in-islamabad", to: "/listing/driving-schools/king-driving-school-|-driving-schools-in-islamabad-15165" },
  { from: "/listing/shaheen-driving-school-driving-schools-in-islamabad", to: "/listing/driving-schools/shaheen-driving-school-|-driving-schools-in-islamabad-15167" },
  { from: "/listing/iqra-driving-institute-driving-schools-in-islamabad", to: "/listing/driving-schools/iqra-driving-institute-|-driving-schools-in-islamabad-15169" },
  { from: "/listing/al-makkah-electric-expert-electrician-in-islamabad", to: "/listing/electrician/al-makkah-electric-expert-|-electrician-in-islamabad-15172" },
  { from: "/listing/khattak-electrical-and-ac-works-electrician-in-islamabad", to: "/listing/electrician/khattak-electrical-and-ac-works-|-electrician-in-islamabad-15174" },
  { from: "/listing/nisar-electrical-services-electrician-in-islamabad", to: "/listing/electrician/nisar-electrical-services-|-electrician-in-islamabad-15176" },
  { from: "/listing/nrsp-farm-house-farm-houses-in-islamabad", to: "/listing/farm-houses/nrsp-farm-house-|-farm-houses-in-islamabad-15178" },
  { from: "/listing/islamabad-farm-houses", to: "/listing/farm-houses/islamabad-farm-houses-15180" },
  { from: "/listing/alvi-farm-house-farm-houses-in-islamabad", to: "/listing/farm-houses/alvi-farm-house-|-farm-houses-in-islamabad-15185" },
  { from: "/listing/executive-club-gym-in-islamabad", to: "/listing/gym/executive-club-|-gym-in-islamabad-15190" },
  { from: "/listing/metroflex-gym-in-islamabad", to: "/listing/gym/metroflex-|-gym-in-islamabad-15196" },
  { from: "/listing/smarts-health-fitness-club-gym-in-islamabad", to: "/listing/gym/smarts-health-&-fitness-club-|-gym-in-islamabad-15199" },
  { from: "/listing/capital-delights-home-delivery-food-in-islamabad", to: "/listing/home-delivery-food/capital-delights-|-home-delivery-food-in-islamabad-15202" },
  { from: "/listing/daily-meal-home-delivery-food-in-islamabad", to: "/listing/home-delivery-food/daily-meal-|-home-delivery-food-in-islamabad-15205" },
  { from: "/listing/14th-street-pizza-co-home-delivery-food-in-islamabad", to: "/listing/home-delivery-food/14th-street-pizza-co.-|-home-delivery-food-in-islamabad-15208" },
  { from: "/listing/shifa-international-hospital-hospitals-in-islamabad", to: "/listing/hospitals/shifa-international-hospital-|-hospitals-in-islamabad-15212" },
  { from: "/listing/maroof-international-hospital-hospitals-in-islamabad", to: "/listing/hospitals/maroof-international-hospital-|-hospitals-in-islamabad-15216" },
  { from: "/listing/cda-hospital-hospitals-in-islamabad", to: "/listing/hospitals/cda-hospital-|-hospitals-in-islamabad-15219" },
  { from: "/listing/jasmine-inn-hotels-in-islamabad", to: "/listing/hotels/jasmine-inn-|-hotels-in-islamabad-15304" },
  { from: "/listing/ramada-hotel-hotels-in-islamabad", to: "/listing/hotels/ramada-hotel-|-hotels-in-islamabad-15306" },
  { from: "/listing/islamabad-hotel", to: "/listing/hotels/islamabad-hotel-15309" },
  { from: "/listing/patiala-jewelers-jewelers-in-islamabad", to: "/listing/jewelers/patiala-jewelers-|-jewelers-in-islamabad-15312" },
  { from: "/listing/al-waris-jewellers-jewelers-in-islamabad", to: "/listing/jewelers/al-waris-jewellers-|-jewelers-in-islamabad-15315" },
  { from: "/listing/gold-mine-jewellers-jewellers-in-islamabad", to: "/listing/jewelers/gold-mine-jewellers-|-jewellers-in-islamabad-15318" },
  { from: "/listing/plot-for-sale-in-dha-lahore-great-real-estate", to: "/listing/property-development/plot-for-sale-in-dha-lahore---great-real-estate-15561" },
  { from: "/listing/excel-labs-laboratories-in-islamabad", to: "/listing/laboratories/excel-labs-|-laboratories-in-islamabad-15603" },
  { from: "/listing/biotech-lab-research-centre-laboratories-in-islamabad", to: "/listing/laboratories/biotech-lab-&-research-centre-|-laboratories-in-islamabad-15606" },
  { from: "/listing/citi-lab-laboratories-in-islamabad", to: "/listing/laboratories/citi-lab-|-laboratories-in-islamabad-15609" },
  { from: "/listing/family-lawyer-lawyers-in-islamabad", to: "/listing/lawyers/family-lawyer-|-lawyers-in-islamabad-15612" },
  { from: "/listing/aamir-mirza-law-associates-lawyers-in-islamabad", to: "/listing/lawyers/aamir-mirza-law-associates-|-lawyers-in-islamabad-15614" },
  { from: "/listing/ckr-zia-advocates-lawyers-in-islamabad", to: "/listing/lawyers/ckr-zia-advocates-|-lawyers-in-islamabad-15617" },
  { from: "/listing/national-library-of-pakistan-libraries-in-islamabad", to: "/listing/libraries/national-library-of-pakistan-|-libraries-in-islamabad-15619" },
  { from: "/listing/central-library-libraries-in-islamabad", to: "/listing/libraries/central-library-|-libraries-in-islamabad-15621" },
  { from: "/listing/islamabad-public-library", to: "/listing/libraries/islamabad-public-library-15623" },
  { from: "/listing/appliconic-mobile-apps-development-in-islamabad", to: "/listing/mobile-app-development/appliconic-|-mobile-apps-development-in-islamabad-15627" },
  {
    from: "/listing/xperia-sol-web-designing-software-house-mobile-apps-development-in-islamabad",
    to: "/listing/mobile-app-development/xperia-sol-web-designing-software-house-|-mobile-apps-development-in-islamabad-15630",
  },
  { from: "/listing/webnways-mobile-app-development-in-islamabad", to: "/listing/mobile-app-development/webnways-|-mobile-app-development-in-islamabad-15633" },
  { from: "/listing/fatima-jinnah-park-parks-in-islamabad", to: "/listing/parks/fatima-jinnah-park-|-parks-in-islamabad-15644" },
  { from: "/listing/lake-view-park-parks-in-islamabad", to: "/listing/parks/lake-view-park-|-parks-in-islamabad-15646" },
  { from: "/listing/margalla-hills-national-park-parks-in-islamabad", to: "/listing/parks/margalla-hills-national-park-|-parks-in-islamabad-15648" },
  { from: "/listing/clean-sweep-pest-control-islamabad", to: "/listing/pest-control/clean-sweep-pest-control-islamabad-15650" },
  {
    from: "/listing/a-to-z-fumigation-pest-and-termitedeemak-control-pest-control-in-islamabad",
    to: "/listing/pest-control/a-to-z-fumigation,-pest-and-termite(deemak)-control-|-pest-control-in-islamabad-15657",
  },
  { from: "/listing/scout-pest-control-islamabad", to: "/listing/pest-control/scout-pest-control-islamabad-15659" },
  { from: "/listing/the-pets-hospital-pets-hospital-in-islamabad", to: "/listing/pets-hospitals/the-pet's-hospital-|-pets-hospital-in-islamabad-15691" },
  { from: "/listing/dr-ranas-pet-care-center-pets-hospitals-in-islamabad", to: "/listing/pets-hospitals/dr-rana's-pet-care-center-|-pets-hospitals-in-islamabad-15695" },
  { from: "/listing/pets-vets-pets-hospitals-in-islamabad", to: "/listing/pets-hospitals/pets-&-vets-|-pets-hospitals-in-islamabad-15701" },
  { from: "/listing/mahas-photography-photographer-in-islamabad", to: "/listing/photographer/maha’s-photography-|-photographer-in-islamabad-15706" },
  { from: "/listing/qamar-anwar-photography-photographer-in-islamabad", to: "/listing/photographer/qamar-anwar-photography-|-photographer-in-islamabad-15708" },
  { from: "/listing/qamar-anwar-photography-photographer-in-islamabad-2", to: "/listing/photographer/qamar-anwar-photography-|-photographer-in-islamabad-15710" },
  { from: "/listing/islamabad-plumber", to: "/listing/plumbers/islamabad-plumber-15714" },
  { from: "/listing/meer-consultants-home-maintenance-services-plumber-in-islamabad", to: "/listing/plumbers/meer-consultants-home-maintenance-services-|-plumber-in-islamabad-15716" },
  { from: "/listing/siraj-plumber-electrician-and-ac-work-plumber-in-islamabad", to: "/listing/plumbers/siraj-plumber-electrician-and-ac-work-|-plumber-in-islamabad-15719" },
  { from: "/listing/islamabad-printers", to: "/listing/printing/islamabad-printers-15721" },
  { from: "/listing/print-ways-printing-in-islamabad", to: "/listing/printing/print-ways-|-printing-in-islamabad-15731" },
  { from: "/listing/printorium-printers-printing-in-islamabad", to: "/listing/printing/printorium-printers-|-printing-in-islamabad-15735" },
  { from: "/listing/forces-estates-property-in-islamabad", to: "/listing/property-development/forces-estates-|-property-in-islamabad-15739" },
  { from: "/listing/star-marketing-pvt-ltd-property-in-islamabad", to: "/listing/property-development/star-marketing-(pvt.)-ltd-|-property-in-islamabad-15742" },
  { from: "/listing/al-hussain-properties-property-in-islamabad", to: "/listing/property-development/al-hussain-properties-|-property-in-islamabad-15745" },
  { from: "/listing/plumber-services-in-lahore-kamwalay-com", to: "/listing/plumbers/plumber-services-in-lahore-|-kamwalay.com-15755" },
  { from: "/listing/the-monal-restaurants-in-islamabad", to: "/listing/restaurants/the-monal-|-restaurants-in-islamabad-15877" },
  { from: "/listing/tuscany-courtyard-restaurants-in-islamabad", to: "/listing/restaurants/tuscany-courtyard-|-restaurants-in-islamabad-15882" },
  { from: "/listing/chilman-restaurant-restaurants-in-islamabad", to: "/listing/restaurants/chilman-restaurant-|-restaurants-in-islamabad-15885" },
  { from: "/listing/jugnus-saloon-salons-in-islamabad", to: "/listing/salons/jugnu's-saloon-|-salons-in-islamabad-15887" },
  { from: "/listing/toni-guy-salons-in-islamabad", to: "/listing/salons/toni-&-guy-|-salons-in-islamabad-15891" },
  { from: "/listing/international-school-of-islamabadinternational-school-of-islamabad", to: "/listing/driving-schools/international-school-of-islamabad-15894" },
  { from: "/listing/preparatory-school-islamabad", to: "/listing/driving-schools/preparatory-school-islamabad-16011" },
  { from: "/listing/roots-millennium-schools-schools-in-islamabad", to: "/listing/driving-schools/roots-millennium-schools-|-schools-in-islamabad-16014" },
  { from: "/listing/mars-security-guards-security-guard-in-islamabad", to: "/listing/security-guard/mars-security-guards-|-security-guard-in-islamabad-16017" },
  { from: "/listing/dgm-security-pvt-ltd-security-guard-in-islamabad", to: "/listing/security-guard/dgm-security-(pvt)-ltd.-|-security-guard-in-islamabad-16023" },
  { from: "/listing/piffers-security-services-pss-security-guard-in-islamabad", to: "/listing/security-guard/piffers-security-services-(pss)-|-security-guard-in-islamabad-16027" },
  { from: "/listing/centaurus-mall-islamabad-shopping-malls-in-islamabad", to: "/listing/shopping-malls/centaurus-mall-islamabad-|-shopping-malls-in-islamabad-16030" },
  { from: "/listing/safa-gold-mall-shopping-malls-in-islamabad", to: "/listing/shopping-malls/safa-gold-mall-|-shopping-malls-in-islamabad-16033" },
  { from: "/listing/giga-mall-shopping-malls-in-islamabad", to: "/listing/shopping-malls/giga-mall-|-shopping-malls-in-islamabad-16036" },
  { from: "/listing/centangle-interactive-pvt-ltd-software-company-in-islamabad", to: "/listing/software-company/centangle-interactive-(pvt.)-ltd.-|-software-company-in-islamabad-16039" },
  { from: "/listing/aurora-solutions-pvt-ltd-software-company-in-islamabad", to: "/listing/software-company/aurora-solutions-pvt-ltd-|-software-company-in-islamabad-16044" },
  { from: "/listing/xorlogics-software-company-in-islamabad", to: "/listing/software-company/xorlogics-|-software-company-in-islamabad-16047" },
  { from: "/listing/backpackers-hostel-student-hostels-in-islamabad", to: "/listing/student-hostels/backpackers-hostel-|-student-hostels-in islamabad-16196" },
  { from: "/listing/twin-city-boys-hostel-student-hostels-in-islamabad", to: "/listing/student-hostels/twin-city-boys-hostel-|-student-hostels-in islamabad-16198" },
  { from: "/listing/girls-hostel-apodis-student-hostels-in-islamabad", to: "/listing/student-hostels/girls-hostel-apodis-|-student-hostels-in islamabad-16200" },
  { from: "/listing/pn-swimming-pool-swimming-pools-in-islamabad", to: "/listing/swimming-pools/pn-swimming-pool-|-swimming-pools-in islamabad-16204" },
  { from: "/listing/islamabad-swimming-pool", to: "/listing/swimming-pools/islamabad-swimming-pool-16206" },
  { from: "/listing/leisure-swimming-pools-islamabad", to: "/listing/swimming-pools/leisure---swimming-pools-islamabad-16208" },
  { from: "/listing/wazir-tailors-fabrics-tailors-in-islamabad", to: "/listing/tailors-and-alterations/wazir-tailors-&-fabrics-|-tailors-in-islamabad-16289" },
  { from: "/listing/bespoke-tailors-tailors-in-islamabad", to: "/listing/tailors-and-alterations/bespoke-tailors-|-tailors-in-islamabad-16292" },
  { from: "/listing/super-tailors-fabrics-tailors-in-islamabad", to: "/listing/tailors-and-alterations/super-tailors-&-fabrics-|-tailors-in-islamabad-16295" },
  { from: "/listing/rehman-travels-travel-in-islamabad", to: "/listing/travel-agents/rehman-travels-|-travel-in-islamabad-16297" },
  { from: "/listing/askari-travel-tours-travel-in-islamabad", to: "/listing/travel-agents/askari-travel-&-tours-|-travel-in-islamabad-16300" },
  { from: "/listing/bukhari-travel-services-travel-in-islamabad", to: "/listing/travel-agents/bukhari-travel-services-|-travel-in-islamabad-16305" },
  { from: "/listing/tfc-home-tutor-provider-islamabad", to: "/listing/tutoring/tfc-(-home-tutor-provider-islamabad-)-16311" },
  { from: "/listing/experts-tutors-tutor-in-islamabad", to: "/listing/tutoring/experts-tutors-|-tutor-in-islamabad-16314" },
  { from: "/listing/java-hts-tutor-in-islamabad", to: "/listing/tutoring/java-hts-|-tutor-in-islamabad-16317" },
  { from: "/listing/comsats-university-universities-in-islamabad", to: "/listing/universities/comsats-university-|-universities-in-islamabad-16396" },
  { from: "/listing/quaid-i-azam-university-universities-in-islamabad", to: "/listing/universities/quaid-i-azam-university-|-universities-in-islamabad-16401" },
  { from: "/listing/air-university-universities-in-islamabad", to: "/listing/universities/air-university-|-universities-in-islamabad-16404" },
  { from: "/listing/nexco-consultants-visa-in-islamabad", to: "/listing/visa-agencies/nexco-consultants-|-visa-in-islamabad-16407" },
  { from: "/listing/abdullah-consultants-pvt-ltd-visa-in-islamabad", to: "/listing/visa-agencies/abdullah-consultants-(pvt)-ltd-|-visa-in-islamabad-16410" },
  { from: "/listing/anatolia-travel-services-pvt-limited-visa-in-islamabad", to: "/listing/visa-agencies/anatolia-travel-services-(pvt)-limited-|-visa-in-islamabad-16431" },
  { from: "/listing/web-matrix-website-development-in-islamabad", to: "/listing/website-development/web-matrix-|-website-development-in-islamabad-16436" },
  { from: "/listing/wikisol-website-development-in-islamabad", to: "/listing/website-development/wikisol-|-website-development-in-islamabad-16438" },
  { from: "/listing/muhammad-ali-technologies-website-development-in-islamabad", to: "/listing/website-development/muhammad-ali-technologies-|-website-development-in-islamabad-16441" },
  { from: "/listing/tulip-banquet-hall-wedding-halls-in-islamabad", to: "/listing/wedding-halls/tulip-banquet-hall-|-wedding-halls-in-islamabad-16446" },
  { from: "/listing/sn-dental-care", to: "/listing/dental/sn-dental-care-16522" },
  { from: "/listing/eden-banquet-hall-wedding-halls-in-islamabad", to: "/listing/wedding-halls/eden-banquet-hall-|-wedding-halls-in-islamabad-16679" },
  { from: "/listing/ajman-marriage-hall-wedding-halls-in-islamabad", to: "/listing/wedding-halls/ajman-marriage-hall-|-wedding-halls-in-islamabad-16682" },
  { from: "/listing/eventoxs-event-management-wedding-planner-in-islamabad", to: "/listing/weddings-planner/eventoxs-event-management-|-wedding-planner-in-islamabad-16684" },
  { from: "/listing/platinum-events-management-wedding-planner-in-islamabad", to: "/listing/weddings-planner/platinum-events-management-|-wedding-planner-in-islamabad-16687" },
  { from: "/listing/royale-wedding-event-planners-wedding-planner-in-islamabad", to: "/listing/weddings-planner/royale-wedding-&-event-planners-|-wedding-planner-in-islamabad-16690" },
  { from: "/listing/madina-wedding-services-wedding-services-in-islamabad", to: "/listing/welding-services/madina-wedding-services-|-wedding-services-in-islamabad-16693" },
  { from: "/listing/adzone-advertiser-advertisers-in-peshawar", to: "/listing/advertisers/adzone-advertiser-|-advertisers-in-peshawar-16802" },
  { from: "/listing/orientm-mccann-advertisers-in-peshawar", to: "/listing/advertisers/orientm-mccann-|-advertisers-in-peshawar-16807" },
  { from: "/listing/jugnus-beauty-salon-beauty-parlour-in-peshawar", to: "/listing/beauty-parlour/jugnu's-beauty-salon-|-beauty-parlour-in-peshawar-16813" },
  { from: "/listing/glambar-beauty-clinic-beauty-parlour-in-peshawar", to: "/listing/beauty-parlour/glambar-beauty-clinic-|-beauty-parlour-in-peshawar-16816" },
  { from: "/listing/blush-beauty-salon-beauty-parlour-in-peshawar", to: "/listing/beauty-parlour/blush-beauty-salon-|-beauty-parlour-in-peshawar-16820" },
  { from: "/listing/wbm-international-online", to: "/listing/shopping-malls/wbm-international-online-17006" },
  { from: "/listing/lawyers-in-gujranwala", to: "/listing/lawyers/sheikh-arslan-ali-advocate-&-tax-consultant-|-lawyers-in-gujranwala-17094" },
  { from: "/listing/london-book-company-book-stores-in-peshawar", to: "/listing/book-stores/london-book-company-|-book-stores-in-peshawar-17348" },
  { from: "/listing/prince-book-house-book-stores-in-peshawar", to: "/listing/book-stores/prince-book-house-|-book-stores-in-peshawar-17351" },
  { from: "/listing/islamia-book-bank-book-stores-in-peshawar", to: "/listing/book-stores/islamia-book-bank-|-book-stores-in-peshawar-17354" },
  { from: "/listing/car-palace-car-dealer-in-peshawar", to: "/listing/car-dealer/car-palace-|-car-dealer-in-peshawar-17359" },
  { from: "/listing/pak-japan-car-center-car-dealer-in-peshawar", to: "/listing/car-dealer/pak-japan-car-center-|-car-dealer-in-peshawar-17362" },
  { from: "/listing/car-ambassador-car-dealer-in-peshawar", to: "/listing/car-dealer/car-ambassador-|-car-dealer-in-peshawar-17366" },
  { from: "/listing/amjad-denting-and-body-workshop-car-denting-painting-in-peshawar", to: "/listing/car-denting-painting/amjad-denting-and-body-workshop-|-car-denting-painting-in-peshawar-17390" },
  { from: "/listing/chaudhry-auto-world-car-denting-painting-in-peshawar", to: "/listing/car-denting-painting/chaudhry-auto-world-|-car-denting-painting-in-peshawar-17392" },
  { from: "/listing/al-falah-rent-a-car-car-rental-in-peshawar", to: "/listing/car-rental/al-falah-rent-a-car-|-car-rental-in-peshawar-17394" },
  { from: "/listing/shani-travel-car-rental-in-peshawar", to: "/listing/car-rental/shani-travel-|-car-rental-in-peshawar-17397" },
  { from: "/listing/insaf-cabs-car-rental-in-peshawar", to: "/listing/car-rental/insaf-cabs-|-car-rental-in-peshawar-17400" },
  { from: "/listing/total-car-wash-car-washer-in-peshawar", to: "/listing/car-washers/total-car-wash-|-car-washer-in-peshawar-17403" },
  { from: "/listing/techno-wash-automatic-car-wash-car-washers-in-peshawar", to: "/listing/car-washers/techno-wash-(automatic-car-wash)-|-car-washers-in-peshawar-17405" },
  { from: "/listing/xtreme-detailing-car-washers-in-peshawar", to: "/listing/car-washers/xtreme-detailing-|-car-washers-in-peshawar-17409" },
  { from: "/listing/abdulghafoor-carpenter-carpenter-in-peshawar", to: "/listing/carpenter/abdulghafoor-carpenter-|-carpenter-in-peshawar-17412" },
  { from: "/listing/ak-wood-works-carpenter-in-peshawar", to: "/listing/carpenter/ak-wood-works-|-carpenter-in-peshawar-17414" },
  { from: "/listing/ciel-wood-works-pvt-limited-carpenter-in-peshawar", to: "/listing/carpenter/ciel-wood-works-(pvt)-limited-|-carpenter-in-peshawar-17420" },
  { from: "/listing/shama-cinema-cinemas-and-theaters-in-peshawar", to: "/listing/cinemas-and-theaters/shama-cinema-|-cinemas-and-theaters-in-peshawar-17539" },
  { from: "/listing/funland-motion-cinemas-and-theaters-in-peshawar", to: "/listing/cinemas-and-theaters/funland-motion-|-cinemas-and-theaters-in-peshawar-17541" },
  { from: "/listing/shahnaz-clinic-clinic-in-peshawar", to: "/listing/doctors-and-clinics/shahnaz-clinic-|-clinic-in-peshawar-17543" },
  { from: "/listing/nawaz-eye-clinic-clinic-in-peshawar", to: "/listing/doctors-and-clinics/nawaz-eye-clinic-|-clinic-in-peshawar-17547" },
  { from: "/listing/dr-kashif-cosmetic-laser-clinic-clinic-in-peshawar", to: "/listing/doctors-and-clinics/dr-kashif-cosmetic-laser-clinic-|-clinic-in-peshawar-17549" },
  { from: "/listing/camel-scape-event-planners", to: "/listing/weddings-planner/camel-scape-event-planners-17786" },
  { from: "/listing/top-tech-cool-islamabad", to: "/listing/electrician/top-tech-cool-islamabad-17797" },
  { from: "/listing/dogar-rent-a-car-in-lahore", to: "/listing/car-rental/dogar-rent-a-car-in-lahore-17806" },
  { from: "/listing/kingdom-real-estate-builders", to: "/listing/property-development/kingdom-real-estate-&-builders-17989" },
  { from: "/listing/pakland-properties-property-in-lahore", to: "/listing/property-development/pakland-properties-|-property-in-lahore-18233" },
  { from: "/listing/ashraf-hotel-restaurant-in-rawalakot", to: "/listing/hotels/ashraf-hotel-&-restaurant-in-rawalakot-18301" },
  { from: "/listing/saf-rent-a-car-lahore-car-rental", to: "/listing/car-rental/saf-rent-a-car-lahore-|-car-rental-18351" },
  { from: "/listing/marjan-hotel-hotels-in-rawalakot-2", to: "/listing/hotels/marjan-hotel-|-hotels-in-rawalakot-18361" },
  { from: "/listing/heaven-heights-guest-house-hotels-in-rawalakot-2", to: "/listing/hotels/heaven-heights-guest-house-|-hotels-in-rawalakot-18366" },
  { from: "/listing/tss-the-solution-services-water-proofing-solutions-2", to: "/listing/pest-control/tss-|-the-solution-services-|-water-proofing-solutions-18466" },
  { from: "/listing/talal-packers-and-movers-home-shifting-services", to: "/listing/general-services/talal-packers-and-movers-home-shifting-services-18468" },
  { from: "/listing/hafiz-enterprisers-scrape-dealer-in-karachi", to: "/listing/general-services/hafiz-enterprisers-|-scrape-dealer-in-karachi-18472" },
  { from: "/listing/haririi-rent-a-car-lahore-car-rental", to: "/listing/car-rental/haririi-rent-a-car-lahore-|-car-rental-18516" },
  { from: "/listing/haq-bahoo-tours-rent-a-car", to: "/listing/car-rental/haq-bahoo-tours-|-rent-a-car-18585" },
  { from: "/listing/my-car-inc-rent-a-car-services-in-lahore", to: "/listing/car-rental/my-car.inc-rent-a-car-services-in-lahore-18645" },
  { from: "/listing/lahori-rent-a-car-car-without-driver-in-lahore", to: "/listing/car-rental/lahori-rent-a-car-|-car-without-driver-in-lahore-18676" },
  { from: "/listing/al-syed-tours-rent-a-car-in-lahore", to: "/listing/car-rental/al-syed-tours-|-rent-a-car-in-lahore-18755" },
  { from: "/listing/al-qaim-driving-school-learn-driving-in-lahore", to: "/listing/driving-schools/al-qaim-driving-school-|-learn-driving-in-lahore-18763" },
  { from: "/listing/alqaim-rent-a-car-rent-a-car-services-in-lahore", to: "/listing/car-rental/alqaim-rent-a-car-|-rent-a-car-services-in-lahore-18772" },
  { from: "/listing/ali-lujpal-rent-a-car-in-lahore", to: "/listing/car-rental/ali-lujpal-rent-a-car-in-lahore-18820" },
  { from: "/listing/shabir-rent-a-car-shabir-rest-house", to: "/listing/car-rental/shabir-rent-a-car-&-shabir-rest-house-18829" },
  { from: "/listing/lahore-rent-a-car-car-rental-in-lahore", to: "/listing/car-rental/lahore-rent-a-car-|-car-rental-in-lahore-18843" },
  { from: "/listing/excellent-rent-a-car-car-rental-services-in-lahore", to: "/listing/car-rental/excellent-rent-a-car-|-car-rental-services-in-lahore-18847" },
  { from: "/listing/gull-tours-and-rent-a-car-in-lahore", to: "/listing/car-rental/gull-tours-and-rent-a-car-in-lahore-18936" },
  { from: "/listing/ali-rent-a-car-car-rental-services-in-lahore", to: "/listing/car-rental/ali-rent-a-car-|-car-rental-services-in-lahore-18959" },
  { from: "/listing/taimur-rent-a-car-car-rental-service-in-lahore", to: "/listing/car-rental/taimur-rent-a-car-|-car-rental-service-in-lahore-18989" },
  { from: "/listing/al-madina-driving-school-in-lahore", to: "/listing/driving-schools/al-madina-driving-school-in-lahore-19045" },
  { from: "/listing/city-rent-a-car-in-islamabad-3", to: "/listing/car-rental/city-rent-a-car-in-islamabad-19124" },
  { from: "/listing/fraz-rent-a-car-in-lahore-driver-or-without-driver-2", to: "/listing/car-rental/fraz-rent-a-car-in-lahore-|-driver-or-without-driver-19127" },
  { from: "/listing/malik-events-event-planner-in-lahore-2", to: "/listing/weddings-planner/malik-events-|-event-planner-in-lahore-19183" },
  { from: "/listing/honda-civic-without-driver-rent-a-car-in-lahore-2", to: "/listing/car-rental/honda-civic-without-driver-rent-a-car-in-lahore-19318" },
  { from: "/listing/al-haneef-tours-rent-a-car-in-lahore", to: "/listing/car-rental/al-haneef-tours-&-rent-a-car-in-lahore-19325" },
  { from: "/listing/waseem-rent-a-car-cars-on-rent-without-driver-in-lahore", to: "/listing/car-rental/waseem-rent-a-car-|-cars-on-rent-without-driver-in-lahore-19369" },
  { from: "/listing/cats-for-sale-in-lahore", to: "/listing/animals-&amp;-birds/cats-for-sale-in-lahore-19383" },
  { from: "/listing/3-bed-house-available-on-rent-in-lahore", to: "/listing/property-for-rent/3-bed-house-available-on-rent-in-lahore-19397" },
  { from: "/listing/high-speed-automotive-solutions-2", to: "/listing/car-rental/high-speed-automotive-solutions-19647" },
  { from: "/listing/tha-tha-rent-a-car-in-gujranwala", to: "/listing/car-rental/tha-tha-rent-a-car-in-gujranwala-19654" },
  { from: "/listing/malik-motors-and-rent-a-car-in-wazirabad", to: "/listing/car-rental/malik-motors-and-rent-a-car-in-wazirabad-19711" },
  { from: "/listing/al-jannat-employment-company-job-provider-3", to: "/listing/job-providers/al-jannat-employment-company-|-job-provider-19742" },
  { from: "/listing/hasnat-employment-job-provider", to: "/listing/job-providers/hasnat-employment-|-job-provider-19774" },
  { from: "/listing/school-management-software", to: "/listing/software-company/school-management-software-19790" },
  { from: "/listing/53-kanal-flour-mill-for-sale-in-running", to: "/listing/property-development/53-kanal-flour-mill-for-sale-in-running-19842" },
  { from: "/listing/highspeed-automotive-solutions", to: "/listing/car-rental/highspeed-automotive-solutions-19846" },
  { from: "/listing/al-hamad-employment-company", to: "/listing/job-providers/al-hamad-employment-company-19873" },
  { from: "/listing/home-tutor-in-karachi-2", to: "/listing/tutoring/home-tutor-in-karachi-19896" },
  { from: "/listing/real-power-generator-engineering", to: "/listing/electronics/real-power-generator-&-engineering-20084" },
  { from: "/listing/tutors-inn-academy", to: "/listing/tutoring/tutor's-inn-academy-20169" },
  { from: "/listing/property-management-and-consultancy-services", to: "/listing/property-development/property-management-and-consultancy-services-20278" },
  { from: "/listing/real-estate-property-in-lahore", to: "/listing/property-development/real-estate-|-property-in-lahore-20301" },
  { from: "/listing/job-provider-in-lahore", to: "/listing/job-providers/malik-trust-associate-(r)-|-job-provider-in-lahore-20355" },
  { from: "/listing/university-of-peshawar-universities-in-peshawar", to: "/listing/universities/university-of-peshawar-|-universities-in-peshawar-20649" },
  { from: "/listing/hamza-hamza-law-associates-2", to: "/listing/lawyers/hamza-&-hamza-law-associates-20652" },
  { from: "/listing/khyber-medical-university-universities-in-peshawar", to: "/listing/universities/khyber-medical-university-|-universities-in-peshawar-20677" },
  {
    from: "/listing/city-university-of-science-and-information-technology-universities-in-peshawar",
    to: "/listing/universities/city-university-of-science-and-information-technology-|-universities-in-peshawar-20681",
  },
  { from: "/listing/edwardes-college-peshawar-colleges-in-peshawar", to: "/listing/colleges/edwardes-college-peshawar-|-colleges-in-peshawar-20685" },
  { from: "/listing/jinnah-college-for-women-colleges-in-peshawar", to: "/listing/colleges/jinnah-college-for-women-|-colleges-in-peshawar-20688" },
  { from: "/listing/government-college-of-management-sciences-colleges-in-peshawar", to: "/listing/colleges/government-college-of-management-sciences-|-colleges-in-peshawar-20690" },
  { from: "/listing/khyber-star-laptop-center-computer-repair-in-peshawar", to: "/listing/computer-repair/khyber-star-laptop-center-|-computer-repair-in-peshawar-20696" },
  { from: "/listing/wah-computer-computer-repair-in-peshawar", to: "/listing/computer-repair/wah-computer-|-computer-repair-in-peshawar-20699" },
  { from: "/listing/spacetech-computers-computer-repair-in-peshawar", to: "/listing/computer-repair/spacetech-computers-|-computer-repair-in-peshawar-20701" },
  { from: "/listing/zenith-construction-engineering-construction-in-peshawar", to: "/listing/construction-services/zenith-construction-&-engineering-|-construction-in-peshawar-20704" },
  { from: "/listing/rana-constructions-construction-in-peshawar", to: "/listing/construction-services/rana-constructions-|-construction-in-peshawar-20707" },
  { from: "/listing/gul-builders-smc-pvt-ltd-construction-in-peshawar", to: "/listing/construction-services/gul-builders-(smc)-pvt-ltd-|-construction-in-peshawar-20710" },
  { from: "/listing/nadeem-real-estate-houses-for-sale-in-lahore", to: "/listing/property-development/nadeem-real-estate-|-houses-for-sale-in-lahore-20883" },
  { from: "/listing/kundis-dentists-dental-in-peshawar", to: "/listing/dental/kundi's-dentists-|-dental-in-peshawar-20950" },
  { from: "/listing/dr-anees-dental-clinic-dental-in-peshawar", to: "/listing/dental/dr.-anees-dental-clinic-|-dental-in-peshawar-20953" },
  { from: "/listing/trial-house-dental-clinic-dental-in-peshawar", to: "/listing/dental/trial-house-dental-clinic-|-dental-in-peshawar-20958" },
  { from: "/listing/sable-vogue-designer-in-peshawar", to: "/listing/designer/sable-vogue-|-designer-in-peshawar-20960" },
  { from: "/listing/dukes-designer-shoes-designer-in-peshawar", to: "/listing/designer/dukes-designer-shoes-|-designer-in-peshawar-20963" },
  { from: "/listing/araish-interior-designer-in-peshawar", to: "/listing/designer/araish-interior-|-designer-in-peshawar-20986" },
  { from: "/listing/softhat-it-solutions-pvt-ltd-digital-marketing-in-peshawar", to: "/listing/digital-marketing/softhat-it-solutions-pvt-(ltd)-|-digital-marketing-in-peshawar-20994" },
  { from: "/listing/cartvela-marketing-digital-marketing-in-peshawar", to: "/listing/digital-marketing/cartvela-marketing-|-digital-marketing-in-peshawar-20997" },
  { from: "/listing/brandom-digital-marketing-digital-marketing-in-peshawar", to: "/listing/digital-marketing/brandom-digital-marketing-|-digital-marketing-in-peshawar-21000" },
  { from: "/listing/ali-assad-sanitary-store", to: "/listing/general-services/ali-assad-sanitary-store-21031" },
  { from: "/listing/town-motor-driving-school-driving-school-in-peshawar", to: "/listing/driving-schools/town-motor-driving-school-|-driving-school-in-peshawar-21069" },
  { from: "/listing/saud-motor-driving-school-driving-schools-in-peshawar", to: "/listing/driving-schools/saud-motor-driving-school-|-driving-schools-in-peshawar-21071" },
  { from: "/listing/traffic-police-driving-school-driving-schools-in-peshawar", to: "/listing/driving-schools/traffic-police-driving-school-|-driving-schools-in-peshawar-21076" },
  { from: "/listing/national-electrical-services-electrician-in-peshawar", to: "/listing/electrician/national-electrical-services-|-electrician-in-peshawar-21078" },
  { from: "/listing/khewa-gul-auto-electrician-electrician-in-peshawar", to: "/listing/electrician/khewa-gul-auto-electrician-|-electrician-in-peshawar-21083" },
  { from: "/listing/nouman-auto-electricians-electrician-in-peshawar", to: "/listing/electrician/nouman-auto-electricians-|-electrician-in-peshawar-21085" },
  { from: "/listing/ays-electronics-electronics-in-peshawar", to: "/listing/electronics/ays-electronics-|-electronics-in-peshawar-21089" },
  { from: "/listing/haq-electronics-electronics-in-peshawar", to: "/listing/electronics/haq-electronics-|-electronics-in-peshawar-21092" },
  { from: "/listing/grand-marquee-event-planner-in-peshawar", to: "/listing/event-planner/grand-marquee-|-event-planner-in-peshawar-21095" },
  { from: "/listing/iris-productions-private-limited-event-planner-in-peshawar", to: "/listing/event-planner/iris-productions-(private)-limited-|-event-planner-in-peshawar-21098" },
  { from: "/listing/al-mustafa-estate-and-builders-all-kind-of-property-in-lahore-2", to: "/listing/property-development/al-mustafa-estate-and-builders-|-all-kind-of-property-in-lahore-21145" },
  { from: "/listing/royal-ceramics-basin-commode-and-orrisa-in-lahore", to: "/listing/construction-services/royal-ceramics-|-basin,-commode-and-orrisa-in-lahore-21194" },
  { from: "/listing/tour-and-treks-tourism-in-pakistan", to: "/listing/travel-agents/tour-and-treks-|-tourism-in-pakistan-21200" },
  { from: "/listing/club-w-fitness-gym-gym-in-peshawar", to: "/listing/gym/club-w-fitness-gym-|-gym-in-peshawar-21223" },
  { from: "/listing/gold-standard-gym-gym-in-peshawar", to: "/listing/gym/gold-standard-gym-|-gym-in-peshawar-21227" },
  { from: "/listing/power-house-gym-gym-in-peshawar", to: "/listing/gym/power-house-gym-|-gym-in-peshawar-21231" },
  { from: "/listing/four-season-fast-food-home-delivery-food-in-peshawar", to: "/listing/home-delivery-food/four-season-fast-food-|-home-delivery-food-in-peshawar-21235" },
  { from: "/listing/food-punch-home-delivery-food-in-peshawar", to: "/listing/home-delivery-food/food-punch-|-home-delivery-food-in-peshawar-21238" },
  { from: "/listing/lady-reading-hospital-mti-hospitals-in-peshawar", to: "/listing/hospitals/lady-reading-hospital-mti-|-hospitals-in-peshawar-21242" },
  { from: "/listing/northwest-general-hospital-research-center-hospitals-in-peshawar", to: "/listing/hospitals/northwest-general-hospital-&-research-center-|-hospitals-in-peshawar-21245" },
  { from: "/listing/prime-hospital-complex-hospitals-in-peshawar", to: "/listing/hospitals/prime-hospital-complex-|-hospitals-in-peshawar-21249" },
  { from: "/listing/fort-continental-hotel-hotels-in-peshawar", to: "/listing/hotels/fort-continental-hotel-|-hotels-in-peshawar-21254" },
  { from: "/listing/sheltons-rezidor-hotels-in-peshawar", to: "/listing/hotels/shelton's-rezidor-|-hotels-in-peshawar-21257" },
  { from: "/listing/greens-hotel-hotels-in-peshawar", to: "/listing/hotels/greens-hotel-|-hotels-in-peshawar-21260" },
  { from: "/listing/akhtar-jewellers-jewellers-in-peshawar", to: "/listing/jewelers/akhtar-jewellers-|-jewellers-in-peshawar-21263" },
  { from: "/listing/taj-jewellers-jewellers-in-peshawar", to: "/listing/jewelers/taj-jewellers-|-jewellers-in-peshawar-21266" },
  { from: "/listing/new-ruby-jewellers-jewellers-in-peshawar", to: "/listing/jewelers/new-ruby-jewellers-|-jewellers-in-peshawar-21269" },
  { from: "/listing/sina-labs-laboratories-in-peshawar", to: "/listing/laboratories/sina-labs-|-laboratories-in-peshawar-21296" },
  { from: "/listing/mineral-testing-laboratory-laboratories-in-peshawar", to: "/listing/laboratories/mineral-testing-laboratory-|-laboratories-in-peshawar-21299" },
  { from: "/listing/al-shifa-clinical-laboratory-laboratories-in-peshawar", to: "/listing/laboratories/al-shifa-clinical-laboratory-|-laboratories-in-peshawar-21302" },
  { from: "/listing/kakakhel-law-associates-lawyers-in-peshawar", to: "/listing/lawyers/kakakhel-law-associates-|-lawyers-in-peshawar-21305" },
  { from: "/listing/ijaz-anwar-and-associates-lawyers-in-peshawar", to: "/listing/lawyers/ijaz-anwar-and-associates-|-lawyers-in-peshawar-21308" },
  { from: "/listing/judiciary-complex-lawyers-in-peshawar", to: "/listing/lawyers/judiciary-complex-|-lawyers-in-peshawar-21310" },
  { from: "/listing/rehmat-memorial-trust-library-libraries-in-peshawar", to: "/listing/libraries/rehmat-memorial-trust-library-|-libraries-in-peshawar-21312" },
  { from: "/listing/salafi-solar-library-libraries-in-peshawar", to: "/listing/libraries/salafi-solar-library-|-libraries-in-peshawar-21316" },
  { from: "/listing/central-library-libraries-in-peshawar", to: "/listing/libraries/central-library-|-libraries-in-peshawar-21318" },
  { from: "/listing/ifahja-pvt-ltd-mobile-app-development-in-peshawar", to: "/listing/mobile-app-development/ifahja-pvt-ltd-|-mobile-app-development-in-peshawar-21320" },
  { from: "/listing/ittefaq-tour-and-travels-rent-a-cars-in-islamabad", to: "/listing/car-rental/ittefaq-tour-and-travels-&-rent-a-cars-in-islamabad-21325" },
  { from: "/listing/nice-link-transport-rent-a-car-in-islamabad", to: "/listing/car-rental/nice-link-transport-|-rent-a-car-in-islamabad-21336" },
  { from: "/listing/taqwa-rent-a-car-tours-operator", to: "/listing/car-rental/taqwa-rent-a-car-&-tours-operator-21342" },
  { from: "/listing/it-artificer-mobile-app-development-in-peshawar", to: "/listing/mobile-app-development/it-artificer-|-mobile-app-development-in-peshawar-21357" },
  { from: "/listing/dotcom-services-mobile-app-development-in-peshawar", to: "/listing/mobile-app-development/dotcom-services-|-mobile-app-development-in-peshawar-21360" },
  { from: "/listing/tatara-park-parks-in-peshawar", to: "/listing/parks/tatara-park-|-parks-in-peshawar-21364" },
  { from: "/listing/jinnah-park-parks-in-peshawar", to: "/listing/parks/jinnah-park-|-parks-in-peshawar-21368" },
  { from: "/listing/garrison-park-parks-in-peshawar", to: "/listing/parks/garrison-park-|-parks-in-peshawar-21373" },
  { from: "/listing/safe-home-pest-management-pest-control-in-peshawar", to: "/listing/pest-control/safe-home-pest-management-|-pest-control-in-peshawar-21375" },
  { from: "/listing/asfandiyar-pest-agro-services-pest-control-in-peshawar", to: "/listing/pest-control/asfandiyar-pest-&-agro-services-|-pest-control-in-peshawar-21379" },
  { from: "/listing/four-paws-pets-clinic-pets-hospitals-in-peshawar", to: "/listing/pets-hospitals/four-paws-pets-clinic-|-pets-hospitals-in-peshawar-21381" },
  { from: "/listing/vets-for-your-pets-pets-hospitals-in-peshawar", to: "/listing/pets-hospitals/vets-for-your-pets-|-pets-hospitals-in-peshawar-21384" },
  { from: "/listing/dmpc-dr-masoom-pets-clinic-pets-hospitals-in-peshawar", to: "/listing/pets-hospitals/dmpc-dr.-masoom-pets-clinic-|-pets-hospitals-in-peshawar-21387" },
  { from: "/listing/wasila-studio-photographer-in-peshawar", to: "/listing/photographer/wasila-studio-|-photographer-in-peshawar-21392" },
  { from: "/listing/estudio-by-abid-photographer-in-peshawar", to: "/listing/photographer/estudio-by-abid-|-photographer-in-peshawar-21394" },
  { from: "/listing/camouflage-studio-photographer-in-peshawar", to: "/listing/photographer/camouflage-studio-|-photographer-in-peshawar-21397" },
  { from: "/listing/welcome-to-thetutors", to: "/listing/tutoring/welcome-to-thetutors-21413" },
  {
    from: "/listing/millat-consultants-architects-electrical-plumbing-hvac-plumber-in-peshawar",
    to: "/listing/plumbers/millat-consultants-(architects,-electrical,-plumbing-&-hvac)-|-plumber-in-peshawar-21436",
  },
  { from: "/listing/shakeel-plumbing-work-plumber-in-peshawar", to: "/listing/plumbers/shakeel-plumbing-work-|-plumber-in-peshawar-21441" },
  { from: "/listing/pak-sarhad-pipe-and-sanitary-store-plumber-in-peshawar", to: "/listing/plumbers/pak-sarhad-pipe-and-sanitary-store-|-plumber-in-peshawar-21443" },
  { from: "/listing/net-valley-telecom-valley-composing-center-printing-in-peshawar", to: "/listing/printing/net-valley-telecom-valley-composing-center-|-printing-in-peshawar-21446" },
  { from: "/listing/printografia-design-paper-mug-printing-in-peshawar", to: "/listing/printing/printografia-design,-paper-&-mug-printing-in-peshawar-21448" },
  { from: "/listing/spinzer-printers-printing-in-peshawar", to: "/listing/printing/spinzer-printers-|-printing-in-peshawar-21451" },
  { from: "/listing/white-house-property-property-in-peshawar", to: "/listing/property-development/white-house-property-|-property-in-peshawar-21455" },
  { from: "/listing/naran-property-dealer-property-in-peshawar", to: "/listing/property-development/naran-property-dealer-|-property-in-peshawar-21476" },
  { from: "/listing/khater-property-property-in-peshawar", to: "/listing/property-development/khater-property-|-property-in-peshawar-21478" },
  { from: "/listing/cafe-crunch-restaurants-in-peshawar", to: "/listing/restaurants/cafe-crunch-|-restaurants-in-peshawar-21481" },
  { from: "/listing/the-green-olives-restaurant-restaurants-in-peshawar", to: "/listing/restaurants/the-green-olive's-restaurant-|-restaurants-in-peshawar-21484" },
  { from: "/listing/usmania-restaurant-restaurants-in-peshawar", to: "/listing/restaurants/usmania-restaurant-|-restaurants-in-peshawar-21487" },
  { from: "/listing/dollis-salon-spa-salons-in-peshawar", to: "/listing/salons/dolli's-salon-&-spa-|-salons-in-peshawar-21490" },
  { from: "/listing/clip-trim-men-saloon-salons-in-peshawar", to: "/listing/salons/clip-&-trim-men-saloon-|-salons-in-peshawar-21493" },
  { from: "/listing/moonway-beauty-salon-salons-in-peshawar", to: "/listing/salons/moonway-beauty-salon-|-salons-in-peshawar-21496" },
  { from: "/listing/inteltrix-website-designing-development-lahore", to: "/listing/software-company/inteltrix---website-designing-&amp;-development-lahore-21796" },
  { from: "/listing/fast-track-tours-rentals-in-lahore", to: "/listing/car-dealer/fast-track-tours-&-rentals-in-lahore-21943" },
  { from: "/listing/car-hub-car-dealer-in-lahore", to: "/listing/car-dealer/car-hub-|-car-dealer-in-lahore-21955" },
  { from: "/listing/best-buy-mall", to: "/listing/shopping-malls/best-buy-mall-22007" },
  { from: "/listing/friends-rent-a-car-multan", to: "/listing/car-rental/friends-rent-a-car-multan-22208" },
  { from: "/listing/schole-international-academy", to: "/listing/driving-schools/schole-international-academy-22341" },
  { from: "/listing/alpha-tech-solution", to: "/listing/digital-marketing/alpha-tech-solution-22397" },
  { from: "/listing/gynecomastia-surgery-lahore", to: "/listing/doctors-and-clinics/gynecomastia-surgery-lahore-22474" },
  { from: "/listing/men-boobs-reduction-surgery-lahore", to: "/listing/doctors-and-clinics/men-boobs-reduction-surgery-lahore-22478" },
  { from: "/listing/pakistan-rent-a-car-in-islamabad", to: "/listing/car-rental/pakistan-rent-a-car-in-islamabad-22578" },
  { from: "/listing/master-car-rental-services-car-rental-in-islamabad", to: "/listing/car-rental/master-car-rental-services-|-car-rental-in-islamabad-22594" },
  { from: "/listing/jmc-rent-a-car-in-islamabad", to: "/listing/car-rental/jmc-rent-a-car-in-islamabad-22672" },
  { from: "/listing/ar-raheem-motors-rent-a-car-in-rawalpindi-and-islamabad-2", to: "/listing/car-rental/ar-raheem-motors-rent-a-car-in-rawalpindi-and-islamabad-22725" },
  { from: "/listing/platinum-auto-detailing-in-lahore-2", to: "/listing/car-denting-painting/platinum-auto-detailing-in-lahore-22801" },
  { from: "/listing/prime-rent-a-car-and-tours-2", to: "/listing/car-rental/prime-rent-a-car-and-tours-22892" },
  { from: "/listing/hamza-travels-rent-a-car-in-islamabad", to: "/listing/car-rental/hamza-travels-rent-a-car-in-islamabad-22902" },
  { from: "/listing/mian-rent-a-car-in-faisalabad-2", to: "/listing/car-rental/mian-rent-a-car-in-faisalabad-23012" },
  { from: "/listing/friends-rent-a-car-in-faisalabad-2", to: "/listing/car-rental/friends-rent-a-car-in-faisalabad-23061" },
  { from: "/listing/lyallpur-rent-a-car-in-faisalabad-3", to: "/listing/car-rental/lyallpur-rent-a-car-in-faisalabad-23146" },
  { from: "/listing/pakistan-rent-a-car-in-faisalabad-2", to: "/listing/car-rental/pakistan-rent-a-car-in-faisalabad-23159" },
  { from: "/listing/umar-rent-a-car-in-lahore-2", to: "/listing/car-rental/umar-rent-a-car-in-lahore-23368" },
  { from: "/listing/car-detailing-in-lahore-2", to: "/listing/car-denting-painting/car-detailing-in-lahore-23438" },
  { from: "/listing/easy-property-link-property-dealers-in-lahore", to: "/listing/property-development/easy-property-link-|-property-dealers-in-lahore-23779" },
  { from: "/listing/friends-car-hire-services-in-faisalabad-2", to: "/listing/car-rental/friends-car-hire-services-in-faisalabad-23783" },
  { from: "/listing/nadeem-ac-service-center", to: "/listing/general-services/nadeem-ac-service-center-23838" },
  { from: "/listing/tafsir-al-ahlam-arabic-tafsir-al-ahlam", to: "/listing/digital-marketing/tafsir-al-ahlam-arabic---tafsir-al-ahlam-23988" },
  { from: "/listing/dr-asad-toor-hair-transplant-clinic", to: "/listing/hospitals/dr-asad-toor-hair-transplant-clinic-23994" },
  { from: "/listing/fairwheels-com", to: "/listing/car-dealer/fairwheels.com-24063" },
  { from: "/listing/jobee-pk", to: "/listing/general-services/jobee.pk-24238" },
  { from: "/listing/deedo-pk-online-baby-shopping-store-in-pakistan", to: "/listing/shopping-malls/deedo.pk---online-baby-shopping-store-in-pakistan-24244" },
  { from: "/listing/kids-emporium", to: "/listing/general-services/kids-emporium-24303" },
  { from: "/listing/sw3-solutions", to: "/listing/software-company/sw3-solutions-24381" },
  { from: "/listing/eschool", to: "/listing/driving-schools/eschool-24388" },
  { from: "/listing/qas-group", to: "/listing/travel-agents/qas-group-24537" },
  { from: "/listing/petsworld-pk-pet-clinic-in-lahore-online-store", to: "/listing/doctors-and-clinics/petsworld.pk-|-pet-clinic-in-lahore-|-online-store-24555" },
  { from: "/listing/the-united-insurance-company-of-pakistan", to: "/listing/general-services/the-united-insurance-company-of-pakistan-24687" },
  { from: "/listing/advanced-driving-school-gujranwala", to: "/listing/driving-schools/advanced-driving-school-gujranwala-24817" },
  { from: "/listing/best-school-management-system-in-pakistan", to: "/listing/software-company/best-school-management-system-in-pakistan-24911" },
  { from: "/listing/rahil-motors-rent-a-car-in-islamabad-2", to: "/listing/car-rental/rahil-motors-rent-a-car-in-islamabad-24927" },
  { from: "/listing/umrah-visa-ticket-booking-services-umrah-on-installment-3", to: "/listing/travel-agents/umrah-visa-&-ticket-booking-services-|-umrah-on-installment-25129" },
  { from: "/listing/watto-associates-estate-advisor-builders", to: "/listing/property-development/watto-associates-|-estate-advisor-&-builders-25234" },
  { from: "/listing/property-advisor-bahria-town-and-bahria-orchard", to: "/listing/property-development/property-advisor-|-bahria-town-and-bahria-orchard-25244" },
  { from: "/listing/pyramids-architects-in-islamabad-engineering-consultants", to: "/listing/construction-services/pyramids-|-architects-in-islamabad-|-engineering-consultants-25410" },
  { from: "/listing/adnan-dental-and-implant-clinic-clinic-in-islamabad", to: "/listing/doctors-and-clinics/adnan-dental-and-implant-clinic-|-clinic-in-islamabad-25426" },
  { from: "/listing/the-great-red-spot", to: "/listing/event-planner/the-great-red-spot-25764" },
  { from: "/listing/panache-by-mona", to: "/listing/shopping-malls/panache-by-mona-25883" },
  { from: "/listing/defence-zone-real-estate-plots-for-sale-in-dha-2", to: "/listing/property-development/defence-zone-real-estate-|-plots-for-sale-in-dha-26699" },
  { from: "/listing/5-marla-homes-on-1-5-year-installment-in-lahore-3", to: "/listing/property-development/5-marla-homes-on-1.5-year-installment-in-lahore-26877" },
  { from: "/listing/4-marla-4-floor-building-for-rent-in-baghbanpura-lahore", to: "/listing/property-for-rent/4-marla-4-floor-building-for-rent-in-baghbanpura,-lahore-26889" },
  { from: "/listing/barki-hadiara-farm-house-for-sale-2", to: "/listing/property-development/barki-hadiara-farm-house-for-sale-27250" },
  { from: "/listing/digital-revolution", to: "/listing/digital-marketing/digital-revolution-27756" },
  { from: "/listing/find-a-suitable-partner-ideal-marriage-beuro-in-lahore-2", to: "/listing/marriage-beuro/find-a-suitable-partner-|-ideal-marriage-beuro-in-lahore-27893" },
  { from: "/listing/zicas-school-system-best-school-in-lahore", to: "/listing/driving-schools/zicas-school-system-|-best-school-in-lahore-27895" },
  { from: "/listing/the-ultimate-guru-heights-in-teaching", to: "/listing/tutoring/the-ultimate-guru-heights-in-teaching-27949" },
  { from: "/listing/al-khair-hajj-2020-umrah", to: "/listing/travel-agents/al-khair-hajj-2020-&-umrah-28004" },
  { from: "/listing/famous-lawyers-in-gujranwala-sheikh-arslan-ali", to: "/listing/lawyers/famous-lawyers-in-gujranwala-|-sheikh-arslan-ali-28111" },
  { from: "/listing/nabi-buksh-law-concern", to: "/listing/lawyers/nabi-buksh-law-concern-28496" },
  { from: "/listing/savera-beauty-parlour", to: "/listing/beauty-parlour/savera-beauty-parlour-28753" },
  { from: "/listing/kashmala-hair-and-esthetic-salon", to: "/listing/beauty-parlour/kashmala-hair-and-esthetic-salon-28755" },
  { from: "/listing/bismillah-beauty-parlour-for-women", to: "/listing/beauty-parlour/bismillah-beauty-parlour-for-women-28760" },
  { from: "/listing/wishie-beauty-saloon-skin-care-center", to: "/listing/beauty-parlour/wishie-beauty-saloon-&-skin-care-center-28765" },
  { from: "/listing/affan-book-depot", to: "/listing/book-stores/affan-book-depot-28776" },
  { from: "/listing/muslim-kitab-ghar", to: "/listing/book-stores/muslim-kitab-ghar-28783" },
  { from: "/listing/sargodha-book-depot-lalian", to: "/listing/book-stores/sargodha-book-depot-lalian-28787" },
  { from: "/listing/suzuki-chiniot-motors-chenab-motors-branch", to: "/listing/car-dealer/suzuki-chiniot-motors-(chenab-motors-branch)-28794" },
  { from: "/listing/chenab-motors-property-center", to: "/listing/car-dealer/chenab-motors-&-property-center-28799" },
  { from: "/listing/al-rehman-motors-cars", to: "/listing/car-dealer/al-rehman-motor,s-car,s-28808" },
  { from: "/listing/rasheed-enterprises", to: "/listing/car-dealer/rasheed-enterprises-28813" },
  { from: "/listing/ali-friends-motor-workshop", to: "/listing/car-rental/ali-friends-motor-workshop-28816" },
  { from: "/listing/theim-auto-workshops", to: "/listing/car-denting-painting/theim-auto-workshops-28819" },
  { from: "/listing/rent-a-car-rabwah", to: "/listing/car-rental/rent-a-car-rabwah-28822" },
  { from: "/listing/cap-taxi-stand-rabwah", to: "/listing/car-rental/cap-taxi-stand-rabwah-28825" },
  { from: "/listing/zavi-bajwa-rent-a-car", to: "/listing/car-rental/zavi-bajwa-rent-a-car-28831" },
  { from: "/listing/shuja-motors-travels-rent-a-car", to: "/listing/car-rental/shuja-motors-travels-&rent-a-car-28835" },
  { from: "/listing/chenab-service-station-and-rent-a-car", to: "/listing/car-rental/chenab-service-station-and-rent-a-car-28840" },
  { from: "/listing/wajahat-service-station", to: "/listing/car-washers/wajahat-service-station-28874" },
  { from: "/listing/shabbir-service-station", to: "/listing/car-washers/shabbir-service-station-28877" },
  { from: "/listing/al-madina-service-point", to: "/listing/car-washers/al-madina-service-point-28880" },
  { from: "/listing/mughal-service-station", to: "/listing/car-washers/mughal-service-station-28883" },
  { from: "/listing/ayyan-wash-point", to: "/listing/car-washers/ayyan-wash-point-28886" },
  { from: "/listing/al-madni-zari-markaz", to: "/listing/car-washers/al-madni-zari-markaz-28889" },
  { from: "/listing/mahr-service-station", to: "/listing/car-washers/mahr-service-station-28892" },
  { from: "/listing/chiniot-furniture-work-shop-chiniot-city", to: "/listing/carpenter/chiniot-furniture-work-shop-chiniot-city-28896" },
  { from: "/listing/chiniot-furniture", to: "/listing/carpenter/chiniot-furniture-28899" },
  { from: "/listing/haq-bahoo-furniture", to: "/listing/carpenter/haq-bahoo-furniture-28902" },
  { from: "/listing/mz-furniture-official", to: "/listing/carpenter/mz-furniture-official-28906" },
  { from: "/listing/complete-wood-interior", to: "/listing/carpenter/complete-wood-interior-28908" },
  { from: "/listing/zafar-carpentry-work-shop", to: "/listing/carpenter/zafar-carpentry-work-shop-28911" },
  { from: "/listing/master-zafars-handicraft-factory", to: "/listing/carpenter/master-zafar's-handicraft-factory-28914" },
  { from: "/listing/chiniot-interiors", to: "/listing/carpenter/chiniot-interiors-28917" },
  { from: "/listing/pyramid-furniture-zafar-furniture", to: "/listing/carpenter/pyramid-furniture---zafar-furniture-28921" },
  { from: "/listing/dr-ali-amin-homeopathic-clinic", to: "/listing/doctors-and-clinics/dr-ali-amin-homeopathic-clinic-28923" },
  { from: "/listing/janjua-care-clinic", to: "/listing/doctors-and-clinics/janjua-care-clinic-28926" },
  { from: "/listing/al-haseeb-clinic", to: "/listing/doctors-and-clinics/al-haseeb-clinic-28929" },
  { from: "/listing/doctor-zahra-hassan-maternity-clinic", to: "/listing/doctors-and-clinics/doctor-zahra-hassan-maternity-clinic-28932" },
  { from: "/listing/mughal-herbal-clinic", to: "/listing/doctors-and-clinics/mughal-herbal-clinic-28935" },
  { from: "/listing/rabwah-eye-clinic", to: "/listing/doctors-and-clinics/rabwah-eye-clinic-28938" },
  { from: "/listing/electrician-and-ac-technician", to: "/listing/electrician/electrician-and-ac-technician-28944" },
  { from: "/listing/ilm-college", to: "/listing/colleges/ilm-college-28964" },
  { from: "/listing/chenab-college-chiniot", to: "/listing/colleges/chenab-college-chiniot-28967" },
  { from: "/listing/superior-college-chiniot", to: "/listing/colleges/superior-college-chiniot-28970" },
  { from: "/listing/din-college-for-women-chiniot", to: "/listing/colleges/din-college-for-women-chiniot-28977" },
  { from: "/listing/tips-college-chiniot", to: "/listing/colleges/tips-college-chiniot-28983" },
  { from: "/listing/pak-poly-technic-institute-chiniot", to: "/listing/colleges/pak-poly-technic-institute-chiniot-28988" },
  { from: "/listing/unified-science-college", to: "/listing/colleges/unified-science-college-28999" },
  { from: "/listing/printing-lahore", to: "/listing/advertisers/printing-lahore-29090" },
  { from: "/listing/badar-computers", to: "/listing/computer-repair/badar-computers-29182" },
  { from: "/listing/mtech-it-solutions", to: "/listing/computer-repair/mtech-it-solutions-29185" },
  { from: "/listing/abdullah-mobile-and-computer", to: "/listing/computer-repair/abdullah-mobile-and-computer-29188" },
  { from: "/listing/malik-computer-lalian", to: "/listing/computer-repair/malik-computer-lalian-29191" },
  { from: "/listing/nadeem-computers", to: "/listing/computer-repair/nadeem-computers-29194" },
  { from: "/listing/xen-highway-division-chiniot", to: "/listing/construction-services/xen-highway-division-chiniot-29201" },
  { from: "/listing/dhuddi-house", to: "/listing/construction-services/dhuddi-house-29203" },
  { from: "/listing/mahr-nasir-timber-dealer", to: "/listing/construction-services/mahr-nasir-timber-dealer-29206" },
  { from: "/listing/nusrat-dental-clinic", to: "/listing/dental/nusrat-dental-clinic-29209" },
  { from: "/listing/midtown-dentals", to: "/listing/dental/midtown-dentals-29211" },
  { from: "/listing/bashir-dental-clinic", to: "/listing/dental/bashir-dental-clinic-29214" },
  { from: "/listing/identist-dr-sana-ahmad", to: "/listing/dental/identist---dr.-sana-ahmad-29217" },
  { from: "/listing/hasnain-saqib-seo-and-digital-marketing-expert", to: "/listing/dental/hasnain-saqib-(seo-and-digital-marketing-expert)-29220" },
  { from: "/listing/stepup-digital-solutions", to: "/listing/digital-marketing/stepup-digital-solutions-29222" },
  { from: "/listing/ad-market-the-social-media-and-printing-agency", to: "/listing/digital-marketing/ad-market-(the-social-media-and-printing-agency)-29226" },
  { from: "/listing/alahmad-driving-training-centre", to: "/listing/driving-schools/alahmad-driving-training-centre-29295" },
  { from: "/listing/ad-parentar", to: "/listing/electrician/ad-parentar-29302" },
  { from: "/listing/shahnawaz-electronic-chiniot", to: "/listing/electronics/shahnawaz-electronic-chiniot-29307" },
  { from: "/listing/hammad-electric-and-electronics", to: "/listing/electronics/hammad-electric-and-electronics-29311" },
  { from: "/listing/m-anwar-electric-store", to: "/listing/electronics/m-anwar-electric-store-29314" },
  { from: "/listing/al-madina-tent-service-chiniot", to: "/listing/event-planner/al-madina-tent-service-chiniot-29317" },
  { from: "/listing/janbah-farm-house", to: "/listing/farm-houses/janbah-farm-house-29320" },
  { from: "/listing/bajwa-farm-house", to: "/listing/farm-houses/bajwa-farm-house-29323" },
  { from: "/listing/body-fitness-gym", to: "/listing/gym/body-fitness-gym-29326" },
  { from: "/listing/fazle-e-umar-health-club", to: "/listing/gym/fazle-e-umar-health-club-29329" },
  { from: "/listing/matloob-gym", to: "/listing/gym/matloob-gym-29333" },
  { from: "/listing/fitness-women-gym", to: "/listing/gym/fitness-women-gym-29338" },
  { from: "/listing/dhq-hospital", to: "/listing/hospitals/dhq-hospital-29341" },
  { from: "/listing/islamia-khairati-hospital-chiniot", to: "/listing/hospitals/islamia-khairati-hospital-chiniot-29344" },
  { from: "/listing/pakistan-anti-goiter-patients-welfare-services", to: "/listing/hospitals/pakistan-anti-goiter-&-patients-welfare-services-29350" },
  { from: "/listing/mohammad-jewellers", to: "/listing/jewelers/mohammad-jewellers-29353" },
  { from: "/listing/fancy-jewellers", to: "/listing/jewelers/fancy-jewellers-29356" },
  { from: "/listing/the-biotech-medical-laboratoriescollection-point-chiniot", to: "/listing/laboratories/the-biotech-medical-laboratories,collection-point-chiniot.-29397" },
  { from: "/listing/shaukat-khanum-laboratory-collection-centres", to: "/listing/laboratories/shaukat-khanum-laboratory-collection-centres-29398" },
  { from: "/listing/sahara-lab-collection-center-chiniot", to: "/listing/laboratories/sahara-lab-collection-center-chiniot-29401" },
  { from: "/listing/adv-malik-fazal-hussain-kalru", to: "/listing/lawyers/adv.malik-fazal-hussain-kalru-29405" },
  { from: "/listing/24-7-law-associates", to: "/listing/lawyers/24-7-law-associates-29408" },
  { from: "/listing/kamran-khan", to: "/listing/lawyers/kamran-khan-29411" },
  { from: "/listing/quaid-e-azam-park", to: "/listing/parks/quaid-e-azam-park-29414" },
  { from: "/listing/library-park-chiniot", to: "/listing/parks/library-park-chiniot-29417" },
  { from: "/listing/art-image-advertising", to: "/listing/advertisers/art-image-advertising-29527" },
  { from: "/listing/3d-art-multan", to: "/listing/advertisers/3d-art-multan-29529" },
  { from: "/listing/chaudhary-brothers-advertising-services", to: "/listing/advertisers/chaudhary-brothers-advertising-services-29532" },
  { from: "/listing/hi-signs", to: "/listing/advertisers/hi-signs-29535" },
  { from: "/listing/gimmick-advertisers", to: "/listing/advertisers/gimmick-advertisers-29538" },
  { from: "/listing/kabootar-mandi", to: "/listing/animals-&amp;-birds/kabootar-mandi-29541" },
  { from: "/listing/pets-and-birds-market%da%af%d9%88%d8%af%da%91%db%8c", to: "/listing/animals-&amp;-birds/pets-and-birds-marketگودڑی-29543" },
  { from: "/listing/best-birds", to: "/listing/animals-&amp;-birds/best-birds-29546" },
  { from: "/listing/imported-bird-centre", to: "/listing/animals-&amp;-birds/imported-bird-centre-29549" },
  { from: "/listing/cosmos-beauty-salon", to: "/listing/beauty-parlour/cosmos-beauty-salon-29552" },
  { from: "/listing/allenora-annie-signature-salon-multan", to: "/listing/beauty-parlour/allenora-annie-signature-salon-multan-29556" },
  { from: "/listing/mirrors-beauty-salon", to: "/listing/beauty-parlour/mirrors-beauty-salon-29559" },
  { from: "/listing/new-ideas-beauty-parlor", to: "/listing/beauty-parlour/new-ideas-beauty-parlor-29562" },
  { from: "/listing/chandni-beauty-parlour", to: "/listing/beauty-parlour/chandni-beauty-parlour-29565" },
  { from: "/listing/multan-kitab-ghar", to: "/listing/book-stores/multan-kitab-ghar-29568" },
  { from: "/listing/carvan-book-center", to: "/listing/beauty-parlour/carvan-book-center-29572" },
  { from: "/listing/news-corner", to: "/listing/book-stores/news-corner-29579" },
  { from: "/listing/tariq-cars", to: "/listing/car-dealer/tariq-cars-29672" },
  { from: "/listing/pakistan-cars", to: "/listing/car-dealer/pakistan-cars-29676" },
  { from: "/listing/kazmi-motors", to: "/listing/car-dealer/kazmi-motors-29679" },
  { from: "/listing/raza-motors-multan", to: "/listing/car-dealer/suzuki-south-punjab-29682" },
  { from: "/listing/rehan-motors", to: "/listing/car-dealer/rehan-motors-29685" },
  { from: "/listing/arshad-motors", to: "/listing/car-dealer/arshad-motors-29688" },
  { from: "/listing/7-speed-auto-detailing-glass-coating", to: "/listing/car-denting-painting/7-speed-auto-detailing-&-glass-coating-29691" },
  { from: "/listing/endless-detailing-multan", to: "/listing/car-denting-painting/endless-detailing-multan-29694" },
  { from: "/listing/car-denting-painting-workshop", to: "/listing/car-denting-painting/car-denting-&-painting-workshop-29697" },
  { from: "/listing/archive-technologies-records-management-company", to: "/listing/general-services/archive-technologies---records-management-company-29719" },
  { from: "/listing/green-tour-rent-a-car", to: "/listing/car-rental/green-tour-rent-a-car-29909" },
  { from: "/listing/kestrel-education", to: "/listing/colleges/kestrel-education-30018" },
  { from: "/listing/gexton-security-services-system", to: "/listing/digital-marketing/gexton-security-&amp;-services-system-30539" },
  { from: "/listing/gexton-apps", to: "/listing/mobile-app-development/gexton-apps-31347" },
  { from: "/listing/a2z-events-solutions", to: "/listing/event-planner/a2z-events-solutions-31481" },
  { from: "/listing/ac-service-lahore", to: "/listing/general-services/ac-service-lahore-31590" },
  { from: "/listing/service-square", to: "/listing/general-services/service-square-31955" },
  { from: "/listing/hanif-centre-electronic-on-easy-installment-3", to: "/listing/electronics/hanif-centre-|-electronic-on-easy-installment-32348" },
  { from: "/listing/united-driving-schools-ladies-and-gents-gujranwala", to: "/listing/travel-agents/united-driving-schools-ladies-and-gents-gujranwala-32374" },
  { from: "/listing/rd-enviro-electronic-on-easy-installment-3", to: "/listing/electronics/rd-enviro-|-electronic-on-easy-installment-32453" },
  { from: "/listing/afzal-electronics-on-easy-installment-3", to: "/listing/electronics/afzal-electronics-on-easy-installment-32457" },
  { from: "/listing/naeem-electronics-larik-easy-installment-2", to: "/listing/electronics/naeem-electronics-larik-|-easy-installment-32460" },
  { from: "/listing/waqas-electronics-easy-installment-3", to: "/listing/electronics/waqas-electronics-|-easy-installment-32463" },
  { from: "/listing/mohkam-electronics-easy-installment-3", to: "/listing/electronics/mohkam-electronics-|-easy-installment-32466" },
  { from: "/listing/pet-mania-pakistan-2", to: "/listing/pets-hospitals/pet-mania-pakistan-32645" },
  { from: "/listing/spi-insurance-company-in-pakistan", to: "/listing/general-services/spi---insurance-company-in-pakistan-32659" },
  { from: "/listing/astrix-technologies", to: "/listing/software-company/astrix-technologies-32676" },
  { from: "/listing/surmawala-electronics-ahmed-pur-3", to: "/listing/electronics/surmawala-electronics-|-ahmed-pur-32783" },
  { from: "/listing/big-screen-events-smd-screen-rental", to: "/listing/event-planner/big-screen-events-(smd-screen-rental)-32790" },
  { from: "/listing/suzuki-derawar-motors-ahmedpur-east-2", to: "/listing/car-dealer/suzuki-derawar-motors-|-ahmedpur-east-32791" },
  { from: "/listing/ahsan-motors-ahmedpur-east-2", to: "/listing/car-dealer/ahsan-motors-|-ahmedpur-east-32796" },
  { from: "/listing/junaid-motors-ahmedpur-east-2", to: "/listing/car-dealer/junaid-motors-|-ahmedpur-east-32798" },
  { from: "/listing/she-beauty-parlour-ahmedpur-east", to: "/listing/beauty-parlour/she-beauty-parlour-|-ahmedpur-east-32800" },
  { from: "/listing/focus-beauty-salon-ahmedpur-east-2", to: "/listing/beauty-parlour/focus-beauty-salon-|-ahmedpur-east-32802" },
  { from: "/listing/rose-beauty-parlour-ahmedpur-east-2", to: "/listing/beauty-parlour/rose-beauty-parlour-|-ahmedpur-east-32804" },
  { from: "/listing/saleemi-book-depot-ahmedpur-east-2", to: "/listing/book-stores/saleemi-book-depot-|-ahmedpur-east-32806" },
  { from: "/listing/pakistan-book-center-and-super-store-ahmedpur-east-2", to: "/listing/book-stores/pakistan-book-center-and-super-store-|-ahmedpur-east-32808" },
  { from: "/listing/rent-a-car-ahmedpur-east-2", to: "/listing/car-rental/rent-a-car-|-ahmedpur-east-32810" },
  { from: "/listing/online-dissertation-writing-services-uk", to: "/listing/content-writers/online-dissertation-writing-services-uk-32829" },
  { from: "/listing/abu-saim-car-wash-ahmedpur-east-2", to: "/listing/car-washers/abu-saim-car-wash-|-ahmedpur-east-32832" },
  { from: "/listing/thedesk-coworking-space", to: "/listing/general-services/thedesk-coworking-space-32833" },
  { from: "/listing/mian-service-station-ahmedpur-east-2", to: "/listing/car-washers/mian-service-station-|-ahmedpur-east-32838" },
  { from: "/listing/dr-muhammad-muzamil-clinic-ahmedpur-east-2", to: "/listing/doctors-and-clinics/dr-muhammad-muzamil-clinic-|-ahmedpur-east-32843" },
  { from: "/listing/alshifa-clinic-ahmedpur-east-2", to: "/listing/doctors-and-clinics/alshifa-clinic-|-ahmedpur-east-32845" },
  { from: "/listing/aazan-clinic-ahmedpur-east-2", to: "/listing/doctors-and-clinics/aazan-clinic-|-ahmedpur-east-32850" },
  { from: "/listing/al-sehat-center-ahmedpur-east-3", to: "/listing/doctors-and-clinics/al-sehat-center-|-ahmedpur-east-32852" },
  { from: "/listing/aiml-college-ahmedpur-east-2", to: "/listing/colleges/aiml-college-|-ahmedpur-east-32855" },
  { from: "/listing/allama-iqbal-college-ahmedpur-east-3", to: "/listing/colleges/allama-iqbal-college-|-ahmedpur-east-32858" },
  { from: "/listing/punjab-college-ahmedpur-east-3", to: "/listing/colleges/punjab-college-|-ahmedpur-east-32861" },
  { from: "/listing/millat-group-of-colleges-ahmedpur-east", to: "/listing/colleges/millat-group-of-colleges-|-ahmedpur-east-32865" },
  { from: "/listing/islamic-model-higher-secondary-school-and-college-ahmedpur-east-2", to: "/listing/colleges/islamic-model-higher-secondary-school-and-college-|-ahmedpur-east-32871" },
  { from: "/listing/vocational-training-institute-ahmedpur-east-2", to: "/listing/colleges/vocational-training-institute-|-ahmedpur-east-32873" },
  { from: "/listing/dar-e-arfa-school-ahmedpur-east-3", to: "/listing/driving-schools/dar-e-arfa-school-|-ahmedpur-east-32876" },
  { from: "/listing/superior-college-arifwala-3", to: "/listing/colleges/superior-college-|-arifwala-32916" },
  { from: "/listing/heritage-college-arifwala-2", to: "/listing/colleges/heritage-college-|-arifwala-32919" },
  { from: "/listing/govt-degree-college-for-women-arifwala-2", to: "/listing/colleges/govt-degree-college-for-women-|-arifwala-32922" },
  { from: "/listing/punjab-group-of-colleges-arifwala-2", to: "/listing/colleges/punjab-group-of-colleges-|-arifwala-32927" },
  { from: "/listing/vocational-training-institute-arifwala-2", to: "/listing/colleges/vocational-training-institute-|-arifwala-32930" },
  { from: "/listing/litchi-beauty-salon-arifwala-2", to: "/listing/beauty-parlour/litchi-beauty-salon-|-arifwala-32933" },
  { from: "/listing/apsra-beauty-cosmic-arifwala-2", to: "/listing/beauty-parlour/apsra-beauty-cosmic-|-arifwala-32936" },
  { from: "/listing/professional-beauty-zone-arifwala-2", to: "/listing/beauty-parlour/professional-beauty-zone-|-arifwala-32938" },
  { from: "/listing/khan-motors-arifwala-2", to: "/listing/car-dealer/khan-motors-|-arifwala-32940" },
  { from: "/listing/lrbt-eye-hospital-arifwala-2", to: "/listing/hospitals/lrbt-eye-hospital-|-arifwala-32942" },
  { from: "/listing/alhamad-clinic-and-hajama-center-arifwala-2", to: "/listing/doctors-and-clinics/alhamad-clinic-and-hajama-center-|-arifwala-32983" },
  { from: "/listing/al-rayyan-ent-clinic-arifwala-2", to: "/listing/doctors-and-clinics/al-rayyan-ent-clinic-|-arifwala-32985" },
  { from: "/listing/jallandhur-homoeopathic-clinic-arifwala-2", to: "/listing/doctors-and-clinics/jallandhur-homoeopathic-clinic-|-arifwala-32987" },
  { from: "/listing/family-health-clinic-arifwala-2", to: "/listing/doctors-and-clinics/family-health-clinic-|-arifwala-32989" },
  { from: "/listing/dr-asif-mirza-arifwala-2", to: "/listing/doctors-and-clinics/dr.-asif-mirza-|-arifwala-32991" },
  { from: "/listing/zulqarnain-foreman-auto-electrician-arifwala-2", to: "/listing/electrician/zulqarnain-foreman-auto-electrician-|-arifwala-32993" },
  { from: "/listing/ar-jewellers-arifwala-2", to: "/listing/jewelers/ar-jewellers-|-arifwala-32995" },
  { from: "/listing/allah-tawakal-jewellers-arifwala-2", to: "/listing/jewelers/allah-tawakal-jewellers-|-arifwala-32997" },
  { from: "/listing/shah-e-najif-jewllers-arifwala-2", to: "/listing/jewelers/shah-e-najif-jewllers-|-arifwala-32999" },
  { from: "/listing/indian-jewellery-and-gift-center-arifwala-2", to: "/listing/jewelers/indian-jewellery-and-gift-center-|-arifwala-33001" },
  { from: "/listing/city-rent-a-car-arifwala-2", to: "/listing/car-rental/city-rent-a-car-|-arifwala-33003" },
  { from: "/listing/bisma-travels-arifwala-3", to: "/listing/car-rental/bisma-travels-|-arifwala-33006" },
  { from: "/listing/national-electronics-arifwala-2", to: "/listing/electronics/national-electronics-|-arifwala-33009" },
  { from: "/listing/surmawala-arifwala-3", to: "/listing/electronics/surmawala-|-arifwala-33013" },
  { from: "/listing/shakeel-electronics-arifwala-2", to: "/listing/electronics/shakeel-electronics-|-arifwala-33020" },
  { from: "/listing/al-madina-electric-and-electronics-arifwala-2", to: "/listing/electronics/al-madina-electric-and-electronics-|-arifwala-33023" },
  { from: "/listing/rehmat-electronic-arifwala-2", to: "/listing/electronics/rehmat-electronic-|-arifwala-33025" },
  { from: "/listing/virtual-university-arifwala-campus-3", to: "/listing/universities/virtual-university-arifwala-campus-33030" },
  { from: "/listing/jinnah-driving-school-arifwala-2", to: "/listing/driving-schools/jinnah-driving-school-|-arifwala-33038" },
  { from: "/listing/the-spirit-school-arifwala-3", to: "/listing/driving-schools/the-spirit-school-|-arifwala-33062" },
  { from: "/listing/new-beacon-house-public-high-school-arifwala-3", to: "/listing/driving-schools/new-beacon-house-public-high-school-|-arifwala-33073" },
  { from: "/listing/the-smart-school-arifwala-2", to: "/listing/driving-schools/the-smart-school-|-arifwala-33076" },
  { from: "/listing/govt-technical-training-centre-arifwala", to: "/listing/colleges/govt-technical-training-centre-|-arifwala-33080" },
  { from: "/listing/bilawal-javaid-advocate-arifwala-2", to: "/listing/lawyers/bilawal-javaid-advocate-|-arifwala-33085" },
  { from: "/listing/hassan-photo-state-arifwala-2", to: "/listing/photographer/hassan-photo-state-|-arifwala-33088" },
  { from: "/listing/taj-photo-state-arifwala-2", to: "/listing/photographer/taj-photo-state-|-arifwala-33090" },
  { from: "/listing/shaheen-marriage-hall-arifwala-2", to: "/listing/wedding-halls/shaheen-marriage-hall-|-arifwala-33092" },
  { from: "/listing/noor-mahal-arifwala-2", to: "/listing/wedding-halls/noor-mahal-|-arifwala-33094" },
  { from: "/listing/mezban-marquee-arifwala-2", to: "/listing/wedding-halls/mezban-marquee-|-arifwala-33096" },
  { from: "/listing/lahori-tailor-arifwala-2", to: "/listing/tailors-and-alterations/lahori-tailor-|-arifwala-33098" },
  { from: "/listing/ideators-pk", to: "/listing/digital-marketing/ideators.pk-33151" },
  { from: "/listing/ms-tailors-gojra", to: "/listing/advertisers/ms-tailors-gojra-33159" },
  { from: "/listing/baoo-asim-arifwala-2", to: "/listing/welding-services/baoo-asim-|-arifwala-33167" },
  { from: "/listing/beautiful-beauty-parlour-attock-2", to: "/listing/beauty-parlour/beautiful-beauty-parlour-|-attock-33185" },
  { from: "/listing/roop-behroop-salon-and-spa-attock-2", to: "/listing/beauty-parlour/roop-behroop-salon-and-spa-|-attock-33187" },
  { from: "/listing/face-focus-beauty-salon-attock-2", to: "/listing/beauty-parlour/face-focus-beauty-salon-|-attock-33189" },
  { from: "/listing/mac-salon-attock-3", to: "/listing/beauty-parlour/mac-salon-|-attock-33191" },
  { from: "/listing/stylish-beauty-parlour-attock-2", to: "/listing/beauty-parlour/stylish-beauty-parlour-|-attock-33194" },
  { from: "/listing/shades-beauty-salon-attock-2", to: "/listing/beauty-parlour/shades-beauty-salon-|-attock-33196" },
  { from: "/listing/pakistan-book-depot-attock-2", to: "/listing/book-stores/pakistan-book-depot-|-attock-33199" },
  { from: "/listing/tashkeel-book-depot-attock-2", to: "/listing/book-stores/tashkeel-book-depot-|-attock-33201" },
  { from: "/listing/ilmi-book-depot-attock-2", to: "/listing/book-stores/ilmi-book-depot-|-attock-33203" },
  { from: "/listing/noor-book-depot-attock-2", to: "/listing/book-stores/noor-book-depot-|-attock-33234" },
  { from: "/listing/suzuki-motors-attock-3", to: "/listing/car-dealer/suzuki-motors-|-attock-33264" },
  { from: "/listing/pro-auto-world-car-showroom-attock-2", to: "/listing/car-dealer/pro-auto-world-car-showroom-|-attock-33268" },
  { from: "/listing/mian-motors-attock-2", to: "/listing/car-dealer/mian-motors-|-attock-33282" },
  { from: "/listing/butt-rent-a-car-attock-2", to: "/listing/car-rental/butt-rent-a-car-|-attock-33284" },
  { from: "/listing/shahid-malik-motors-and-rent-a-car-attock", to: "/listing/car-rental/shahid-malik-motors-and-rent-a-car-|-attock-33286" },
  { from: "/listing/hassan-rent-a-car-attock-2", to: "/listing/car-rental/hassan-rent-a-car-|-attock-33290" },
  { from: "/listing/hamza-rent-a-car-attock-2", to: "/listing/car-rental/hamza-rent-a-car-|-attock-33292" },
  { from: "/listing/lucky-rent-a-car-attock-2", to: "/listing/car-rental/lucky-rent-a-car-|-attock-33295" },
  { from: "/listing/barkat-moter-rent-a-car-attock-2", to: "/listing/car-rental/barkat-moter-rent-a-car-|-attock-33297" },
  { from: "/listing/raza-khan-tours-karachi", to: "/listing/car-rental/raza-khan-tours-karachi-33302" },
  { from: "/listing/saeen-rent-a-car-tours-2", to: "/listing/car-rental/saeen-rent-a-car-&-tours-33317" },
  { from: "/listing/mardan-motors-rent-a-car", to: "/listing/car-rental/mardan-motors-&-rent-a-car-33324" },
  { from: "/listing/earn-money-by-rent-your-car", to: "/listing/car-rental/earn-money-by-rent-your-car-33337" },
  { from: "/listing/al-wajid-rent-a-car", to: "/listing/car-rental/al-wajid-rent-a-car-33339" },
  { from: "/listing/a-h-rent-a-car-karachi-3", to: "/listing/car-rental/a.h-rent-a-car-karachi-33372" },
  { from: "/listing/abdullah-car-wash-and-oil-centre-attock-2", to: "/listing/car-washers/abdullah-car-wash-and-oil-centre-|-attock-33389" },
  { from: "/listing/pasha-car-wash-attock-2", to: "/listing/car-washers/pasha-car-wash-|-attock-33397" },
  { from: "/listing/akhtar-hussain-qureshi-car-wash-attock-2", to: "/listing/car-washers/akhtar-hussain-qureshi-car-wash-|-attock-33402" },
  { from: "/listing/punjab-car-wash-attock-2", to: "/listing/car-washers/punjab-car-wash-|-attock-33404" },
  { from: "/listing/attock-dental-clinic-attock-2", to: "/listing/dental/attock-dental-clinic-|-attock-33406" },
  { from: "/listing/the-heart-clinic-attock", to: "/listing/doctors-and-clinics/the-heart-clinic-|-attock-33408" },
  { from: "/listing/doctor-noor-ul-amin-awan-attock-2", to: "/listing/doctors-and-clinics/doctor-noor-ul-amin-awan-|-attock-33411" },
  { from: "/listing/skin-laser-and-beauty-clinic-attock-2", to: "/listing/doctors-and-clinics/skin-laser-and-beauty-clinic-|-attock-33416" },
  { from: "/listing/ayub-dental-surgery-attock-2", to: "/listing/dental/ayub-dental-surgery-|-attock-33419" },
  { from: "/listing/aadil-homeopathic-clinic-attock-2", to: "/listing/doctors-and-clinics/aadil-homeopathic-clinic-|-attock-33423" },
  { from: "/listing/noorani-autos-car-a-c-and-rent-a-car-service", to: "/listing/car-rental/noorani-autos-car-a-c-and-rent-a-car-service-33450" },
  { from: "/listing/government-college-of-commerce-attock-2", to: "/listing/colleges/government-college-of-commerce-|-attock-33468" },
  { from: "/listing/government-degree-college-for-women-attock-2", to: "/listing/colleges/government-degree-college-for-women-|-attock-33470" },
  { from: "/listing/federal-college-of-science-and-commerce-attock-2", to: "/listing/colleges/federal-college-of-science-and-commerce-|-attock-33472" },
  { from: "/listing/precedent-school-and-college-attock-3", to: "/listing/driving-schools/precedent-school-and-college-|-attock-33474" },
  { from: "/listing/scholars-college-cantt-attock-2", to: "/listing/colleges/scholars-college-cantt-|-attock-33477" },
  { from: "/listing/govt-college-of-technology-attock-2", to: "/listing/colleges/govt-college-of-technology-|-attock-33479" },
  { from: "/listing/new-pc-world-computers-attock-2", to: "/listing/computer-repair/new-pc-world-computers-|-attock-33481" },
  { from: "/listing/sultania-builders-attock-2", to: "/listing/construction-services/sultania-builders-|-attock-33483" },
  { from: "/listing/mudassar-auto-electrician-and-car-ac-attock-2", to: "/listing/electrician/mudassar-auto-electrician-and-car-ac-|-attock-33512" },
  { from: "/listing/auction-sheet", to: "/listing/verification-services/japanese-auction-sheet-verification-33520" },
  { from: "/listing/al-madina-electronics-attock-2", to: "/listing/electronics/al-madina-electronics-|-attock-33523" },
  { from: "/listing/noor-electronics-attock-2", to: "/listing/electronics/noor-electronics-|-attock-33525" },
  { from: "/listing/karachi-marriage-bureau-2", to: "/listing/marriage-beuro/karachi-marriage-bureau-33582" },
  { from: "/listing/mahyas-marriage-bureau-karachi-2", to: "/listing/marriage-beuro/mahyas-marriage-bureau-|-karachi-33584" },
  { from: "/listing/bz-rishta-and-shaadi-online-service-karachi-2", to: "/listing/marriage-beuro/bz-rishta-and-shaadi-online-service-|-karachi-33586" },
  { from: "/listing/perfect-jodi-marriage-bureau-karachi-2", to: "/listing/marriage-beuro/perfect-jodi-marriage-bureau-|-karachi-33588" },
  { from: "/listing/ahmed-marriage-bureau-karachi", to: "/listing/marriage-beuro/ahmed-marriage-bureau-|-karachi-33687" },
  { from: "/listing/al-hamd-marriage-bureau-karachi-2", to: "/listing/marriage-beuro/al-hamd-marriage-bureau-|-karachi-33689" },
  { from: "/listing/aasra-marriage-bureau-karachi-2", to: "/listing/marriage-beuro/aasra-marriage-bureau-|-karachi-33691" },
  { from: "/listing/asm-marriage-services-karachi-2", to: "/listing/marriage-beuro/asm-marriage-services-|-karachi-33694" },
  { from: "/listing/begum-zaheer-marriage-bureau-karachi-2", to: "/listing/marriage-beuro/begum-zaheer-marriage-bureau-|-karachi-33696" },
  { from: "/listing/royal-marriage-bureau-karachi-2", to: "/listing/marriage-beuro/royal-marriage-bureau-|-karachi-33700" },
  { from: "/listing/lahore-marriage-bureau-2", to: "/listing/marriage-beuro/lahore-marriage-bureau-33708" },
  { from: "/listing/bukhari-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/bukhari-marriage-bureau-|-lahore-33712" },
  { from: "/listing/baji-nasim-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/baji-nasim-marriage-bureau-|-lahore-33715" },
  { from: "/listing/uk-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/uk-marriage-bureau-|-lahore-33717" },
  { from: "/listing/mahyas-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/mahyas-marriage-bureau-|-lahore-33731" },
  { from: "/listing/nikah-global-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/nikah-global-marriage-bureau-|-lahore-33733" },
  { from: "/listing/awan-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/awan-marriage-bureau-|-lahore-33736" },
  { from: "/listing/pret-diaries", to: "/listing/shopping-malls/pret-diaries-33756" },
  { from: "/listing/prime-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/prime-marriage-bureau-|-lahore-33796" },
  { from: "/listing/minhaj-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/minhaj-marriage-bureau-|-lahore-33798" },
  { from: "/listing/khidmat-star-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/khidmat-star-marriage-bureau-|-lahore-33800" },
  { from: "/listing/bismillah-marriage-bureau-lahore-2", to: "/listing/marriage-beuro/bismillah-marriage-bureau-|-lahore-33802" },
  { from: "/listing/hina-marriage-bureau-international-services-islamabad-2", to: "/listing/marriage-beuro/hina-marriage-bureau-international-services-|-islamabad-33804" },
  { from: "/listing/saeban-marriage-bureau-islamabad-3", to: "/listing/marriage-beuro/saeban-marriage-bureau-|-islamabad-33806" },
  { from: "/listing/al-syed-marriage-islamabad-2", to: "/listing/marriage-beuro/al-syed-marriage-|-islamabad-33809" },
  { from: "/listing/eman-marriage-bureau-islamabad-2", to: "/listing/marriage-beuro/eman-marriage-bureau-|-islamabad-33811" },
  { from: "/listing/ajmal-herbs", to: "/listing/beauty-parlour/ajmal-herbs-33860" },
  { from: "/listing/property-expert-consultant-bahria-town-karachi", to: "/listing/property-development/property-expert-consultant-bahria-town-karachi-33894" },
  { from: "/listing/fancy-driving-school-attock-2", to: "/listing/driving-schools/fancy-driving-school-|-attock-33937" },
  { from: "/listing/apex-group-of-colleges", to: "/listing/colleges/apex-group-of-colleges-33975" },
  { from: "/listing/total-pest-control-services-karachi-2", to: "/listing/pest-control/total-pest-control-services-karachi-33981" },
  { from: "/listing/next-level-gym-attock-2", to: "/listing/gym/next-level-gym-|-attock-34006" },
  { from: "/listing/power-gym-attock-2", to: "/listing/gym/power-gym-|-attock-34008" },
  { from: "/listing/classic-gym-2-attock-2", to: "/listing/gym/classic-gym-2-|-attock-34010" },
  { from: "/listing/perfect-gym-attock-2", to: "/listing/gym/perfect-gym-|-attock-34012" },
  { from: "/listing/khan-power-gym-attock-2", to: "/listing/gym/khan-power-gym-|-attock-34014" },
  { from: "/listing/pizza-92-attock-2", to: "/listing/home-delivery-food/pizza-92-|-attock-34016" },
  { from: "/listing/attock-fast-food-attock-2", to: "/listing/restaurants/attock-fast-food-|-attock-34018" },
  { from: "/listing/burger-and-pizza-hut-attock-2", to: "/listing/home-delivery-food/burger-and-pizza-hut-|-attock-34020" },
  { from: "/listing/taj-mahal-restaurant-attock-2", to: "/listing/restaurants/taj-mahal-restaurant-|-attock-34022" },
  { from: "/listing/bravo-foods-attock-2", to: "/listing/restaurants/bravo-foods-|-attock-34024" },
  { from: "/listing/irfania-restaurant-attock-2", to: "/listing/restaurants/irfania-restaurant-|-attock-34026" },
  { from: "/listing/umania-restaurant-attock-3", to: "/listing/restaurants/umania-restaurant-|-attock-34028" },
  { from: "/listing/osaka-corporation-pest-control-service-in-karachi-3", to: "/listing/pest-control/osaka-corporation-pest-control-service-in-karachi-34047" },
  { from: "/listing/plots-and-houses-available-in-bahria-town-karachi", to: "/listing/property-development/plots-and-houses-available-in-bahria-town-karachi-34054" },
  { from: "/listing/property-bank-government-plots-available-in-taiser-town-karachi", to: "/listing/property-development/property-bank-|-government-plots-available-in-taiser-town,-karachi-34083" },
  { from: "/listing/perfect-pest-control-services-in-karachi-4", to: "/listing/pest-control/perfect-pest-control-services-in-karachi-34110" },
  { from: "/listing/onieo", to: "/listing/shopping-malls/onieo-34140" },
  { from: "/listing/pak-china-town-karachi-property-dealer-real-estate-service", to: "/listing/property-development/pak-china-town-karachi-|-property-dealer-&-real-estate-service-34144" },
  { from: "/listing/gold-house-jewelers-attock-2", to: "/listing/jewelers/gold-house-jewelers-|-attock-34160" },
  { from: "/listing/iftikhar-jewellers-attock-2", to: "/listing/jewelers/iftikhar-jewellers-|-attock-34162" },
  { from: "/listing/fashion-jeweller-attock-2", to: "/listing/jewelers/fashion-jeweller-|-attock-34166" },
  { from: "/listing/sardar-tauseef-law-associates-attock-2", to: "/listing/lawyers/sardar-tauseef-law-associates-|-attock-34168" },
  { from: "/listing/qutab-law-office-attock-2", to: "/listing/lawyers/qutab-law-office-|-attock-34171" },
  { from: "/listing/district-bar-associaton-attock-2", to: "/listing/lawyers/district-bar-associaton-|-attock-34173" },
  { from: "/listing/elibrary-punjab-attock-2", to: "/listing/laboratories/elibrary-punjab-|-attock-34179" },
  { from: "/listing/mesam-haider-photography-attock-2", to: "/listing/photographer/mesam-haider-photography-|-attock-34182" },
  { from: "/listing/kaleem-sons-digital-color-laboratory-and-photo-studio-attock-2", to: "/listing/photographer/kaleem-sons-digital-color-laboratory-and-photo-studio-|-attock-34184" },
  { from: "/listing/mehria-wedding-marquees-attock", to: "/listing/weddings-planner/mehria-wedding-marquees-|-attock-34186" },
  { from: "/listing/home-shifting-relocation-islamabad", to: "/listing/pickups-and-trucks/home-shifting-relocation-|-islamabad-34211" },
  { from: "/listing/transportation-of-all-type-in-pakistan", to: "/listing/pickups-and-trucks/transportation-of-all-type-in-pakistan-34213" },
  { from: "/listing/moving-and-packing-services-for-all-type-in-islambad", to: "/listing/pickups-and-trucks/moving-and-packing-services-for-all-type-in-islambad-34216" },
  { from: "/listing/packers-and-movers-home-shifting-services-in-karachi", to: "/listing/pickups-and-trucks/packers-and-movers-home-shifting-services-in-karachi-34218" },
  { from: "/listing/goods-transport-company-in-karachi", to: "/listing/pickups-and-trucks/goods-transport-company-in-karachi-34222" },
  { from: "/listing/packers-and-movers-in-karachi-2", to: "/listing/pickups-and-trucks/packers-and-movers-in-karachi-34225" },
  { from: "/listing/home-shifting-services-in-karachi-2", to: "/listing/pickups-and-trucks/home-shifting-services-in-karachi-34227" },
  { from: "/listing/wholesale-clothing-for-men-and-women-in-usa-wearglam-inc", to: "/listing/shopping-malls/wholesale-clothing-for-men-and-women-in-usa---wearglam-inc-34257" },
  { from: "/listing/buy-designers-suits-online-in-pakistan-at-best-price-posha", to: "/listing/shopping-malls/buy-designers-suits-online-in-pakistan-at-best-price---posha-34260" },
  { from: "/listing/eazy-movers-and-packers-best-home-shifting-all-pakistan-services", to: "/listing/pickups-and-trucks/eazy-mover's-and-packer's-best-home-shifting-all-pakistan-services-34281" },
  { from: "/listing/wgtc-daily-door-to-door-car-carrier-and-container-service", to: "/listing/pickups-and-trucks/wgtc-daily-door-to-door-car-carrier-and-container-service-34284" },
  { from: "/listing/panther-packers-and-movers-islamabad-4", to: "/listing/pickups-and-trucks/panther-packers-and-movers-|-islamabad-34296" },
  { from: "/listing/meat-and-eat-easy-qurbani-sahulat", to: "/listing/general-services/meat-and-eat-|-easy-qurbani-sahulat-in-lahore-islamabad-34308" },
  { from: "/listing/ali-santry-and-electrick-store-attock-2", to: "/listing/plumbers/ali-santry-and-electrick-store-|-attock-34345" },
  { from: "/listing/attock-printing-press-2", to: "/listing/printing/attock-printing-press-34347" },
  { from: "/listing/jamil-panaflex-printers-attock-4", to: "/listing/printing/jamil-panaflex-printers-|-attock-34349" },
  { from: "/listing/heritage-advertisers-and-printers-attock-2", to: "/listing/printing/heritage-advertisers-and-printers-|-attock-34354" },
  { from: "/listing/beautiful-beauty-parlour-attock-4", to: "/listing/salons/beautiful-beauty-parlour-|-attock-34356" },
  { from: "/listing/baby-diapers-wholesale-hapi-napi", to: "/listing/digital-marketing/baby-diapers-wholesale-|-hapi-napi-34383" },
  { from: "/listing/lala-anwar-qasai-lahore-2", to: "/listing/general-services/lala-anwar-qasai-|-lahore-34443" },
  { from: "/listing/qasai-for-qurbani-available-in-lahore-4", to: "/listing/general-services/qasai-for-qurbani-available-in-lahore-34447" },
  { from: "/listing/butchers-available-for-eid-ul-azha-in-lahore-3", to: "/listing/general-services/butchers-available-for-eid-ul-azha-in-lahore-34453" },
  { from: "/listing/ramzan-qasai-lahore-4", to: "/listing/general-services/ramzan-qasai-|-lahore-34457" },
  { from: "/listing/haider-qasai-available-lahore-3", to: "/listing/general-services/haider-qasai-available-|-lahore-34461" },
  { from: "/listing/advance-car-driving-training-with-uk-certification", to: "/listing/driving-schools/advance-car-driving-training-with-uk-certification-34464" },
  { from: "/listing/mobeen-qasai-available-in-islamabad-3", to: "/listing/general-services/mobeen-qasai-available-in-islamabad-34620" },
  { from: "/listing/rent-your-car-and-earn-money-al-safar-rent-a-car", to: "/listing/car-rental/rent-your-car-and-earn-money-|-al-safar-rent-a-car-34644" },
  { from: "/listing/cnc-laser-cutting-cuttingmaster-com-2", to: "/listing/general-services/cnc-laser-cutting-|-cuttingmaster.com-34659" },
  { from: "/listing/pest-solution-disinfection-services-in-karachi-3", to: "/listing/pest-control/pest-solution-disinfection-services-in-karachi-34671" },
  { from: "/listing/j-k-fumigation-pest-control-services-in-karachi-3", to: "/listing/pest-control/j.k-fumigation-|-pest-control-services-in-karachi-34719" },
  { from: "/listing/oxygen-shoes", to: "/listing/beauty-parlour/oxygen-shoes-34735" },
  { from: "/listing/daily-aftab", to: "/listing/content-writers/daily-aftab-34764" },
  { from: "/listing/sts-rent-a-car-limo-services", to: "/listing/car-rental/sts-rent-a-car-&amp;-limo-services-34768" },
  { from: "/listing/pakistan-fire-and-fumigation-services-in-karachi", to: "/listing/pest-control/pakistan-fire-and-fumigation-services-in-karachi-34772" },
  { from: "/listing/spot-termite-pest-control-in-lahore", to: "/listing/pest-control/spot-termite-&-pest-control-in-lahore-34789" },
  { from: "/listing/smart-solar-system-lahore-solar-provider", to: "/listing/solar-providers/smart-solar-system-lahore-|-solar-provider-34793" },
  { from: "/listing/bindas-collection-affordable-shopping-online", to: "/listing/shopping-malls/bindas-collection-–-affordable-shopping-online-34795" },
  { from: "/listing/export-mall", to: "/listing/shopping-malls/export-mall-34800" },
  { from: "/listing/askari-pest-control-services-in-lahore-2", to: "/listing/pest-control/askari-pest-control-services-in-lahore-34862" },
  { from: "/listing/pts-rent-a-car-karachi-pakistan-transport-service-2", to: "/listing/car-rental/pts-rent-a-car-karachi-(pakistan-transport-service)-35055" },
  { from: "/listing/kuickpay", to: "/listing/advertisers/kuickpay-35099" },
  { from: "/listing/leads360", to: "/listing/advertisers/leads360-35124" },
  { from: "/listing/best-laser-hair-removal-center-in-lahore", to: "/listing/doctors-and-clinics/best-laser-hair-removal-center-in-lahore-35248" },
  { from: "/listing/fumigation-services-in-karachi", to: "/listing/general-services/fumigation-services-in-karachi-35254" },
  { from: "/listing/best-gynecologist-in-lahore-dr-raheela-farooq", to: "/listing/doctors-and-clinics/best-gynecologist-in-lahore-|-dr-raheela-farooq-35277" },
  { from: "/listing/artificial-jewellery-in-pakistan", to: "/listing/jewelers/artificial-jewellery-in-pakistan-35279" },
  { from: "/listing/alkhaleej-clinics", to: "/listing/doctors-and-clinics/alkhaleej-clinics-35285" },
  { from: "/listing/call-call-pest-control-in-karachi-2", to: "/listing/pest-control/call-&-call-pest-control-in-karachi-35320" },
  { from: "/listing/scout-pest-control-services-in-islamabad", to: "/listing/pest-control/scout-pest-control-services-in-islamabad-35355" },
  { from: "/listing/jewelryshop", to: "/listing/jewelers/jewelryshop-35385" },
  { from: "/listing/graphic-designer-in-lahore", to: "/listing/designer/graphic-designer-in-lahore-35405" },
  { from: "/listing/online-grocery-store-hyderabad-dawoodonline", to: "/listing/shopping-malls/online-grocery-store-hyderabad---dawoodonline-35433" },
  { from: "/listing/amber-gohar", to: "/listing/designer/amber-gohar-35488" },
  { from: "/listing/computer-repairs-and-maintenance-in-lahore", to: "/listing/computer-repair/computer-repairs-and-maintenance-in-lahore-35495" },
  { from: "/listing/the-global-services-pest-control-services-in-rawalpindi", to: "/listing/pest-control/the-global-services-|-pest-control-services-in-rawalpindi-35523" },
  { from: "/listing/droidor", to: "/listing/software-company/droidor-35532" },
  { from: "/listing/786-surveillance-solution-pvt-ltd", to: "/listing/general-services/786-surveillance-solution-pvt-ltd-35588" },
  { from: "/listing/zainab-islamic-academy", to: "/listing/driving-schools/zainab-islamic-academy-35611" },
  { from: "/listing/job-portal", to: "/listing/job-providers/job-portal-35616" },
  { from: "/listing/rudn-enclave", to: "/listing/construction-services/rudn-enclave-35619" },
  { from: "/listing/event-specialist-by-mudassir", to: "/listing/photographer/event-specialist-by-mudassir-35649" },
  { from: "/listing/care-life-lahore-ambulance", to: "/listing/hospitals/care-life-lahore-ambulance-35675" },
  { from: "/listing/best-hair-transplant-in-lahore-hair-clinic", to: "/listing/doctors-and-clinics/best-hair-transplant-in-lahore-hair-clinic-35679" },
  { from: "/listing/smd-screen-rental-services", to: "/listing/event-planner/smd-screen-rental-services-35689" },
  { from: "/listing/tutoria-pk", to: "/listing/colleges/tutoria.pk-35735" },
  { from: "/listing/graduate-pk", to: "/listing/universities/graduate.pk-35751" },
  { from: "/listing/guts-pk-online-stationery-shop", to: "/listing/shopping-malls/guts-pk-online-stationery-shop-35753" },
  { from: "/listing/best-essay-writing-service-uk", to: "/listing/content-writers/best-essay-writing-service-uk-35759" },
  { from: "/listing/hafi-pest-control-services", to: "/listing/pest-control/hafi-pest-control-services-35765" },
  { from: "/listing/go-foodio", to: "/listing/home-delivery-food/go-foodio-35770" },
  { from: "/listing/infinit-connections", to: "/listing/software-company/infinit-connections-35773" },
  { from: "/listing/atmos-a-leading-digital-marketing-printing-and-plastic", to: "/listing/digital-marketing/atmos-–-a-leading-digital-marketing,-printing-and-plastic-35814" },
  { from: "/listing/fajira", to: "/listing/mobile-app-development/fajira-35821" },
  { from: "/listing/b4ucabs-taxi-service-in-lahorekarachi", to: "/listing/car-rental/b4ucabs:-taxi-service-in-lahore,karachi-35827" },
  { from: "/listing/superior-high-care-pest-control-and-cleaning-services-termit", to: "/listing/pest-control/superior-high-care-pest-control-and-cleaning-services-termit-35829" },
  { from: "/listing/storeonline", to: "/listing/general-services/storeonline-35831" },
  { from: "/listing/rapid-movers-and-packers", to: "/listing/travel-agents/rapid-movers-and-packers-35837" },
  { from: "/listing/safe-home-pest-management", to: "/listing/pest-control/safe-home-pest-management-35869" },
  { from: "/listing/deal-and-deals", to: "/listing/property-development/deal-and-deals-35910" },
  { from: "/listing/digital-marketing-agency-pakistan-dimmark-solutions", to: "/listing/digital-marketing/digital-marketing-agency-pakistan-|-dimmark-solutions-35932" },
  { from: "/listing/leads-estates", to: "/listing/property-development/leads-estates-35938" },
  { from: "/listing/ccodez-pvt-ltd", to: "/listing/digital-marketing/ccodez-pvt-ltd-35940" },
  { from: "/listing/ghoomo-phiroo-pakistan", to: "/listing/travel-agents/ghoomo-phiroo-pakistan-35941" },
  { from: "/listing/ali-home-termite-the-home-3", to: "/listing/pest-control/ali-home-termite-|-the-home-35945" },
  { from: "/listing/umeed-marriage-consultancy-3", to: "/listing/marriage-beuro/umeed-marriage-consultancy-35987" },
  { from: "/listing/suitable-thesis-writing-service-in-pakistan", to: "/listing/tutoring/suitable-thesis-writing-service-in-pakistan-36004" },
  { from: "/listing/aesthetic-clinics-in-karachi", to: "/listing/advertisers/aesthetic-clinics-in-karachi-36008" },
  { from: "/listing/smart-games-academy", to: "/listing/driving-schools/smart-games-academy-36015" },
  { from: "/listing/serene-event-complex-best-wedding-event-hall-in-lahore-2020", to: "/listing/wedding-halls/serene-event-complex,-best-wedding-event-hall-in-lahore-2020-36017" },
  { from: "/listing/mas-production-3-o-communique", to: "/listing/designer/mas-production--3.o-communique-36020" },
  { from: "/listing/aenzay-homes", to: "/listing/advertisers/aenzay-homes-36025" },
  { from: "/listing/e-litecigs", to: "/listing/electronics/e-litecigs-36030" },
  { from: "/listing/kamrajenterprises", to: "/listing/construction-services/kamrajenterprises-36034" },
  { from: "/listing/the-propertunity", to: "/listing/property-development/the-propertunity-36037" },
  { from: "/listing/universal-fumigation-service-2", to: "/listing/pest-control/universal-fumigation-service-36049" },
  { from: "/listing/khan-jee-water-tank-cleaning", to: "/listing/general-services/khan-jee-water-tank-cleaning-36054" },
  { from: "/listing/smart-window-solutions", to: "/listing/designer/smart-window-solutions-36058" },
  { from: "/listing/ainak-pk", to: "/listing/advertisers/ainak.pk-36064" },
  { from: "/listing/luxurious-furniture-which-will-change-your-lifestyle-home", to: "/listing/general-services/luxurious-furniture-which-will-change-your-lifestyle--home-&amp;-36071" },
  { from: "/listing/cometinsure", to: "/listing/general-services/cometinsure-36076" },
  { from: "/listing/home-tutor-in-islamabad-by-iamtutor", to: "/listing/tutoring/home-tutor-in-islamabad-by-iamtutor-36079" },
  { from: "/listing/law-point-buy-law-books-online", to: "/listing/book-stores/law-point-buy-law-books-online-36084" },
  { from: "/listing/experts-repair", to: "/listing/electrician/experts-repair-36089" },
  { from: "/listing/online-shopping-in-pakistan-laptops-apple-gadgets", to: "/listing/electronics/online-shopping-in-pakistan-|-laptops-|-apple-|-gadgets-36104" },
  { from: "/listing/united-track-system", to: "/listing/general-services/united-track-system-36110" },
  { from: "/listing/earths-promise", to: "/listing/restaurants/earth's-promise-36116" },
  { from: "/listing/sys-visas-pvt-limited", to: "/listing/visa-agencies/sys-visas-(pvt)-limited-36123" },
  { from: "/listing/prime-fumigation-services-in-karachi-past-control-services", to: "/listing/pest-control/prime-fumigation-services-in-karachi,-past-control-services-36141" },
  { from: "/listing/lahore-smart-city", to: "/listing/property-development/lahore-smart-city-36153" },
  { from: "/listing/zubair-book-depot", to: "/listing/book-stores/zubair-book-depot-36161" },
  { from: "/listing/solar-panel-solutions-by-azaan-engineering", to: "/listing/solar-providers/solar-panel-solutions-by-azaan-engineering-36171" },
  { from: "/listing/diesel-generator-systems-by-by-azaan-engineering-2", to: "/listing/general-services/diesel-generator-systems-by-by-azaan-engineering-36177" },
  { from: "/listing/b4u-foundation-poor-people-support-program", to: "/listing/verification-services/b4u-foundation---poor-people-support-program-36181" },
  { from: "/listing/no-1-pest-control-fumigation-company", to: "/listing/pest-control/no.1-pest-control-fumigation-company-36184" },
  { from: "/listing/seo-desires-providing-seo-services-in-pakistan", to: "/listing/digital-marketing/seo-desires-providing-seo-services-in-pakistan-36192" },
  { from: "/listing/professional-pest-control-services-in-pakistan-pestrap", to: "/listing/pest-control/professional-pest-control-services-in-pakistan-|-pestrap-36198" },
  { from: "/listing/solar-solutions-by-nanx-engineering-pvt-ltd", to: "/listing/solar-providers/solar-solutions-by-nanx-engineering-(pvt.)-ltd.-36203" },
  { from: "/listing/crystal-fox-events-event-management-and-planning-company-2", to: "/listing/event-planner/crystal-fox-events-|-event-management-and-planning-company-36227" },
  { from: "/listing/techsmiths-digital-marketing-agency", to: "/listing/website-development/techsmiths---digital-marketing-agency-36236" },
  { from: "/listing/sellit24", to: "/listing/advertisers/sellit24-36241" },
  { from: "/listing/capital-smart-city", to: "/listing/property-development/capital-smart-city-36248" },
  { from: "/listing/softenica-technologies", to: "/listing/website-development/softenica-technologies-36269" },
  { from: "/listing/cambridge-home-tuition-network-in-lahore-islamabad-rawalpindi", to: "/listing/tutoring/cambridge-home-tuition-network-in-lahore-islamabad-rawalpindi-36272" },
  { from: "/listing/solar-system-pakistan-pvt-ltd", to: "/listing/solar-providers/solar-system-pakistan-pvt.-ltd.-36275" },
  { from: "/listing/wsl-consultants", to: "/listing/travel-agents/wsl-consultants-36276" },
  { from: "/listing/romeroot", to: "/listing/website-development/romeroot-36280" },
  { from: "/listing/fix-power-solar-solutions", to: "/listing/solar-providers/fix-power-solar-solutions-36290" },
  { from: "/listing/dgmux-a-digital-marketing-agency", to: "/listing/digital-marketing/dgmux-a-digital-marketing-agency-36328" },
  { from: "/listing/creative-logo-design-in-pakistan-best-logo-design-company", to: "/listing/general-services/creative-logo-design-in-pakistan-|-best-logo-design-company-36331" },
  { from: "/listing/termite-treatment-in-lahore", to: "/listing/pest-control/termite-treatment-in-lahore-36361" },
  { from: "/listing/art-supplies-office-supplies-printing-packaging", to: "/listing/advertisers/art-supplies-|-office-supplies-|-printing-&amp;-packaging-36384" },
  { from: "/listing/asaan-buy", to: "/listing/shopping-malls/asaan-buy-36401" },
  { from: "/listing/experience-electronic-technician-jobs-in-karachi", to: "/listing/job-providers/experience-electronic-technician-jobs-in-karachi-36440" },
  { from: "/listing/solar-solutions-by-shaheen-enterprise-pvt-ltd", to: "/listing/solar-providers/solar-solutions-by-shaheen-enterprise-(pvt.)-ltd.-36442" },
  { from: "/listing/top-advocates-in-gujranwala-sheikh-arslan-ali", to: "/listing/lawyers/top-advocates-in-gujranwala-|-sheikh-arslan-ali-36473" },
  { from: "/listing/goyes", to: "/listing/digital-marketing/goyes-36479" },
  { from: "/listing/nawaz-solar-system-faisalabad-solar-solutions", to: "/listing/solar-providers/nawaz-solar-system-faisalabad-|-solar-solutions-36500" },
  { from: "/listing/best-home-sale-online-in-pakistan-maallik-com", to: "/listing/property-for-rent/best-home-sale-online-in-pakistan|-maallik.com-36514" },
  { from: "/listing/runma-injection-molding-robot-arm-co-ltd", to: "/listing/general-services/runma-injection-molding-robot-arm-co.,-ltd.-36519" },
  { from: "/listing/bosch-floating-solar-pv-platform-system-co-ltd", to: "/listing/solar-providers/bosch-floating-solar-pv-platform-system-co.,-ltd.-36523" },
  { from: "/listing/topper-floating-solar-pv-mounting-manufacturer-co-ltd", to: "/listing/solar-providers/topper-floating-solar-pv-mounting-manufacturer-co.,-ltd.-36526" },
  { from: "/listing/solar-panel-providers-in-lahore-sun-players-co-pvt-ltd", to: "/listing/solar-providers/solar-panel-providers-in-lahore-|-sun-players-&-co-pvt.-ltd.-36530" },
  { from: "/listing/website-designing-development-company", to: "/listing/website-development/website-designing-&amp;-development-company-36541" },
  { from: "/listing/wazirabad-gakhar-rahwali-to-gujarnwala-pick-drop", to: "/listing/car-rental/wazirabad-gakhar-rahwali-to-gujarnwala-pick-drop-36552" },
  { from: "/listing/tesla-pv-solar-solutions-in-pakistan-2", to: "/listing/solar-providers/tesla-pv-solar-solutions-in-pakistan-36555" },
  { from: "/listing/upm-pest-control-services-in-lahore-and-termite-control-la", to: "/listing/pest-control/upm---pest-control-services-in-lahore-and-termite-control-la-36569" },
  { from: "/listing/tiles-and-sanitary-al-assad-traders", to: "/listing/general-services/tiles-and-sanitary-|-al-assad-traders-36588" },
  { from: "/listing/thestationerycompany-pk-art-supplies-and-stationery-shop", to: "/listing/shopping-malls/thestationerycompany.pk---art-supplies-and-stationery-shop-36604" },
  { from: "/listing/justsun-heavy-duty-truck-manufacturer-co-ltd", to: "/listing/pickups-and-trucks/justsun-heavy-duty-truck-manufacturer-co.,-ltd.-36622" },
  { from: "/listing/winssol-website-designing-development-lahore", to: "/listing/software-company/winssol-–-website-designing-&amp;-development-lahore-36625" },
  { from: "/listing/pest-control-fumigation-services-in-lahore-2", to: "/listing/pest-control/pest-control-&-fumigation-services-in-lahore-36631" },
  { from: "/listing/best-ladies-fitness-gym-lahore", to: "/listing/gym/best-ladies-fitness-gym-lahore-36647" },
  { from: "/listing/shopkraz-clothing-and-home-store", to: "/listing/shopping-malls/shopkraz---clothing-and-home-store-36660" },
  { from: "/listing/ultronicslights", to: "/listing/electronics/ultronicslights-36684" },
  { from: "/listing/aq-aviation-travel-and-tours", to: "/listing/travel-agents/aq-aviation-travel-and-tours-36693" },
  { from: "/listing/business-software-consulting", to: "/listing/software-company/business-software-consulting-36699" },
  { from: "/listing/achasoda-is-b2b-e-commerce-platform", to: "/listing/electronics/achasoda-is-b2b-e-commerce-platform-36702" },
  { from: "/listing/delta-international-recruitment-agency", to: "/listing/visa-agencies/delta-international-recruitment-agency-36704" },
  { from: "/listing/digital-marketing-agecny-pakistan-ansoff-marketing", to: "/listing/digital-marketing/digital-marketing-agecny-pakistan-|-ansoff-marketing-36712" },
  { from: "/listing/outdoor-advertising-agency-in-karachi-pakistan-rz-corp", to: "/listing/advertisers/outdoor-advertising-agency-in-karachi-pakistan-|-r&amp;z-corp-36718" },
  { from: "/listing/event-booking-in-lahore", to: "/listing/event-planner/event-booking-in-lahore-36727" },
  { from: "/listing/interwood-mobel-pvt-ltd", to: "/listing/carpenter/interwood-mobel-pvt-ltd-36730" },
  { from: "/listing/eva-anna-farms", to: "/listing/farm-houses/eva-anna-farms-36733" },
  { from: "/listing/muzzybbc-pakistan-best-language-learning-program-for-all", to: "/listing/book-stores/muzzybbc-pakistan©---best-language-learning-program-for-all-36743" },
  { from: "/listing/kca-tools", to: "/listing/electronics/kca-tools-36768" },
  { from: "/listing/thesis-writing-service", to: "/listing/tutoring/thesis-writing-service-36769" },
  { from: "/listing/alpha-construction-contractor", to: "/listing/construction-services/alpha-construction-contractor-36774" },
  { from: "/listing/cns-rent-a-car-03454142020", to: "/listing/car-rental/cns-rent-a-car-03454142020-36792" },
  { from: "/listing/online-ielts-school", to: "/listing/tutoring/online-ielts-school-36796" },
  { from: "/listing/the-travel-corner-travels-tours", to: "/listing/travel-agents/the-travel-corner-travels-&amp;-tours-36803" },
  { from: "/listing/champion-pk", to: "/listing/construction-services/champion.pk-36808" },
  { from: "/listing/wealth-bridge-consulting", to: "/listing/lawyers/wealth-bridge-consulting-36823" },
  { from: "/listing/ozonecc", to: "/listing/construction-services/ozonecc-36831" },
  { from: "/listing/eventions-event-planners", to: "/listing/event-planner/eventions-event-planners-36833" },
  { from: "/listing/qalamkar-luxury-brand", to: "/listing/shopping-malls/qalamkar-luxury-brand-36837" },
  { from: "/listing/islamabad-hijama-clinic", to: "/listing/doctors-and-clinics/islamabad-hijama-clinic-36843" },
  { from: "/listing/coronavirus-fumigation-and-pest-control-services-covid-19", to: "/listing/pest-control/coronavirus-fumigation-and-pest-control-services-(covid-19)-36859" },
  { from: "/listing/top-overseas-education-consultant-in-pakistan", to: "/listing/universities/top-overseas-education-consultant-in-pakistan-36872" },
  { from: "/listing/ab-jeans", to: "/listing/shopping-malls/ab-jeans-36892" },
  { from: "/listing/sports-apparel", to: "/listing/gym/sports-apparel-36896" },
  { from: "/listing/1-online-english-learning-courses-emas", to: "/listing/tutoring/#1-online-english-learning-courses-@emas-36914" },
  { from: "/listing/food-of-pakistan", to: "/listing/restaurants/food-of-pakistan-36918" },
  { from: "/listing/resegments-digital-marketing-agency", to: "/listing/digital-marketing/resegments-digital-marketing-agency-36921" },
  { from: "/listing/pakpets", to: "/listing/animals-&amp;-birds/pakpets-36924" },
  { from: "/listing/interior-design", to: "/listing/designer/interior-design-36929" },
  { from: "/listing/digits2", to: "/listing/digital-marketing/digits2-36934" },
  { from: "/listing/sign-solution", to: "/listing/advertisers/sign-solution-36936" },
  { from: "/listing/bismillah-logistics-2", to: "/listing/general-services/bismillah-logistics-36944" },
  { from: "/listing/maticline-liquid-filling-bottling-line-co-ltd", to: "/listing/general-services/maticline-liquid-filling-bottling-line-co.,-ltd.-36952" },
  { from: "/listing/the-farmer-school-2", to: "/listing/driving-schools/the-farmer-school-36957" },
  { from: "/listing/qalamkar-luxury-formals", to: "/listing/shopping-malls/qalamkar-luxury-formals-36961" },
  { from: "/listing/mughal-furniture-online-home-store-for-furniture-decor-o", to: "/listing/carpenter/mughal-furniture---online-home-store-for-furniture,-decor,-o-36964" },
  { from: "/listing/shoes-corner-is-the-best-seller-of-all-kinds-of-shoes-in-pak", to: "/listing/general-services/shoes-corner-is-the-best-seller-of-all-kinds-of-shoes-in-pak-36968" },
  { from: "/listing/pak-west-industries-pvt-ltd", to: "/listing/general-services/pak-west-industries-pvt-ltd-36970" },
  { from: "/listing/eagle-property-air-conditioning", to: "/listing/electronics/eagle-property-air-conditioning-36973" },
  { from: "/listing/casual-coat-for-men", to: "/listing/shopping-malls/casual-coat-for-men-36978" },
  { from: "/listing/we-provide-website-hosting-in-pakistan", to: "/listing/website-development/we-provide-website-hosting-in-pakistan-36981" },
  { from: "/listing/he-technology", to: "/listing/electronics/he-technology-36986" },
  { from: "/listing/best-web-development-company-in-lahore", to: "/listing/software-company/best-web-development-company-in-lahore-36992" },
  { from: "/listing/lifestyle-collection", to: "/listing/shopping-malls/lifestyle-collection-36995" },
  { from: "/listing/interior", to: "/listing/designer/interior-37000" },
  { from: "/listing/prime-pest-control", to: "/listing/pest-control/prime-pest-control-37004" },
  { from: "/listing/flow-hcm", to: "/listing/electronics/flow-hcm-37028" },
  { from: "/listing/give-a-new-start-to-your-own-business", to: "/listing/advertisers/give-a-new-start-to-your-own-business-37032" },
  { from: "/listing/shop-pakistani-women-designer-dresses-clothes", to: "/listing/general-services/shop-pakistani-women-designer-dresses,-clothes-37033" },
  { from: "/listing/amsat", to: "/listing/general-services/amsat-37039" },
  { from: "/listing/zapple-pk", to: "/listing/shopping-malls/zapple.pk-37041" },
  { from: "/listing/mughal-furniture-online-home-store-for-furniture-decor-o-2", to: "/listing/carpenter/mughal-furniture---online-home-store-for-furniture,-decor,-o-37046" },
  { from: "/listing/pakbizzle", to: "/listing/shopping-malls/pakbizzle-37053" },
  { from: "/listing/codeninja", to: "/listing/website-development/codeninja-37056" },
  { from: "/listing/mughal-furniture-online-home-store-for-furniture-decor-o-3", to: "/listing/carpenter/mughal-furniture---online-home-store-for-furniture,-decor,-o-37059" },
  { from: "/listing/cosmetic-expert-hair-transplant-skin-and-plastic-surgery-c", to: "/listing/doctors-and-clinics/cosmetic-expert-hair-transplant-,-skin-and-plastic-surgery-c-37065" },
  { from: "/listing/juniorscart", to: "/listing/advertisers/juniorscart-37072" },
  { from: "/listing/wordpress-geeks-website-development-agency", to: "/listing/software-company/wordpress-geeks-website-development-agency-37076" },
  { from: "/listing/cosmetic-expert-hair-transplant-skin-and-plastic-surgery", to: "/listing/doctors-and-clinics/cosmetic-expert-hair-transplant-,-skin-and-plastic-surgery-37080" },
  { from: "/listing/tax-and-business-consultant", to: "/listing/lawyers/tax-and-business-consultant-37085" },
  { from: "/listing/mughal-furniture-online-home-store-for-furniture-decor-o-4", to: "/listing/designer/mughal-furniture---online-home-store-for-furniture,-decor,-o-37114" },
  { from: "/listing/lamosaik", to: "/listing/shopping-malls/lamosaik-37117" },
  { from: "/listing/company-setup-consultancy", to: "/listing/website-development/company-setup-consultancy-37120" },
  { from: "/listing/best-ladies-dresses-buy-online-shopping-clothes-for-women", to: "/listing/shopping-malls/best-ladies-dresses,-buy-online-shopping-clothes-for-women-37123" },
  { from: "/listing/agriquartiles-2", to: "/listing/farm-houses/agriquartiles-37131" },
  { from: "/listing/sohni-saiban", to: "/listing/property-development/sohni-saiban-37134" },
  { from: "/listing/jj-aesthetics-hair-tranlsplant-skin-clinic-in-rawalpindi", to: "/listing/doctors-and-clinics/jj-aesthetics---hair-tranlsplant-&amp;-skin-clinic-in-rawalpindi-37135" },
  { from: "/listing/knowledge-of-holy-quran", to: "/listing/driving-schools/knowledge-of-holy-quran-37143" },
  { from: "/listing/al-wasay-international", to: "/listing/hospitals/al-wasay-international-37144" },
  { from: "/listing/secure-ict", to: "/listing/software-company/secure-ict-37151" },
  { from: "/listing/ark-computer-store", to: "/listing/computer-repair/ark-computer-store-37160" },
  { from: "/listing/salaar-marketing", to: "/listing/property-development/salaar-marketing-37184" },
  { from: "/listing/net-solar-solar-company-in-pakistan", to: "/listing/solar-providers/net-solar---solar-company-in-pakistan-37202" },
  { from: "/listing/infra-energy-renewable-energy-consultants", to: "/listing/construction-services/infra-energy--renewable-energy-consultants-37205" },
  { from: "/listing/digiarch", to: "/listing/designer/digiarch-37218" },
  { from: "/listing/ibrahim-logistics-2", to: "/listing/general-services/ibrahim-logistics-37221" },
  { from: "/listing/hunza-unique-tours", to: "/listing/travel-agents/hunza-unique-tours-37234" },
  { from: "/listing/nippon-ecological-school-of-japanese-language-2", to: "/listing/driving-schools/nippon-ecological-school-of-japanese-language-37238" },
  { from: "/listing/buy-youtube-subscribers-legit-fast-non-drop-service-b", to: "/listing/digital-marketing/buy-youtube-subscribers-legit-&amp;-fast-[non-drop-service]-||-b-37242" },
  { from: "/listing/qmart-2", to: "/listing/mobile-app-development/qmart-37246" },
  { from: "/listing/pure-zafarani", to: "/listing/general-services/pure-zafarani-37251" },
  { from: "/listing/grafixworld", to: "/listing/website-development/grafixworld-37258" },
  { from: "/listing/herarify", to: "/listing/doctors-and-clinics/herarify-37261" },
  { from: "/listing/welcome-to-fad-fashion-design-in-pakistan", to: "/listing/shopping-malls/welcome-to-fad--fashion-&amp;-design-in-pakistan-37267" },
  { from: "/listing/topper-luquid-bottling-machines-co-ltd", to: "/listing/general-services/topper-luquid-bottling-machines-co.,-ltd.-37273" },
  { from: "/listing/zardi", to: "/listing/jewelers/zardi-37279" },
  { from: "/listing/vision-consultancy", to: "/listing/visa-agencies/vision-consultancy-37284" },
  { from: "/listing/furniture", to: "/listing/construction-services/furniture-37288" },
  { from: "/listing/best-pakistan-online-shop-with-first-check-then-pay-service", to: "/listing/shopping-malls/best-pakistan-online-shop-with-first-check-then-pay-service-37291" },
  { from: "/listing/international-packer-and-mover", to: "/listing/pickups-and-trucks/international-packer-and-mover-37295" },
  { from: "/listing/serene-event-complex", to: "/listing/event-planner/serene-event-complex-37296" },
  { from: "/listing/experienced-corporate-event-management-companies-in-lahore", to: "/listing/event-planner/experienced-corporate-event-management-companies-in-lahore-37301" },
  { from: "/listing/senior-tutors", to: "/listing/tutoring/senior-tutors-37306" },
  { from: "/listing/interior-design-2", to: "/listing/designer/interior-design-37350" },
  { from: "/listing/idrees-leather", to: "/listing/shopping-malls/idrees-leather-37354" },
  { from: "/listing/professional-web-design-company-lahore", to: "/listing/construction-services/professional-web-design-company-lahore-37365" },
  { from: "/listing/mehar-rent-a-car", to: "/listing/car-rental/mehar-rent-a-car-37370" },
  { from: "/listing/hijama-center-islamabad", to: "/listing/doctors-and-clinics/hijama-center-islamabad-37403" },
  { from: "/listing/ontrack", to: "/listing/home-delivery-food/ontrack-37405" },
  { from: "/listing/lineart-digital-marketing-agency", to: "/listing/digital-marketing/lineart-digital-marketing-agency-37424" },
  { from: "/listing/pakistanies-com", to: "/listing/software-company/pakistanies.com-37428" },
  { from: "/listing/all-prize-bond-draw-2021-schedule-list-result", to: "/listing/cinemas-and-theaters/all-prize-bond-draw-2021-|-schedule-|-list-|-result-37449" },
  { from: "/listing/siviq-car-rental", to: "/listing/car-rental/siviq-car-rental-37450" },
  { from: "/listing/khanial-builders", to: "/listing/construction-services/khanial-builders-37456" },
  { from: "/listing/lineart-digital-marketing-agency-2", to: "/listing/digital-marketing/lineart-digital-marketing-agency-37458" },
  { from: "/listing/interior-2", to: "/listing/designer/interior-37464" },
  { from: "/listing/interior-designing", to: "/listing/designer/interior-designing-37468" },
  { from: "/listing/hashim-trading-company", to: "/listing/general-services/hashim-trading-company-37472" },
  { from: "/listing/vivid-online-learning", to: "/listing/general-services/vivid-online-learning-37492" },
  { from: "/listing/wingspan-international", to: "/listing/designer/wingspan-international-37498" },
  { from: "/listing/tgm-store", to: "/listing/general-services/tgm-store-37505" },
  { from: "/listing/army-dog-center-pakistan", to: "/listing/animals-&amp;-birds/army-dog-center-pakistan-37510" },
  { from: "/listing/zesoft-sotware-house", to: "/listing/mobile-app-development/zesoft---sotware-house-37514" },
  { from: "/listing/sigma-properties-and-marketing-pvt-ltd", to: "/listing/property-development/sigma-properties-and-marketing-pvt-ltd-37522" },
  { from: "/listing/fast-grow-more", to: "/listing/digital-marketing/fast-grow-more-37523" },
  { from: "/listing/red-velvet-hotel-restaurant", to: "/listing/hotels/red-velvet-hotel-&amp;-restaurant-37530" },
  { from: "/listing/best-laser-machines-uv-printers-crest-makerspace-pakistan", to: "/listing/printing/best-laser-machines-&amp;-uv-printers|-crest-makerspace-pakistan-37536" },
  { from: "/listing/online-shopping-in-pakistan", to: "/listing/shopping-malls/online-shopping-in-pakistan-37541" },
  { from: "/listing/red-velvet-hotel-restaurant-2", to: "/listing/hotels/red-velvet-hotel-&amp;-restaurant-37545" },
  { from: "/listing/sirmaya-com", to: "/listing/property-development/sirmaya.com-37553" },
  { from: "/listing/real-estate", to: "/listing/software-company/real-estate-37557" },
  { from: "/listing/undergarments-for-men", to: "/listing/shopping-malls/undergarments-for-men-37561" },
  { from: "/listing/upvc-windows-in-lahore", to: "/listing/construction-services/upvc-windows-in-lahore-37564" },
  { from: "/listing/best-online-shopping-store-in-hunza-gilgit-pakistan", to: "/listing/shopping-malls/best-online-shopping-store-in-hunza-gilgit-pakistan-37567" },
  { from: "/listing/codinia", to: "/listing/software-company/codinia-37573" },
  { from: "/listing/alpha-track-pvt-ltd", to: "/listing/car-rental/alpha-track-(pvt.)-ltd-37574" },
  { from: "/listing/nayakam-neye-soach", to: "/listing/computer-repair/nayakam---neye-soach-37578" },
  { from: "/listing/flexy-transfers", to: "/listing/travel-agents/flexy-transfers-37581" },
  { from: "/listing/click-and-deliver", to: "/listing/travel-agents/click-and-deliver-37585" },
  { from: "/listing/lucky-taxis", to: "/listing/travel-agents/lucky-taxis-37589" },
  { from: "/listing/grocery-basket", to: "/listing/general-services/grocery-basket-37594" },
  { from: "/listing/shoes-corner-is-the-best-seller-of-all-kinds-of-shoes-in-pak-2", to: "/listing/shopping-malls/shoes-corner-is-the-best-seller-of-all-kinds-of-shoes-in-pak-37596" },
  { from: "/listing/smiley", to: "/listing/mobile-app-development/smiley-37600" },
  { from: "/listing/web-development-and-marketing-agency-xtreme-programmers", to: "/listing/software-company/web-development-and-marketing-agency-xtreme-programmers-37604" },
  { from: "/listing/social-media-marketing-company", to: "/listing/digital-marketing/social-media-marketing-company-37613" },
  { from: "/listing/best-retina-specialist-in-lahore", to: "/listing/hospitals/best-retina-specialist-in-lahore-37616" },
  { from: "/listing/fabric-printing-offers-custom-printing-on-lots-of-products", to: "/listing/designer/fabric-printing-offers-custom-printing-on-lots-of-products-37617" },
  { from: "/listing/fumigation-services-in-pakistan", to: "/listing/pest-control/fumigation-services-in-pakistan-37621" },
  { from: "/listing/deemak-control-treatment-in-pakistan", to: "/listing/pest-control/deemak-control-treatment-in-pakistan-37628" },
  { from: "/listing/webhostech-best-web-hosting-in-lahore", to: "/listing/software-company/webhostech-|-best-web-hosting-in-lahore-37634" },
  { from: "/listing/pest-control-services-in-lahore", to: "/listing/pest-control/pest-control-services-in-lahore-37637" },
  { from: "/listing/mughal-furniture-online-home-store-for-furniture-decor", to: "/listing/general-services/mughal-furniture---online-home-store-for-furniture,-decor.-37639" },
  { from: "/listing/hearing-aids-in-lahore", to: "/listing/doctors-and-clinics/hearing-aids-in-lahore-37645" },
  { from: "/listing/hassan-and-hussain-enterprises", to: "/listing/construction-services/hassan-and-hussain-enterprises-37650" },
  { from: "/listing/the-life-residencia", to: "/listing/construction-services/the-life-residencia-37654" },
  { from: "/listing/plumber-work-any-type", to: "/listing/plumbers/plumber-work-any-type-37662" },
  { from: "/listing/asiamet-steel-industries", to: "/listing/general-services/asiamet-steel-industries-37664" },
  { from: "/listing/digital-marketing-services-in-pakistan-brand-builders", to: "/listing/digital-marketing/digital-marketing-services-in-pakistan-|-brand-builders-37671" },
  { from: "/listing/steel-pipes-tubes-industries-spti", to: "/listing/general-services/steel-pipes-&amp;-tubes-industries-(spti)-37676" },
  { from: "/listing/lwd-global", to: "/listing/digital-marketing/lwd-global-37684" },
  { from: "/listing/simple-talaq-procedure-in-pakistan-in-2021-advocate-nazia", to: "/listing/general-services/simple-talaq-procedure-in-pakistan-in-2021-–-advocate-nazia-37687" },
  { from: "/listing/qalamkar-luxury-formals-2", to: "/listing/shopping-malls/qalamkar-luxury-formals-37693" },
  { from: "/listing/plants-islamabad", to: "/listing/general-services/plants-islamabad-37698" },
  { from: "/listing/waterproofing-treatment-roof-leakage-treatment-basement-leak", to: "/listing/general-services/waterproofing-treatment-roof-leakage-treatment-basement-leak-37703" },
  { from: "/listing/sculpture", to: "/listing/construction-services/sculpture-37709" },
  { from: "/listing/alvi-law-associates-2", to: "/listing/lawyers/alvi-law-associates-37710" },
  { from: "/listing/digital-media-line", to: "/listing/digital-marketing/digital-media-line-37713" },
  { from: "/listing/home-tutors-in-lahore-cantt", to: "/listing/tutoring/home-tutors-in-lahore-cantt-37718" },
  { from: "/listing/makaanshop", to: "/listing/property-development/makaanshop-37720" },
  { from: "/listing/shopistan", to: "/listing/advertisers/shopistan-37726" },
  { from: "/listing/salaar-marketing-2", to: "/listing/property-development/salaar-marketing-37737" },
  { from: "/listing/artificial-jewelry-shopping-in-pakistan-at-best-prices-bl", to: "/listing/jewelers/artificial-jewelry-shopping-in-pakistan-at-best-prices-|-bl-37742" },
  { from: "/listing/autograph-signature-suites", to: "/listing/property-development/autograph-signature-suites-37745" },
  { from: "/listing/secrets-of-pakistan", to: "/listing/advertisers/secrets-of-pakistan-37754" },
  { from: "/listing/online-shopping-in-pakistan-tejar-pk", to: "/listing/electronics/online-shopping-in-pakistan---tejar.pk-37761" },
  { from: "/listing/kcs-network", to: "/listing/pickups-and-trucks/kcs-network-37764" },
  { from: "/listing/1-online-shopping-in-pakistan-99pkr-com", to: "/listing/shopping-malls/1-online-shopping-in-pakistan-99pkr.com-37767" },
  { from: "/listing/seobee-top-seo-services-agency-in-pakistan", to: "/listing/digital-marketing/seobee---top-seo-services-agency-in-pakistan-37770" },
  { from: "/listing/ilm-ul-quran-online-academy-provide-online-quran-tutor", to: "/listing/tutoring/ilm-ul-quran-online-academy-provide-online-quran-tutor-37776" },
  { from: "/listing/the-champion-real-estate-marketing-company", to: "/listing/farm-houses/the-champion-real-estate-marketing-company-37782" },
  { from: "/listing/technology-gates", to: "/listing/digital-marketing/technology-gates-37784" },
  { from: "/listing/lenticas-private-limited", to: "/listing/software-company/lenticas-private-limited-37786" },
  { from: "/listing/mbm-mariabasitmalik", to: "/listing/shopping-malls/mbm.mariabasitmalik-37788" },
  { from: "/listing/magento-development-company", to: "/listing/website-development/magento-development-company-37795" },
  { from: "/listing/freight-forwarder-best-freight-forwarding-company-in-pakis", to: "/listing/general-services/freight-forwarder-|-best-freight-forwarding-company-in-pakis-37800" },
  { from: "/listing/badri-management-consultancy", to: "/listing/general-services/badri-management-consultancy-37807" },
  { from: "/listing/health-engineering-solutions-pvt-ltd", to: "/listing/construction-services/health-engineering-solutions-(pvt)-ltd-37810" },
  { from: "/listing/hat-enterprises", to: "/listing/laboratories/hat-enterprises-37818" },
  { from: "/listing/temok-web-hosting-pakistan", to: "/listing/website-development/temok-web-hosting-pakistan-37822" },
  { from: "/listing/getnow-online-store", to: "/listing/home-delivery-food/getnow-online-store-37837" },
  { from: "/listing/electric-chulha", to: "/listing/general-services/electric-chulha-37839" },
  { from: "/listing/business-setup-services-in-dubai", to: "/listing/digital-marketing/business-setup-services-in-dubai-37841" },
  { from: "/listing/smartphone-mobile-accessories-pocketwifi", to: "/listing/electronics/smartphone-mobile-accessories-pocketwifi-37844" },
  { from: "/listing/realth-technologies", to: "/listing/software-company/realth-technologies-37849" },
  { from: "/listing/the-shaadi-filmers", to: "/listing/weddings-planner/the-shaadi-filmers-37851" },
  { from: "/listing/selectronics-for-the-best-electronics", to: "/listing/electronics/selectronics-for-the-best-electronics-37857" },
  { from: "/listing/universal-services", to: "/listing/general-services/universal-services-37859" },
  { from: "/listing/sunglassesco-pk", to: "/listing/general-services/sunglassesco.pk-37864" },
  { from: "/listing/real-estate-2", to: "/listing/property-development/real-estate-37869" },
  { from: "/listing/it-solutions-marketing-services-and-call-center-setup", to: "/listing/digital-marketing/it-solutions,-marketing-services-and-call-center-setup-37871" },
  { from: "/listing/dr-mubasher-ikram-ent", to: "/listing/hospitals/dr-mubasher-ikram-ent-37877" },
  { from: "/listing/dsmart-solutions-mobile-application-development", to: "/listing/mobile-app-development/dsmart-solutions-|-mobile-application-development-37882" },
  { from: "/listing/bricks-and-stones", to: "/listing/construction-services/bricks-and-stones-37885" },
  { from: "/listing/car-wash-services", to: "/listing/car-washers/car-wash-services-37893" },
  { from: "/listing/hunza-bazar", to: "/listing/digital-marketing/hunza-bazar-37905" },
  { from: "/listing/enterprise-cube-limited", to: "/listing/digital-marketing/enterprise-cube-limited-37913" },
  { from: "/listing/kharidari-com", to: "/listing/advertisers/kharidari.com-37919" },
  { from: "/listing/make-money-by-investing-your-cash", to: "/listing/advertisers/make-money-by-investing-your-cash-37923" },
  { from: "/listing/bizzcare-general-trading-exporter", to: "/listing/hospitals/bizzcare-general-trading-exporter-37926" },
  { from: "/listing/pakistan-international-container-terminal", to: "/listing/general-services/pakistan-international-container-terminal-37934" },
  { from: "/listing/zeal-engineering", to: "/listing/electrician/zeal-engineering-37935" },
  { from: "/listing/lost-in-paris-online-perfume-store-in-pakistan", to: "/listing/beauty-parlour/lost-in-paris-online-perfume-store-in-pakistan-37949" },
  { from: "/listing/indigo-hotel", to: "/listing/hotels/indigo-hotel-37956" },
  { from: "/listing/green-tea", to: "/listing/advertisers/green-tea-37959" },
  { from: "/listing/court-marriage-divorce-family-cases-lawyer-in-faisalabad", to: "/listing/lawyers/court-marriage,-divorce,-family-cases-lawyer-in-faisalabad-37966" },
  { from: "/listing/packing-innovation-best-packaging-company-in-pakistan", to: "/listing/printing/packing-innovation-|-best-packaging-company-in-pakistan-37971" },
  { from: "/listing/ishopping-pk-online-shopping-in-pakistan-with-cash-on", to: "/listing/electronics/ishopping.pk:-online-shopping-in-pakistan-with-cash-on-...-37975" },
  { from: "/listing/noor-academy-pakistan", to: "/listing/driving-schools/noor-academy-pakistan-37981" },
  { from: "/listing/w3smm-3", to: "/listing/digital-marketing/w3smm-37985" },
  { from: "/listing/ochre-pk", to: "/listing/shopping-malls/ochre.pk-37988" },
  { from: "/listing/shophive", to: "/listing/digital-marketing/shophive-37989" },
  { from: "/listing/win-technologies", to: "/listing/general-services/win-technologies-37996" },
  { from: "/listing/seo-specialist-sohail-sarwar", to: "/listing/digital-marketing/seo-specialist--sohail-sarwar-38002" },
  { from: "/listing/fabricators", to: "/listing/restaurants/fabricators-38008" },
  { from: "/listing/collapsible-tube-company", to: "/listing/laboratories/collapsible-tube-company-38016" },
  { from: "/listing/nh-educational-consultant", to: "/listing/job-providers/nh-educational-consultant-38017" },
  { from: "/listing/tours", to: "/listing/travel-agents/tours-38039" },
  { from: "/listing/alain-enterprises", to: "/listing/construction-services/alain-enterprises-38043" },
  { from: "/listing/digitalizetraining-digitalize-training", to: "/listing/digital-marketing/digitalizetraining-|-digitalize-training-38046" },
  { from: "/listing/khanaan-2", to: "/listing/advertisers/khanaan-38050" },
  { from: "/listing/2btech", to: "/listing/digital-marketing/2btech-38051" },
  { from: "/listing/future-labs", to: "/listing/laboratories/future-labs-38057" },
  { from: "/listing/brandzhub", to: "/listing/shopping-malls/brandzhub-38061" },
  { from: "/listing/rays-marketing-islamabad", to: "/listing/property-development/rays-marketing-islamabad-38064" },
  { from: "/listing/fresh-chicken-cuts-river-fish-frozen-food-in-lahore", to: "/listing/home-delivery-food/fresh-chicken-cuts,-river-fish,-frozen-food-in-lahore-38070" },
  { from: "/listing/zufta-travel", to: "/listing/travel-agents/zufta-travel-38074" },
  { from: "/listing/babaturi", to: "/listing/website-development/babaturi-38077" },
  { from: "/listing/it-express-service", to: "/listing/digital-marketing/it-express-service-38081" },
  { from: "/listing/global-real-estate-services", to: "/listing/property-development/global-real-estate-services-38085" },
  { from: "/listing/business-set-up-in-dubai-shuraa", to: "/listing/general-services/business-set-up-in-dubai---shuraa-38089" },
  { from: "/listing/leading-best-logistics-company-in-pakistan-lahore-karachi", to: "/listing/travel-agents/leading-best-logistics-company-in-pakistan,-lahore,-karachi-38093" },
  { from: "/listing/best-eye-specialist-in-lahore-dr-asif-khokhar", to: "/listing/doctors-and-clinics/best-eye-specialist-in-lahore---dr.-asif-khokhar-38097" },
  { from: "/listing/predams-services-private-limited", to: "/listing/property-development/predams-services-private-limited-38102" },
  { from: "/listing/best-dentists-in-lahore-370-practicing-dentists", to: "/listing/dental/best-dentists-in-lahore---370-practicing-dentists-38106" },
  { from: "/listing/mg-motors-pakistan", to: "/listing/car-dealer/mg-motors-pakistan-38110" },
  { from: "/listing/software-development-company", to: "/listing/website-development/software-development-company-38113" },
  { from: "/listing/online-shopping-in-pakistan-home-shopping", to: "/listing/electronics/online-shopping-in-pakistan---home-shopping-38115" },
  { from: "/listing/emaar-pakistan", to: "/listing/property-development/emaar-pakistan-38130" },
  { from: "/listing/go-foodio-2", to: "/listing/restaurants/go-foodio-38133" },
  { from: "/listing/wordpress-developer", to: "/listing/website-development/wordpress-developer-38137" },
  { from: "/listing/graphic-designing-lahore-inteltrix", to: "/listing/website-development/graphic-designing-lahore-|-inteltrix-38144" },
  { from: "/listing/charmsphere", to: "/listing/digital-marketing/charmsphere-38149" },
  { from: "/listing/environmental-zone-water-solution-services-ez", to: "/listing/general-services/environmental-zone-water-solution-services-(ez)-38151" },
  { from: "/listing/charmsphere-2", to: "/listing/advertisers/charmsphere-38153" },
  { from: "/listing/oale-mobile", to: "/listing/electronics/oale-mobile-38161" },
  { from: "/listing/plastic-packaging-solutions", to: "/listing/general-services/plastic-packaging-solutions-38167" },
  { from: "/listing/hightbrow-best-immigration-and-student-visa-consultants", to: "/listing/visa-agencies/hightbrow:-best-immigration-and-student-visa-consultants-38170" },
  { from: "/listing/fakhar-al-oudh-buy-oudh-attar-in-pakistan", to: "/listing/beauty-parlour/fakhar-al-oudh-|-buy-oudh-&amp;-attar-in-pakistan-38173" },
  { from: "/listing/english-language-course-in-karachi", to: "/listing/colleges/english-language-course-in-karachi-38178" },
  { from: "/listing/dilltex", to: "/listing/hotels/dilltex-38181" },
  { from: "/listing/makeup-city", to: "/listing/beauty-parlour/makeup-city-38185" },
  { from: "/listing/attock-jewellers", to: "/listing/jewelers/attock-jewellers-38188" },
  { from: "/listing/line-resource-pvt-limited", to: "/listing/software-company/line-resource-(pvt)-limited.-38192" },
  { from: "/listing/lingerie-sleepwear-shops-in-karachi", to: "/listing/shopping-malls/lingerie-sleepwear-shops-in-karachi-38200" },
  { from: "/listing/prismatic-technologies", to: "/listing/software-company/prismatic-technologies-38205" },
  { from: "/listing/ehaus-co-working-space", to: "/listing/property-for-rent/ehaus-co-working-space-38208" },
  { from: "/listing/pathology-lab-management-software", to: "/listing/laboratories/pathology-lab-management-software-38217" },
  { from: "/listing/babysavers-pk-the-most-trusted-online-baby-products-store", to: "/listing/shopping-malls/babysavers.pk---the-most-trusted-online-baby-products-store-38226" },
  { from: "/listing/ac-repairing-service-in-multan-pakistan", to: "/listing/car-denting-painting/ac-repairing-service-in-multan-pakistan-38230" },
  { from: "/listing/mulberry-feel", to: "/listing/designer/mulberry-feel-38249" },
  { from: "/listing/inertiasoft", to: "/listing/website-development/inertiasoft-38260" },
  { from: "/listing/bricks-and-stones-construction-turnkey-solutions", to: "/listing/construction-services/bricks-and-stones---construction-&amp;-turnkey-solutions-38264" },
  { from: "/listing/online-grocery-shopping-store-in-karachi-pakistan-asanbuy", to: "/listing/home-delivery-food/online-grocery-shopping-store-in-karachi-pakistan-|-asanbuy-38273" },
  { from: "/listing/best-job-portal-in-lahore-razaaq-com", to: "/listing/job-providers/best-job-portal-in-lahore-|-razaaq.com-38275" },
  { from: "/listing/off-campus-consulting-pakistan", to: "/listing/colleges/off-campus-consulting-pakistan-38279" },
  { from: "/listing/muhafiz-pest-termite-control", to: "/listing/pest-control/muhafiz-pest-&amp;-termite-control-38289" },
  { from: "/listing/haririi-rent-a-car-lahore", to: "/listing/car-rental/haririi-rent-a-car-lahore-38292" },
  { from: "/listing/roof-waterproofing-heat-proofing", to: "/listing/general-services/roof-waterproofing-heat-proofing-38346" },
  { from: "/listing/shams-enterprises", to: "/listing/general-services/shams-enterprises-38357" },
  { from: "/listing/ha-collection-carpets-curtains", to: "/listing/general-services/ha-collection-carpets-&amp;-curtains-38358" },
  { from: "/listing/almishkat", to: "/listing/hospitals/almishkat-38376" },
  { from: "/listing/seo-boost", to: "/listing/digital-marketing/seo-boost-38381" },
  { from: "/listing/gujjar-travels-tours-rent-a-car-in-lahore-2", to: "/listing/car-rental/gujjar-travels-&-tours-|-rent-a-car-in-lahore-38384" },
  { from: "/listing/4-seasons-rent-a-car-lahore-car-rental-services-in-lahore", to: "/listing/car-rental/4-seasons-rent-a-car-lahore-|-car-rental-services-in-lahore-38395" },
  { from: "/listing/hol-online-pharmacy-home-care-services-lab-tests", to: "/listing/hospitals/hol---online-pharmacy,-home-care-services-&amp;-lab-tests-38413" },
  { from: "/listing/autox-pakistan", to: "/listing/car-denting-painting/autox-pakistan-38421" },
  { from: "/listing/awan-tours-rent-a-car-in-lahore-2", to: "/listing/car-rental/awan-tours-&-rent-a-car-in-lahore-38423" },
  { from: "/listing/global-guest-house-hotels-in-faisal-town-lahore", to: "/listing/hotels/global-guest-house-|-hotels-in-faisal-town-lahore-38444" },
  { from: "/listing/electrical-switches", to: "/listing/electronics/electrical-switches-38462" },
  { from: "/listing/eazy-artiles-no-1-world-wide-blog-and-article-site", to: "/listing/content-writers/eazy-artiles-no.1-world-wide-blog-and-article-site-38465" },
  { from: "/listing/fauve", to: "/listing/shopping-malls/fauve-38468" },
  { from: "/listing/charm-sphere", to: "/listing/shopping-malls/charm-sphere-38472" },
  { from: "/listing/roof-water-poofing-roof-heat-proofing-bathroom-water-tank-le", to: "/listing/pest-control/roof-water-poofing-roof-heat-proofing-bathroom-water-tank-le-38477" },
  { from: "/listing/solar-companies-in-lahore-netsolar", to: "/listing/solar-providers/solar-companies-in-lahore-|-netsolar-38482" },
  { from: "/listing/alqaim-driving-school-gulberg-3-lahore", to: "/listing/driving-schools/alqaim-driving-school-gulberg-3-lahore-38524" },
  { from: "/listing/rabia-massage-center-in-gulberg-lahore", to: "/listing/salons/rabia-massage-center-in-gulberg-lahore-38528" },
  { from: "/listing/bhutta-cars-new-used-car-dealer-in-lahore", to: "/listing/car-dealer/bhutta-cars-|-new-&-used-car-dealer-in-lahore-38538" },
  { from: "/listing/online-shopping", to: "/listing/shopping-malls/online-shopping-38544" },
  { from: "/listing/keydevs-software-house", to: "/listing/software-company/keydevs-software-house-38549" },
  { from: "/listing/the-seo-spot", to: "/listing/advertisers/the-seo-spot-38550" },
  { from: "/listing/asian-farm-house-lahore", to: "/listing/farm-houses/asian-farm-house-lahore-38554" },
  { from: "/listing/muddhouse-by-saima-rana", to: "/listing/designer/muddhouse-by-saima-rana-38563" },
  { from: "/listing/cec-pakistan", to: "/listing/visa-agencies/cec-pakistan-38565" },
  { from: "/listing/azurebyszk", to: "/listing/jewelers/azurebyszk-38575" },
  { from: "/listing/3dsium", to: "/listing/website-development/3dsium-38577" },
  { from: "/listing/royal-rent-a-car-in-karachi-with-without-driver", to: "/listing/car-rental/royal-rent-a-car-in-karachi-with-driver-38581" },
  { from: "/listing/webpro-pk", to: "/listing/website-development/webpro.pk-38583" },
  { from: "/listing/termite-treatment-in-lahore-pest-control-services-in-lahor", to: "/listing/pest-control/termite-treatment-in-lahore-|-pest-control-services-in-lahor-38598" },
  { from: "/listing/prince-mir-motors-car-dealer-in-lahore", to: "/listing/car-dealer/prince-mir-motors-|-car-dealer-in-lahore-38600" },
  { from: "/listing/plots-for-sale-in-islamabad-on-installments", to: "/listing/property-development/plots-for-sale-in-islamabad-on-installments-38608" },
  { from: "/listing/creative-mortals", to: "/listing/website-development/creative-mortals-38623" },
  { from: "/listing/amcyfive-digital-marketing-agency-in-lahore", to: "/listing/digital-marketing/amcyfive-digital-marketing-agency-in-lahore-38630" },
  { from: "/listing/ume-rent-a-car-lahore-self-drive-without-driver-2", to: "/listing/car-rental/ume-rent-a-car-lahore-|-self-drive-without-driver-38631" },
  { from: "/listing/anjum-hameed-law-firm", to: "/listing/lawyers/anjum-hameed-law-firm-38641" },
  { from: "/listing/ibucket-pk", to: "/listing/shopping-malls/ibucket.pk-38643" },
  { from: "/listing/fbs-consultant", to: "/listing/general-services/fbs-consultant-38654" },
  { from: "/listing/plots-for-sale-purchase-in-dha-defence-quetta-minhas-associates", to: "/listing/property-development/plots-for-sale-purchase-in-dha-defence-quetta-|-minhas-associates-38656" },
  { from: "/listing/sale-purchase-and-rent-altaf-associates-property-dealer-2", to: "/listing/property-development/sale-purchase-and-rent-|-altaf-associates-|-property-dealer-38661" },
  { from: "/listing/inam-girls-hostel", to: "/listing/student-hostels/inam-girls-hostel-38686" },
  { from: "/listing/specter", to: "/listing/shopping-malls/specter-38688" },
  { from: "/listing/easy-rent-a-car-islamabad-car-rental-in-islamabad", to: "/listing/car-rental/easy-rent-a-car-islamabad-|-car-rental-in-islamabad-38695" },
  { from: "/listing/glassesmart", to: "/listing/shopping-malls/glassesmart-38711" },
  { from: "/listing/car-services-in-lahore", to: "/listing/car-washers/car-services-in-lahore-38714" },
  { from: "/listing/fast-track-car-rental-limousine-services-in-islamabad-2", to: "/listing/car-rental/fast-track-car-rental-&-limousine-services-in-islamabad-38717" },
  { from: "/listing/capital-one-rent-a-car-in-islamabad-2", to: "/listing/car-rental/capital-one-rent-a-car-in-islamabad-38735" },
  { from: "/listing/new-vision-real-estate-property-dealer-in-islamabad-2", to: "/listing/property-development/new-vision-real-estate-property-dealer-in-islamabad-38739" },
  { from: "/listing/roof-waterproofing-heat-proofing-2", to: "/listing/general-services/roof-waterproofing-heat-proofing-38744" },
  { from: "/listing/haririi-rent-a-car-lahore-3", to: "/listing/car-rental/haririi-rent-a-car-lahore-38748" },
  { from: "/listing/solution-archive", to: "/listing/digital-marketing/solution-archive-38751" },
  { from: "/listing/muhammadi-property-advisor-and-motors-sale-purchase-of-property-2", to: "/listing/property-development/muhammadi-property-advisor-and-motors-|-sale-purchase-of-property-38755" },
  { from: "/listing/apni-malkiat", to: "/listing/property-development/apni-malkiat-38763" },
  { from: "/listing/cometinsure-2", to: "/listing/advertisers/cometinsure-38774" },
  { from: "/listing/dhl-parcel-point", to: "/listing/travel-agents/dhl-parcel-point-38776" },
  { from: "/listing/madina-hospital-in-sargodha-2", to: "/listing/hospitals/madina-hospital-in-sargodha-38781" },
  { from: "/listing/toyota-cars-company-in-karachi-2", to: "/listing/car-dealer/toyota-cars-company-in-karachi-38783" },
  { from: "/listing/eschool-pakistans-no-1-school-management-system", to: "/listing/software-company/eschool---pakistan's-no.-1-school-management-system-38816" },
  { from: "/listing/advertizer-pk", to: "/listing/advertisers/advertizer.pk-38826" },
  { from: "/listing/one-step-hub-2", to: "/listing/shopping-malls/one-step-hub-38834" },
  { from: "/listing/pen-tutor", to: "/listing/tutoring/pen-tutor-38841" },
  { from: "/listing/super-wheels-in-islamabad-sale-purchase-and-rent-a-car", to: "/listing/car-dealer/super-wheels-in-islamabad-|-sale-purchase-and-rent-a-car-38847" },
  { from: "/listing/cyborg-media-tech", to: "/listing/advertisers/cyborg-media-tech-38862" },
  { from: "/listing/chand-tour", to: "/listing/travel-agents/chand-tour-38868" },
  { from: "/listing/rawaha-estate-builders-pvt-ltd-builders-in-rawalpindi-3", to: "/listing/construction-services/rawaha-estate-&-builders-pvt.-ltd.-|-builders-in-rawalpindi-38875" },
  { from: "/listing/parhlo-directories", to: "/listing/advertisers/parhlo-directories-38880" },
  { from: "/listing/al-haq-marriage-bureau-iqbal-town-lahore", to: "/listing/marriage-beuro/al-haq-marriage-bureau-iqbal-town-lahore-38883" },
  { from: "/listing/property-dealer-in-faisalabad", to: "/listing/property-development/property-dealer-in-faisalabad-38887" },
  { from: "/listing/online-marketing-services-for-small-businesses", to: "/listing/digital-marketing/online-marketing-services-for-small-businesses-38921" },
  { from: "/listing/digital-marketing-lahore", to: "/listing/digital-marketing/digital-marketing-lahore-38925" },
  { from: "/listing/al-rehman-estate-agency-property-dealer-in-faisalabad-2", to: "/listing/property-development/al-rehman-estate-agency-|-property-dealer-in-faisalabad-38929" },
  {
    from: "/listing/omega-residencia-faisalabad-property-master-property-marketing-consultant",
    to: "/listing/property-development/omega-residencia-faisalabad-|-property-master-|-property-marketing-&-consultant-38932",
  },
  { from: "/listing/construction-material-prices-in-pakistan", to: "/listing/construction-services/construction-material-prices-in-pakistan-38950" },
  { from: "/listing/shopattip-multi-vendor-e-commerce-store", to: "/listing/shopping-malls/shopattip-multi-vendor-e-commerce-store-38953" },
  { from: "/listing/csp-construction-pvt-ltd", to: "/listing/construction-services/csp-construction-pvt.-ltd.-38958" },
  { from: "/listing/prince-punjab-motors-faisalabad", to: "/listing/car-dealer/prince-punjab-motors-faisalabad-38960" },
  { from: "/listing/dogar-rent-a-car-lahore-dogar-city-car-services-pvt-ltd", to: "/listing/car-rental/dogar-rent-a-car-lahore-&-dogar-city-car-services-pvt.-ltd.-38967" },
  { from: "/listing/niazi-transport-service", to: "/listing/travel-agents/niazi-transport-service-38991" },
  { from: "/listing/sharp-elevators", to: "/listing/construction-services/sharp-elevators-38993" },
  { from: "/listing/general-electric-gas-appliances", to: "/listing/electronics/general-electric-&amp;-gas-appliances-39078" },
  { from: "/listing/cloth-centre", to: "/listing/shopping-malls/cloth-centre-39090" },
  { from: "/listing/abdullaha-rent-a-car-in-dha-lahore", to: "/listing/car-rental/abdullaha-rent-a-car-in-dha-lahore-39105" },
  { from: "/listing/hss-leather", to: "/listing/tailors-and-alterations/hss-leather-39112" },
  { from: "/listing/xiamen-make-locks-manufacturer-co-ltd", to: "/listing/security-guard/xiamen-make-locks-manufacturer-co.,-ltd.-39121" },
  { from: "/listing/connect-solutions", to: "/listing/software-company/connect-solutions-39127" },
  { from: "/listing/tillah-gota", to: "/listing/shopping-malls/tillah-gota-39129" },
  { from: "/listing/aamir-motors-and-rent-a-car-in-lahore", to: "/listing/car-rental/aamir-motors-and-rent-a-car-in-lahore-39136" },
  { from: "/listing/latest-ready-to-wear-sustainable-apparel-for-women", to: "/listing/tailors-and-alterations/latest-ready-to-wear-sustainable-apparel-for-women-39160" },
  { from: "/listing/westpoint", to: "/listing/electronics/westpoint-39162" },
  { from: "/listing/hangmao-stone-marble-granite-co-ltd", to: "/listing/construction-services/hangmao-stone-marble-granite-co.,-ltd.-39163" },
  { from: "/listing/china-bavora-printed-packaging-co-ltd", to: "/listing/printing/china-bavora-printed-packaging-co.,-ltd.-39170" },
  { from: "/listing/shah-je-rent-a-car", to: "/listing/car-rental/shah-je-rent-a-car-39181" },
  { from: "/listing/termite-deemak-pest-control-services", to: "/listing/pest-control/termite-(deemak)-&-pest-control-services-39227" },
  { from: "/listing/covid-disinfection-spray-and-dengue-spray", to: "/listing/pest-control/covid-disinfection-spray-and-dengue-spray-39235" },
  { from: "/listing/visitpk", to: "/listing/travel-agents/visitpk-39250" },
  { from: "/listing/kolaxo-print", to: "/listing/printing/kolaxo-print-39256" },
  { from: "/listing/online-shopping-in-pakistan-2", to: "/listing/shopping-malls/online-shopping-in-pakistan-39260" },
  { from: "/listing/zoom-line-marketing", to: "/listing/property-development/zoom-line-marketing-39263" },
  { from: "/listing/topper-cam-locks-manufacturer-co-ltd", to: "/listing/security-guard/topper-cam-locks-manufacturer-co.,-ltd.-39266" },
  { from: "/listing/newspaper-ads", to: "/listing/advertisers/newspaper-ads-39270" },
  { from: "/listing/topper-vending-machine-lock-manufacturer-co-ltd", to: "/listing/security-guard/topper-vending-machine-lock-manufacturer-co.,-ltd.-39275" },
  { from: "/listing/rajput-homeopathic-medical-college", to: "/listing/colleges/rajput-homeopathic-medical-college-39278" },
  { from: "/listing/nkfs-fumigation-services-in-karachi", to: "/listing/pest-control/nkfs-fumigation-services-in-karachi-39279" },
  { from: "/listing/sohail-termite-control-services-2", to: "/listing/pest-control/sohail-termite-control-services-39303" },
  { from: "/listing/capital-home-care-fumigation-cleaning-services-2", to: "/listing/pest-control/capital-home-care-|-fumigation-&-cleaning-services-39333" },
  { from: "/listing/tradex-international-construction-company-in-lahore", to: "/listing/construction-services/tradex-international-construction-company-in-lahore-39338" },
  { from: "/listing/sadia-junaid", to: "/listing/designer/sadia-junaid-39355" },
  { from: "/listing/connect-hrms", to: "/listing/software-company/connect-hrms-39358" },
  { from: "/listing/primax-pest-prevention-smc-pvt-ltd", to: "/listing/pest-control/primax-pest-prevention-smc-pvt.-ltd.-39365" },
  {
    from: "/listing/shaheen-home-care-waterproofing-and-heat-proofing-services-in-islamabad",
    to: "/listing/pest-control/shaheen-home-care-waterproofing-and-heat-proofing-services-in-islamabad-39375",
  },
  { from: "/listing/termite-control-and-fumigation-service-the-global-services", to: "/listing/pest-control/termite-control-and-fumigation-service-|-the-global-services-39379" },
  { from: "/listing/prince-rana-motors-islamabad", to: "/listing/car-dealer/prince-rana-motors-islamabad-39385" },
  { from: "/listing/waterproofing-heat-proofing-fumigation-pest-control", to: "/listing/general-services/waterproofing,-heat-proofing,-fumigation-and-pest-control-39395" },
  { from: "/listing/king-pest-control-services-in-lahore-2", to: "/listing/pest-control/king-pest-control-services-in-islamabad-&-rawalpindi-39405" },
  { from: "/listing/world-fantastic-services-in-karachi-2", to: "/listing/general-services/world-fantastic-services-in-karachi-39458" },
  { from: "/listing/bismillah-logistics-3", to: "/listing/digital-marketing/bismillah-logistics-39468" },
  { from: "/listing/hi-techtermitecontrolworldsmostreliabletermiteexterminato", to: "/listing/pest-control/hi-techtermitecontrol(world,smostreliabletermiteexterminato)-39471" },
  { from: "/listing/lcci-pk", to: "/listing/digital-marketing/lcci.pk-39475" },
  { from: "/listing/pest-control-services-across-the-pakistan", to: "/listing/pest-control/pest-control-services-across-the-pakistan-39479" },
  { from: "/listing/simple-automation-solutions-private-limited", to: "/listing/software-company/simple-automation-solutions-(private)-limited-39521" },
  { from: "/listing/pak-communications-security-surveillance-communication", to: "/listing/software-company/pak-communications---security,-surveillance-&amp;-communication-39531" },
  { from: "/listing/bizzbless", to: "/listing/advertisers/bizzbless-39535" },
  { from: "/listing/khuda-ki-basti", to: "/listing/advertisers/khuda-ki-basti-39539" },
  { from: "/listing/shamsi-home-solution-all-fumigation-services-in-lahore", to: "/listing/pest-control/shamsi-home-solution-|-all-fumigation-services-in-lahore-39542" },
  { from: "/listing/fumigation-services-in-karachi-and-whole-sindh", to: "/listing/pest-control/fumigation-services-in-karachi-and-whole-sindh-39553" },
  { from: "/listing/cleaning-fumigation-pest-control-and-waterproofing-services", to: "/listing/general-services/cleaning,-fumigation,-pest-control-and-waterproofing-services-39561" },
  { from: "/listing/discounts-pk", to: "/listing/shopping-malls/discounts-pk-39562" },
  { from: "/listing/greenlandscapes", to: "/listing/farm-houses/greenlandscapes-39585" },
  { from: "/listing/ocentra-engineering-services-solar-panel-solutions-in-lahore", to: "/listing/solar-providers/ocentra-engineering-services-|-solar-panel-solutions-in-lahore-39589" },
  { from: "/listing/amir-power-solution-solar-panel-solutions-in-lahore", to: "/listing/solar-providers/amir-power-solution-|-solar-panel-solutions-in-lahore-39603" },
  { from: "/listing/schola-nova-the-best-school-in-islamabad", to: "/listing/driving-schools/schola-nova---the-best-school-in-islamabad-39617" },
  { from: "/listing/best-pest-control-services-in-lahore", to: "/listing/pest-control/best-pest-control-services-in-lahore-39624" },
  { from: "/listing/eco-pest-management-services-2", to: "/listing/pest-control/eco-pest-management-services-39634" },
  { from: "/listing/prius-parts-house-in-rawalpindi", to: "/listing/car-denting-painting/prius-parts-house-in-rawalpindi-39646" },
  { from: "/listing/solar-system-battery-inverter-in-karachi", to: "/listing/solar-providers/solar-system-battery-inverter-in-karachi-39671" },
  { from: "/listing/standard-solar-power-solutions-in-karachi", to: "/listing/solar-providers/standard-solar-power-solutions-in-karachi-39709" },
  { from: "/listing/3d-foam-wall-sheet", to: "/listing/printing/3d-foam-wall-sheet-39720" },
  { from: "/listing/leatic-enterprises", to: "/listing/general-services/leatic-enterprises-39725" },
  { from: "/listing/fast-track-car-rental", to: "/listing/car-rental/fast-track-car-rental-39731" },
  { from: "/listing/green-hovel", to: "/listing/software-company/green-hovel-39743" },
  { from: "/listing/amigo-estate-pvt-ltd", to: "/listing/property-development/amigo-estate-(pvt)-ltd.-39746" },
  { from: "/listing/friends-solar-services-solar-panel-providers-in-lahore-3", to: "/listing/solar-providers/friends-solar-services-|-solar-panel-providers-in-lahore-39749" },
  { from: "/listing/nadperfumes", to: "/listing/beauty-parlour/nadperfumes-39757" },
  { from: "/listing/perfect-electric-service-solar-panel-solutions-in-karachi", to: "/listing/solar-providers/perfect-electric-service-|-solar-panel-solutions-in-karachi-39760" },
  { from: "/listing/rent-a-car-online", to: "/listing/car-rental/rent-a-car-online-39794" },
  { from: "/listing/haroons-estate-and-builders", to: "/listing/property-development/haroons-estate-and-builders-39796" },
  { from: "/listing/zameenmap-com-2", to: "/listing/architecture/zameenmap.com-|-architecture-in-faisalabad-39803" },
  { from: "/listing/education-consultants", to: "/listing/colleges/education-consultants-39814" },
  { from: "/listing/natural-liquid-hand-wash-online-in-pakistan", to: "/listing/shopping-malls/natural-liquid-hand-wash-online-in-pakistan-39820" },
  { from: "/listing/servotech-solar-power-solutions-in-rawalpindi-2", to: "/listing/solar-providers/servotech-|-solar-power-solutions-in-rawalpindi-39831" },
  { from: "/listing/solar-panel-installation-friends-solar-services", to: "/listing/solar-providers/solar-panel-installation-|-friends-solar-services-39845" },
  { from: "/listing/solar-panels-for-home-friends-solar-services-2", to: "/listing/solar-providers/solar-panels-for-home-|-friends-solar-services-39849" },
  { from: "/listing/solar-energy-companies-in-lahore", to: "/listing/solar-providers/solar-energy-companies-in-lahore-39855" },
  { from: "/listing/best-solar-providers-in-lahore-2", to: "/listing/solar-providers/best-solar-providers-in-lahore-39859" },
  { from: "/listing/install-solar-electric-system-solar-energy-solutions", to: "/listing/solar-providers/install-solar-electric-system---solar-energy-solutions-39863" },
  { from: "/listing/affordable-solar-systems-best-solar-system-company-in-pakistan", to: "/listing/solar-providers/affordable-solar-systems-|-best-solar-system-company-in-pakistan-39866" },
  { from: "/listing/solar-energy-solar-system-and-solar-panels-in-lahore", to: "/listing/solar-providers/solar-energy,-solar-system-and-solar-panels-in-lahore-39869" },
  { from: "/listing/solar-panel-water-pump-systems-solar-panels-for-agriculture", to: "/listing/solar-providers/solar-panel-water-pump-systems-|-solar-panels-for-agriculture-39872" },
  { from: "/listing/run-ac-on-solar-power-friends-solar-services", to: "/listing/solar-providers/run-ac-on-solar-power-|-friends-solar-services-39881" },
  { from: "/listing/black-camels-abaya", to: "/listing/designer/black-camels-abaya-39882" },
  { from: "/listing/e-build-pakistan-buy-construction-materials-services-onli", to: "/listing/construction-services/e-build-pakistan:-buy-construction-materials-&amp;-services-onli-39913" },
  { from: "/listing/probot9", to: "/listing/digital-marketing/probot9-39915" },
  { from: "/listing/solar-panel-providers-in-karachi", to: "/listing/solar-providers/solar-panel-providers-in-karachi-39920" },
  { from: "/listing/aes-energy-solutions-pvt-ltd", to: "/listing/electronics/aes-energy-solutions-pvt-ltd.-39952" },
  { from: "/listing/diesel-generators-electrical-panels-solar-energy-and-ups-solutions", to: "/listing/solar-providers/diesel-generators,-electrical-panels,-solar-energy-and-ups-solutions-39958" },
  { from: "/listing/finman-group", to: "/listing/general-services/finman-group-39970" },
  { from: "/listing/bandhan-marriage-beuro-lahore-best-marriage-beuro-in-lahore-2", to: "/listing/marriage-beuro/bandhan-marriage-beuro-lahore-|-best-marriage-beuro-in-lahore-39991" },
  { from: "/listing/car-rent", to: "/listing/car-rental/car-rent-39995" },
  {
    from: "/listing/husnain-cooling-geyser-center-in-lahore-husnain-ac-service-husnain-room-cooler",
    to: "/listing/ac-repair-services/husnain-cooling-&-geyser-center-in-lahore-|-husnain-ac-service-|-husnain-room-cooler-39997",
  },
  {
    from: "/listing/rehman-cooling-and-electric-center-ac-repair-services-in-valancia-town-lahore",
    to: "/listing/ac-repair-services/rehman-cooling-and-electric-center-|-ac-repair-services-in-valancia-town-lahore-40002",
  },
  { from: "/listing/air-cooler-ac-gizar-center-ac-repair-services-in-township", to: "/listing/ac-repair-services/air-cooler-ac-&-gizar-center-|-ac-repair-services-in-township-40009" },
  { from: "/listing/ac-technician", to: "/listing/ac-repair-services/ac-technician-40024" },
  {
    from: "/listing/international-bandhan-rishta-advisor-and-marriage-bureau-in-gujranwala",
    to: "/listing/marriage-beuro/international-bandhan-rishta-advisor-and--marriage-bureau-in-gujranwala-40034",
  },
  { from: "/listing/samz-beauty-clinic-in-sargodha", to: "/listing/beauty-parlour/sam'z-beauty-clinic-in-sargodha-40042" },
  { from: "/listing/urgent-rishta-in-wapda-town-lahore-2", to: "/listing/marriage-beuro/urgent-rishta-in-wapda-town-lahore-40061" },
  { from: "/listing/islamabad-pest-control-water-proofing-in-islamabad-3", to: "/listing/pest-control/islamabad-pest-control-&-water-proofing-in-islamabad-40075" },
  { from: "/listing/select-solar-electrical-technologies", to: "/listing/solar-providers/select---solar-electrical-technologies-40078" },
  { from: "/listing/webdesign-pk", to: "/listing/digital-marketing/webdesign.pk-40086" },
  { from: "/listing/edceum", to: "/listing/universities/edceum-40106" },
  { from: "/listing/marks-kitchen", to: "/listing/home-delivery-food/marks-kitchen-40116" },
  { from: "/listing/al-jannat-motors-tours-and-rent-a-car-in-lahore-2", to: "/listing/car-rental/al-jannat-motors-|-tours-and-rent-a-car-in-lahore-40129" },
  { from: "/listing/water-and-heat-proofing-solution-in-lahore", to: "/listing/waterproofing-company/water-and-heat-proofing-solution-in-lahore-40132" },
  { from: "/listing/protect-your-home-and-stop-all-water-leaks-tss-2", to: "/listing/waterproofing-company/protect-your-home-and-stop-all-water-leaks-|-tss-40135" },
  { from: "/listing/waterproofing-services-in-lahore-the-solution-services-2", to: "/listing/waterproofing-company/waterproofing-services-in-lahore-|-the-solution-services-40137" },
  { from: "/listing/water-tank-leakage-repair-bathroom-leakage-repair-lahore-2", to: "/listing/waterproofing-company/water-tank-leakage-repair-|-bathroom-leakage-repair-lahore-40140" },
  { from: "/listing/saleen-pest-control-company-in-lahore-3", to: "/listing/pest-control/saleen-pest-control-company-in-lahore-40142" },
  { from: "/listing/honda-ring-road", to: "/listing/car-dealer/honda-ring-road-40147" },
  { from: "/listing/zam-zam-car-rental-company-in-lahore", to: "/listing/car-rental/zam-zam-car-rental-company-in-lahore-40156" },
  {
    from: "/listing/butt-screen-center-lahore-windscreen-replacement-and-glass-replacement-in-lahore",
    to: "/listing/car-denting-painting/butt-screen-center-lahore-|-windscreen-replacement-and-glass-replacement-in-lahore-40177",
  },
  { from: "/listing/point-of-sale-system", to: "/listing/general-services/point-of-sale-system-40218" },
  { from: "/listing/arena-of-aesthetics", to: "/listing/doctors-and-clinics/arena-of-aesthetics-40226" },
  { from: "/listing/tour-lahore-to-babusar-top", to: "/listing/travel-agents/tour-lahore-to-babusar-top-40233" },
  { from: "/listing/jutt-real-estate-lahore-buy-sell-and-property-for-rent-in-lahore", to: "/listing/property-development/jutt-real-estate-lahore-|-buy-sell-and-property-for-rent-in-lahore-40256" },
  { from: "/listing/car-available-for-rent-with-driver-in-lahore", to: "/listing/car-rental/car-available-for-rent-with-driver-in-lahore-40266" },
  { from: "/listing/shared-office-space-perfect-co-working-spacee", to: "/listing/property-for-rent/shared-office-space---perfect-co-working-spacee-40277" },
  { from: "/listing/galaxy-car-hire-rent-a-car", to: "/listing/car-rental/galaxy-car-hire-rent-a-car-40282" },
  { from: "/listing/uniform-house-in-faisalabad-uniform-fabrics-in-faisalabad", to: "/listing/shopping-malls/uniform-house-in-faisalabad-|-uniform-fabrics-in-faisalabad-40295" },
  { from: "/listing/micro-car-rental-company-in-islamabad-pakistan", to: "/listing/car-rental/micro-car-rental-company-in-islamabad-pakistan-40360" },
  { from: "/listing/enon-energy-solar-solution-providers-in-lahore", to: "/listing/solar-providers/enon-energy-|-solar-solution-providers-in-lahore-40407" },
  {
    from: "/listing/air-conditioning-refrigerator-system-mudassir-cool-point-in-lahore",
    to: "/listing/ac-repair-services/air-conditioning-&-refrigerator-system-|-mudassir-cool-point-in-lahore-40436",
  },
  { from: "/listing/saeed-driving-school-for-ladies-and-gents-in-islamabad", to: "/listing/driving-schools/saeed-driving-school-ladies-&-gants-(motor-&-rent-a-car)-40439" },
  { from: "/listing/spoken-english-learning-academy-gujranwala", to: "/listing/general-services/spoken-english-learning-academy-gujranwala-40456" },
  { from: "/listing/academic-task", to: "/listing/general-services/academic-task-40463" },
  { from: "/listing/zobia-hospital", to: "/listing/hospitals/zobia-hospital-40472" },
  { from: "/listing/malik-driving-center-in-wah-cantt", to: "/listing/driving-schools/malik-driving-center-in-wah-cantt-40478" },
  { from: "/listing/uplift-pakistan", to: "/listing/digital-marketing/uplift-pakistan-40502" },
  { from: "/listing/faheem-rent-a-car-and-car-driving-school-in-multan", to: "/listing/driving-schools/faheem-rent-a-car-and-car-driving-school-in-multan-40511" },
  { from: "/listing/khokhar-driving-school-rent-a-car-service-in-karachi", to: "/listing/driving-schools/khokhar-driving-school-&-rent-a-car-service-in-karachi-40523" },
  { from: "/listing/mughal-architecture", to: "/listing/architecture/mughal-architecture-40538" },
  { from: "/listing/original-centrum-silver-online-in-karachi-03067788111", to: "/listing/general-services/original-centrum-silver-online-in-karachi---03067788111-40541" },
  { from: "/listing/altaf-ghaffar-real-estate-management-consultancy-services", to: "/listing/property-development/altaf-ghaffar-real-estate-management-&amp;-consultancy-services-40546" },
  { from: "/listing/maria-khalil-hospitalsargodha", to: "/listing/hospitals/maria-khalil-hospital,sargodha-40557" },
  { from: "/listing/urduflix", to: "/listing/digital-marketing/urduflix-40572" },
  { from: "/listing/hack-protection", to: "/listing/website-development/hack-protection-40573" },
  { from: "/listing/nutritionists-society-of-pakistan", to: "/listing/doctors-and-clinics/nutritionists-society-of-pakistan-40579" },
  { from: "/listing/server4sale-pk", to: "/listing/website-development/server4sale-pk-40587" },
  { from: "/listing/pizza-oven-price-in-pakistan", to: "/listing/electronics/pizza-oven-price-in-pakistan-40590" },
  { from: "/listing/residency-one", to: "/listing/property-development/residency-one-40592" },
  { from: "/listing/abbas-ac-repair-service-in-lahore", to: "/listing/ac-repair-services/abbas-ac-repair-service-in-lahore-40595" },
  { from: "/listing/redbox-estate", to: "/listing/property-development/redbox-estate-40606" },
  { from: "/listing/royal-business-solutions", to: "/listing/construction-services/royal-business-solutions-40616" },
  { from: "/listing/lionhdb-properties", to: "/listing/construction-services/lionhdb-properties-40624" },
  { from: "/listing/creative-sprout-media", to: "/listing/website-development/creative-sprout-media-40630" },
  { from: "/listing/massage-by-maham-in-lahore", to: "/listing/beauty-parlour/full-body-massage-in-lahore-|-massage-by-maham-40635" },
  { from: "/listing/aesthetic-shapes", to: "/listing/hospitals/aesthetic-shapes-40640" },
  { from: "/listing/mohkam-electronics", to: "/listing/ac-repair-services/mohkam-electronics-40646" },
  { from: "/listing/techhub-pakistan", to: "/listing/property-for-rent/techhub-pakistan-40651" },
  { from: "/listing/books-and-stationery", to: "/listing/book-stores/books-and-stationery-40656" },
  { from: "/listing/nasir-brothers-false-ceiling-in-lahore", to: "/listing/construction-services/nasir-brothers-|-false-ceiling-in-lahore-40664" },
  { from: "/listing/pak-aims-the-institute-of-management-sciences", to: "/listing/universities/pak-aims-:-the-institute-of-management-sciences-40727" },
  { from: "/listing/fitflex-bodybuilding-supplements", to: "/listing/shopping-malls/fitflex-bodybuilding-supplements-40731" },
  { from: "/listing/maktaba-one", to: "/listing/book-stores/maktaba-one-40736" },
  { from: "/listing/capital-air", to: "/listing/ac-repair-services/capital-air-40739" },
  { from: "/listing/cctv-center-2", to: "/listing/electronics/cctv-center-40756" },
  { from: "/listing/houzzlab", to: "/listing/construction-services/houzzlab-40761" },
  { from: "/listing/real-estate-marketing-company-in-lahore", to: "/listing/general-services/real-estate-marketing-company-in-lahore-40766" },
  { from: "/listing/dupas", to: "/listing/general-services/dupas-40769" },
  { from: "/listing/seomystery", to: "/listing/advertisers/seomystery-40770" },
  { from: "/listing/karachi-false-ceiling", to: "/listing/construction-services/karachi-false-ceiling-service-40784" },
  { from: "/listing/decent-chemical-services-in-karachi", to: "/listing/construction-services/decent-chemical-services-in-karachi-40799" },
  { from: "/listing/tech-base", to: "/listing/electronics/tech-base-40824" },
  { from: "/listing/mackenzie-co", to: "/listing/shopping-malls/mackenzie-&amp;-co-40825" },
  { from: "/listing/al-jadeed-laptops", to: "/listing/electronics/al-jadeed-laptops-40830" },
  { from: "/listing/buy-best-collection-in-pakistan", to: "/listing/designer/buy-best-collection-in-pakistan-40835" },
  { from: "/listing/market-now", to: "/listing/advertisers/market-now-40837" },
  { from: "/listing/manahil-estate", to: "/listing/property-development/manahil-estate-40842" },
  { from: "/listing/abdullah-interiors", to: "/listing/construction-services/abdullah-interiors-in-islamabad-40845" },
  { from: "/listing/expobird", to: "/listing/advertisers/expobird-40880" },
  { from: "/listing/roof-waterproofing-services-pak-chemical-services", to: "/listing/waterproofing-company/roof-waterproofing-services-(-pak-chemical-services-)-40884" },
  { from: "/listing/property-buy-rent-real-estate-online-portal-in-pakistan", to: "/listing/property-development/property-buy-rent-|-real-estate-online-portal-in-pakistan-40890" },
  { from: "/listing/speedomedia-best-digital-marketing-agency-in-pakistan", to: "/listing/digital-marketing/speedomedia--best-digital-marketing-agency-in-pakistan-40897" },
  { from: "/listing/roof-waterproofing-services-roofs-master-chemical-company", to: "/listing/waterproofing-company/roof-waterproofing-services-roofs-master-chemical-company-40899" },
  { from: "/listing/sunbeam-food-spices", to: "/listing/gym/sunbeam-food-&amp;-spices-40905" },
  { from: "/listing/online-furniture-in-lahore", to: "/listing/beauty-parlour/online-furniture-in-lahore-40909" },
  { from: "/listing/millat-guest-house", to: "/listing/hotels/millat-guest-house-40910" },
  { from: "/listing/saf-rent-a-car-lahore-2", to: "/listing/car-rental/saf-rent-a-car-lahore-40913" },
  { from: "/listing/allure-beauty", to: "/listing/shopping-malls/allure-beauty-40918" },
  { from: "/listing/versatile-herbal-pharma-pvt-ltd", to: "/listing/doctors-and-clinics/versatile-herbal-pharma-(pvt)-ltd.-40922" },
  { from: "/listing/online-car-accessories-store-in-pakistan", to: "/listing/car-dealer/online-car-accessories-store-in-pakistan-40925" },
  { from: "/listing/upvc-windows-doors", to: "/listing/construction-services/upvc-windows-&amp;-doors-40926" },
  { from: "/listing/waleed-plaster-of-paris-ceiling-in-islamabad", to: "/listing/construction-services/waleed-plaster-of-paris-ceiling-in-islamabad-40935" },
  { from: "/listing/motor-workshop-and-oil-shop-anwar-autos", to: "/listing/car-denting-painting/motor-workshop-and-oil-shop-|-anwar-autos-in-lahore-40952" },
  { from: "/listing/coding-agents", to: "/listing/software-company/coding-agents-40966" },
  { from: "/listing/north-voyagers", to: "/listing/travel-agents/north-voyagers-40991" },
  { from: "/listing/disney-tours-travels", to: "/listing/general-services/disney-tours-&amp;-travels-40994" },
  { from: "/listing/polycrafts-pvt-limited", to: "/listing/general-services/polycrafts-(pvt)-limited-40996" },
  { from: "/listing/iciltek-pakistan-private-limited", to: "/listing/software-company/iciltek-pakistan-private-limited-40999" },
  { from: "/listing/institute-of-behavioral-psychology", to: "/listing/doctors-and-clinics/institute-of-behavioral-psychology-41004" },
  { from: "/listing/pgs-engineering-consultants-builders", to: "/listing/construction-services/pgs-engineering-consultants-&-builders-in-peshawar-41006" },
  { from: "/listing/campaign-leader", to: "/listing/digital-marketing/campaign-leader-41070" },
  { from: "/listing/ibp-remedial-services", to: "/listing/driving-schools/ibp-remedial-services-41074" },
  { from: "/listing/rainbow-hosiery-pvt-ltd", to: "/listing/printing/rainbow-hosiery-(pvt)-ltd-41076" },
  { from: "/listing/best-pizzas-in-lahore", to: "/listing/restaurants/best-pizzas-in-lahore-41079" },
  { from: "/listing/woodex-interior", to: "/listing/architecture/woodex-interior-41082" },
  { from: "/listing/therapy-works-pvt-ltd-karachi", to: "/listing/hospitals/therapy-works-pvt.-ltd-karachi-41088" },
  { from: "/listing/health-n-life-pk", to: "/listing/general-services/health-n-life-pk-41093" },
  { from: "/listing/activefiler-ntn-registration-and-income-tax-filing", to: "/listing/general-services/activefiler---ntn-registration-and-income-tax-filing-41095" },
  { from: "/listing/annies-home-decor", to: "/listing/weddings-planner/annies-home-decor-41098" },
  { from: "/listing/rapid-movers-dha-lahore", to: "/listing/advertisers/rapid-movers-dha-lahore-41102" },
  { from: "/listing/web-designing-services", to: "/listing/website-development/web-designing-services-41105" },
  { from: "/listing/sign-up-solution-best-software-house", to: "/listing/digital-marketing/sign-up-solution-|-best-software-house-41109" },
  { from: "/listing/nisha-beauty-salon", to: "/listing/beauty-parlour/nisha-beauty-salon-41114" },
  { from: "/listing/ssd-store", to: "/listing/computer-repair/ssd-store-41117" },
  { from: "/listing/aa-associate-llc", to: "/listing/advertisers/a&amp;a-associate-llc-41129" },
  { from: "/listing/falaksher-fallse-cellling-center", to: "/listing/construction-services/falaksher-fallse-cellling-center-41136" },
  { from: "/listing/da-drawer", to: "/listing/shopping-malls/da-drawer-41138" },
  { from: "/listing/the-luxury-girls-hostel-in-lahore", to: "/listing/property-for-rent/the-luxury-girls-hostel-in-lahore-41143" },
  { from: "/listing/gi-solutions-home-renovation-ceiling-services-in-lahore", to: "/listing/construction-services/gi-solutions-|-all-kind-of-renovation,-branding-and-event-management-41149" },
  {
    from: "/listing/bm-construction-co-home-renovation-construction-and-ceiling-services-in-lahore",
    to: "/listing/construction-services/bm-construction-&-co-|-home-renovation,-construction-and-ceiling-services-in-lahore-41156",
  },
  { from: "/listing/kids-polo-association", to: "/listing/designer/kids-polo-association-41179" },
  { from: "/listing/falaksher-fallse-celling", to: "/listing/construction-services/falaksher-fallse-celling-41188" },
  { from: "/listing/prof-dr-rashid-zia-ent-specialist-in-lahore", to: "/listing/doctors-and-clinics/prof-dr.-rashid-zia-|-ent-specialist-in-lahore-41189" },
  { from: "/listing/home-insulation-system", to: "/listing/waterproofing-company/home-insulation-system-41195" },
  { from: "/listing/studybitests", to: "/listing/tutoring/studybitests-41200" },
  { from: "/listing/ajmal-dawakhana", to: "/listing/general-services/ajmal-dawakhana-41209" },
  { from: "/listing/dawakhana-hakim-ajmal-khan", to: "/listing/general-services/dawakhana-hakim-ajmal-khan-41212" },
  { from: "/listing/da-smart-store", to: "/listing/shopping-malls/da-smart-store-41216" },
  { from: "/listing/ningbo-green-light-energy", to: "/listing/solar-providers/ningbo-green-light-energy-41234" },
  { from: "/listing/law-reforms", to: "/listing/lawyers/law-reforms-41243" },
  { from: "/listing/musk-al-mahal", to: "/listing/shopping-malls/musk-al-mahal-41251" },
  { from: "/listing/kalm", to: "/listing/general-services/kalm-41255" },
  { from: "/listing/hamza-hamza-law-associates-best-tax-lawyers-in-lahore", to: "/listing/lawyers/hamza-&-hamza-law-associates-|-best-tax-lawyers-in-lahore-41262" },
  { from: "/listing/study-mbbs-in-china", to: "/listing/general-services/study-mbbs-in-china-41278" },
  { from: "/listing/construction-company-in-islamabad-2", to: "/listing/construction-services/construction-company-in-islamabad-41282" },
  { from: "/listing/mian-law-chamber", to: "/listing/lawyers/mian-law-chamber-|-legal-help-in-lahore-41292" },
  { from: "/listing/pak-chemical-services-roof-waterproofing-services", to: "/listing/waterproofing-company/pak-chemical-services-roof-waterproofing-services-41297" },
  { from: "/listing/sadys-academy-by-sj", to: "/listing/salons/sady's-academy---by-sj-41304" },
  { from: "/listing/ajmal-dawakhana-herbal-company", to: "/listing/doctors-and-clinics/ajmal-dawakhana---herbal-company-41307" },
  { from: "/listing/rent-a-car-islamabad-moon-car-rental", to: "/listing/car-rental/rent-a-car-islamabad---moon-car-rental-41328" },
  { from: "/listing/bismillah-travels-rent-a-car-in-lahore", to: "/listing/car-rental/bismillah-travels-|-rent-a-car-in-lahore-41350" },
  { from: "/listing/itutors-islamabad", to: "/listing/job-providers/itutors-islamabad-41370" },
  { from: "/listing/blue-world-city", to: "/listing/property-development/blue-world-city-41375" },
  { from: "/listing/global-exports-from-pakistan-largest-exporter-in-the-world", to: "/listing/general-services/global-exports-from-pakistan-largest-exporter-in-the-world-41380" },
  { from: "/listing/washup", to: "/listing/general-services/washup-41392" },
  { from: "/listing/green-peak-international", to: "/listing/advertisers/green-peak-international-41396" },
  { from: "/listing/apksoft-it-solution-provider", to: "/listing/software-company/apksoft-it-solution-provider-41401" },
  { from: "/listing/brand-builders-2", to: "/listing/digital-marketing/brand-builders-41436" },
  { from: "/listing/aysonline", to: "/listing/ac-repair-services/aysonline-41439" },
  { from: "/listing/nova-city-islamabad", to: "/listing/property-development/nova-city-islamabad-41496" },
  { from: "/listing/blue-world-city-2", to: "/listing/property-development/blue-world-city-41497" },
  { from: "/listing/park-view-city-islamabad", to: "/listing/property-development/park-view-city-islamabad-41501" },
  { from: "/listing/bilal-rent-a-car-in-lahore", to: "/listing/car-rental/bilal-rent-a-car-in-lahore-|-prado,-v8,-fortuner-and-revo-for-rent-41505" },
  { from: "/listing/best-injection-molding-machines-in-lahore", to: "/listing/general-services/best-injection-molding-machines-in-lahore-41510" },
  { from: "/listing/trucks-for-rent-in-lahore-rashid-transport-company", to: "/listing/car-rental/trucks-for-rent-in-lahore-|-rashid-transport-company-41512" },
  { from: "/listing/rbs-law-associates", to: "/listing/lawyers/rbs-law-associates-41519" },
  { from: "/listing/zapta-technologies-digital-solutions-and-services", to: "/listing/website-development/zapta-technologies:-digital-solutions-and-services-41525" },
  { from: "/listing/areejas-wardrobe", to: "/listing/general-services/areejas-wardrobe-41530" },
  { from: "/listing/hiline", to: "/listing/architecture/hiline-41535" },
  { from: "/listing/iprc-islamabad-physiotherapy-and-rehabilitation-centre", to: "/listing/hospitals/iprc---islamabad-physiotherapy-and-rehabilitation-centre-41539" },
  { from: "/listing/salman-yousaf-khan-law-associates-best-family-lawyer-in-islamabad", to: "/listing/lawyers/salman-yousaf-khan-law-associates-|-best-family-lawyer-in-islamabad-41544" },
  { from: "/listing/law-society-pakistan-the-law-firm-legal-practitioners-and-consultant", to: "/listing/lawyers/law-society-pakistan-the-law-firm-|-legal-practitioners-and-consultant-41547" },
  { from: "/listing/xtreme-tourism", to: "/listing/event-planner/xtreme-tourism-41551" },
  { from: "/listing/bbl-sleep-well", to: "/listing/general-services/bbl-sleep-well-41554" },
  { from: "/listing/corporate-printing-services-indus-printing", to: "/listing/general-services/corporate-printing-services-|-indus-printing-41560" },
  { from: "/listing/green-palms-gwadar-plot-for-sale", to: "/listing/construction-services/green-palms-gwadar-plot-for-sale-41585" },
  { from: "/listing/wagon-r-rent-a-car-lahore-cars-for-rent-in-lahore", to: "/listing/car-rental/wagon-r-rent-a-car-lahore-|-cars-for-rent-in-lahore-41590" },
  { from: "/listing/girls-hostel-in-lahore", to: "/listing/hotels/girls-hostel-in-lahore-41595" },
  { from: "/listing/reliance-pest-control", to: "/listing/pest-control/reliance-pest-control-41605" },
  { from: "/listing/solar-business", to: "/listing/solar-providers/solar-business-41612" },
  { from: "/listing/liftlow", to: "/listing/car-rental/liftlow-41616" },
  { from: "/listing/zia-dental-care", to: "/listing/doctors-and-clinics/zia-dental-care-41622" },
  { from: "/listing/rubber-sheet-manufacturer-and-supplier", to: "/listing/architecture/rubber-sheet-manufacturer-and-supplier-41635" },
  { from: "/listing/unique-fumigation", to: "/listing/pest-control/unique-fumigation-41642" },
  { from: "/listing/rehman-ambulance-services-in-lahore", to: "/listing/hospitals/rehman-ambulance-services-in-lahore-41646" },
  { from: "/listing/new-malik-rent-a-cars-lahore-malik-qaiser-awan-2", to: "/listing/car-rental/new-malik-rent-a-cars-lahore-|-malik-qaiser-awan-41652" },
  { from: "/listing/website-designer-and-graphic-3d-animation", to: "/listing/website-development/website-designer-and-graphic-3d-animation-41662" },
  { from: "/listing/car-rental-with-driver", to: "/listing/car-rental/car-rental-with-driver-41670" },
  { from: "/listing/quality-herald", to: "/listing/electronics/quality-herald-41675" },
  { from: "/listing/zain-real-estate-and-builders", to: "/listing/property-development/zain-real-estate-and-builders-41679" },
  { from: "/listing/axzone-property-private-limited", to: "/listing/construction-services/axzone-property-(private)-limited-41686" },
  { from: "/listing/blue-world-city-islamabad", to: "/listing/construction-services/blue-world-city-islamabad-41695" },
  { from: "/listing/bin-sajir-establishments", to: "/listing/construction-services/bin-sajir-establishments.-41696" },
  { from: "/listing/mafco-tech-engineering", to: "/listing/general-services/mafco-tech-engineering-41702" },
  { from: "/listing/synergy-corporation", to: "/listing/solar-providers/synergy-corporation-41706" },
  { from: "/listing/online-shopping-store-in-pakistan", to: "/listing/shopping-malls/online-shopping-store-in-pakistan-41710" },
  { from: "/listing/digital-marketing-2", to: "/listing/digital-marketing/digital-marketing-41714" },
  { from: "/listing/cambridge-home-tutors", to: "/listing/tutoring/cambridge-home-tutors-41719" },
  { from: "/listing/fumigation-services-in-karachi-coronavirus-disinfection-services", to: "/listing/pest-control/fumigation-services-in-karachi-|-coronavirus-disinfection-services-41725" },
  { from: "/listing/jeevay-shahanshah-goods-transport-company", to: "/listing/pickups-and-trucks/jeevay-shahanshah-goods-transport-company-41750" },
  { from: "/listing/vinncorp", to: "/listing/software-company/vinncorp-41754" },
  { from: "/listing/electro-city", to: "/listing/electronics/electro-city-41757" },
  { from: "/listing/amazon-products-in-pakistan-waoomart", to: "/listing/digital-marketing/amazon-products-in-pakistan|-waoomart-41762" },
  { from: "/listing/ensmile-invisible-dental-aligners", to: "/listing/dental/ensmile-invisible-dental-aligners-41766" },
  { from: "/listing/newbeeinc", to: "/listing/job-providers/newbeeinc-41767" },
  { from: "/listing/materialz1", to: "/listing/construction-services/materialz1-41771" },
  { from: "/listing/apex-learning", to: "/listing/general-services/apex-learning-41778" },
  { from: "/listing/faisal-associates", to: "/listing/architecture/faisal-associates-41782" },
  { from: "/listing/epoxy-flooring-in-pakistan-the-ultimate-solution", to: "/listing/construction-services/epoxy-flooring-in-pakistan,-the-ultimate-solution-41786" },
  { from: "/listing/surveillance-security-system", to: "/listing/electronics/surveillance-security-system-41794" },
  { from: "/listing/schola-nova", to: "/listing/driving-schools/schola-nova-41807" },
  { from: "/listing/cnc-router-machine-lahore-cnc-machines-buy-in-pakistan-2", to: "/listing/general-services/cnc-router-machine-lahore-|-cnc-machines-buy-in-pakistan-41810" },
  { from: "/listing/musa-rent-a-car-tour-and-travel", to: "/listing/car-rental/musa-rent-a-car-tour-and-travels-lahore-41814" },
  { from: "/listing/olympic-enterprises", to: "/listing/general-services/olympic-enterprises-41850" },
  { from: "/listing/autocad-experts-offer-cad-bim-courses-in-karachi", to: "/listing/architecture/autocad-experts-offer-cad-&amp;-bim-courses-in-karachi-41856" },
  { from: "/listing/jawad-ali-photography", to: "/listing/photographer/jawad-ali-photography-41860" },
  { from: "/listing/united-fumigation-services-in-karachi", to: "/listing/pest-control/united-fumigation-services-in-karachi-41867" },
  { from: "/listing/martin-dow-pharmaceutical-company-in-pakistan", to: "/listing/general-services/martin-dow-|-pharmaceutical-company-in-pakistan-41874" },
  { from: "/listing/proglads", to: "/listing/gym/proglads-41876" },
  { from: "/listing/interior-designer", to: "/listing/architecture/interior-designer-41884" },
  { from: "/listing/tile-leveling-system-and-spacers", to: "/listing/construction-services/tile-leveling-system-and-spacers-41893" },
  { from: "/listing/seo-services-in-lahore-2", to: "/listing/digital-marketing/seo-services-in-lahore-41902" },
  { from: "/listing/printing-press-islamabad", to: "/listing/printing/printing-press-islamabad-41903" },
  { from: "/listing/jang-news-islamabad", to: "/listing/advertisers/jang-news-islamabad-41907" },
  { from: "/listing/travelustaad-com", to: "/listing/travel-agents/travelustaad.com-41918" },
  { from: "/listing/ittefaq-electronics", to: "/listing/electronics/ittefaq-electronics-41921" },
  { from: "/listing/the-event-decors", to: "/listing/event-planner/the-event-decors-41928" },
  { from: "/listing/glorious-builders", to: "/listing/construction-services/glorious-builders-41932" },
  { from: "/listing/physiofitt", to: "/listing/doctors-and-clinics/physiofitt-41938" },
  { from: "/listing/zain-brothers-construction-company-in-lahore", to: "/listing/construction-services/zain-brothers-construction-company-in-lahore-41951" },
  { from: "/listing/green-pest-control", to: "/listing/pest-control/green-pest-control-and-fumigation-services-in-islamabad-41955" },
  { from: "/listing/seo-expert", to: "/listing/digital-marketing/seo-expert-41958" },
  { from: "/listing/rafia-pk", to: "/listing/shopping-malls/rafia.pk-41966" },
  { from: "/listing/velocityfitness", to: "/listing/gym/velocityfitness-41971" },
  { from: "/listing/north-driving-school", to: "/listing/driving-schools/north-driving-school-41978" },
  { from: "/listing/billboards-in-lahore-karachi-islamabad-peshawar", to: "/listing/advertisers/billboards-in-lahore-karachi-islamabad-peshawar-41986" },
  { from: "/listing/sterwrap-1-sterile-wrapping-solutions-in-pakistan", to: "/listing/laboratories/sterwrap---#1-sterile-wrapping-solutions-in-pakistan-42001" },
  { from: "/listing/rashid-latif-medical-complex", to: "/listing/colleges/rashid-latif-medical-complex-42003" },
  { from: "/listing/business-brand-franchise-consultant-in-pakistan", to: "/listing/general-services/business-&amp;-brand-franchise-consultant-in-pakistan-42007" },
  { from: "/listing/soft-enterprise", to: "/listing/software-company/soft-enterprise-42013" },
  { from: "/listing/edookan", to: "/listing/general-services/edookan-42015" },
  { from: "/listing/pest-control-service", to: "/listing/pest-control/pest-control-service-42020" },
  { from: "/listing/xtremes-tourism", to: "/listing/advertisers/xtremes-tourism-42026" },
  { from: "/listing/capital-smart-city-2", to: "/listing/property-development/capital-smart-city-42039" },
  { from: "/listing/industradgroup", to: "/listing/electronics/industradgroup-42043" },
  { from: "/listing/pak-lawyer-associates-2", to: "/listing/lawyers/pak-lawyer-associates-42046" },
  { from: "/listing/message-communications-advertising-agency", to: "/listing/advertisers/message-communications-|-advertising-agency-42051" },
  { from: "/listing/the-shoppies", to: "/listing/gym/the-shoppies-42055" },
  { from: "/listing/entertainment-bracket", to: "/listing/general-services/entertainment-bracket-42057" },
  { from: "/listing/digital-wolves-pakistan", to: "/listing/digital-marketing/digital-wolves-pakistan-42061" },
  { from: "/listing/chemicals-agent-in-pakistan", to: "/listing/general-services/chemicals-agent-in-pakistan-42066" },
  { from: "/listing/femingle", to: "/listing/beauty-parlour/femingle-42073" },
  { from: "/listing/kidsdailyroutine", to: "/listing/book-stores/kidsdailyroutine-42080" },
  { from: "/listing/cambridge-home-tutors-services", to: "/listing/tutoring/cambridge-home-tutors-services-42085" },
  { from: "/listing/compro-system", to: "/listing/computer-repair/compro-system-42088" },
  { from: "/listing/path-finder-rent-a-car-in-karachi", to: "/listing/car-rental/pathfinder-rent-a-car-in-karachi-42094" },
  { from: "/listing/serani-chemicals-services-roof-waterproofing-services", to: "/listing/waterproofing-company/serani-chemicals-services--roof-waterproofing-services-42124" },
  { from: "/listing/i-know-my-toys", to: "/listing/shopping-malls/i-know-my-toys-42129" },
  { from: "/listing/best-dentist-in-lahore-near-me", to: "/listing/dental/best-dentist-in-lahore-near-me-42130" },
  { from: "/listing/hsbro-solutions", to: "/listing/website-development/hsbro-solutions-42135" },
  { from: "/listing/sportswear-product", to: "/listing/gym/sportswear-product-42144" },
  { from: "/listing/e360-pk", to: "/listing/software-company/e360.pk-42156" },
  { from: "/listing/glorious-builders-2", to: "/listing/architecture/glorious-builders-42162" },
  { from: "/listing/adamjee-durabuilt-pvt-ltd", to: "/listing/electronics/adamjee-durabuilt-(pvt)-ltd-42166" },
  { from: "/listing/unigold-traders", to: "/listing/general-services/unigold-traders-42169" },
  { from: "/listing/bravo-nutrition", to: "/listing/gym/bravo-nutrition-42174" },
  { from: "/listing/ams-security-vision", to: "/listing/electronics/ams-security-vision-42175" },
  { from: "/listing/hs-real-estate-agency", to: "/listing/construction-services/h&amp;s-real-estate-agency-42181" },
  { from: "/listing/morokom-inc", to: "/listing/computer-repair/morokom-inc-42185" },
  { from: "/listing/paragon-overseas-education-pvt-ltd", to: "/listing/visa-agencies/paragon-overseas-education-pvt.-ltd.-42186" },
  { from: "/listing/mukabbir-school", to: "/listing/driving-schools/mukabbir-school-42189" },
  { from: "/listing/real-estate-marketing-companies-in-pakistan", to: "/listing/digital-marketing/real-estate-marketing-companies-in-pakistan-42192" },
  { from: "/listing/epets-pk", to: "/listing/animals-&amp;-birds/epets.pk-42201" },
  { from: "/listing/web-cafe", to: "/listing/software-company/web-cafe-42204" },
  { from: "/listing/seo-training-courses-lahore", to: "/listing/digital-marketing/seo-training-courses-lahore-42207" },
  { from: "/listing/laraibnow", to: "/listing/electronics/laraibnow-42210" },
  { from: "/listing/buy-sell-rent-properties", to: "/listing/construction-services/buy,-sell-&amp;-rent-properties-42212" },
  { from: "/listing/archxstudio-2", to: "/listing/architecture/archxstudio-42216" },
  { from: "/listing/umair-seo-expert-in-lahore-seo-consultant-seo-company", to: "/listing/digital-marketing/umair-seo-expert-in-lahore---seo-consultant---seo-company-42221" },
  { from: "/listing/local-taxi-cab-services-lahore-cabi-technologies", to: "/listing/car-rental/local-taxi-cab-services-lahore-|-cabi-technologies-42228" },
  { from: "/listing/webgeniee", to: "/listing/website-development/webgeniee-42230" },
  { from: "/listing/stationers-pk", to: "/listing/book-stores/stationers.pk-42234" },
  { from: "/listing/kia-sportage-for-rent-in-lahore-sportage-on-rent-in-lahore", to: "/listing/car-rental/kia-sportage-for-rent-in-lahore-|-sportage-on-rent-in-lahore-42239" },
  { from: "/listing/study-abroad-consulants-in-lahore", to: "/listing/advertisers/study-abroad-consulants-in-lahore-42242" },
  { from: "/listing/islamabad-physiotherapy-rehabilitation-center", to: "/listing/doctors-and-clinics/islamabad-physiotherapy-&amp;-rehabilitation-center-42250" },
  { from: "/listing/saafsafaipkgmail-com", to: "/listing/pest-control/saafsafaipk@gmail.com-42254" },
  { from: "/listing/packing-tape-manufacturer-in-lahore", to: "/listing/general-services/packing-tape-manufacturer-in-lahore-42256" },
  { from: "/listing/version-xi-pink", to: "/listing/general-services/version-xi-pink-42261" },
  { from: "/listing/packing-machine-manufacturer", to: "/listing/architecture/packing-machine-manufacturer-42263" },
  { from: "/listing/paklanads-pk", to: "/listing/construction-services/paklanads.pk-42272" },
  { from: "/listing/sadabahaar", to: "/listing/shopping-malls/sadabahaar-42275" },
  { from: "/listing/falcon-tours-travels-toyota-prado-for-rent-in-islamabad", to: "/listing/car-rental/toyota-prado-for-rent-in-islamabad-|-falcon-tours-&-travels-42279" },
  { from: "/listing/sigma-digital-hub", to: "/listing/website-development/sigma-digital-hub-42286" },
  { from: "/listing/cancun-airport-transportation", to: "/listing/travel-agents/cancun-airport-transportation-42296" },
  { from: "/listing/cancun-shuttle-transportation", to: "/listing/travel-agents/cancun-shuttle-transportation-42299" },
  { from: "/listing/city-car-rental-cancun", to: "/listing/car-rental/city-car-rental-cancun-42304" },
  { from: "/listing/official-cancun-airport-transportation", to: "/listing/travel-agents/official-cancun-airport-transportation-42308" },
  { from: "/listing/rzc-fashion", to: "/listing/shopping-malls/rzc-fashion-42311" },
  { from: "/listing/online-fashion-store", to: "/listing/general-services/online-fashion-store-42315" },
  { from: "/listing/vip-afridi-rent-a-car-in-peshawar", to: "/listing/car-rental/vip-afridi-rent-a-car-in-peshawar-42317" },
  { from: "/listing/leading-edge-physiotherapy-rehabilitation-center", to: "/listing/doctors-and-clinics/leading-edge-physiotherapy-&amp;-rehabilitation-center-42328" },
  { from: "/listing/softileo", to: "/listing/software-company/softileo-42332" },
  { from: "/listing/city-car-rental-cabo-san-lucas", to: "/listing/car-rental/city-car-rental-cabo-san-lucas-42339" },
  { from: "/listing/airport-transfers-cancun", to: "/listing/travel-agents/airport-transfers-cancun-42344" },
  { from: "/listing/car-rental-cancun-by-city-car-rental", to: "/listing/car-rental/car-rental-cancun-by-city-car-rental-42347" },
  { from: "/listing/city-car-rental-los-angeles", to: "/listing/car-rental/city-car-rental-los-angeles-42351" },
  { from: "/listing/city-car-rental-los-cabos-airport", to: "/listing/car-rental/city-car-rental-los-cabos-airport-42356" },
  { from: "/listing/edookan-2", to: "/listing/shopping-malls/edookan-42362" },
  { from: "/listing/hire-dedicated-developers", to: "/listing/job-providers/hire-dedicated-developers-42367" },
  { from: "/listing/premium-quality-marble-tiles-in-pakistan", to: "/listing/general-services/premium-quality-marble-tiles-in-pakistan-42372" },
  { from: "/listing/best-marketing-agency-peshawar", to: "/listing/advertisers/best-marketing-agency-peshawar-42373" },
  { from: "/listing/uncle-fixer", to: "/listing/general-services/uncle-fixer-42374" },
  { from: "/listing/rafi-group", to: "/listing/property-development/rafi-group-42392" },
  { from: "/listing/savyour", to: "/listing/software-company/savyour-42398" },
  { from: "/listing/messagemuse-digital-agency", to: "/listing/software-company/messagemuse-digital-agency-42408" },
  { from: "/listing/hitech-solutions", to: "/listing/electronics/hitech-solutions-42412" },
  { from: "/listing/fatography", to: "/listing/photographer/fatography-42418" },
  { from: "/listing/lightning-it-solution", to: "/listing/advertisers/lightning-it-solution-42424" },
  { from: "/listing/k-counsels", to: "/listing/lawyers/k-counsels-42427" },
  { from: "/listing/towel-showel", to: "/listing/shopping-malls/towel-showel-42430" },
  { from: "/listing/bilal-says-motivation-jobs-life-self-help-blog", to: "/listing/content-writers/bilal-says--motivation,-jobs,-life-&amp;-self-help-blog-42434" },
  { from: "/listing/hinz-pakistan", to: "/listing/shopping-malls/hinz-pakistan-42443" },
  { from: "/listing/datesnmates-2", to: "/listing/general-services/datesnmates-42446" },
  { from: "/listing/wish-surgical-2", to: "/listing/general-services/wish-surgical-42453" },
  { from: "/listing/the-best-ent-lahore", to: "/listing/doctors-and-clinics/the-best-ent-lahore-42459" },
  { from: "/listing/eighteen-islamabad", to: "/listing/property-development/eighteen-islamabad-42466" },
  { from: "/listing/cloud-villas-by-cloud-services", to: "/listing/construction-services/cloud-villas-by-cloud-services-42471" },
  { from: "/listing/safe-havens-enterprises", to: "/listing/pest-control/safe-havens-enterprises-42476" },
  { from: "/listing/highbrow-international", to: "/listing/visa-agencies/highbrow-international-42482" },
  { from: "/listing/real-estate-agency-in-pakistan", to: "/listing/property-development/real-estate-agency-in-pakistan-42484" },
  { from: "/listing/indusrobe-online-clothing-brand-in-pakistan", to: "/listing/shopping-malls/indusrobe---online-clothing-brand-in-pakistan-42494" },
  { from: "/listing/hajra-garments", to: "/listing/designer/hajra-garments-42498" },
  { from: "/listing/find-my-doctor-pk", to: "/listing/hospitals/find-my-doctor-pk-42499" },
  { from: "/listing/home-appliances-2", to: "/listing/electronics/home-appliances-42505" },
  { from: "/listing/leading-edge-physiotherapy-rehabilitation", to: "/listing/hospitals/leading-edge-physiotherapy-&amp;-rehabilitation-42510" },
  { from: "/listing/leading-pipe-manufacturers-in-pakistan-newtech-pipes", to: "/listing/plumbers/leading-pipe-manufacturers-in-pakistan-|-newtech-pipes-42514" },
  { from: "/listing/web-development-company-in-lahore", to: "/listing/advertisers/web-development-company-in-lahore-42520" },
  { from: "/listing/ary-jewellers", to: "/listing/jewelers/ary-jewellers-42525" },
  { from: "/listing/web-development-services-in-abbottabad-2", to: "/listing/software-company/web-development-services-in-abbottabad-42529" },
  { from: "/listing/winders-consultants", to: "/listing/general-services/winders-consultants-42534" },
  { from: "/listing/the-time-news", to: "/listing/verification-services/the-time-news-42543" },
  { from: "/listing/softbridge", to: "/listing/software-company/softbridge-42547" },
  { from: "/listing/tag-choice", to: "/listing/shopping-malls/tag-choice-42552" },
  { from: "/listing/best-hi-tea-and-dinner-buffet-in-lahore-lamessa-indigo-hotel", to: "/listing/restaurants/best-hi-tea-and-dinner-buffet-in-lahore-lamessa-indigo-hotel-42560" },
  { from: "/listing/buzz-communication-complete-voice-over-solution", to: "/listing/advertisers/buzz-communication-complete-voice-over-solution-42565" },
  { from: "/listing/vietnam-white-marble", to: "/listing/construction-services/vietnam-white-marble-42568" },
  { from: "/listing/ssl-training", to: "/listing/digital-marketing/ssl-training-42581" },
  { from: "/listing/nayza", to: "/listing/general-services/nayza-42589" },
  { from: "/listing/creative-next-solutions", to: "/listing/website-development/creative-next-solutions-42593" },
  { from: "/listing/mh-advocates-corporate-consultants", to: "/listing/lawyers/m&amp;h-advocates-&amp;-corporate-consultants-42601" },
  { from: "/listing/dealanddeals-pk", to: "/listing/property-development/dealanddeals.pk-42607" },
  { from: "/listing/s2s-marketing", to: "/listing/advertisers/s2s-marketing-42614" },
  { from: "/listing/rehbar-pets-clinic", to: "/listing/pets-hospitals/rehbar-pets-clinic-42616" },
  { from: "/listing/best-architecture-design-company", to: "/listing/architecture/best-architecture-design-company-42625" },
  { from: "/listing/siltaki-earrings-online-store", to: "/listing/jewelers/siltaki-earrings-online-store-42629" },
  { from: "/listing/lal-hutti", to: "/listing/shopping-malls/lal-hutti-42632" },
  { from: "/listing/sight-pakistan", to: "/listing/hospitals/sight-pakistan-42641" },
  { from: "/listing/experts-helping-businesses-ehb", to: "/listing/general-services/experts-helping-businesses-(ehb)-42645" },
  { from: "/listing/libaasmart-online-clothes-shopping-in-pakistan", to: "/listing/shopping-malls/libaasmart---online-clothes-shopping-in-pakistan-42648" },
  { from: "/listing/konfor-activewear", to: "/listing/shopping-malls/konfor-activewear-42654" },
  { from: "/listing/best-online-shopping-website-in-pakistan-baba-boota", to: "/listing/general-services/best-online-shopping-website-in-pakistan-|-baba-boota-42660" },
  { from: "/listing/cakes-and-bakes", to: "/listing/home-delivery-food/cakes-and-bakes-42676" },
  { from: "/listing/textile", to: "/listing/advertisers/textile-42680" },
  { from: "/listing/travel-and-tours", to: "/listing/travel-agents/travel-and-tours-42682" },
  { from: "/listing/saver-solar-systems", to: "/listing/solar-providers/saver-solar-systems-42686" },
  { from: "/listing/rental-cars-in-usa-miami-international-airport-mia", to: "/listing/travel-agents/rental-cars-in-usa---miami-international-airport-(mia)-42688" },
  { from: "/listing/door-to-door-marketing-flyer-distribution-karachi", to: "/listing/advertisers/door-to-door-marketing-|-flyer-distribution-karachi-42691" },
  { from: "/listing/vemessage", to: "/listing/general-services/vemessage-42696" },
  { from: "/listing/fiesta-endless-solutions", to: "/listing/colleges/fiesta-endless-solutions-42702" },
  { from: "/listing/led-lights-in-pakistan", to: "/listing/electronics/led-lights-in-pakistan-42707" },
  { from: "/listing/awf-real-estate-and-builders", to: "/listing/property-development/awf-real-estate-and-builders-42711" },
  { from: "/listing/softbridge-2", to: "/listing/website-development/softbridge-42712" },
  { from: "/listing/alay", to: "/listing/shopping-malls/alay-42722" },
  { from: "/listing/fizanoor", to: "/listing/advertisers/fizanoor-42732" },
  { from: "/listing/fumigation-pest-control-karachi", to: "/listing/pest-control/fumigation-pest-control-karachi-42743" },
  { from: "/listing/transcast-media", to: "/listing/advertisers/transcast-media-42749" },
  { from: "/listing/yopals-com-best-business-review-sites", to: "/listing/advertisers/yopals.com-|-best-business-review-sites-42752" },
  { from: "/listing/auto-hub", to: "/listing/car-dealer/auto-hub-42762" },
  { from: "/listing/suraj-bijli", to: "/listing/solar-providers/suraj-bijli-42765" },
  { from: "/listing/abdullah-home", to: "/listing/advertisers/abdullah-home-42766" },
  { from: "/listing/how-to-get-profit-in-real-estate", to: "/listing/property-development/how-to-get-profit-in-real-estate?-42773" },
  { from: "/listing/foodoplanet", to: "/listing/home-delivery-food/foodoplanet-42779" },
  { from: "/listing/91-dental-solutions", to: "/listing/dental/91-dental-solutions-42802" },
  { from: "/listing/lightning-it-solution-2", to: "/listing/digital-marketing/lightning-it-solution-42816" },
  { from: "/listing/prosource-dynamics", to: "/listing/digital-marketing/prosource-dynamics-42859" },
  { from: "/listing/superior-university", to: "/listing/universities/superior-university-42870" },
  { from: "/listing/apksoft-pvt-ltd", to: "/listing/software-company/apksoft-(pvt.)-ltd.-42874" },
  { from: "/listing/trans-cast-media", to: "/listing/digital-marketing/trans-cast-media-42878" },
  { from: "/listing/bachon-ki-dunya-online-toys", to: "/listing/general-services/bachon-ki-dunya-online-toys-42881" },
  { from: "/listing/more-creative-pakistan-design-advertising-agency-lahore", to: "/listing/advertisers/more-creative-pakistan-|-design-&amp;-advertising-agency-lahore-42886" },
  { from: "/listing/tamim-digital-website-designing-development-company", to: "/listing/website-development/tamim-digital---website-designing-&amp;-development-company-42889" },
  { from: "/listing/fire-alarm-system-price-in-pakistan", to: "/listing/general-services/fire-alarm-system-price-in-pakistan-42891" },
  { from: "/listing/umair-seo-expert-in-lahore-seo-consultant-seo-company-2", to: "/listing/website-development/umair-seo-expert-in-lahore---seo-consultant---seo-company-42897" },
  { from: "/listing/dhl-4", to: "/listing/advertisers/dhl-42899" },
  { from: "/listing/wp-experts", to: "/listing/website-development/wp-experts-42905" },
  { from: "/listing/afflec", to: "/listing/electronics/afflec-42907" },
  { from: "/listing/autostore-pk", to: "/listing/car-dealer/autostore.pk-42912" },
  { from: "/listing/best-inam-girls-hostel-in-lahore", to: "/listing/advertisers/best-inam-girls-hostel-in-lahore-42917" },
  { from: "/listing/one-eleven-group-marketing-and-development", to: "/listing/property-development/one-eleven-group-marketing-and-development-42919" },
  { from: "/listing/immigration-lawyer-in-pakistan", to: "/listing/lawyers/immigration-lawyer-in-pakistan-42923" },
  { from: "/listing/new-metro-city", to: "/listing/property-development/new-metro-city-42936" },
  { from: "/listing/metron-international", to: "/listing/construction-services/metron-international-42942" },
  { from: "/listing/packers-movers-international-pmi", to: "/listing/general-services/packers-&amp;-movers-international-(pmi)-42954" },
  { from: "/listing/the-cancun-shuttle", to: "/listing/travel-agents/the-cancun-shuttle-42959" },
  { from: "/listing/digital-defenders", to: "/listing/advertisers/digital-defenders-42964" },
  { from: "/listing/shahi-wear", to: "/listing/shopping-malls/shahi-wear-42967" },
  { from: "/listing/the-civil-engineering", to: "/listing/construction-services/the-civil-engineering-42970" },
  { from: "/listing/dupas-2", to: "/listing/advertisers/dupas-42979" },
  { from: "/listing/park-view-city-payment-plan", to: "/listing/property-for-rent/park-view-city-payment-plan-42986" },
  { from: "/listing/kasur-hearing-center-pvt-ltd", to: "/listing/doctors-and-clinics/kasur-hearing-center-pvt.ltd-42994" },
  { from: "/listing/xtremes-marketing", to: "/listing/property-for-rent/xtremes-marketing-43002" },
  { from: "/listing/lavish-home-decors", to: "/listing/architecture/lavish-home-decors-43003" },
  { from: "/listing/tech-vision", to: "/listing/digital-marketing/tech-vision-43008" },
  { from: "/listing/cozmetica", to: "/listing/advertisers/cozmetica-43009" },
  { from: "/listing/rijjas-collection", to: "/listing/shopping-malls/rijjas-collection-43014" },
  { from: "/listing/yarsons-international", to: "/listing/dental/yarsons-international-43025" },
  { from: "/listing/skin-whitening-injections-in-lahore", to: "/listing/doctors-and-clinics/skin-whitening-injections-in-lahore-43031" },
  { from: "/listing/islamabad-physiotherapy-and-rehabilitation-centre", to: "/listing/hospitals/islamabad-physiotherapy-and-rehabilitation-centre-43036" },
  { from: "/listing/plentys-pk-trusted-online-shopping-store", to: "/listing/shopping-malls/plentys-pk-–-trusted-online-shopping-store-43037" },
  { from: "/listing/website-development-company", to: "/listing/software-company/website-development-company-43043" },
  { from: "/listing/nku-technologies", to: "/listing/digital-marketing/nku-technologies-43045" },
  { from: "/listing/sajjad-co", to: "/listing/general-services/sajjad-&amp;-co-43048" },
  { from: "/listing/checkmylock", to: "/listing/general-services/checkmylock-43049" },
  { from: "/listing/silicon-nexus", to: "/listing/software-company/silicon-nexus-43051" },
  { from: "/listing/honey-in-pakistan-pickles-murabba", to: "/listing/home-delivery-food/honey-in-pakistan-|-pickles-|-murabba.-43057" },
  { from: "/listing/avocado", to: "/listing/designer/avocado-43063" },
  { from: "/listing/logoking", to: "/listing/designer/logoking-43069" },
  { from: "/listing/mn-kids-store", to: "/listing/shopping-malls/mn-kids-store-43072" },
  { from: "/listing/kaptain", to: "/listing/car-rental/kaptain-43080" },
  { from: "/listing/babytoys-pk", to: "/listing/shopping-malls/babytoys.pk-43083" },
  { from: "/listing/rent-a-car-pd", to: "/listing/car-rental/rent-a-car-pd-43088" },
  { from: "/listing/zameen-crm", to: "/listing/advertisers/zameen-crm-43090" },
  { from: "/listing/smart-advertisement", to: "/listing/advertisers/smart-advertisement-43100" },
  { from: "/listing/edupride-language-centre", to: "/listing/tutoring/edupride-language-centre-43103" },
  { from: "/listing/web-development-company-in-lahore-2", to: "/listing/website-development/web-development-company-in-lahore-43108" },
  { from: "/listing/asn-systems-fire-alarm-system-fire-alarm-in-karachi", to: "/listing/security-guard/asn-systems-|-fire-alarm-system-|-fire-alarm-in-karachi-43110" },
  { from: "/listing/jagah-online", to: "/listing/property-development/jagah-online-43113" },
  { from: "/listing/ask-wakeel-pk", to: "/listing/lawyers/ask-wakeel-.-pk-43118" },
  { from: "/listing/roofing-service-in-lahore", to: "/listing/advertisers/roofing-service-in-lahore-43120" },
  { from: "/listing/serene-event-complex-2", to: "/listing/event-planner/serene-event-complex-43126" },
  { from: "/listing/neon-chemicals", to: "/listing/hospitals/neon-chemicals-43144" },
  { from: "/listing/skin-deep-international", to: "/listing/doctors-and-clinics/skin-deep-international-43149" },
  { from: "/listing/air-technologies", to: "/listing/software-company/air-technologies-43154" },
  { from: "/listing/quellxcode-pvt-ltd", to: "/listing/software-company/quellxcode-pvt-ltd-43157" },
  { from: "/listing/bhatti-printing-press-rawalpindi-islamabad", to: "/listing/printing/bhatti-printing-press-rawalpindi-islamabad-43159" },
  { from: "/listing/kayazar-online-e-commerce-store", to: "/listing/shopping-malls/kayazar---online-e-commerce-store-43162" },
  { from: "/listing/sk-stones-pvt-ltd", to: "/listing/construction-services/sk-stones-pvt.-ltd.-43168" },
  { from: "/listing/bamboo-union", to: "/listing/restaurants/bamboo-union-43181" },
  { from: "/listing/imaginedge-creatives", to: "/listing/software-company/imaginedge-creatives-43183" },
  { from: "/listing/ids-institute-of-digital-skills", to: "/listing/colleges/ids---institute-of-digital-skills-43193" },
  { from: "/listing/uk-visa-consultants", to: "/listing/visa-agencies/uk-visa-consultants-43204" },
  { from: "/listing/schola-nova-2", to: "/listing/driving-schools/schola-nova-43207" },
  { from: "/listing/legal-advice-in-pakistan", to: "/listing/lawyers/legal-advice-in-pakistan-43221" },
  { from: "/listing/adwiser-advertising", to: "/listing/advertisers/adwiser-advertising-43228" },
  { from: "/listing/adview", to: "/listing/advertisers/adview-43233" },
  { from: "/listing/aga-khan-university-hospital-medical-centre-jail-road-lahore", to: "/listing/hospitals/aga-khan-university-hospital-medical-centre-jail-road-lahore-43243" },
  { from: "/listing/dr-husnain-aesthetic-clinic", to: "/listing/doctors-and-clinics/dr-husnain-aesthetic-clinic-43247" },
  { from: "/listing/belco-chocolates", to: "/listing/home-delivery-food/belco-chocolates-43251" },
  { from: "/listing/texholab", to: "/listing/digital-marketing/texholab-43256" },
  { from: "/listing/website-designing-in-lahore", to: "/listing/designer/website-designing-in-lahore-43263" },
  { from: "/listing/fazal-sons-electronics", to: "/listing/electronics/fazal-sons-electronics-43266" },
  { from: "/listing/kamalia-khaddar", to: "/listing/advertisers/kamalia-khaddar-43271" },
  { from: "/listing/international-gloves-pvt-ltd", to: "/listing/welding-services/international-gloves-(pvt)-ltd.-43272" },
  { from: "/listing/medicalbookstore-pk", to: "/listing/book-stores/medicalbookstore.pk-43277" },
  { from: "/listing/one-soq", to: "/listing/general-services/one-soq-43280" },
  { from: "/listing/cancun-airport-shuttle", to: "/listing/pickups-and-trucks/cancun-airport-shuttle-43295" },
  { from: "/listing/hinz-pakistans-best-knitwear-brand", to: "/listing/shopping-malls/hinz---pakistan's-best-knitwear-brand-43302" },
  { from: "/listing/plumbing-service-in-karachi", to: "/listing/plumbers/plumbing-service-in-karachi-43307" },
  { from: "/listing/fatima-travels", to: "/listing/travel-agents/fatima-travels-43309" },
  { from: "/listing/koh-e-noor-logistics", to: "/listing/general-services/koh-e-noor-logistics-43311" },
  { from: "/listing/asma-lawyers-in-pakistan", to: "/listing/lawyers/asma-lawyers-in-pakistan-43317" },
  { from: "/listing/shopsmart", to: "/listing/general-services/shopsmart-43328" },
  { from: "/listing/dfc-tank-pressure-vessels-manufacturer-co-ltd", to: "/listing/construction-services/dfc-tank-pressure-vessels-manufacturer-co.,-ltd-43333" },
  { from: "/listing/pixelpk-software-development-company", to: "/listing/software-company/pixelpk-software-development-company-43336" },
  { from: "/listing/dnw-diaper-production-line-manufacturer-co-ltd", to: "/listing/electronics/dnw-diaper-production-line-manufacturer-co.,-ltd-43344" },
  { from: "/listing/ajmal-dawakhana-herbal-mediicnes", to: "/listing/general-services/ajmal-dawakhana---herbal-mediicnes-43348" },
  { from: "/listing/amazon-products-in-pakistan-amazon-shopping-waoomart", to: "/listing/general-services/amazon-products-in-pakistan---amazon-shopping-|-waoomart-43354" },
  { from: "/listing/online-shopping-bazaar", to: "/listing/shopping-malls/online-shopping-bazaar-43356" },
  { from: "/listing/home-tuitions-in-lahore", to: "/listing/tutoring/home-tuitions-in-lahore-43362" },
  { from: "/listing/waseelah-pk", to: "/listing/job-providers/waseelah.pk-43382" },
  { from: "/listing/shifa-regenerative-and-rejuvenation-clinic", to: "/listing/doctors-and-clinics/shifa-regenerative-and-rejuvenation-clinic-43387" },
  { from: "/listing/islamabad-physiotherapy-and-rehabilitation-centre-2", to: "/listing/hospitals/islamabad-physiotherapy-and-rehabilitation-centre-43398" },
  { from: "/listing/westpoint-pakistan", to: "/listing/general-services/westpoint-pakistan-43401" },
  { from: "/listing/roof-waterproofing-heat-proofing-water-tank-bathroom-leakage", to: "/listing/waterproofing-company/roof-waterproofing-heat-proofing-water-tank-bathroom-leakage-43411" },
  { from: "/listing/physio-meds-physiotherapist-near-me-in-lahore", to: "/listing/doctors-and-clinics/physio-meds---physiotherapist-near-me-in-lahore-43416" },
  { from: "/listing/premier-institute", to: "/listing/general-services/premier-institute-43429" },
  { from: "/listing/gexton-security-2", to: "/listing/security-guard/gexton-security-43432" },
  { from: "/listing/online-electonic-shopping-store-in-pakistan", to: "/listing/electronics/online-electonic-shopping-store-in-pakistan-43444" },
  { from: "/listing/softa-solutions_-softasol", to: "/listing/software-company/softa-solutions_-(softasol)-43445" },
  { from: "/listing/islamic-insurance-takaful-with-state-life-insurance-isl", to: "/listing/general-services/islamic-insurance,-(-takaful)-with-state-life-insurance,-isl-43452" },
  { from: "/listing/aerospace-baykee", to: "/listing/electronics/aerospace-baykee-43455" },
  { from: "/listing/1-ten-solutions", to: "/listing/website-development/1-ten-solutions-43461" },
  { from: "/listing/dawakhana-hakeem-ajmal-khan", to: "/listing/doctors-and-clinics/dawakhana-hakeem-ajmal-khan-43464" },
  { from: "/listing/army-dog-center", to: "/listing/security-guard/army-dog-center-43474" },
  { from: "/listing/khussa", to: "/listing/shopping-malls/khussa-43478" },
  { from: "/listing/newon", to: "/listing/advertisers/newon-43487" },
  { from: "/listing/shams-power", to: "/listing/general-services/shams-power-43490" },
  { from: "/listing/viper", to: "/listing/digital-marketing/viper-43494" },
  { from: "/listing/leading-real-estate-consultant-in-karachi-fr-associate", to: "/listing/property-development/leading-real-estate-&amp;-consultant-in-karachi---fr-associate-43499" },
  { from: "/listing/the-upcut", to: "/listing/general-services/the-upcut-43502" },
  { from: "/listing/r3-stem-cell-pakistan", to: "/listing/doctors-and-clinics/r3-stem-cell-pakistan-43505" },
  { from: "/listing/organico", to: "/listing/general-services/organico-43516" },
  { from: "/listing/pest-control-services-in-lahore-2", to: "/listing/pest-control/pest-control-services-in-lahore-43522" },
  { from: "/listing/afghan-carpet", to: "/listing/construction-services/afghan-carpet-43528" },
  { from: "/listing/toppla-portable-toilet-co-ltd", to: "/listing/architecture/toppla-portable-toilet-co.,-ltd-43536" },
  { from: "/listing/toppla-abs-hedp-plastic-locker-manufacturer-co-ltd", to: "/listing/architecture/toppla-abs-hedp-plastic-locker-manufacturer-co.,ltd-43540" },
  { from: "/listing/medel-cochlear-implant-cost-in-pakistan", to: "/listing/doctors-and-clinics/medel-cochlear-implant-cost-in-pakistan-43546" },
  { from: "/listing/sharetrade-artificial-plant-manufacturer-co-ltd", to: "/listing/shopping-malls/sharetrade-artificial-plant-manufacturer-co.,-ltd-43555" },
  { from: "/listing/mukhtar-a-sheikh-is-the-leading-hospital-in-pakistan", to: "/listing/hospitals/mukhtar-a.-sheikh-is-the-leading-hospital-in-pakistan-43562" },
  { from: "/listing/riphah-international-colleges", to: "/listing/colleges/riphah-international-colleges-43568" },
  { from: "/listing/digika-solutions", to: "/listing/digital-marketing/digika-solutions-43572" },
  { from: "/listing/yeasincere-vacuum-sealer-manufacturer-co-ltd", to: "/listing/electronics/yeasincere-vacuum-sealer-manufacturer-co-ltd-43582" },
  { from: "/listing/genius-institute-lahore-best-ielts-preparation-institute", to: "/listing/tutoring/genius-institute-lahore-|-best-ielts-preparation-institute-43586" },
  { from: "/listing/jiayu-sunglasses-manufacturer-co-ltd", to: "/listing/shopping-malls/jiayu-sunglasses-manufacturer-co.,-ltd-43593" },
  { from: "/listing/haroon-advertising", to: "/listing/advertisers/haroon-advertising-43598" },
  { from: "/listing/property-wise", to: "/listing/property-development/property-wise-43603" },
  { from: "/listing/print-shop", to: "/listing/designer/print-shop-43614" },
  { from: "/listing/numan-fabrics", to: "/listing/shopping-malls/numan-fabrics-43621" },
  { from: "/listing/qazi-investments", to: "/listing/property-development/qazi-investments-43631" },
  { from: "/listing/news", to: "/listing/advertisers/news-43635" },
  { from: "/listing/nevexo-technologies", to: "/listing/software-company/nevexo-technologies-43638" },
  { from: "/listing/belissa-event", to: "/listing/event-planner/belissa-event-43640" },
  { from: "/listing/alkaram-textile-mills", to: "/listing/architecture/alkaram-textile-mills-43645" },
  { from: "/listing/ankle-wrist-weights", to: "/listing/gym/ankle-wrist-weights-43652" },
  { from: "/listing/erp-products-and-solutions", to: "/listing/software-company/erp-products-and-solutions-43655" },
  { from: "/listing/virtual-us", to: "/listing/tailors-and-alterations/virtual-us-43660" },
  { from: "/listing/massage-center-lahore", to: "/listing/salons/massage-center-lahore-43666" },
  { from: "/listing/harjaga-com-real-estate-investment-opportunities", to: "/listing/construction-services/harjaga.com-|-real-estate-investment-opportunities.-43674" },
  { from: "/listing/rank-karachi", to: "/listing/digital-marketing/rank-karachi-43676" },
  { from: "/listing/edhi-welfare-center-in-the-us-provides-donation-relief-fun", to: "/listing/advertisers/edhi-welfare-center-in-the-us-provides-donation-&amp;-relief-fun-43681" },
  { from: "/listing/leyjao-pk", to: "/listing/shopping-malls/leyjao.pk-43684" },
  { from: "/listing/kitab-nagri-pk", to: "/listing/book-stores/kitab-nagri-pk-43688" },
  { from: "/listing/ahmed-medico-ahmedmedico", to: "/listing/hospitals/ahmed-medico-|-ahmedmedico-43697" },
  { from: "/listing/sk-stones", to: "/listing/shopping-malls/sk-stones-43704" },
  { from: "/listing/aligners", to: "/listing/dental/aligners-43707" },
  { from: "/listing/web-development-in-pakistan", to: "/listing/website-development/web-development-in-pakistan-43712" },
  { from: "/listing/alkaram-estate", to: "/listing/property-development/alkaram-estate-43714" },
  { from: "/listing/zewar-khan", to: "/listing/jewelers/zewar-khan-43718" },
  { from: "/listing/pp-packing-strap", to: "/listing/pickups-and-trucks/pp-packing-strap-43721" },
  { from: "/listing/tknack-digital-marketing-agency", to: "/listing/digital-marketing/tknack-digital-marketing-agency-43726" },
  { from: "/listing/we-grow-your-business-all-over-the-world", to: "/listing/software-company/we-grow-your-business-all-over-the-world-43728" },
  { from: "/listing/amazon-training-institute", to: "/listing/digital-marketing/amazon-training-institute-43737" },
  { from: "/listing/essaysnassignments", to: "/listing/general-services/essaysnassignments-43747" },
  { from: "/listing/nabeel-termite-control", to: "/listing/pest-control/nabeel-termite-control-43757" },
  { from: "/listing/printing-ink", to: "/listing/printing/printing-ink-43761" },
  { from: "/listing/faisal-associates-2", to: "/listing/architecture/faisal-associates-43766" },
  { from: "/listing/tameerkaro", to: "/listing/construction-services/tameerkaro-43770" },
  { from: "/listing/1clickwebs-seo-services-in-pakistan", to: "/listing/digital-marketing/1clickwebs-seo-services-in-pakistan-43793" },
  { from: "/listing/vitamin-deck", to: "/listing/general-services/vitamin-deck-43798" },
  { from: "/listing/digital-solutions-for-your-business-growth", to: "/listing/software-company/digital-solutions-for-your-business-growth-43805" },
  { from: "/listing/lahore-42-news-2", to: "/listing/advertisers/lahore-42-news-43812" },
  { from: "/listing/international-digital-marketing-company-digital-diva", to: "/listing/digital-marketing/international-digital-marketing-company-|-digital-diva-43817" },
  { from: "/listing/awi-collection", to: "/listing/shopping-malls/awi-collection-43820" },
  { from: "/listing/auraq-publications", to: "/listing/printing/auraq-publications-43823" },
  { from: "/listing/plots-available-on-installments", to: "/listing/property-development/plots-available-on-installments-43826" },
  { from: "/listing/best-diagnostic-centre-medical-centre-in-pakistan", to: "/listing/hospitals/best-diagnostic-centre-&amp;-medical-centre-in-pakistan-43829" },
  { from: "/listing/real-estate-agency-agent-provide-house-and-plot-for-sale", to: "/listing/property-development/real-estate-agency-&amp;-agent-provide-house-and-plot-for-sale-43839" },
  { from: "/listing/household-products", to: "/listing/general-services/household-products-43844" },
  { from: "/listing/estate-land-marketing", to: "/listing/property-development/estate-land-marketing-43847" },
  { from: "/listing/furniturezonepk-store", to: '/listing/shopping-malls/"furniturezonepk-store-"-43850' },
  { from: "/listing/home-tutor-in-karachi-3", to: "/listing/tutoring/home-tutor-in-karachi-43852" },
  { from: "/listing/garments-manufacturers", to: "/listing/general-services/garments-manufacturers-43856" },
  { from: "/listing/best-led-tv-in-pakistan-multynet", to: "/listing/shopping-malls/best-led-tv-in-pakistan-|-multynet-43863" },
  { from: "/listing/dikratv", to: "/listing/general-services/dikratv-43869" },
  { from: "/listing/emirates-tshirts", to: "/listing/advertisers/emirates-tshirts-43872" },
  { from: "/listing/chaaye-khana-centaurus", to: "/listing/restaurants/chaaye-khana-centaurus-43916" },
  { from: "/listing/british-haj-travel-limited", to: "/listing/travel-agents/british-haj-travel-limited-43917" },
  { from: "/listing/the-corporate-international", to: "/listing/lawyers/the-corporate-international-43926" },
  { from: "/listing/quality-wood-work", to: "/listing/carpenter/quality-wood-work-43930" },
  { from: "/listing/facial-kit", to: "/listing/beauty-parlour/facial-kit-43936" },
  { from: "/listing/elixir-hair-transplant-center-islamabad", to: "/listing/doctors-and-clinics/elixir-hair-transplant-center-islamabad-43939" },
  { from: "/listing/house-of-machines", to: "/listing/construction-services/house-of-machines-43949" },
  { from: "/listing/construction-renovation-company-islamabad", to: "/listing/general-services/construction-&amp;-renovation-company-islamabad-43956" },
  { from: "/listing/real-estate-3", to: "/listing/property-development/real-estate-43961" },
  { from: "/listing/meridian-tech-digital-marketing-agency-pakistan", to: "/listing/digital-marketing/meridian-tech---digital-marketing-agency-pakistan-43963" },
  { from: "/listing/pioneer-techniques", to: "/listing/architecture/pioneer-techniques-43976" },
  { from: "/listing/legal-law-firm-advocates-and-legal-consultations", to: "/listing/lawyers/legal-law-firm-advocates-and-legal-consultations-43980" },
  { from: "/listing/rays", to: "/listing/electronics/rays-43989" },
  { from: "/listing/broadway-pizza", to: "/listing/restaurants/broadway-pizza-43992" },
  { from: "/listing/online-store-for-home-textile-products-apparel", to: "/listing/shopping-malls/online-store-for-home-textile-products-&amp;-apparel-43997" },
  { from: "/listing/gull-e-rukh", to: "/listing/designer/gull-e-rukh-44001" },
  { from: "/listing/web-development-services", to: "/listing/digital-marketing/web-development-services-44006" },
  { from: "/listing/multiple-beast", to: "/listing/advertisers/multiple-beast-44010" },
  { from: "/listing/coloc-work", to: "/listing/libraries/coloc.work-44014" },
  { from: "/listing/claws-printers", to: "/listing/printing/claws-printers-44019" },
  { from: "/listing/yopals-com-best-restaurants-in-lahore-best-dinner-restau", to: "/listing/hotels/yopals.com-|-best-restaurants-in-lahore-|-best-dinner-restau-44033" },
  { from: "/listing/alita-accessories", to: "/listing/jewelers/alita-accessories-44037" },
  { from: "/listing/infinity-learning-space", to: "/listing/driving-schools/infinity-learning-space-44042" },
  { from: "/listing/laraibnow-2", to: "/listing/electronics/laraibnow-44046" },
  { from: "/listing/zebra-marketing", to: "/listing/property-development/zebra-marketing-44051" },
  { from: "/listing/homes-pakistan-buy-sell-rent-homes-properties", to: "/listing/property-development/homes-pakistan-|-buy-sell-rent-homes-&amp;-properties-44057" },
  { from: "/listing/buy-best-mascara-online-in-pakistan-from-theskinfit-com", to: "/listing/shopping-malls/buy-best-mascara-online-in-pakistan-from-theskinfit.com-44069" },
  { from: "/listing/alhadiassociates", to: "/listing/property-development/alhadiassociates-44072" },
  { from: "/listing/zayrex-enterprises", to: "/listing/shopping-malls/zayrex-enterprises-44076" },
  { from: "/listing/digital-training-it-training-institute-in-lahore", to: "/listing/driving-schools/digital-training---it-training-institute-in-lahore-44084" },
  { from: "/listing/bitsoftsol", to: "/listing/website-development/bitsoftsol-44086" },
  { from: "/listing/trekker-club-best-honeymoon-packages", to: "/listing/travel-agents/trekker-club-best-honeymoon-packages-44091" },
  { from: "/listing/food-force-free-restaurant-website-builder", to: "/listing/restaurants/food-force-free-restaurant-website-builder-44097" },
  { from: "/listing/xtremes-marketing-2", to: "/listing/property-for-rent/xtremes-marketing-44104" },
  { from: "/listing/ammarforte-real-estate-marketing-company", to: "/listing/general-services/ammarforte-real-estate-marketing-company-44107" },
  { from: "/listing/construction-company-islamabad-2", to: "/listing/construction-services/construction-company-islamabad-44114" },
  { from: "/listing/costa-logistics-packers-and-movers-in-lahore-pakistan", to: "/listing/general-services/costa-logistics-packers-and-movers-in-lahore-pakistan-44120" },
  { from: "/listing/awsol", to: "/listing/digital-marketing/awsol-44122" },
  { from: "/listing/ideators-us", to: "/listing/advertisers/ideators-us-44127" },
  { from: "/listing/global-travel-solutions", to: "/listing/travel-agents/global-travel-solutions-44129" },
  { from: "/listing/maftta", to: "/listing/general-services/maftta-44132" },
  { from: "/listing/protech-solutions", to: "/listing/digital-marketing/protech-solutions-44136" },
  { from: "/listing/beautyfly", to: "/listing/beauty-parlour/beautyfly-44139" },
  { from: "/listing/jewel-studio", to: "/listing/jewelers/jewel-studio-44144" },
  { from: "/listing/karachi-fire-corporation", to: "/listing/general-services/karachi-fire-corporation-44148" },
  { from: "/listing/petvet-pets-for-sale-in-lahore", to: "/listing/animals-&amp;-birds/petvet---pets-for-sale-in-lahore-44161" },
  { from: "/listing/xingyun-group-pakistan", to: "/listing/general-services/xingyun-group-pakistan-44168" },
  { from: "/listing/cooper-canvas", to: "/listing/general-services/cooper-canvas-44175" },
  { from: "/listing/abaya-online-shop", to: "/listing/designer/abaya-online-shop-44181" },
  { from: "/listing/seo-cottage", to: "/listing/digital-marketing/seo-cottage-44186" },
  { from: "/listing/wovenlotus", to: "/listing/architecture/wovenlotus-44189" },
  { from: "/listing/royalmarriagepk-com", to: "/listing/marriage-beuro/royalmarriagepk.com-44193" },
  { from: "/listing/rent-a-car-islamabad", to: "/listing/car-rental/rent-a-car-islamabad-44195" },
  { from: "/listing/longboardsgeek", to: "/listing/general-services/longboardsgeek-44201" },
  { from: "/listing/womens-ngo", to: "/listing/hospitals/women's-ngo-44203" },
  { from: "/listing/cheryls-company", to: "/listing/beauty-parlour/cheryls-company-44205" },
  { from: "/listing/garment-geeks", to: "/listing/shopping-malls/garment-geeks-44217" },
  { from: "/listing/chemical-industries-in-lahore", to: "/listing/general-services/chemical-industries-in-lahore-44225" },
  { from: "/listing/the-wing-co-working-space-in-dha-2-islamabad-rawalpindi", to: "/listing/general-services/the-wing---co-working-space-in-dha-2-islamabad-&amp;-rawalpindi-44232" },
  { from: "/listing/edceum-abroad-study-visa-consultant-in-lahore", to: "/listing/visa-agencies/edceum--abroad-study-visa-consultant-in-lahore-44235" },
  { from: "/listing/oht-shop", to: "/listing/general-services/oht-shop-44239" },
  { from: "/listing/mena-legal", to: "/listing/general-services/mena-legal-44243" },
  { from: "/listing/best-veterinary-instruments", to: "/listing/hospitals/best-veterinary-instruments-44247" },
  { from: "/listing/uksofaworld", to: "/listing/designer/uksofaworld-44251" },
  { from: "/listing/laser-inn", to: "/listing/doctors-and-clinics/laser-inn-44253" },
  { from: "/listing/frontpay", to: "/listing/general-services/frontpay-44262" },
  { from: "/listing/ready-to-wear", to: "/listing/jewelers/ready-to-wear-44273" },
  { from: "/listing/nevi-home-turkish-furniture", to: "/listing/advertisers/nevi-home-turkish-furniture-44279" },
  { from: "/listing/the-soorat", to: "/listing/beauty-parlour/the-soorat-44283" },
  { from: "/listing/doctor-clour-nippon-paint-franchise", to: "/listing/advertisers/doctor-clour-nippon-paint-franchise-44284" },
  { from: "/listing/lead-academy", to: "/listing/driving-schools/lead-academy-44291" },
  { from: "/listing/mavens-and-co", to: "/listing/lawyers/mavens-and-co.-44300" },
  { from: "/listing/kla-pakistan", to: "/listing/lawyers/kla-pakistan-44307" },
  { from: "/listing/optimum-technology", to: "/listing/electronics/optimum-technology-44311" },
  { from: "/listing/obi-decor", to: "/listing/designer/obi-decor-44314" },
  { from: "/listing/operations-pro-pvt-ltd", to: "/listing/digital-marketing/operations-pro-(pvt)-ltd-44321" },
  { from: "/listing/accountants-in-islamabad-accotech", to: "/listing/general-services/accountants-in-islamabad---accotech-44324" },
  { from: "/listing/fakhri-brothers", to: "/listing/ac-repair-services/fakhri-brothers-44326" },
  { from: "/listing/glam-house-by-arooj-and-annie", to: "/listing/beauty-parlour/glam-house-by-arooj-and-annie-44331" },
  { from: "/listing/monzoro", to: "/listing/shopping-malls/monzoro-44334" },
  { from: "/listing/harjaga", to: "/listing/architecture/harjaga-44337" },
  { from: "/listing/online-shopping-in-pakistan-3", to: "/listing/shopping-malls/online-shopping-in-pakistan-44341" },
  { from: "/listing/lahore-smart-city-2", to: "/listing/digital-marketing/lahore-smart-city-44345" },
  { from: "/listing/arwaj-solutions-pvt-ltd", to: "/listing/software-company/arwaj-solutions-(pvt.)-ltd.-44353" },
  { from: "/listing/mayola-pakistan", to: "/listing/general-services/mayola-pakistan-44359" },
  { from: "/listing/head-massage-in-lahore", to: "/listing/salons/head-massage-in-lahore-44365" },
  { from: "/listing/best-urdu-books", to: "/listing/book-stores/best-urdu-books-44367" },
  { from: "/listing/biztrack-business-setup-in-dubai", to: "/listing/general-services/biztrack-business-setup-in-dubai-44372" },
  { from: "/listing/pakistan-seo-services", to: "/listing/digital-marketing/pakistan-seo-services-44383" },
  { from: "/listing/tutorbees-net", to: "/listing/colleges/tutorbees.net-44389" },
  { from: "/listing/shandaarbuy", to: "/listing/electronics/shandaarbuy-44397" },
  { from: "/listing/salim-habib-university-shu", to: "/listing/universities/salim-habib-university-(shu)-44399" },
  { from: "/listing/real-estate-license-in-dubai-aa-associate-llc", to: "/listing/general-services/real-estate-license-in-dubai-a&amp;a-associate-llc-44402" },
  { from: "/listing/nebosh-in-pakistan", to: "/listing/digital-marketing/nebosh-in-pakistan-44405" },
  { from: "/listing/rangoon-the-bags-hub", to: "/listing/shopping-malls/rangoon---the-bags-hub-44423" },
  { from: "/listing/creativeon", to: "/listing/software-company/creativeon-44427" },
  { from: "/listing/noah-builders-nyc-general-contractor-nyc", to: "/listing/construction-services/noah-builders-nyc-general-contractor-nyc-44429" },
  { from: "/listing/covid-disinfection-services-islamabad", to: "/listing/pest-control/covid-disinfection-services-islamabad-44434" },
  { from: "/listing/online-shopping-store-in-pakistan-belive", to: "/listing/shopping-malls/online-shopping-store-in-pakistan---belive-44435" },
  { from: "/listing/linbrook-school", to: "/listing/universities/linbrook-school-44440" },
  { from: "/listing/shopier-online-shopping-pakistan", to: "/listing/shopping-malls/shopier---online-shopping-pakistan-44445" },
  { from: "/listing/business-insider", to: "/listing/printing/business-insider-44447" },
  { from: "/listing/tutor-cycle-home-tutor-in-karachi-home-tuition-karachi", to: "/listing/tutoring/tutor-cycle-|-home-tutor-in-karachi-|-home-tuition-karachi.-44451" },
  { from: "/listing/best-mall-in-lahore-safest-place-to-visit-packages-mall", to: "/listing/shopping-malls/best-mall-in-lahore,-safest-place-to-visit-–-packages-mall-44457" },
  { from: "/listing/quran-for-kids", to: "/listing/universities/quran-for-kids-44465" },
  { from: "/listing/shoppersstop-pk", to: "/listing/shopping-malls/shoppersstop.pk-44468" },
  { from: "/listing/easy-rishta", to: "/listing/weddings-planner/easy-rishta-44470" },
  { from: "/listing/khaleej-times-newspaper-advertising-rates-navicoads", to: "/listing/advertisers/khaleej-times-newspaper-advertising-rates---navicoads-44481" },
  { from: "/listing/nete-bidet-seat-attachments-manufacturer-co-ltd", to: "/listing/construction-services/nete-bidet-seat-attachments-manufacturer-co.,-ltd-44486" },
  { from: "/listing/ipsbpo", to: "/listing/digital-marketing/ipsbpo-44491" },
  { from: "/listing/aligners-2", to: "/listing/general-services/aligners-44494" },
  { from: "/listing/dupas-3", to: "/listing/general-services/dupas-44502" },
  { from: "/listing/idealacny-mart", to: "/listing/general-services/idealacny-mart-44504" },
  { from: "/listing/atrule-technologies", to: "/listing/website-development/atrule-technologies-44512" },
  { from: "/listing/vapes-direct", to: "/listing/general-services/vapes-direct-44515" },
  { from: "/listing/movers-and-packers-in-palm-jumeirah", to: "/listing/travel-agents/movers-and-packers-in-palm-jumeirah-44534" },
  { from: "/listing/mystic-advertising", to: "/listing/advertisers/mystic-advertising-44541" },
  { from: "/listing/godesign-technologies", to: "/listing/advertisers/godesign-technologies-44542" },
  { from: "/listing/muzammil-center", to: "/listing/shopping-malls/muzammil-center-44548" },
  { from: "/listing/human-care-laboratory", to: "/listing/doctors-and-clinics/human-care-laboratory-44555" },
  { from: "/listing/e-store-design-development-in-pakistan", to: "/listing/website-development/e-store-design-&amp;-development-in-pakistan-44562" },
  { from: "/listing/buy-construction-building-materials-in-pakistan", to: "/listing/construction-services/buy-construction-&amp;-building-materials-in-pakistan-44572" },
  { from: "/listing/blupixel", to: "/listing/digital-marketing/blupixel-44574" },
  { from: "/listing/leather-upholstery-in-dubai", to: "/listing/software-company/leather-upholstery-in-dubai-44577" },
  { from: "/listing/lylac-by-maheen", to: "/listing/designer/lylac-by-maheen-44580" },
  { from: "/listing/gospark", to: "/listing/digital-marketing/gospark-44588" },
  { from: "/listing/eezo-home-design", to: "/listing/general-services/eezo-home-design-44605" },
  { from: "/listing/online-food-delivery", to: "/listing/restaurants/online-food-delivery-44617" },
  { from: "/listing/cancun-international-airport-transportation", to: "/listing/travel-agents/cancun-international-airport-transportation-44622" },
  { from: "/listing/ahmadrealeastate", to: "/listing/advertisers/ahmadrealeastate-44626" },
  { from: "/listing/taj-residencia-islamabad", to: "/listing/property-development/taj-residencia-islamabad-44628" },
  { from: "/listing/trading", to: "/listing/website-development/trading-44634" },
  { from: "/listing/india-bungy", to: "/listing/solar-providers/india-bungy-44637" },
  { from: "/listing/pro-business-plan-writing-services-uk-businesswritinghub", to: "/listing/general-services/pro-business-plan-writing-services-uk-|-businesswritinghub-44644" },
  { from: "/listing/limousine-car-service-in-new-york", to: "/listing/travel-agents/limousine-car-service-in-new-york-44652" },
  { from: "/listing/smart-home-pakistan", to: "/listing/designer/smart-home-pakistan-44654" },
  { from: "/listing/sweet-rack", to: "/listing/restaurants/sweet-rack-44658" },
  { from: "/listing/shopen-pk", to: "/listing/shopping-malls/shopen.pk-44660" },
  { from: "/listing/faisal-hills-islamabad", to: "/listing/property-development/faisal-hills-islamabad-44662" },
  { from: "/listing/zuria-dor", to: "/listing/general-services/zuria-dor-44666" },
  { from: "/listing/dissertationhelpzone", to: "/listing/content-writers/dissertationhelpzone-44675" },
  { from: "/listing/nku-technology", to: "/listing/mobile-app-development/nku-technology-44681" },
  { from: "/listing/silver-city-rawalpindi", to: "/listing/property-development/silver-city-rawalpindi-44686" },
  { from: "/listing/noor-sofa-maker-best-corner-sofa-in-lahore", to: "/listing/advertisers/noor-sofa-maker,-best-corner-sofa-in-lahore-44696" },
  { from: "/listing/ravi-exchange", to: "/listing/general-services/ravi-exchange-44698" },
  { from: "/listing/home-tutor-karachi-tutor-academy-in-karachi", to: "/listing/driving-schools/home-tutor-karachi-|-tutor-academy-in-karachi-44707" },
  { from: "/listing/triplefast-trading-services-pvt-ltd", to: "/listing/solar-providers/triplefast-trading-&amp;-services-pvt.-ltd.-44713" },
  { from: "/listing/psychthru", to: "/listing/doctors-and-clinics/psychthru-44722" },
  { from: "/listing/dextrosolution", to: "/listing/website-development/dextrosolution-44739" },
  { from: "/listing/bioneatgcc-provides-globally-household-cleaning", to: "/listing/architecture/bioneatgcc-provides-globally-household-cleaning-44742" },
  { from: "/listing/creative-company-profile-design-in-lahore-by-navicoads", to: "/listing/software-company/creative-company-profile-design-in-lahore-by-navicoads-44746" },
  { from: "/listing/yousuf-institute-of-english-language", to: "/listing/colleges/yousuf-institute-of-english-language-44750" },
  { from: "/listing/hrsg-2", to: "/listing/job-providers/hrsg-44760" },
  { from: "/listing/hondaringroad", to: "/listing/car-dealer/hondaringroad-44764" },
  { from: "/listing/spiritfurnitures", to: "/listing/carpenter/spiritfurnitures-44768" },
  { from: "/listing/ccdmarketing", to: "/listing/digital-marketing/ccdmarketing-44772" },
  { from: "/listing/paktranscript-medical-transcription-company", to: "/listing/general-services/paktranscript-medical-transcription-company-44775" },
  { from: "/listing/dr-sikander-jangda-dental-clinic", to: "/listing/doctors-and-clinics/dr-sikander-jangda-dental-clinic-44781" },
  { from: "/listing/cake-lounge-bakers-sweets", to: "/listing/home-delivery-food/cake-lounge---bakers-&amp;-sweets-44789" },
  { from: "/listing/movers-and-packers-in-lahore", to: "/listing/advertisers/movers-and-packers-in-lahore-44794" },
  { from: "/listing/chinese-language-linguistics-services", to: "/listing/tutoring/chinese-language-linguistics-services.-44799" },
  { from: "/listing/buy-women-handbags-online", to: "/listing/general-services/buy-women-handbags-online-44811" },
  { from: "/listing/water-treatment-services", to: "/listing/general-services/water-treatment-services-44826" },
  { from: "/listing/galaxy-movers", to: "/listing/travel-agents/galaxy-movers-44834" },
  { from: "/listing/m-a-awan-co-advocates-tax-business-consultants", to: "/listing/lawyers/m.a.-awan-&amp;-co---advocates,-tax-&amp;-business-consultants-44836" },
  { from: "/listing/hayas-creation", to: "/listing/designer/haya's-creation-44845" },
  { from: "/listing/digital-marketing-agency", to: "/listing/digital-marketing/digital-marketing-agency-44848" },
  { from: "/listing/courtmarriagekarachi", to: "/listing/lawyers/courtmarriagekarachi-44854" },
  { from: "/listing/your-pet-planet", to: "/listing/animals-&amp;-birds/your-pet-planet-44856" },
  { from: "/listing/learn-french-online", to: "/listing/driving-schools/learn-french-online-44866" },
  { from: "/listing/online-jewelleries-in-pakistan", to: "/listing/jewelers/online-jewelleries-in-pakistan-44872" },
  { from: "/listing/green-solar-solutions", to: "/listing/solar-providers/green-solar-solutions-44874" },
  { from: "/listing/rangriwaj", to: "/listing/shopping-malls/rangriwaj-44880" },
  { from: "/listing/jb-saeed-studio", to: "/listing/shopping-malls/jb-saeed-studio-44885" },
  { from: "/listing/efrotech", to: "/listing/software-company/efrotech-44898" },
  { from: "/listing/buy-pakistani-bridal-dresses", to: "/listing/shopping-malls/buy-pakistani-bridal-dresses-44900" },
  { from: "/listing/advocate-chaudhry-obaid-naseem", to: "/listing/lawyers/advocate-chaudhry-obaid-naseem-44905" },
  { from: "/listing/union-developers", to: "/listing/property-development/union-developers-44909" },
  { from: "/listing/movers-and-packers", to: "/listing/general-services/movers-and-packers-44920" },
  { from: "/listing/instacare", to: "/listing/software-company/instacare-44926" },
  { from: "/listing/web-hosting-pakistan", to: "/listing/website-development/web-hosting-pakistan-44940" },
  { from: "/listing/steel-lights-and-poles", to: "/listing/welding-services/steel-lights-and-poles-44943" },
  { from: "/listing/balajs-official", to: "/listing/general-services/balajs-official-44946" },
  { from: "/listing/thechaimpionpk", to: "/listing/property-for-rent/thechaimpionpk-44954" },
  { from: "/listing/bookssquare-org", to: "/listing/book-stores/bookssquare.org-44965" },
  { from: "/listing/pakistan-movers-packers", to: "/listing/pickups-and-trucks/pakistan-movers-&amp;-packers-44969" },
  { from: "/listing/translation-empire", to: "/listing/general-services/translation-empire-44973" },
  { from: "/listing/software-craft", to: "/listing/website-development/software-craft-44975" },
  { from: "/listing/e-commerce-online-shopping", to: "/listing/advertisers/e-commerce-online-shopping-45027" },
  { from: "/listing/glow-amd-go", to: "/listing/doctors-and-clinics/glow-amd-go-45051" },
  { from: "/listing/dental-square", to: "/listing/dental/dental-square-45053" },
  { from: "/listing/apksquard", to: "/listing/architecture/apksquard-45059" },
  { from: "/listing/ecommerce-website", to: "/listing/car-rental/ecommerce-website-45063" },
  { from: "/listing/muscon-sports", to: "/listing/gym/muscon-sports-45077" },
  { from: "/listing/jadeed-motors", to: "/listing/car-rental/jadeed-motors-45082" },
  { from: "/listing/getnowpk-2", to: "/listing/shopping-malls/getnowpk-45090" },
  { from: "/listing/thewing-coworking-space-in-islamabad-coworking-space", to: "/listing/general-services/thewing---coworking-space-in-islamabad-|-coworking-space-45092" },
  { from: "/listing/accountants-in-islamabad-accotech-2", to: "/listing/general-services/accountants-in-islamabad---accotech-45095" },
  { from: "/listing/tabani-real-esatate", to: "/listing/construction-services/tabani-real-esatate-45105" },
  { from: "/listing/order-banao", to: "/listing/electronics/order-banao-45110" },
  { from: "/listing/pins-and-pearls", to: "/listing/shopping-malls/pins-and-pearls-45116" },
  { from: "/listing/home-shifting-service-in-rawalpindi-islamabad", to: "/listing/pickups-and-trucks/home-shifting-service-in-rawalpindi-&amp;-islamabad-45122" },
  { from: "/listing/blue-world-city-islamabad-2", to: "/listing/property-development/blue-world-city-islamabad-45128" },
  { from: "/listing/digitalux-net", to: "/listing/mobile-app-development/digitalux.net-45130" },
  { from: "/listing/black-rose-color-experts", to: "/listing/beauty-parlour/black-rose-color-experts-45134" },
  { from: "/listing/tahir-law-firm", to: "/listing/lawyers/tahir-law-firm-45137" },
  { from: "/listing/legal-bridge-llp", to: "/listing/lawyers/legal-bridge-llp-45143" },
  { from: "/listing/transparent-hands", to: "/listing/hospitals/transparent-hands-45153" },
  { from: "/listing/buyoyepk", to: "/listing/digital-marketing/buyoyepk-45158" },
  { from: "/listing/artx-pro", to: "/listing/digital-marketing/artx-pro-45163" },
  { from: "/listing/mathlete", to: "/listing/driving-schools/mathlete-45167" },
  { from: "/listing/raas", to: "/listing/construction-services/raas-45176" },
  { from: "/listing/we-buy-houses-indianapolis", to: "/listing/property-development/we-buy-houses-indianapolis-45181" },
  { from: "/listing/hospitality", to: "/listing/hotels/hospitality-45185" },
  { from: "/listing/paragon-international-marketing", to: "/listing/construction-services/paragon-international-marketing-45198" },
  { from: "/listing/best-real-estate-in-bahria-town", to: "/listing/property-development/best-real-estate-in-bahria-town-45201" },
  { from: "/listing/bestar-hospitality", to: "/listing/designer/bestar-hospitality-45207" },
  { from: "/listing/roof-heatproofing-waterproofing-water-tank-bathrom-leakage", to: "/listing/waterproofing-company/roof-heatproofing-&amp;-waterproofing-water-tank-bathrom-leakage-45213" },
  { from: "/listing/taj-birds", to: "/listing/animals-&amp;-birds/taj-birds-45218" },
  { from: "/listing/crafted-furniture", to: "/listing/carpenter/crafted-furniture-45221" },
  { from: "/listing/marketing", to: "/listing/digital-marketing/marketing-45232" },
  { from: "/listing/qadri-movers-and-packers", to: "/listing/general-services/qadri-movers-and-packers-45239" },
  { from: "/listing/shaheen-photography-pics-editing-full-hd-and-graphics-design", to: "/listing/photographer/shaheen-photography-pics-editing-full-hd-and-graphics-design-45245" },
  { from: "/listing/dynamic-developers-software-house-rahim-yar-khan", to: "/listing/software-company/dynamic-developers-software-house-rahim-yar-khan-45251" },
  { from: "/listing/wise-market-pakistan", to: "/listing/electronics/wise-market-pakistan-45261" },
  { from: "/listing/matrimonial-matrimony-services-in-pakistan", to: "/listing/marriage-beuro/matrimonial-&amp;-matrimony-services-in-pakistan-45264" },
  { from: "/listing/bizcognitis", to: "/listing/verification-services/bizcognitis-45269" },
  { from: "/listing/minutemirror", to: "/listing/content-writers/minutemirror-45275" },
  { from: "/listing/abeer-travel-and-tours", to: "/listing/travel-agents/abeer-travel-and-tours-45278" },
  { from: "/listing/etsyonlineshop", to: "/listing/general-services/etsyonlineshop-45281" },
  { from: "/listing/ulearn-online-education", to: "/listing/colleges/ulearn---online-education-45289" },
  { from: "/listing/leading-digital-marketing-and-web-development-agency-in-uk", to: "/listing/digital-marketing/leading-digital-marketing-and-web-development-agency-in-uk-45290" },
  { from: "/listing/hi-tech-drivers-education", to: "/listing/driving-schools/hi-tech-drivers-education-45294" },
  { from: "/listing/ezhire-rent-a-car-company", to: "/listing/car-rental/ezhire---rent-a-car-company-45299" },
  { from: "/listing/advance-diagnostic-centre-faisalabad-branch", to: "/listing/doctors-and-clinics/advance-diagnostic-centre-faisalabad-branch-45308" },
  { from: "/listing/jewella", to: "/listing/jewelers/jewella-45318" },
  { from: "/listing/marks", to: "/listing/general-services/marks-45324" },
  { from: "/listing/double-apk", to: "/listing/mobile-app-development/double-apk-45342" },
  { from: "/listing/national-logistics-cell-nlc", to: "/listing/general-services/national-logistics-cell-(nlc)-45345" },
  { from: "/listing/genuine-leather-jackets-usa", to: "/listing/tailors-and-alterations/genuine-leather-jackets-usa-45350" },
  { from: "/listing/the-nomad-digital", to: "/listing/digital-marketing/the-nomad-digital-45355" },
  { from: "/listing/drc-healthcare-pakistan", to: "/listing/general-services/drc-healthcare-pakistan-45360" },
  { from: "/listing/cw-resources", to: "/listing/universities/c&amp;w-resources-45367" },
  { from: "/listing/shah-corporation", to: "/listing/website-development/shah-corporation-45374" },
  { from: "/listing/krishna-outsourcing", to: "/listing/construction-services/krishna-outsourcing-45378" },
  { from: "/listing/we-rise-group-of-companies", to: "/listing/property-development/we-rise-group-of-companies-45381" },
  { from: "/listing/pakistan-today", to: "/listing/advertisers/pakistan-today-45382" },
  { from: "/listing/college-life", to: "/listing/driving-schools/college-life-45388" },
  { from: "/listing/shah-it-institute", to: "/listing/general-services/shah-it-institute-45391" },
  { from: "/listing/ac-services-in-karachi", to: "/listing/ac-repair-services/ac-services-in-karachi-45396" },
  { from: "/listing/cable-tray-cable-tray-accessories-alfazalengineering-com", to: "/listing/electronics/cable-tray-|-cable-tray-accessories-alfazalengineering.com-45401" },
  { from: "/listing/custom-software-development-company", to: "/listing/software-company/custom-software-development-company-45410" },
  { from: "/listing/pak-girls-hostel", to: "/listing/student-hostels/pak-girls-hostel-45413" },
  { from: "/listing/hamzaengineeringsolutions", to: "/listing/architecture/hamzaengineeringsolutions-45416" },
  { from: "/listing/ncmnh", to: "/listing/general-services/ncmnh-45421" },
  { from: "/listing/digital-marketing-institute", to: "/listing/tutoring/digital-marketing-institute-45428" },
  { from: "/listing/service-provider", to: "/listing/general-services/service-provider-45430" },
  { from: "/listing/online-jewelry-store-in-pakistan-uclick-pk", to: "/listing/jewelers/online-jewelry-store-in-pakistan-–-uclick.pk-45433" },
  { from: "/listing/fire-safety-trading-pvt-ltd", to: "/listing/general-services/fire-safety-trading-(pvt)-ltd-45441" },
  { from: "/listing/go4shine-cosmetics", to: "/listing/beauty-parlour/go4shine-cosmetics-45449" },
  { from: "/listing/misaf-energy", to: "/listing/solar-providers/misaf-energy-45451" },
  { from: "/listing/thebullpen", to: "/listing/advertisers/thebullpen-45464" },
  { from: "/listing/tecnsol", to: "/listing/software-company/tecnsol-45469" },
  { from: "/listing/seo-company-lahore-umair-seo-expert", to: "/listing/digital-marketing/seo-company-lahore-|-umair-seo-expert-45475" },
  { from: "/listing/courier", to: "/listing/advertisers/courier-45477" },
  { from: "/listing/azlaan-technologies", to: "/listing/software-company/azlaan-technologies-45478" },
  { from: "/listing/glowqueen-pk", to: "/listing/beauty-parlour/glowqueen.pk-45485" },
  { from: "/listing/oprim", to: "/listing/mobile-app-development/oprim-45488" },
  { from: "/listing/scentsnstories", to: "/listing/beauty-parlour/scentsnstories-45491" },
  { from: "/listing/prime-consultants-trainers", to: "/listing/general-services/prime-consultants-&amp;-trainers-45500" },
  { from: "/listing/zam-studios", to: "/listing/website-development/zam-studios-45504" },
  { from: "/listing/led-light-delta-source-bahria-town-lahore", to: "/listing/electronics/led-light---delta-source-–-bahria-town-lahore-45508" },
  { from: "/listing/saeed-traders-mian-channu", to: "/listing/farm-houses/saeed-traders,-mian-channu-45520" },
  { from: "/listing/snacks-pk", to: "/listing/home-delivery-food/snacks-pk-45525" },
  { from: "/listing/hatimi-flavors", to: "/listing/home-delivery-food/hatimi-flavors-45530" },
  { from: "/listing/teqholic", to: "/listing/digital-marketing/teqholic-45534" },
  { from: "/listing/nutrican-pakistan-premium-cat-and-dog-food", to: "/listing/animals-&amp;-birds/nutrican-pakistan-|-premium-cat-and-dog-food-45538" },
  { from: "/listing/newtechera", to: "/listing/website-development/newtechera-45543" },
  { from: "/listing/buy-online-nikah-dress-bridel-dresses-nomistudio", to: "/listing/shopping-malls/buy-online-nikah-dress-|-bridel-dresses-|-nomistudio-45550" },
  { from: "/listing/best-it-company-in-pakistan", to: "/listing/software-company/best-it-company-in-pakistan-45554" },
  { from: "/listing/topindus-decorative-labels-patches-co-ltd", to: "/listing/general-services/topindus-decorative-labels-&amp;-patches-co.,-ltd.-45563" },
  { from: "/listing/ar-heating-service", to: "/listing/plumbers/ar-heating-service-45569" },
  { from: "/listing/best-photographer-in-kaarchi", to: "/listing/photographer/best-photographer-in-kaarchi-45573" },
  { from: "/listing/mb-malik-then-now-forever", to: "/listing/general-services/mb-malik-–-then,-now-forever-45578" },
  { from: "/listing/hot-n-roll-ayesha-manzil", to: "/listing/restaurants/hot-n-roll-ayesha-manzil-45586" },
  { from: "/listing/venue-hub", to: "/listing/event-planner/venue-hub-45593" },
  { from: "/listing/lahore-smart-city-4", to: "/listing/property-development/lahore-smart-city-45599" },
  { from: "/listing/health-care-solutions", to: "/listing/hospitals/health-care-solutions-45620" },
  { from: "/listing/revival-enterprise-smc-pvt-ltd", to: "/listing/general-services/revival-enterprise-smc-(pvt)-ltd.-45624" },
  { from: "/listing/best-addiction-treatment-center-in-lahore", to: "/listing/verification-services/best-addiction-treatment-center-in-lahore-45634" },
  { from: "/listing/buy-dogs-in-lahore-petvet-pk", to: "/listing/animals-&amp;-birds/buy-dogs-in-lahore---petvet.pk-45636" },
  { from: "/listing/ethyl-3-oxo-4-phenylbutanoate-cas718-08-1", to: "/listing/advertisers/ethyl-3-oxo-4-phenylbutanoate-cas718-08-1-45641" },
  { from: "/listing/celebrity-jacket", to: "/listing/general-services/celebrity-jacket-45648" },
  { from: "/listing/hacsons", to: "/listing/general-services/hac&amp;sons-45653" },
  { from: "/listing/abbas-cloth-house", to: "/listing/tailors-and-alterations/abbas-cloth-house-45659" },
  { from: "/listing/mahboob-rizvi-law-associates", to: "/listing/lawyers/mahboob-rizvi-law-associates-45666" },
  { from: "/listing/buy-kitchen-appliances-and-home-decor-items-in-pakistan", to: "/listing/general-services/buy-kitchen-appliances-and-home-decor-items-in-pakistan-45672" },
  { from: "/listing/make-my-firm", to: "/listing/general-services/make-my-firm-45676" },
  { from: "/listing/royal-cosmetic-surgery-clinic-dubai", to: "/listing/doctors-and-clinics/royal-cosmetic-surgery-clinic-dubai-45679" },
  { from: "/listing/asan-shopping", to: "/listing/digital-marketing/asan-shopping-45682" },
  { from: "/listing/evolve", to: "/listing/general-services/evolve-45689" },
  { from: "/listing/anis-apparel", to: "/listing/tailors-and-alterations/anis-apparel-45692" },
  { from: "/listing/office-cleaning-services-orchid-company", to: "/listing/general-services/office-cleaning-services---orchid-company-45697" },
  { from: "/listing/qita-developers", to: "/listing/property-development/qita-developers-45701" },
  { from: "/listing/allahwala-engineers", to: "/listing/designer/allahwala-engineers-45704" },
  { from: "/listing/sajdhaj01", to: "/listing/beauty-parlour/sajdhaj01-45709" },
  { from: "/listing/ideoversity", to: "/listing/software-company/ideoversity-45720" },
  { from: "/listing/royal-car-rentals", to: "/listing/car-rental/royal-car-rentals-45726" },
  { from: "/listing/bharmal-system", to: "/listing/software-company/bharmal-system-45751" },
  { from: "/listing/vape-cave", to: "/listing/general-services/vape-cave-45756" },
  { from: "/listing/best-real-estate-properties-for-buy-sell-rent-and-invest", to: "/listing/property-development/best-real-estate-properties-for-buy,-sell,-rent-and-invest-45760" },
  { from: "/listing/al-nadi-al-burhani-sports-and-recreational-centre", to: "/listing/driving-schools/al-nadi-al-burhani-sports-and-recreational-centre-45765" },
  { from: "/listing/moiz-hussain-exploring-human-excellence", to: "/listing/driving-schools/moiz-hussain-exploring-human-excellence-45767" },
  { from: "/listing/cartco-online-shopping-selling-store", to: "/listing/shopping-malls/cartco--online-shopping-&amp;-selling-store-45770" },
  { from: "/listing/sapphire-properties", to: "/listing/property-development/sapphire-properties-45775" },
  { from: "/listing/best-drug-rehabilitation-center-in-karachi", to: "/listing/hospitals/best-drug-rehabilitation-center-in-karachi-45779" },
  { from: "/listing/daraz-pk", to: "/listing/shopping-malls/daraz.pk-45785" },
  { from: "/listing/faisalabad-news", to: "/listing/advertisers/faisalabad-news-45786" },
  { from: "/listing/weight-loss-surgery-pakistan", to: "/listing/hospitals/weight-loss-surgery-pakistan-45791" },
  { from: "/listing/midwifery-association-of-pakistan", to: "/listing/hospitals/midwifery-association-of-pakistan-45793" },
  { from: "/listing/weijier-international-trade-hebei-co-ltd", to: "/listing/advertisers/weijier-international-trade-(hebei)-co.,-ltd-45794" },
  { from: "/listing/courtmarriages", to: "/listing/verification-services/courtmarriages-45796" },
  { from: "/listing/helen-o-grady-academy-pakistan", to: "/listing/driving-schools/helen-o'-grady-academy-pakistan-45799" },
  { from: "/listing/saifee-engineering-and-contracting-services", to: "/listing/construction-services/saifee-engineering-and-contracting-services-45802" },
  { from: "/listing/court-marriage-lawyers", to: "/listing/lawyers/court-marriage-lawyers-45811" },
  { from: "/listing/chhaaon", to: "/listing/general-services/chhaaon-45816" },
  { from: "/listing/pines-marketing", to: "/listing/property-development/pines-marketing-45817" },
  { from: "/listing/amafhh-paper-and-board-works", to: "/listing/general-services/amafhh-paper-and-board-works-45821" },
  { from: "/listing/aquafin", to: "/listing/waterproofing-company/aquafin-45826" },
  { from: "/listing/galaxy-movers-2", to: "/listing/pickups-and-trucks/galaxy-movers-45829" },
  { from: "/listing/short-courses-in-lahore-2", to: "/listing/advertisers/short-courses-in-lahore-45846" },
  { from: "/listing/ghar47-com", to: "/listing/property-development/ghar47.com-45849" },
  { from: "/listing/childcare-course-assignment-help", to: "/listing/general-services/childcare-course-assignment-help-45854" },
  { from: "/listing/magellan-travels-explore-beautiful-pakistan", to: "/listing/travel-agents/magellan-travels-|-explore-beautiful-pakistan-45860" },
  { from: "/listing/mk-cosmetics-pakistan", to: "/listing/beauty-parlour/mk-cosmetics-pakistan-45871" },
  { from: "/listing/brands-galleria", to: "/listing/shopping-malls/brands-galleria-45877" },
  { from: "/listing/al-wahda-industry", to: "/listing/general-services/al-wahda-industry-45881" },
  { from: "/listing/lets-compare-online-pvt-ltd", to: "/listing/general-services/let's-compare-online-(pvt)-ltd-45884" },
  { from: "/listing/food-concepts", to: "/listing/event-planner/food-concepts-45887" },
  { from: "/listing/sarina-flooring", to: "/listing/general-services/sarina-flooring-45893" },
  { from: "/listing/farooq-engineering", to: "/listing/construction-services/farooq-engineering-45903" },
  { from: "/listing/mahir-company-home-maintenance-service", to: "/listing/ac-repair-services/mahir-company-|-home-maintenance-service-45905" },
  { from: "/listing/capital-brand-technologies", to: "/listing/advertisers/capital-brand-technologies-45908" },
  { from: "/listing/online-cosmetics-store-in-pakistan-care-se-behtar-kya", to: "/listing/beauty-parlour/online-cosmetics-store-in-pakistan-|-care-se-behtar-kya-45911" },
  { from: "/listing/best-female-dentist-in-karachi", to: "/listing/dental/best-female-dentist-in-karachi-45917" },
  { from: "/listing/beauty-salon-in-karachi", to: "/listing/beauty-parlour/beauty-salon-in-karachi-45920" },
  { from: "/listing/mim-at-law", to: "/listing/lawyers/mim-at-law-45925" },
  { from: "/listing/ibuying-mobile-accessories", to: "/listing/electronics/ibuying-mobile-accessories-45929" },
  { from: "/listing/pakistan-seo-company-served-best-offer", to: "/listing/general-services/pakistan-seo-company-served-best-offer-45930" },
  { from: "/listing/brooks-chemical-roof-heat-proofing-waterproofing-services", to: "/listing/waterproofing-company/brooks-chemical-roof-heat-proofing-&amp;-waterproofing-services-45933" },
  { from: "/listing/reviewsbank", to: "/listing/software-company/reviewsbank-45937" },
  { from: "/listing/anhui-moker-new-material-technology-co-ltd", to: "/listing/advertisers/anhui-moker-new-material-technology-co.,ltd-45940" },
  { from: "/listing/karachi-fumigation", to: "/listing/pest-control/karachi-fumigation-45944" },
  { from: "/listing/s-t-a-r-s-pvt-ltd", to: "/listing/software-company/s.t.a.r.s-pvt-ltd-45947" },
  { from: "/listing/imlaak-2", to: "/listing/property-development/imlaak-45955" },
  { from: "/listing/ireland-assignment-help", to: "/listing/content-writers/ireland-assignment-help-45957" },
  { from: "/listing/rafia-pk-2", to: "/listing/designer/rafia.pk-45960" },
  { from: "/listing/friends-property-network", to: "/listing/construction-services/friends-property-network-45964" },
  { from: "/listing/arif-rajan", to: "/listing/general-services/arif-rajan-45966" },
  { from: "/listing/hvac-control-technologist", to: "/listing/ac-repair-services/hvac-control-technologist-45970" },
  { from: "/listing/chapal-builders", to: "/listing/property-development/chapal-builders-45976" },
  { from: "/listing/best-safe-driver-safe-driver-safe-driver-dubai", to: "/listing/car-rental/best-safe-driver-|safe-driver-|safe-driver-dubai-45980" },
  { from: "/listing/aaa-associates-real-estate", to: "/listing/property-development/aaa-associates-real-estate-45986" },
  { from: "/listing/rent-a-car-in-karachi-deinfa-motors", to: "/listing/car-rental/rent-a-car-in-karachi---deinfa-motors-45992" },
  { from: "/listing/bahria-sales-properties", to: "/listing/farm-houses/bahria-sales-properties-46002" },
  { from: "/listing/trendy-womens-fashion-girls-accessories-pakistan", to: "/listing/jewelers/trendy-women's-fashion-|-girls-accessories-pakistan-46007" },
  { from: "/listing/multi-plc-training-course-in-lahore", to: "/listing/training/multi-plc-training-course-in-lahore-46013" },
  { from: "/listing/hospital-management-system", to: "/listing/software-company/hospital-management-system-46016" },
  { from: "/listing/technical-training-institute", to: "/listing/colleges/technical-training-institute-46022" },
  { from: "/listing/alpha-associates", to: "/listing/property-development/alpha-associates-46023" },
  { from: "/listing/payfast", to: "/listing/visa-agencies/payfast-46029" },
  { from: "/listing/ali-rathore-associates", to: "/listing/property-development/ali-rathore-associates-46039" },
  { from: "/listing/cyber-mart-pk", to: "/listing/shopping-malls/cyber-mart-pk-46044" },
  { from: "/listing/cv-ireland", to: "/listing/content-writers/cv-ireland-46047" },
  { from: "/listing/raza-packers-movers", to: "/listing/general-services/raza-packers-&amp;-movers-46051" },
  { from: "/listing/esar-digital", to: "/listing/digital-marketing/esar-digital-46056" },
  { from: "/listing/itbeam", to: "/listing/software-company/itbeam-46060" },
  { from: "/listing/mayaar-store", to: "/listing/general-services/mayaar-store-46063" },
  { from: "/listing/best-tech-blog", to: "/listing/content-writers/best-tech-blog-46073" },
  { from: "/listing/institute-of-modern-languages", to: "/listing/tutoring/institute-of-modern-languages-46077" },
  { from: "/listing/hope-drug-treatment-center", to: "/listing/hospitals/hope-drug-treatment-center-46091" },
  { from: "/listing/milestone-project-management-services", to: "/listing/general-services/milestone-project-management-services-46094" },
  { from: "/listing/professional-training-institute-ebridge", to: "/listing/digital-marketing/professional-training-institute---ebridge-46097" },
  { from: "/listing/yari-pk", to: "/listing/job-providers/yari.pk-46102" },
  { from: "/listing/fine-art-material-store", to: "/listing/shopping-malls/fine-art-material-store-46107" },
  { from: "/listing/building-construction-company-interior-architects", to: "/listing/construction-services/building-construction-company-|-interior-architects-46118" },
  { from: "/listing/health-care-supplements-in-pakistan", to: "/listing/general-services/health-care-supplements-in-pakistan-46123" },
  { from: "/listing/wabees", to: "/listing/beauty-parlour/wabees-46126" },
  { from: "/listing/assignment-maker", to: "/listing/digital-marketing/assignment-maker-46136" },
  { from: "/listing/corvitfsd", to: "/listing/driving-schools/corvitfsd-46140" },
  { from: "/listing/kraft-paper-bag-flexible-packaging", to: "/listing/general-services/kraft-paper-bag,-flexible-packaging-46141" },
  { from: "/listing/zara-mens-rehans-premium-mens-wear", to: "/listing/shopping-malls/zara-men's,-rehan's-premium-men's-wear-46144" },
  { from: "/listing/power-cable-techno-power-cable", to: "/listing/construction-services/power-cable,-techno-power-cable-46147" },
  { from: "/listing/aci-bleach-atlantis-chemical", to: "/listing/book-stores/aci-bleach,-atlantis-chemical-46150" },
  { from: "/listing/safe-locker-ksp-safe-and-vault", to: "/listing/security-guard/safe-locker,-ksp-safe-and-vault-46154" },
  { from: "/listing/novels-atlantis-publication", to: "/listing/book-stores/novels,-atlantis-publication-46157" },
  { from: "/listing/wash-cloth-pakistan-towels", to: "/listing/beauty-parlour/wash-cloth,-pakistan-towels-46159" },
  { from: "/listing/home-security-camera-services", to: "/listing/electronics/home-security-camera-services-46162" },
  { from: "/listing/bhatia-associates", to: "/listing/lawyers/bhatia-associates-46167" },
  { from: "/listing/photoshoot-studio-hire-or-rental", to: "/listing/advertisers/photoshoot-studio-hire-or-rental-46199" },
  { from: "/listing/rohtas-marketing-2", to: "/listing/digital-marketing/rohtas-marketing-46203" },
  { from: "/listing/assignment-help-uae", to: "/listing/software-company/assignment-help-uae-46205" },
  { from: "/listing/real-estate-4", to: "/listing/advertisers/real-estate-46207" },
  { from: "/listing/home-tutor-lahore", to: "/listing/tutoring/home-tutor-lahore-46210" },
  { from: "/listing/faseo-seo-services-in-pakistan", to: "/listing/digital-marketing/faseo-|-seo-services-in-pakistan-46214" },
  { from: "/listing/silk-packers-movers-islamabad", to: "/listing/general-services/silk-packers-&amp;-movers-islamabad-46218" },
  { from: "/listing/seo-course-in-pakistan", to: "/listing/digital-marketing/seo-course-in-pakistan-46223" },
  { from: "/listing/the-other-bazaar-ecommerce-enablement-company", to: "/listing/digital-marketing/the-other-bazaar-ecommerce-enablement-company-46236" },
  { from: "/listing/asco-properties-private-limited", to: "/listing/property-development/asco-properties-(private)-limited-46238" },
  { from: "/listing/pest-control-karachi-fumigation-services-in-karachi", to: "/listing/pest-control/pest-control-karachi-|-fumigation-services-in-karachi-46241" },
  { from: "/listing/decent-departmental-store", to: "/listing/general-services/decent-departmental-store-46246" },
  { from: "/listing/plan-z-dev-we-develop-your-ideas", to: "/listing/website-development/plan-z-dev-|-we-develop-your-ideas-46250" },
  { from: "/listing/mj-travel-group-events", to: "/listing/travel-agents/mj-travel-group-&amp;-events-46259" },
  { from: "/listing/hikvision-pakistan-security-camera-company", to: "/listing/electronics/hikvision-pakistan-security-camera-company-46262" },
  { from: "/listing/lhv-admission-2021-in-lahore", to: "/listing/driving-schools/lhv-admission-2021-in-lahore-46268" },
  { from: "/listing/the-ping-pong-paddle", to: "/listing/general-services/the-ping-pong-paddle-46281" },
  { from: "/listing/padel-o-paddles", to: "/listing/general-services/padel-o-paddles-46284" },
  { from: "/listing/cake-shake-bakers", to: "/listing/restaurants/cake-shake-bakers-46295" },
  { from: "/listing/bin-shafiq-digital", to: "/listing/digital-marketing/bin-shafiq-digital-46298" },
  { from: "/listing/abundance-drive", to: "/listing/general-services/abundance-drive-46309" },
  { from: "/listing/web-development-services-2", to: "/listing/digital-marketing/web-development-services-46314" },
  { from: "/listing/cdb-real-estate", to: "/listing/construction-services/cdb-real-estate-46328" },
  { from: "/listing/binate-digital-pvt-ltd", to: "/listing/advertisers/binate-digital-pvt-ltd-46332" },
  { from: "/listing/guruapp-com", to: "/listing/beauty-parlour/guruapp.com-46337" },
  { from: "/listing/mechaniconwheels-pk", to: "/listing/general-services/mechaniconwheels.pk-46338" },
  { from: "/listing/tajweez-properties-real-estate-agency-in-islamabad", to: "/listing/property-development/tajweez-properties-|-real-estate-agency-in-islamabad-46341" },
  { from: "/listing/horizon-energy", to: "/listing/solar-providers/horizon-energy-46346" },
  { from: "/listing/mini-splits-outlet-has-been-provide-ac", to: "/listing/ac-repair-services/mini-splits-outlet-has-been-provide-ac-46365" },
  { from: "/listing/top-hotel-in-hunza", to: "/listing/travel-agents/top-hotel-in-hunza-46368" },
  { from: "/listing/plan-z-web-empowering-your-web-identity", to: "/listing/website-development/plan-z-web-|-empowering-your-web-identity.-46381" },
  { from: "/listing/pakistanpedia", to: "/listing/advertisers/pakistanpedia-46387" },
  { from: "/listing/interlink-movers", to: "/listing/travel-agents/interlink-movers-46390" },
  { from: "/listing/vital-honey", to: "/listing/general-services/vital-honey-46408" },
  { from: "/listing/croza-wear", to: "/listing/shopping-malls/croza-wear-46410" },
  { from: "/listing/sky-exchange-quick-money-exchange", to: "/listing/general-services/sky-exchange-|-quick-money-exchange-46417" },
  { from: "/listing/dynaamx", to: "/listing/digital-marketing/dynaamx-46421" },
  { from: "/listing/cerostech-digital-marketing-pakistan", to: "/listing/digital-marketing/cerostech---digital-marketing-pakistan-46450" },
  { from: "/listing/tibbi-pk", to: "/listing/laboratories/tibbi.pk-46456" },
  { from: "/listing/decorum-pakistan", to: "/listing/architecture/decorum-pakistan-46460" },
  { from: "/listing/hsa-consultants", to: "/listing/visa-agencies/hsa-consultants-46466" },
  { from: "/listing/home-and-online-tuition-academy-in-islamabad", to: "/listing/tutoring/home-and-online-tuition-academy-in-islamabad-46469" },
  { from: "/listing/link-international-exchange", to: "/listing/verification-services/link-international-exchange-46474" },
  { from: "/listing/spectrum-consultancy", to: "/listing/lawyers/spectrum-consultancy-46477" },
  { from: "/listing/nhs-legal", to: "/listing/lawyers/nhs-legal-46481" },
  { from: "/listing/bulk-branded-sms-pakistan", to: "/listing/advertisers/bulk-branded-sms-pakistan-46487" },
  { from: "/listing/ideoversity-2", to: "/listing/digital-marketing/ideoversity-46497" },
  { from: "/listing/the-northwalk", to: "/listing/shopping-malls/the-northwalk-46505" },
  { from: "/listing/moody-pk-food-ride-deliver", to: "/listing/home-delivery-food/moody.pk---food,-ride,-deliver-46509" },
  { from: "/listing/digital-marketing-agency-in-pakistan", to: "/listing/digital-marketing/digital-marketing-agency-in-pakistan-46516" },
  { from: "/listing/organic-hub-by-taaniya", to: "/listing/website-development/organic-hub-by-taaniya-46522" },
  { from: "/listing/asma-best-lawyers-in-pakistan", to: "/listing/lawyers/asma-best-lawyers-in-pakistan-46529" },
  { from: "/listing/marzban-clinic", to: "/listing/doctors-and-clinics/marzban-clinic-46533" },
  { from: "/listing/malabis", to: "/listing/shopping-malls/malabis-46539" },
  { from: "/listing/best-hospitality-education-and-tourism-course-in-pakistan", to: "/listing/colleges/best-hospitality-education-and-tourism-course-in-pakistan-46548" },
  { from: "/listing/rb-salon", to: "/listing/salons/rb-salon-46553" },
  { from: "/listing/kolaxo-print-makes-a-designing-manufacturing", to: "/listing/software-company/kolaxo-print-makes-a-designing-&amp;-manufacturing-46559" },
  { from: "/listing/gari-pk", to: "/listing/car-dealer/gari-pk-46563" },
  { from: "/listing/cheap-essay-writing-uk", to: "/listing/universities/cheap-essay-writing-uk-46565" },
  { from: "/listing/presence", to: "/listing/general-services/presence-46575" },
  { from: "/listing/stars-academy-lahore", to: "/listing/colleges/stars-academy-lahore-46581" },
  { from: "/listing/pallet-racking-system", to: "/listing/architecture/pallet-racking-system-46588" },
  { from: "/listing/roof-waterproofing-and-heatproofing-services", to: "/listing/waterproofing-company/roof-waterproofing-and-heatproofing-services-46592" },
  { from: "/listing/mashallah-marriage-bureau", to: "/listing/marriage-beuro/mashallah-marriage-bureau-46596" },
  { from: "/listing/get-professional-phd-dissertation-help", to: "/listing/tutoring/get-professional-phd-dissertation-help-46600" },
  { from: "/listing/seo-services-lahore", to: "/listing/digital-marketing/seo-services-lahore-46603" },
  { from: "/listing/bwlogics", to: "/listing/software-company/bwlogics-46607" },
  { from: "/listing/tods-n-teens", to: "/listing/shopping-malls/tods-n-teens-46614" },
  { from: "/listing/home-tutors-in-lahore-for-home-tuition", to: "/listing/tutoring/home-tutors-in-lahore-for-home-tuition-46618" },
  { from: "/listing/house-of-arfa", to: "/listing/advertisers/house-of-arfa-46626" },
  { from: "/listing/wac-consultants", to: "/listing/visa-agencies/wac-consultants-46632" },
  { from: "/listing/hgs-cosmetics", to: "/listing/shopping-malls/hgs-cosmetics-46635" },
  { from: "/listing/prime-it-solution", to: "/listing/website-development/prime-it-solution-46636" },
  { from: "/listing/tap-builders", to: "/listing/property-for-rent/tap-builders-46643" },
  { from: "/listing/shah-techno-digital-marketing-agency", to: "/listing/advertisers/shah-techno---digital-marketing-agency-46672" },
  { from: "/listing/fan-coil-unit-s-a-associates", to: "/listing/ac-repair-services/fan-coil-unit---s.a.-associates-46675" },
  { from: "/listing/pakistan-lounge", to: "/listing/advertisers/pakistan-lounge-46679" },
  { from: "/listing/nestack-reviews-and-ratings", to: "/listing/mobile-app-development/nestack-reviews-and-ratings-46688" },
  { from: "/listing/banarsi-al-rahim-brothers", to: "/listing/designer/banarsi-al-rahim-brothers-46692" },
  { from: "/listing/imperial-homes", to: "/listing/shopping-malls/imperial-homes-46694" },
  { from: "/listing/digital-marketing-agency-2", to: "/listing/digital-marketing/digital-marketing-agency-46700" },
  { from: "/listing/betpro-dealer-betpro-exch", to: "/listing/advertisers/betpro-dealer---betpro-exch-46704" },
  { from: "/listing/romerose", to: "/listing/digital-marketing/romerose-46710" },
  { from: "/listing/hp-monthly-magazine-for-hotel-restaurant-tourism-industr", to: "/listing/tutoring/hp-monthly-magazine-for-hotel,-restaurant,-tourism-&amp;-industr-46712" },
  { from: "/listing/fim-technologies", to: "/listing/software-company/fim-technologies-46716" },
  { from: "/listing/synergy-utimaco-partners-in-pakistan", to: "/listing/computer-repair/synergy-|-utimaco-partners-in-pakistan-46732" },
  { from: "/listing/khas-stores", to: "/listing/shopping-malls/khas-stores-46737" },
  { from: "/listing/neemopani", to: "/listing/advertisers/neemopani-46740" },
  { from: "/listing/vision-enterprise", to: "/listing/general-services/vision-enterprise-46746" },
  { from: "/listing/rent-a-car-islamabad-2", to: "/listing/car-rental/rent-a-car-islamabad-46750" },
  { from: "/listing/phonedaddy", to: "/listing/electronics/phonedaddy-46754" },
  { from: "/listing/visage-cosmetics-pk", to: "/listing/beauty-parlour/visage-cosmetics-pk-46759" },
  { from: "/listing/spacesuites", to: "/listing/hotels/spacesuites-46764" },
  { from: "/listing/nuanyang-import-and-export-trading-co-ltd", to: "/listing/beauty-parlour/nuanyang-import-and-export-trading-co.,-ltd-46768" },
  { from: "/listing/vm-art-gallery", to: "/listing/property-development/vm-art-gallery-46770" },
  { from: "/listing/smd-screen", to: "/listing/electrician/smd-screen-46777" },
  { from: "/listing/navicosoft", to: "/listing/website-development/navicosoft-46781" },
  { from: "/listing/nova-citt-peshawar", to: "/listing/property-development/nova-citt-peshawar-46786" },
  { from: "/listing/rudn-enclave-rawalpindi", to: "/listing/property-development/rudn-enclave-rawalpindi-46790" },
  { from: "/listing/car-rental-service", to: "/listing/car-rental/car-rental-service-46793" },
  { from: "/listing/watch-centre-pakistan", to: "/listing/jewelers/watch-centre-pakistan-46799" },
  { from: "/listing/burj-yaqub", to: "/listing/property-for-rent/burj-yaqub-46807" },
  { from: "/listing/tutor-club-home-online-tutor", to: "/listing/tutoring/tutor-club---home-online-tutor-46811" },
  { from: "/listing/wahid-leather-fitness", to: "/listing/advertisers/wahid-leather-&amp;-fitness-46824" },
  { from: "/listing/superb-developer", to: "/listing/digital-marketing/superb-developer-46836" },
  { from: "/listing/gym-clothes-for-men-and-women", to: "/listing/clothing-and-accessories/gym-clothes-for-men-and-women-46859" },
  { from: "/listing/divers-club-pakistan-travel-tours", to: "/listing/travel-agents/divers-club-–-pakistan-travel-&amp;-tours-46863" },
  { from: "/listing/catalyst-time-and-url-tracking-app-in-pakistan", to: "/listing/software-company/catalyst-|-time-and-url-tracking-app-in-pakistan-46868" },
  { from: "/listing/veteran-security-protection-pvt-ltd", to: "/listing/security-guard/veteran-security-&amp;-protection-(pvt)-ltd-46872" },
  { from: "/listing/park-view-city-lahore", to: "/listing/property-development/park-view-city-lahore-46880" },
  { from: "/listing/wa-himalaya-international", to: "/listing/home-delivery-food/w&amp;a-himalaya-international-46892" },
  { from: "/listing/tadresi-printing-press", to: "/listing/advertisers/tadresi-printing-press-46901" },
  { from: "/listing/the-cake-land", to: "/listing/restaurants/the-cake-land-46904" },
  { from: "/listing/dental-care-center-fsd", to: "/listing/dental/dental-care-center-fsd-46912" },
  { from: "/listing/zalfax-sports", to: "/listing/gym/zalfax-sports-46913" },
  { from: "/listing/growth-xperts", to: "/listing/digital-marketing/growth-xperts-46919" },
  { from: "/listing/luxury-farmhouse-in-karachi-spotit", to: "/listing/farm-houses/luxury-farmhouse-in-karachi-|-spotit-46931" },
  { from: "/listing/duofan", to: "/listing/advertisers/duofan-46953" },
  { from: "/listing/care-taker", to: "/listing/pest-control/care-taker-46955" },
  { from: "/listing/fast-technology", to: "/listing/software-company/fast-technology-46963" },
  { from: "/listing/the-dissertation-help", to: "/listing/content-writers/the-dissertation-help-47013" },
  { from: "/listing/bakra-mandi-lahore", to: "/listing/animals-&amp;-birds/bakra-mandi-lahore-47017" },
  { from: "/listing/online-perfume-store-in-pakisatn", to: "/listing/beauty-parlour/online-perfume-store-in-pakisatn-47043" },
  { from: "/listing/locksmith-dubai", to: "/listing/advertisers/locksmith-dubai-47054" },
  { from: "/listing/al-jalil-garden-lahore", to: "/listing/property-development/al-jalil-garden-lahore-47058" },
  { from: "/listing/bestpickvacuum", to: "/listing/website-development/bestpickvacuum-47062" },
  { from: "/listing/forces-jackets", to: "/listing/designer/forces-jackets-47067" },
  { from: "/listing/software-craft-pvt-ltd", to: "/listing/software-company/software-craft-pvt.ltd-47073" },
  { from: "/listing/skylines-solar", to: "/listing/electronics/skylines-solar-47082" },
  { from: "/listing/school-management-system", to: "/listing/software-company/school-management-system-47098" },
  { from: "/listing/primlink-a-manufacturer-audit-in-pakistan-aisa", to: "/listing/travel-agents/primlink-|-a-manufacturer-audit-in-pakistan-aisa-47102" },
  { from: "/listing/best-rhinoplasty", to: "/listing/hospitals/best-rhinoplasty-47108" },
  { from: "/listing/betpro-dealer", to: "/listing/advertisers/betpro-dealer-47111" },
  { from: "/listing/cheap-dissertation-writing-uk", to: "/listing/content-writers/cheap-dissertation-writing-uk-47117" },
  { from: "/listing/arhamsoft-pvt-ltd", to: "/listing/software-company/arhamsoft-pvt-ltd-47121" },
  { from: "/listing/abees-clothes", to: "/listing/shopping-malls/abees-clothes-47125" },
  { from: "/listing/hawaiistore", to: "/listing/digital-marketing/hawaiistore-47129" },
  { from: "/listing/rubber-tumblast-machine-supplier", to: "/listing/general-services/rubber-tumblast-machine-supplier-47137" },
  { from: "/listing/sl-basics", to: "/listing/general-services/sl-basics-47142" },
  { from: "/listing/jiangsu-bangdeya-new-material-technology-co-ltd", to: "/listing/general-services/jiangsu bangdeya new material technology co., ltd-47144" },
  { from: "/listing/gotoassignmenthelp", to: "/listing/wedding-halls/gotoassignmenthelp-47147" },
  { from: "/listing/beautician-ab-ap-k-ghar-per", to: "/listing/salons/beautician--ab-ap-k-ghar-per-47150" },
  { from: "/listing/indigo-hight", to: "/listing/restaurants/indigo-hight-47153" },
  { from: "/listing/digital-siddhu-academy-seo-services", to: "/listing/digital-marketing/digital-siddhu-academy-seo-services-47158" },
  { from: "/listing/charchand-moulded-plastic-furniture-chairs", to: "/listing/shopping-malls/charchand-moulded-plastic-furniture-&amp;-chairs-47162" },
  { from: "/listing/self-study-help-materials-for-all-students", to: "/listing/colleges/self-study-help-materials-for-all-students-47171" },
  { from: "/listing/aliel-pakistan", to: "/listing/jewelers/aliel-pakistan-47189" },
  { from: "/listing/xeven-solutions-pvt-ltd", to: "/listing/software-company/xeven-solutions-(pvt)-ltd.-47192" },
  { from: "/listing/home-advisor", to: "/listing/pest-control/home-advisor-47196" },
  { from: "/listing/used-furniture-buyers-in-dubai", to: "/listing/pickups-and-trucks/used-furniture-buyers-in-dubai-47202" },
  { from: "/listing/gexton-apps-2", to: "/listing/mobile-app-development/gexton-apps-47205" },
  { from: "/listing/gexton-security-3", to: "/listing/security-guard/gexton-security-47208" },
  { from: "/listing/sadquain-enterprises", to: "/listing/printing/sadquain-enterprises-47211" },
  { from: "/listing/v4-international-llp", to: "/listing/architecture/v4-international-llp-47217" },
  { from: "/listing/expobird-2", to: "/listing/digital-marketing/expobird-47223" },
  { from: "/listing/aircred-limited", to: "/listing/software-company/aircred-limited-47228" },
  { from: "/listing/unique-track", to: "/listing/car-dealer/unique-track-47231" },
  { from: "/listing/timiz-impex", to: "/listing/shopping-malls/timiz-impex-47237" },
  { from: "/listing/best-driving-school", to: "/listing/driving-schools/best-driving-school-47244" },
  { from: "/listing/best-driving-school-2", to: "/listing/driving-schools/best-driving-school-47249" },
  { from: "/listing/united-software-technologies-international", to: "/listing/software-company/united-software-&amp;-technologies-international-47254" },
  { from: "/listing/big-flag-media", to: "/listing/digital-marketing/big-flag-media-47262" },
  { from: "/listing/islamabad-hearing-center", to: "/listing/doctors-and-clinics/islamabad-hearing-center-47269" },
  { from: "/listing/custom-football-badges", to: "/listing/designer/custom-football-badges-47273" },
  { from: "/listing/allied-services-international-pvt-limited", to: "/listing/visa-agencies/allied-services-international-(pvt)-limited-47275" },
  { from: "/listing/khita-com-pk-a-realestate-agency-sialkot", to: "/listing/property-development/khita.com.pk-a-realestate-agency-sialkot-47284" },
  { from: "/listing/pearl-residencia", to: "/listing/property-development/pearl-residencia-47287" },
  { from: "/listing/digital-marketing-3", to: "/listing/digital-marketing/digital-marketing-47297" },
  { from: "/listing/the-baketown", to: "/listing/home-delivery-food/the-baketown-47302" },
  { from: "/listing/bookfair", to: "/listing/book-stores/bookfair-47309" },
  { from: "/listing/advanced-electro-services", to: "/listing/general-services/advanced-electro-services-47314" },
  { from: "/listing/nuvuk-digital-marketing-agency", to: "/listing/advertisers/nuvuk-digital-marketing-agency-47319" },
  { from: "/listing/high-street-international", to: "/listing/gym/high-street-international-47326" },
  { from: "/listing/car-rental", to: "/listing/car-rental/car-rental-47332" },
  { from: "/listing/cdl-recruitments", to: "/listing/pickups-and-trucks/cdl-recruitments-47335" },
  { from: "/listing/talal-packers-and-movers-in-karachi", to: "/listing/general-services/talal-packers-and-movers-in-karachi-47344" },
  { from: "/listing/wahid-leather-fitness-2", to: "/listing/gym/wahid-leather-&amp;-fitness-47347" },
  { from: "/listing/hopemarketing-2", to: "/listing/property-development/hopemarketing-47352" },
  { from: "/listing/mateen-enterprise", to: "/listing/general-services/mateen-enterprise-47358" },
  { from: "/listing/al-noor-orchard-lahore", to: "/listing/architecture/al-noor-orchard-lahore-47362" },
  { from: "/listing/prime-valley-islamabad", to: "/listing/property-development/prime-valley-islamabad-47363" },
  { from: "/listing/student-care-software", to: "/listing/software-company/student-care-software-47365" },
  { from: "/listing/protection-fire-safety-traders", to: "/listing/security-guard/protection-fire-&amp;-safety-traders-47368" },
  { from: "/listing/raza-kazim-associates", to: "/listing/lawyers/raza-kazim-associates-47376" },
  { from: "/listing/grand-taj-hotel", to: "/listing/hotels/grand-taj-hotel-47379" },
  { from: "/listing/rock-salts", to: "/listing/advertisers/rock-salts-47386" },
  { from: "/listing/pulse-pipette-tips-consumables-co-ltd", to: "/listing/general-services/pulse-pipette-tips-&amp;-consumables-co.,-ltd.-47397" },
  { from: "/listing/nova-city-islamabad-2", to: "/listing/property-development/nova-city-islamabad-47400" },
  { from: "/listing/park-view-city-islamabad-2", to: "/listing/property-development/park-view-city-islamabad-47405" },
  { from: "/listing/lahore-smart-city-5", to: "/listing/property-development/lahore-smart-city-47408" },
  { from: "/listing/joint-surgical-corporation", to: "/listing/salons/joint-surgical-corporation-47415" },
  { from: "/listing/park-green-city-pvt-ltd", to: "/listing/property-development/park-green-city-pvt.-ltd.-47422" },
  { from: "/listing/tick-industries", to: "/listing/printing/tick-industries-47427" },
  { from: "/listing/ebridge-technologies", to: "/listing/digital-marketing/ebridge-technologies-47432" },
  { from: "/listing/synergy-oracle-partners-in-pakistan", to: "/listing/computer-repair/synergy-|-oracle-partners-in-pakistan-47434" },
  { from: "/listing/marketing-giants", to: "/listing/advertisers/marketing-giants-47436" },
  { from: "/listing/tameer-group", to: "/listing/property-development/tameer-group-47445" },
  { from: "/listing/eworldest-solution-smc-private-limited", to: "/listing/website-development/eworldest-solution-(smc-private)-limited-47449" },
  { from: "/listing/john-fleming", to: "/listing/hospitals/john-fleming-47452" },
  { from: "/listing/legacy-real-estate-group", to: "/listing/property-development/legacy-real-estate-group-47456" },
  { from: "/listing/multiwood-furnitures", to: "/listing/architecture/multiwood-furnitures-47467" },
  { from: "/listing/see-consultants-architects", to: "/listing/architecture/see-consultants-&amp;-architects-47470" },
  { from: "/listing/wellshop-pk", to: "/listing/shopping-malls/wellshop.pk-47489" },
  { from: "/listing/accounting-services-usa", to: "/listing/digital-marketing/accounting-services-usa-47496" },
  { from: "/listing/capital-valley-islamabad", to: "/listing/property-development/capital-valley-islamabad-47498" },
  { from: "/listing/assignment-help", to: "/listing/content-writers/assignment-help-47500" },
  { from: "/listing/talal-packers-and-movers", to: "/listing/general-services/talal-packers-and-movers-47506" },
  { from: "/listing/best-stationery-items-website-supa-pk", to: "/listing/advertisers/best-stationery-items-website-supa.pk-47513" },
  { from: "/listing/fablous-pharma", to: "/listing/beauty-parlour/fablous-pharma-47515" },
  { from: "/listing/termite-control-services-lahore", to: "/listing/pest-control/termite-control-services-lahore-47518" },
  { from: "/listing/real-jewellers", to: "/listing/jewelers/real-jewellers-47523" },
  { from: "/listing/model-steel", to: "/listing/construction-services/model-steel-47531" },
  { from: "/listing/help-are-us", to: "/listing/general-services/help-are-us-47534" },
  { from: "/listing/spectrum-consultancy-2", to: "/listing/lawyers/spectrum-consultancy-47538" },
  { from: "/listing/himalayan-chef-2", to: "/listing/general-services/himalayan-chef-47556" },
  { from: "/listing/spectrum-consultancy-3", to: "/listing/verification-services/spectrum-consultancy-47559" },
  { from: "/listing/pk-womens-den", to: "/listing/content-writers/pk-womens-den-47562" },
  { from: "/listing/sultan-dental-surgical", to: "/listing/dental/sultan-dental-surgical-47572" },
  { from: "/listing/demure-sports", to: "/listing/shopping-malls/demure-sports-47575" },
  { from: "/listing/mydeal-pk", to: "/listing/shopping-malls/mydeal.pk-47577" },
  { from: "/listing/buy-narrative-essay-cheap", to: "/listing/content-writers/buy-narrative-essay-cheap-47585" },
  { from: "/listing/tarkhan-furniture-store", to: "/listing/shopping-malls/tarkhan-furniture-store-47588" },
  { from: "/listing/rehman-travel", to: "/listing/travel-agents/rehman-travel-47590" },
  { from: "/listing/meetupkarachi", to: "/listing/hotels/meetupkarachi-47594" },
  {
    from: "/listing/%da%88%db%8c%d8%ac%db%8c%d9%b9%d9%84-%d9%85%d8%a7%d8%b1%da%a9%db%8c%d9%b9%d9%86%da%af-%da%a9%db%8c-%d8%a7%db%81%d9%85%db%8c%d8%aa",
    to: "/listing/digital-marketing/ڈیجیٹل-مارکیٹنگ-کی-اہمیت-47596",
  },
  { from: "/listing/sarina-flooring-2", to: "/listing/carpenter/sarina-flooring-47600" },
  { from: "/listing/famous-movie-jackets", to: "/listing/shopping-malls/famous-movie-jackets-47604" },
  { from: "/listing/nasim-fertility-center", to: "/listing/doctors-and-clinics/nasim-fertility-center-47607" },
  { from: "/listing/home-cleaning-office-cleaning-services-in-delhi-ncr", to: "/listing/general-services/home-cleaning-office-cleaning-services-in-delhi-ncr-47614" },
  { from: "/listing/amanatali-%d8%b5%d9%88%d9%81%db%81-%da%a9%d8%a7%d8%b1%d9%be%d9%b9-%d9%88%d8%a7%d8%b4", to: "/listing/general-services/amanatali-صوفہ-کارپٹ-واش-47618" },
  { from: "/listing/capital-valley-islamabad-2", to: "/listing/construction-services/capital-valley-islamabad-47620" },
  { from: "/listing/hvac-companies-in-pakistan", to: "/listing/ac-repair-services/hvac-companies-in-pakistan-47624" },
  { from: "/listing/bahria-orchard-phase-4", to: "/listing/property-development/bahria-orchard-phase-4-47628" },
  { from: "/listing/techbuzz", to: "/listing/digital-marketing/techbuzz-47629" },
  { from: "/listing/surgical-instruments", to: "/listing/dental/surgical-instruments-47634" },
  { from: "/listing/master-moltyfoam", to: "/listing/general-services/master-moltyfoam-47636" },
  { from: "/listing/ulearn-lms", to: "/listing/colleges/ulearn-lms-47644" },
  { from: "/listing/ehsan-packers-and-movers-in-islamabad", to: "/listing/pickups-and-trucks/ehsan-packers-and-movers-in-islamabad-47651" },
  { from: "/listing/online-shopping-in-pakistan-4", to: "/listing/beauty-parlour/online-shopping-in-pakistan-47657" },
  { from: "/listing/glorious-builders-3", to: "/listing/construction-services/glorious-builders-47660" },
  { from: "/listing/edinfini", to: "/listing/universities/edinfini-47664" },
  { from: "/listing/asi-soccer-company", to: "/listing/advertisers/asi-soccer-company-47677" },
  { from: "/listing/mattresses", to: "/listing/general-services/mattresses-47682" },
  { from: "/listing/waxing-services-in-lahore", to: "/listing/beauty-parlour/waxing-services-in-lahore-47683" },
  { from: "/listing/pak-property-portal", to: "/listing/property-development/pak-property-portal-47688" },
  { from: "/listing/arbish-sports-industries", to: "/listing/gym/arbish-sports-industries-47694" },
  { from: "/listing/read-free-online-trending-novel", to: "/listing/book-stores/read-free-online-trending-novel-47699" },
  { from: "/listing/talal-fitness-equipment", to: "/listing/gym/talal-fitness-equipment-47701" },
  { from: "/listing/sigma-properties-mkt-pvt-ltd", to: "/listing/property-development/sigma-properties-&amp;-mkt-pvt-ltd-47705" },
  { from: "/listing/decor-n-co", to: "/listing/electronics/decor-n-co-47713" },
  { from: "/listing/aroma-waxing-clinic", to: "/listing/beauty-parlour/aroma-waxing-clinic-47716" },
  { from: "/listing/ey-shop-branded-products-in-usa-baby-toys-gift-shop", to: "/listing/animals-&amp;-birds/ey-shop,-branded-products-in-usa-,-baby-toys-&amp;-gift-shop-47721" },
  { from: "/listing/islamabad-home-tuition-providers", to: "/listing/tutoring/islamabad-home-tuition-providers-47725" },
  { from: "/listing/truguard-security", to: "/listing/security-guard/truguard-security-47734" },
  { from: "/listing/keto-camp", to: "/listing/home-delivery-food/keto-camp-47737" },
  { from: "/listing/news-gear", to: "/listing/car-dealer/news-gear-47740" },
  { from: "/listing/moonbox-2", to: "/listing/designer/moonbox-47742" },
  { from: "/listing/best-neurosurgeon-in-lahore", to: "/listing/advertisers/best-neurosurgeon-in-lahore-47744" },
  { from: "/listing/ac-repair-services-in-karachi", to: "/listing/ac-repair-services/ac-repair-services-in-karachi-47755" },
  { from: "/listing/fs-sports-impex", to: "/listing/advertisers/fs-sports-impex-47759" },
  { from: "/listing/topline-marketing-pvt-ltd", to: "/listing/property-development/topline-marketing-pvt.-ltd.-47761" },
  { from: "/listing/cheap-umrah-packages", to: "/listing/travel-agents/cheap-umrah-packages-47765" },
  { from: "/listing/karsaaz", to: "/listing/ac-repair-services/karsaaz-47772" },
  { from: "/listing/digital-marketing-agency-in-pakistan-advertising-agency", to: "/listing/digital-marketing/digital-marketing-agency-in-pakistan-advertising-agency-.-47784" },
  { from: "/listing/ketifa-clothing-brand", to: "/listing/advertisers/ketifa-clothing-brand-47789" },
  { from: "/listing/web-development-company-in-lahore-3", to: "/listing/website-development/web-development-company-in-lahore-47792" },
  { from: "/listing/mens-dress-shirts", to: "/listing/designer/men’s-dress-shirts-47807" },
  { from: "/listing/devssol", to: "/listing/software-company/devssol-47808" },
  { from: "/listing/moltyhome-best-furniture-website-in-pakistan", to: "/listing/advertisers/moltyhome-best-furniture-website-in-pakistan-47812" },
  { from: "/listing/doctor-fehmida", to: "/listing/doctors-and-clinics/doctor-fehmida-47815" },
  { from: "/listing/xian-senyi-new-material-technology-co-ltd", to: "/listing/hospitals/xi'an-senyi-new-material-technology-co.,-ltd-47822" },
  { from: "/listing/rafi-group-2", to: "/listing/property-development/rafi-group-47824" },
  { from: "/listing/happy-house", to: "/listing/general-services/happy-house-47830" },
  { from: "/listing/prism-town-gujar-khan-housing-society", to: "/listing/construction-services/prism-town-gujar-khan---housing-society-47831" },
  { from: "/listing/knockout-pest-control-professional", to: "/listing/pest-control/knockout-pest-control-professional-47837" },
  { from: "/listing/smart-sun-power", to: "/listing/general-services/smart-sun-power-47842" },
  { from: "/listing/fusion-ave", to: "/listing/general-services/fusion-ave-47848" },
  { from: "/listing/globaldailyinfo-com", to: "/listing/software-company/globaldailyinfo.com-47852" },
  { from: "/listing/12-marla-residential-i-block-bahria-town-phase-8", to: "/listing/property-development/12-marla-residential-i-block-bahria-town-phase-8-47855" },
  { from: "/listing/h-a-design-studio", to: "/listing/architecture/h-a-design-studio-47860" },
  { from: "/listing/alsa-pakistan-2", to: "/listing/hospitals/alsa-pakistan-47867" },
  { from: "/listing/electrician-services-in-pakistan", to: "/listing/electrician/electrician-services-in-pakistan-47871" },
  { from: "/listing/cezanne", to: "/listing/shopping-malls/cezanne-47875" },
  { from: "/listing/ra-property-hub", to: "/listing/property-development/ra-property-hub-47880" },
  { from: "/listing/best-rhinoplasty-surgeon-in-lahore", to: "/listing/hospitals/best-rhinoplasty-surgeon-in-lahore-47893" },
  { from: "/listing/tibbi", to: "/listing/doctors-and-clinics/tibbi-47907" },
  { from: "/listing/best-steel-doors-in-kerala", to: "/listing/construction-services/best-steel-doors-in-kerala-47913" },
  { from: "/listing/alwasila", to: "/listing/general-services/alwasila-47917" },
  { from: "/listing/ra-property-hub-2", to: "/listing/property-development/ra-property-hub-47921" },
  { from: "/listing/virtual-experts-limited", to: "/listing/digital-marketing/virtual-experts-limited-47926" },
  { from: "/listing/nextsol", to: "/listing/advertisers/nextsol-47930" },
  { from: "/listing/ac-installation-services-in-lahore", to: "/listing/ac-repair-services/ac-installation-services-in-lahore-47936" },
  { from: "/listing/life-insurance-company-state-life-islamabad-pakistan", to: "/listing/advertisers/life-insurance-company-(state-life)-islamabad,-pakistan.-47948" },
  { from: "/listing/active-goat", to: "/listing/shopping-malls/active-goat-47950" },
  { from: "/listing/bada-education-australia", to: "/listing/visa-agencies/bada-education-australia-47957" },
  { from: "/listing/army-dog-center-peshawar-03009195279", to: "/listing/pets-hospitals/army-dog-center-peshawar-03009195279-47961" },
  { from: "/listing/blue-town-with-smart-city-features", to: "/listing/property-development/blue-town-with-smart-city-features-47965" },
  { from: "/listing/dentoral-studio", to: "/listing/dental/dentoral-studio-47969" },
  { from: "/listing/enrollers-enroll-to-learn-freelancing-digital-skills", to: "/listing/digital-marketing/enrollers---enroll-to-learn-freelancing-&amp;-digital-skills-47974" },
  { from: "/listing/relaxsit", to: "/listing/architecture/relaxsit-47978" },
  { from: "/listing/logitech-periperal", to: "/listing/electronics/logitech-periperal-47983" },
  { from: "/listing/bol-news", to: "/listing/general-services/bol-news-47990" },
  { from: "/listing/zero-and-beyond-2", to: "/listing/general-services/zero-and-beyond-47998" },
  { from: "/listing/tech-exon", to: "/listing/digital-marketing/tech-exon-48003" },
  { from: "/listing/zainab-collection", to: "/listing/designer/zainab-collection-48010" },
  { from: "/listing/hotel-galaxy-karachi", to: "/listing/hotels/hotel-galaxy-karachi-48011" },
  { from: "/listing/my-home-store", to: "/listing/general-services/my-home-store-48016" },
  { from: "/listing/mawebsol-2", to: "/listing/website-development/mawebsol-48021" },
  { from: "/listing/getnow", to: "/listing/shopping-malls/getnow-48028" },
  { from: "/listing/china-wholse-products-online-in-paksitan", to: "/listing/shopping-malls/china-wholse-products-online-in-paksitan-48031" },
  { from: "/listing/aussie-asean-education-immigration-pakistan", to: "/listing/visa-agencies/aussie-asean-education-&amp;-immigration---pakistan-48041" },
  { from: "/listing/professional-digital-marketing-agency", to: "/listing/advertisers/professional-digital-marketing-agency-48046" },
  { from: "/listing/ss-legal-consultants", to: "/listing/lawyers/ss-legal-consultants-48050" },
  { from: "/listing/home-tutor-in-lahore-mixt-home-tutors", to: "/listing/tutoring/home-tutor-in-lahore-||-mixt-home-tutors-48053" },
  { from: "/listing/mateen-enterprises", to: "/listing/hospitals/mateen-enterprises-48059" },
  { from: "/listing/best-royal-fixer-service-in-uae", to: "/listing/ac-repair-services/best-royal-fixer-service-in-uae-48062" },
  { from: "/listing/shopify-website-development-web-designer-agency-uae", to: "/listing/digital-marketing/shopify-website-development-&amp;-web-designer-agency-uae-48065" },
  { from: "/listing/web-designing-service-in-pakistan", to: "/listing/digital-marketing/web-designing-service-in-pakistan-48068" },
  { from: "/listing/pest-control-service-2", to: "/listing/pest-control/pest-control-service-48071" },
  { from: "/listing/the-cannabis-boxes", to: "/listing/printing/the-cannabis-boxes-48080" },
  { from: "/listing/brownstone-marketing", to: "/listing/property-development/brownstone-marketing-48081" },
  { from: "/listing/hair-transplant-in-pakistan", to: "/listing/doctors-and-clinics/hair-transplant-in-pakistan-48088" },
  { from: "/listing/ra-property-hub-in-islamabad", to: "/listing/property-development/ra-property-hub-in-islamabad-48092" },
  { from: "/listing/shama-tarpal-house", to: "/listing/waterproofing-company/shama-tarpal-house-48107" },
  { from: "/listing/raheel-electrician-plumber-services-in-lahore", to: "/listing/electrician/raheel-electrician-plumber-services-in-lahore-48110" },
  { from: "/listing/electrician-in-karachi", to: "/listing/electrician/electrician-in-karachi-48113" },
  { from: "/listing/amazon-products-online-in-pakistan", to: "/listing/general-services/amazon-products-online-in-pakistan-48116" },
  { from: "/listing/imported-books-online-shopping-in-pakistan", to: "/listing/book-stores/imported-books-online-shopping-in-pakistan-48122" },
  { from: "/listing/advice-associates", to: "/listing/general-services/advice-associates-48127" },
  { from: "/listing/hamara-office", to: "/listing/event-planner/hamara-office-48135" },
  { from: "/listing/burhan-law", to: "/listing/lawyers/burhan-law-48141" },
  { from: "/listing/mbr-properties-private-limited", to: "/listing/property-development/mbr-properties-private-limited-48148" },
  { from: "/listing/best-skin-care-treatment-in-lahore-pakistan-at-cosmetique", to: "/listing/hospitals/best-skin-care-treatment-in-lahore-pakistan-at-cosmetique-48157" },
  { from: "/listing/leyjao-pk-3", to: "/listing/electronics/leyjao.pk-48161" },
  { from: "/listing/meet-rafay", to: "/listing/digital-marketing/meet-rafay-48167" },
  { from: "/listing/shomi-official", to: "/listing/shopping-malls/shomi-official-48181" },
  { from: "/listing/tab-city-rawalpindi", to: "/listing/property-development/tab-city-rawalpindi-48185" },
  { from: "/listing/m-rameez-ul-haq-1-seo-consultant-in-pakistan", to: "/listing/digital-marketing/m.-rameez-ul-haq-#1-seo-consultant-in-pakistan-48192" },
  { from: "/listing/mi-store-pk", to: "/listing/electronics/mi-store-pk-48196" },
  { from: "/listing/syncsecure-technologies-instant-electric-geyser", to: "/listing/electrician/sync&amp;secure-technologies-instant-electric-geyser-48200" },
  { from: "/listing/muhammad-rameez-top-digital-marketing-expert-in-karachi", to: "/listing/digital-marketing/muhammad-rameez---top-digital-marketing-expert-in-karachi-48202" },
  { from: "/listing/tech-it-post", to: "/listing/content-writers/tech-it-post-48204" },
  { from: "/listing/mirza-muhammad-zeeshan", to: "/listing/digital-marketing/mirza-muhammad-zeeshan-48207" },
  { from: "/listing/strapack", to: "/listing/printing/strapack-48215" },
  { from: "/listing/clickordernow", to: "/listing/shopping-malls/clickordernow-48218" },
  { from: "/listing/future-star-car-rental", to: "/listing/advertisers/future-star-car-rental-48222" },
  { from: "/listing/telectronics", to: "/listing/electronics/telectronics-48225" },
  { from: "/listing/macro-car-rentals-1-car-rental-service-in-karachi", to: "/listing/car-rental/macro-car-rentals-|-#1-car-rental-service-in-karachi-48229" },
  { from: "/listing/7-sky-consultancy-pvt-ltd", to: "/listing/visa-agencies/7-sky-consultancy-pvt-ltd-48237" },
  { from: "/listing/tab-city-rawalpindi-2", to: "/listing/construction-services/tab-city-rawalpindi-48239" },
  { from: "/listing/urban-developers-one-of-the-nations-premier-builders", to: "/listing/construction-services/urban-developers,-one-of-the-nation’s-premier-builders.-48247" },
  { from: "/listing/alibaba-official-partner-in-sialkot-web-design-development", to: "/listing/website-development/alibaba-official-partner-in-sialkot-|-web-design-development-48252" },
  { from: "/listing/computer-xperts-branch-office-lahore", to: "/listing/digital-marketing/computer-xperts-branch-office-(lahore)-48258" },
  { from: "/listing/app-development-company", to: "/listing/mobile-app-development/app-development-company-48261" },
  { from: "/listing/makaan-solutions-pvt-ltd", to: "/listing/property-development/makaan-solutions-pvt-ltd-48263" },
  { from: "/listing/businessleadsworld", to: "/listing/general-services/businessleadsworld-48265" },
  { from: "/listing/logo-design-dubai", to: "/listing/designer/logo-design-dubai-48269" },
  { from: "/listing/burhan-associates", to: "/listing/lawyers/burhan-&amp;-associates-48272" },
  { from: "/listing/ra-property-hub-3", to: "/listing/property-development/ra-property-hub-48273" },
  { from: "/listing/best-skin-care-treatment-inlahore-pakistan-at-cosmetique", to: "/listing/doctors-and-clinics/best-skin-care-treatment-inlahore-pakistan-at-cosmetique-48282" },
  { from: "/listing/rancer-sports", to: "/listing/digital-marketing/rancer-sports-48289" },
  { from: "/listing/supernatural-botanicals", to: "/listing/advertisers/supernatural-botanicals-48294" },
  { from: "/listing/clinion", to: "/listing/software-company/clinion-48295" },
  { from: "/listing/sell-your-samsung-galaxy-s20", to: "/listing/mobile-app-development/sell-your-samsung-galaxy-s20-48302" },
  { from: "/listing/visa-hot-spot", to: "/listing/visa-agencies/visa-hot-spot-48306" },
  { from: "/listing/web-design-and-web-development", to: "/listing/website-development/web-design-and-web-development-48307" },
  { from: "/listing/tradeem-international-clothing-supplier", to: "/listing/general-services/tradeem-international-clothing-supplier-48309" },
  { from: "/listing/my-guard-service", to: "/listing/general-services/my-guard-service-48312" },
  { from: "/listing/bespoke-patches-uk", to: "/listing/designer/bespoke-patches-uk-48317" },
  { from: "/listing/a1-fumigations", to: "/listing/pest-control/a1-fumigations-48319" },
  { from: "/listing/raheel-electrician-plumber-services-in-lahore-2", to: "/listing/electrician/raheel-electrician-plumber-services-in-lahore-48322" },
  { from: "/listing/emaan-constructions", to: "/listing/construction-services/emaan-constructions-48335" },
  { from: "/listing/agility-business-services", to: "/listing/general-services/agility-business-services-48346" },
  { from: "/listing/best-designers-in-pakistan", to: "/listing/beauty-parlour/best-designers-in-pakistan-48354" },
  { from: "/listing/letstriage", to: "/listing/software-company/letstriage-48356" },
  { from: "/listing/voice-of-justice", to: "/listing/lawyers/voice-of-justice-48363" },
  { from: "/listing/bazzlepk", to: "/listing/shopping-malls/bazzlepk-48372" },
  { from: "/listing/al-raheem-associates", to: "/listing/property-for-rent/al-raheem-associates-48376" },
  { from: "/listing/leopard-courier-tracking", to: "/listing/verification-services/leopard-courier-tracking-48382" },
  { from: "/listing/it-centre-rahim-yar-khan", to: "/listing/software-company/it-centre-rahim-yar-khan-48385" },
  { from: "/listing/tripscon", to: "/listing/travel-agents/tripscon-48389" },
  { from: "/listing/digital-marketing-company-in-islamabad", to: "/listing/digital-marketing/digital-marketing-company-in-islamabad-48401" },
  { from: "/listing/soli-surf-kitchen-design-in-pakistan-with-prices", to: "/listing/construction-services/soli-surf---kitchen-design-in-pakistan-with-prices-48410" },
  { from: "/listing/toyota-creek", to: "/listing/car-dealer/toyota-creek-48414" },
  { from: "/listing/deinfa", to: "/listing/car-rental/deinfa-48417" },
  { from: "/listing/swift-care", to: "/listing/security-guard/swift-care-48420" },
  { from: "/listing/interior-designer-2", to: "/listing/designer/interior-designer-48423" },
  { from: "/listing/blue-world-city-3", to: "/listing/property-development/blue-world-city-48426" },
  { from: "/listing/seo-service-in-pakistan-best-digital-marketing-agency", to: "/listing/digital-marketing/seo-service-in-pakistan---best-digital-marketing-agency-48433" },
  { from: "/listing/fareed-fashion", to: "/listing/general-services/fareed-fashion-48445" },
  { from: "/listing/coppergat-cables", to: "/listing/electronics/coppergat-cables-48449" },
  { from: "/listing/home-kitchen-appliances-aardee-pakistan", to: "/listing/electronics/home-&amp;-kitchen-appliances---aardee-pakistan-48453" },
  { from: "/listing/cctv-camera-surveillance-systems", to: "/listing/shopping-malls/cctv-camera-&amp;-surveillance-systems-48456" },
  { from: "/listing/packing-tape-manufacturer", to: "/listing/construction-services/packing-tape-manufacturer-48457" },
  { from: "/listing/dfsk-prince-capital-motors-islamabad", to: "/listing/car-dealer/dfsk-prince-capital-motors-islamabad-48470" },
  { from: "/listing/the-leo-solutions-2", to: "/listing/software-company/the-leo-solutions-48478" },
  { from: "/listing/video-production-services", to: "/listing/advertisers/video-production-services-48492" },
  { from: "/listing/arif-rajan-2", to: "/listing/website-development/arif-rajan-48495" },
  { from: "/listing/__trashed-3", to: "/listing/architecture/mehshan-interiors-48498" },
  { from: "/listing/jeeto-pakistan-helpline", to: "/listing/general-services/jeeto-pakistan-helpline-48505" },
  { from: "/listing/amna-khadija-top-clothing-brand-in-pakistan", to: "/listing/tailors-and-alterations/amna-khadija-|-top-clothing-brand-in-pakistan-48510" },
  { from: "/listing/zain-properties", to: "/listing/property-development/zain-properties-48515" },
  { from: "/listing/the-masters-real-estate-2", to: "/listing/architecture/the-masters-real-estate-48523" },
  { from: "/listing/%d9%85%d8%a7%da%88%d8%b1%d9%86-%da%88%d8%b1%d8%a7%d8%a6%db%8c-%da%a9%d9%84%db%8c%d9%86", to: "/listing/general-services/ماڈرن-ڈرائی-کلین-48526" },
  { from: "/listing/sports-one-international", to: "/listing/advertisers/sports-one-international-48539" },
  { from: "/listing/shaping-nutrition", to: "/listing/gym/shaping-nutrition-48541" },
  { from: "/listing/multan-sofa", to: "/listing/general-services/multan-sofa-48544" },
  { from: "/listing/unofficial-clothing", to: "/listing/shopping-malls/unofficial-clothing-48547" },
  { from: "/listing/cremor", to: "/listing/home-delivery-food/cremor-48550" },
  { from: "/listing/exipure-tropical-weight-loss-formula", to: "/listing/shopping-malls/exipure-|-tropical-weight-loss-formula-48552" },
  { from: "/listing/clothing-brands-in-pakistan", to: "/listing/tailors-and-alterations/clothing-brands-in-pakistan-48564" },
  { from: "/listing/dtactix-agency", to: "/listing/digital-marketing/dtactix-agency-48578" },
  { from: "/listing/pinnacle-house-construction-company", to: "/listing/construction-services/pinnacle-house-construction-company-48585" },
  { from: "/listing/abl-funds", to: "/listing/general-services/abl-funds-48588" },
  { from: "/listing/navaid-trading-co", to: "/listing/general-services/navaid-trading-co-48603" },
  { from: "/listing/al-ameen-travel-and-tours", to: "/listing/travel-agents/al-ameen-travel-and-tours-48606" },
  { from: "/listing/toyzone-pk-2", to: "/listing/driving-schools/toyzone.pk-48610" },
  { from: "/listing/jobsaving-its-all-about-jobsaving", to: "/listing/job-providers/jobsaving---its-all-about-jobsaving-48614" },
  { from: "/listing/bearmaxgroup", to: "/listing/car-denting-painting/bearmaxgroup-48615" },
  { from: "/listing/tiny-treasures", to: "/listing/general-services/tiny-treasures-48623" },
  { from: "/listing/sapphire-properties-3", to: "/listing/property-development/sapphire-properties-48626" },
  { from: "/listing/rozee-pk", to: "/listing/job-providers/rozee.pk-48628" },
  { from: "/listing/macnkro-travel-agency", to: "/listing/travel-agents/macnkro-travel-agency-48631" },
  { from: "/listing/bashir-neurospine-institute-bni", to: "/listing/hospitals/bashir-neurospine-institute-(bni)-48638" },
  { from: "/listing/rudn-enclave-2", to: "/listing/advertisers/rudn-enclave-48642" },
  { from: "/listing/mehran-experts", to: "/listing/construction-services/mehran-experts-48644" },
  { from: "/listing/best-architectural-best-architects-services-in-lahore", to: "/listing/architecture/best-architectural-|-best-architects-services-in-lahore-48648" },
  { from: "/listing/pearl-residencia-2", to: "/listing/property-development/pearl-residencia-48662" },
  { from: "/listing/dcp-fire-extinguishers-in-karachi", to: "/listing/general-services/dcp-fire-extinguishers-in-karachi-48666" },
  { from: "/listing/grey-bricks-mrketing", to: "/listing/property-for-rent/grey-bricks-mrketing-48673" },
  { from: "/listing/ahb-movers-and-packers-lahore", to: "/listing/general-services/ahb-movers-and-packers-lahore-48688" },
  { from: "/listing/beta-web-sol", to: "/listing/digital-marketing/beta-web-sol-48693" },
  { from: "/listing/faisal-town-phase-2", to: "/listing/property-development/faisal-town-phase-2-48699" },
  { from: "/listing/solar-me", to: "/listing/solar-providers/solar-me-48705" },
  { from: "/listing/intellexal-solutions", to: "/listing/software-company/intellexal-solutions-48715" },
  { from: "/listing/dovercourt-immigration", to: "/listing/visa-agencies/dovercourt-immigration-48720" },
  { from: "/listing/best-islamic-insurance-company-in-pakistan", to: "/listing/general-services/best-islamic-insurance-company-in-pakistan-48728" },
  { from: "/listing/aman-medical-services-islamabad-ams", to: "/listing/hospitals/aman-medical-services-islamabad-(ams)-48732" },
  { from: "/listing/seo-google-ranking", to: "/listing/digital-marketing/seo,-google-ranking-48735" },
  { from: "/listing/trip-finders-pvt-ltd", to: "/listing/travel-agents/trip-finders-pvt-ltd.-48740" },
  { from: "/listing/digital-marketing-services-in-usa-the-web-factory", to: "/listing/digital-marketing/digital-marketing-services-in-usa-|-the-web-factory-48742" },
  { from: "/listing/microtechx", to: "/listing/general-services/microtechx-48746" },
  { from: "/listing/rentole", to: "/listing/car-rental/rentole-48749" },
  { from: "/listing/si-global-solutions", to: "/listing/digital-marketing/si-global-solutions-48750" },
  { from: "/listing/master-offisys-work-stations-pakistan", to: "/listing/general-services/master-offisys-work-stations-pakistan-48756" },
  { from: "/listing/flika-studios", to: "/listing/digital-marketing/flika-studios-48759" },
  { from: "/listing/transparent-hands-trust", to: "/listing/doctors-and-clinics/transparent-hands-trust-48760" },
  { from: "/listing/si-global-solutions-2", to: "/listing/digital-marketing/si-global-solutions-48761" },
  { from: "/listing/houseofbamboo", to: "/listing/architecture/houseofbamboo-48764" },
  { from: "/listing/3-way-movers-packers-islamabad", to: "/listing/general-services/3-way-movers-&amp;-packers-islamabad-48766" },
  { from: "/listing/best-office-and-home-furniture-in-dubai", to: "/listing/home-improvement/best-office-and-home-furniture-in-dubai-48773" },
  { from: "/listing/askani-group-of-companies", to: "/listing/general-services/askani-group-of-companies-48776" },
  { from: "/listing/best-driving-school-in-lahore-03006157555-royal-driving", to: "/listing/driving-schools/best-driving-school-in-lahore-03006157555-royal-driving-48779" },
  { from: "/listing/nextstepged", to: "/listing/advertisers/nextstepged-48783" },
  { from: "/listing/imc-hospital", to: "/listing/hospitals/imc-hospital-48788" },
  { from: "/listing/design-1o1", to: "/listing/designer/design-1o1-48805" },
  { from: "/listing/novai-digital", to: "/listing/digital-marketing/novai-digital-48809" },
  { from: "/listing/leaflet-distribution-companies-in-lahore", to: "/listing/general-services/leaflet-distribution-companies-in-lahore-48813" },
  { from: "/listing/film-making-photography", to: "/listing/photographer/film-making,-photography-48820" },
  { from: "/listing/the-skyscraper", to: "/listing/construction-services/the-skyscraper-48827" },
  { from: "/listing/latest-mobile-phone-specs-prices-in-pakistan", to: "/listing/software-company/latest-mobile-phone-specs-&amp;-prices-in-pakistan-48831" },
  { from: "/listing/behtar-zindagi-centre-multan", to: "/listing/doctors-and-clinics/behtar-zindagi-centre-multan-48835" },
  { from: "/listing/happy-cattle-dairies", to: "/listing/animals-&amp;-birds/happy-cattle-dairies-48846" },
  { from: "/listing/coahing-center-pk", to: "/listing/general-services/coahing-center-pk-48851" },
  { from: "/listing/modera-enterprises", to: "/listing/designer/modera-enterprises-48853" },
  { from: "/listing/si-global-solutions-3", to: "/listing/digital-marketing/si-global-solutions-48860" },
  { from: "/listing/cafe-romansu-one-of-the-best-restaurants-in-dha-lahore", to: "/listing/restaurants/cafe-romansu---one-of-the-best-restaurants-in-dha-lahore-48865" },
  { from: "/listing/zr-fabrics", to: "/listing/shopping-malls/zr-fabrics-48879" },
  { from: "/listing/leopard-tracking", to: "/listing/verification-services/leopard-tracking-48883" },
  { from: "/listing/systechware", to: "/listing/software-company/systechware-48886" },
  { from: "/listing/online-delivery-management-software", to: "/listing/designer/online-delivery-management-software-48898" },
  { from: "/listing/brand-medic", to: "/listing/hospitals/brand-medic-48903" },
  { from: "/listing/samz-makeup-studio-salon", to: "/listing/beauty-parlour/sam'z-makeup-studio-&amp;-salon-48908" },
  { from: "/listing/sme-bank-loan-for-small-business", to: "/listing/verification-services/sme-bank---loan-for-small-business-48915" },
  { from: "/listing/customer-satisfaction-survey-app", to: "/listing/designer/customer-satisfaction-survey-app-48927" },
  { from: "/listing/eshaitsic", to: "/listing/shopping-malls/eshaitsic-48930" },
  { from: "/listing/business-consultant-and-immigrations-services", to: "/listing/visa-agencies/business-consultant-and-immigrations-services-48932" },
  { from: "/listing/buying-and-selling-marketplace", to: "/listing/general-services/buying-and-selling-marketplace-48938" },
  { from: "/listing/arcue-technologies-a-software-house", to: "/listing/software-company/arcue-technologies---a-software-house-48958" },
  { from: "/listing/at-luxus-marketing", to: "/listing/property-development/at-luxus-marketing-48961" },
  { from: "/listing/stackup-solutions-provides-the-best-results-of-web-developme", to: "/listing/general-services/stackup-solutions-provides-the-best-results-of-web-developme-48967" },
  { from: "/listing/rose-wood-furniture", to: "/listing/construction-services/rose-wood-furniture-48970" },
  { from: "/listing/valour-education", to: "/listing/colleges/valour-education-48977" },
  { from: "/listing/syed-brothers", to: "/listing/construction-services/syed-brothers-48980" },
  { from: "/listing/digital-media-point", to: "/listing/digital-marketing/digital-media-point-48983" },
  { from: "/listing/al-nehan-car-rental-service", to: "/listing/car-rental/al-nehan-car-rental-service-48986" },
  { from: "/listing/the-outsource-company", to: "/listing/digital-marketing/the-outsource-company-48991" },
  { from: "/listing/filer-tax", to: "/listing/general-services/filer-tax-48996" },
  { from: "/listing/shah-water-tank-cleaning-service", to: "/listing/waterproofing-company/shah-water-tank-cleaning-service-49000" },
  { from: "/listing/jns-education", to: "/listing/universities/jns-education-49011" },
  { from: "/listing/geyser-services", to: "/listing/plumbers/geyser-services-49024" },
  { from: "/listing/cosmetique", to: "/listing/hospitals/cosmetique-49035" },
  { from: "/listing/green-palms-cpec", to: "/listing/property-development/green-palms--cpec-49041" },
  { from: "/listing/masteroffisys", to: "/listing/general-services/masteroffisys-49047" },
  { from: "/listing/rholab-interactive", to: "/listing/welding-services/rholab-interactive-49052" },
  { from: "/listing/best-seo-expert-in-pakistan", to: "/listing/digital-marketing/best-seo-expert-in-pakistan-49057" },
  { from: "/listing/step-schools", to: "/listing/book-stores/step-schools-49060" },
];

export default oldLinks;
