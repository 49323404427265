import moment from "moment";
import { AiFillStar } from "react-icons/ai";

const urlMaker = (param) => {
  return `${param
    .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
    .replace(/-+/g, "-")
    .toLowerCase()}`;
};

const linkify = (t) => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = t.replace(replacePattern1, "$1");

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, "http://$2");

  return replacedText.includes("http") ? replacedText : "http://" + replacedText;
};

const calculateRating = (param) => {
  let totalRating = 0;
  param.reviews.forEach((item, i) => {
    if (true) {
      let avg = (Number(item.cleanliness) + Number(item.location) + Number(item.staff) + Number(item.comfort) + Number(item.money)) / 5;
      totalRating += avg;
    } else {
      totalRating += parseInt(item.business);
    }
  });
  if (totalRating != 0) {
    totalRating = totalRating / param.reviews.length;
  }
  return Number(totalRating.toFixed(2));
};

const calculateRatingSingle = (item) => {
  let avg = (Number(item.cleanliness) + Number(item.location) + Number(item.staff) + Number(item.comfort) + Number(item.money)) / 5;
  return Number(avg);
};

const calculateFoodRatingSingle = (item) => {
  let avg = (Number(item.cleanliness) + Number(item.location) + Number(item.staff) + Number(item.comfort) + Number(item.money)) / 5;
  return Number(avg);
};

const calculateReviewsBusiness = (param) => {
  var array = {
    bad: 0,
    poor: 0,
    average: 0,
    great: 0,
    excellent: 0,
  };
  param.forEach((item, i) => {
    if (item.business == 1) array.bad++;
    else if (item.business == 2) array.poor++;
    else if (item.business == 3) array.average++;
    else if (item.business == 4) array.great++;
    else if (item.business == 5) array.excellent++;
  });
  array.total = param && param.length;
  return array;
};

const calculateReviewsOther = (param) => {
  var array = {
    cleanliness: 0,
    location: 0,
    staff: 0,
    comfort: 0,
    money: 0,
    facilities: 0,
    wifi: 0,
  };
  param.forEach((item, i) => {
    array.cleanliness += Number(item.cleanliness);
    array.location += Number(item.location);
    array.staff += Number(item.staff);
    array.comfort += Number(item.comfort);
    array.money += Number(item.money);
    array.facilities += Number(item.facilities);
    array.wifi += Number(item.wifi);
  });
  array.cleanliness = array.cleanliness / param.length;
  array.location = array.location / param.length;
  array.staff = array.staff / param.length;
  array.comfort = array.comfort / param.length;
  array.money = array.money / param.length;
  array.facilities = array.facilities / param.length;
  array.wifi = array.wifi / param.length;
  array.total = param && param.length;
  return array;
};

const calculateReviewsFood = (param) => {
  var array = {
    cleanliness: 0,
    location: 0,
    staff: 0,
    comfort: 0,
    money: 0,
  };
  param.forEach((item, i) => {
    array.cleanliness += Number(item.cleanliness);
    array.location += Number(item.location);
    array.staff += Number(item.staff);
    array.comfort += Number(item.comfort);
    array.money += Number(item.money);
  });
  array.cleanliness = (array.cleanliness / param.length || 0).toFixed(1);
  array.location = (array.location / param.length || 0).toFixed(1);
  array.staff = (array.staff / param.length || 0).toFixed(1);
  array.comfort = (array.comfort / param.length || 0).toFixed(1);
  array.money = (array.money / param.length || 0).toFixed(1);
  array.total = param && param.length;
  return array;
};

const stars = (limit, size = 20) => {
  var color;
  if (limit > 0 && limit <= 1) {
    color = "red";
  } else if (limit > 1 && limit <= 2) {
    color = "orange";
  } else if (limit > 2 && limit <= 3) {
    color = "yellow";
  } else if (limit > 3 && limit <= 4) {
    color = "lightgreen";
  } else if (limit > 4 && limit < 5) {
    color = "green";
  } else {
    color = "#94292D";
  }

  var rows = [];
  for (var i = 1; i <= limit; i++) {
    rows.push(
      <>
        <AiFillStar className="d-none d-md-block" color="orange" size={size} key={i} />

        <div className=" mr-1 d-block d-md-none" style={{ backgroundColor: color, borderRadius: "6px", padding: "3px 4px" }}>
          <AiFillStar color="white" size={size} key={i} />
        </div>
      </>
    );
  }

  for (var i = rows.length; i < 5; i++) {
    rows.push(
      <>
        <AiFillStar className="d-none d-md-block" color="grey" size={size} key={i} />
        <div className=" mr-1 d-md-none d-block align-self-center" style={{ borderRadius: "6px", padding: "3px 4px", backgroundColor: "#ececec" }}>
          <AiFillStar color="white" size={size} key={i} />
        </div>
      </>
    );
  }

  return <span className="d-flex">{rows}</span>;
};

const isFood = (param) => {
  return param.category.toLowerCase().includes("take aways") || param.category.toLowerCase().includes("cafes") || param.category.toLowerCase().includes("restaurants");
};

const isTravel = (param) => {
  return param.category.toLowerCase().includes("apartments") || param.category.toLowerCase().includes("hotels") || param.category.toLowerCase().includes("guest house");
};

const openOrClose = (hour) => {
  const start = moment(hour.start, "HH:mm");
  const end = moment(hour.end, "HH:mm");
  const current = moment(new Date());

  if (current.isAfter(start) && current.isBefore(end)) {
    return "Open Now";
  } else {
    return "Closed";
  }
};

const formatPrice = (price) => {
  return new Intl.NumberFormat("en-PK", { maximumFractionDigits: 0, minimumFractionDigits: 0, style: "currency", currency: "PKR" }).format(price);
};

export {
  urlMaker,
  linkify,
  calculateRating,
  calculateFoodRatingSingle,
  calculateRatingSingle,
  calculateReviewsBusiness,
  calculateReviewsOther,
  calculateReviewsFood,
  stars,
  isFood,
  isTravel,
  openOrClose,
  formatPrice,
};
