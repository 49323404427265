import React, { useState } from "react";
import { BiHomeAlt, BiSearchAlt2, BiPlus } from "react-icons/bi";
import { BsSuitHeart, BsPerson } from "react-icons/bs";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/User";
import { Auth } from "../../../Context/Auth.context";
import "./MobileFooter.scss";
import { urlMaker } from "../../../helpers";

function Mobile() {
  const { isAuthenticated, location } = Auth();
  const [menu, setMenu] = useState(false);

  return (
    <div id="footer">
      <div className="footer bg-white" style={{ zIndex: "2" }}>
        <div className="d-flex justify-content-between">
          <Link to="/">
            <div>
              <BiHomeAlt className="footer-icon" />
              <p>Home</p>
            </div>
          </Link>
          <Link to={`/listing/search/${location && location.city ? `city_${urlMaker(location.city)}/` : ""}`}>
            <div>
              <BiSearchAlt2 className="footer-icon" />
              <p>Search</p>
            </div>
          </Link>
          <Link to="/submit-listing">
            <div className="add bg-white">
              <BiPlus className="footer-icon" />
            </div>
            <div>
              <p className="align-self-center pt-2">Add</p>
            </div>{" "}
          </Link>
          <Link to="/events-search">
            <BsSuitHeart className="footer-icon" />
            <p>Events</p>
          </Link>
          {isAuthenticated ? (
            <div onClick={() => setMenu(true)}>
              <BsPerson className="footer-icon" />
              <p>Account</p>
            </div>
          ) : (
            <Link to="/sign-in">
              <BsPerson className="footer-icon" />
              <p>Account</p>
            </Link>
          )}
        </div>
      </div>
      <Sidebar menu={menu} setMenu={setMenu} />
    </div>
  );
}
export default Mobile;
