import React from "react";
import { useLocation } from "react-router-dom";
import MenuSidebar from "../Components/Desktop/Headers/MenuSidebar";

function AuthorizedLayout({ children }) {
  const { pathname } = useLocation();

  return (
    <div className="container-fluid-1">
      {pathname != "/checkout" && !pathname.includes("/thank-you/") && <MenuSidebar />}
      {children}
    </div>
  );
}

export default AuthorizedLayout;
