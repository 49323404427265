import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { Row, Col, Button } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import Autocomplete from "react-autocomplete";
import { urlMaker } from "../../../../helpers";
import { FaTimes } from "react-icons/fa";
import "./SearchBar.scss";
import { Auth } from "../../../../Context/Auth.context";

const SearchBar = () => {
  const { pathname } = useLocation();

  return pathname.includes("/sms") ? (
    <SMSSearch />
  ) : pathname.includes("/mobile") ? (
    <MobileSearch />
  ) : pathname.includes("/ring-tones") || pathname.includes("/ringtones") ? (
    <RingtoneSearch />
  ) : pathname.includes("/names") ? (
    <NamesSearch />
  ) : pathname.includes("/stock") ? (
    <StockSearch />
  ) : pathname.includes("/event") ? (
    <EventSearch />
  ) : (
    <ListingSearch />
  );
};

const SMSSearch = () => {
  const getValue = (value) => {
    if (value.type == "Message") {
      return `/sms/${urlMaker(value.category)}/${urlMaker(value.value.split(" ").slice(0, 3).join(" ").replaceAll(" ", "-").toLowerCase())}-${value.id}`;
    } else {
      return `/sms/${urlMaker(value.value)}-${value.id}`;
    }
  };

  return <PureComponent url="messages/search?str=" placeholder="Search SMS & Quotes" getValue={getValue} condition={"Message"} />;
};

const NamesSearch = () => {
  const getValue = (value) => {
    if (value.type == "Category") {
      return `/names/search/pcategory_${urlMaker(value.label)}_${value.id}/`;
    } else {
      return `/names/${urlMaker(value.category)}/${urlMaker(value.label)}-${value.id}`;
    }
  };

  return <PureComponent url="names/search?str=" placeholder="Search Names" getValue={getValue} condition={"Name"} />;
};

const StockSearch = () => {
  const getValue = (value) => {
    if (value.type == "Category") {
      return `/stock/${urlMaker(value.label)}_${value.id}/`;
    } else {
      return `/stock/${urlMaker(value.category)}/${urlMaker(value.label)}_${value.id}`;
    }
  };

  return <PureComponent url="stock/search?str=" placeholder="Search Stock Images & Video" getValue={getValue} condition={"Stock"} />;
};

const RingtoneSearch = () => {
  const getValue = (value) => {
    if (value.type == "Ringtone") {
      return `/ringtones/${urlMaker(value.category)}/${urlMaker(value.value)}-${value.id}`;
    } else {
      return `/ringtones/${urlMaker(value.value)}_${value.id}`;
    }
  };

  return <PureComponent url="ringtones/search?str=" placeholder="Search Ringtones & Category" getValue={getValue} condition={"Ringtone"} />;
};

const MobileSearch = () => {
  const getValue = (value) => {
    if (value.type == "Mobile") {
      return `/mobile/${urlMaker(value.value)}_${value.id}`;
    } else {
      return `/mobiles/search/-/bd_${urlMaker(value.value)}_${value.id}`;
    }
  };

  return <PureComponent url="mobilelisting/search?str=" placeholder="Search Mobile Phones & Brands" getValue={getValue} condition="Mobile" />;
};

const PureComponent = ({ url, placeholder, getValue, condition }) => {
  const { push } = useHistory();
  const [value, setValue] = useState();
  const [suggestions, setSuggestions] = useState([]);

  const search = (e) => {
    const value = e.target.value;
    setValue(value);

    if (value.length > 1) {
      Axios.get(`${process.env.REACT_APP_API_URL}${url}${value}`).then((res) => {
        const { data } = res;

        setSuggestions(data);
      });
    }
  };

  const goTo = (value) => {
    push(value);
  };

  return (
    <Row
      className="no-gutters mr-xl-5 mr-lg-5 border rounded"
      style={{
        borderTopLeftRadius: "6px",
        borderBottomLeftRadius: "6px",
      }}
    >
      <Col xl={11} lg={10} xs={5}>
        <Row className="no-gutters bg-white rounded-left p-2">
          <Col xl={12} lg={12} md={12}>
            <div className="">
              <Autocomplete
                items={suggestions}
                renderItem={(item, isHighlighted) => (
                  <div
                    className="p-3 text-overflow"
                    style={{
                      background: isHighlighted ? "lightgray" : "white",
                    }}
                  >
                    {item.type == condition ? <BsSearch /> : <BiCategoryAlt />} <span className="ml-2 mt-1">{item.label}</span>
                  </div>
                )}
                value={value}
                inputProps={{
                  placeholder,
                  style: autoCompleteInputProps,
                }}
                wrapperProps={{ className: "auto-complete" }}
                wrapperStyle={{ width: "100%" }}
                menuStyle={autoCompleteMenuStyle}
                // onSelect={(item) => searchStr(item)}
                onSelect={goTo}
                onChange={search}
                getItemValue={getValue}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col xl={1} lg={2} xs={2}>
        <Button variant="danger" style={buttonStyle} className="rounded-right w-100">
          <span style={{ fontSize: "20px" }}>{value ? <FaTimes onClick={() => setValue()} /> : <BsSearch />}</span>
        </Button>
      </Col>
    </Row>
  );
};

const EventSearch = () => {
  const { push } = useHistory();
  const { changeCity } = Auth();
  const [value, setValue] = useState();
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const search = (e) => {
    const value = e.target.value;
    setValue(value);

    if (value.length > 1) {
      Axios.get(`${process.env.REACT_APP_API_URL}event/search?str=${value}`).then((res) => {
        const { data } = res;

        setSuggestions(data);
      });
    }
  };
  const searchLocation = (e) => {
    const value = e.target.value;
    setLocation(value);

    if (value.length > 1) {
      Axios.get(`${process.env.REACT_APP_API_URL}city/search?str=${value}`).then((res) => {
        const { data } = res;

        setLocations(data);
      });
    }
  };

  const searchEvent = () => {
    var event = suggestions.filter((item) => item.value == value);
    var city = locations.filter((item) => item.value == location);
    if (city.length > 0) {
      if (city[0].type == "City") changeCity(city[0].value);
      city = `${urlMaker(city[0].type)}_${urlMaker(city[0].value)}/`;
    } else {
      city = location ? `city_${urlMaker(location)}/` : "";
    }

    if (event.length > 0) {
      getValue(event[0], city);
    } else {
      event = value ? `search_${urlMaker(value)}/` : "";
      push(`/event/search/${event}${city}`);
    }
  };

  const getValue = (value, city) => {
    if (value.type == "Event") {
      push(`/event/${urlMaker(value.category)}/${urlMaker(value.value)}-${value.id}`);
    } else if (value.type == "SubCategory") {
      push(`/event/search/${urlMaker(value.value)}_s-${value.id}/${city}`);
    } else {
      push(`/event/search/${urlMaker(value.value)}_c-${value.id}/${city}`);
    }
  };

  return (
    <Row
      className="no-gutters mr-xl-5 mr-lg-5 border rounded"
      style={{
        borderTopLeftRadius: "6px",
        borderBottomLeftRadius: "6px",
      }}
    >
      <Col xl={11} lg={10} xs={5}>
        <form autoComplete="off">
          <Row className="no-gutters bg-white rounded-left p-2">
            <Col xl={6} lg={6} md={6}>
              <div className="">
                <Autocomplete
                  items={suggestions}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="p-3 text-overflow"
                      style={{
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      {item.type == "Event" ? <BsSearch /> : <BiCategoryAlt />} <span className="ml-2 mt-1">{item.label}</span>
                    </div>
                  )}
                  value={value}
                  inputProps={{
                    placeholder: "Search Events",
                    style: autoCompleteInputProps,
                  }}
                  wrapperProps={{ className: "auto-complete" }}
                  wrapperStyle={{ width: "100%" }}
                  menuStyle={autoCompleteMenuStyle}
                  // onSelect={(item) => searchStr(item)}
                  onSelect={(e) => setValue(e)}
                  onChange={search}
                  getItemValue={(e) => e.value}
                />
              </div>
            </Col>
            <Col className="border-left" xl={6} lg={6} md={6}>
              <div>
                <Autocomplete
                  items={locations}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="p-3 text-overflow"
                      style={{
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      <BsSearch /> <span className="ml-2 mt-1">{item.label}</span>
                    </div>
                  )}
                  value={location}
                  inputProps={{
                    placeholder: "City or Area",
                    style: autoCompleteInputProps,
                  }}
                  wrapperProps={{ className: "auto-complete" }}
                  wrapperStyle={{ width: "100%" }}
                  menuStyle={autoCompleteMenuStyle}
                  // onSelect={(item) => searchStr(item)}
                  onSelect={(e) => setLocation(e)}
                  onChange={searchLocation}
                  getItemValue={(e) => e.value}
                />
              </div>
            </Col>
          </Row>
        </form>
      </Col>
      <Col xl={1} lg={2} xs={2}>
        <Button onClick={searchEvent} variant="danger" style={buttonStyle} className="rounded-right w-100">
          <span style={{ fontSize: "20px" }}>
            <BsSearch />
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const ListingSearch = () => {
  const { push } = useHistory();
  const { changeCity } = Auth();
  const [value, setValue] = useState();
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const search = (e) => {
    const value = e.target.value;
    setValue(value);

    if (value.length > 1) {
      Axios.get(`${process.env.REACT_APP_API_URL}listing/search?str=${value}`).then((res) => {
        const { data } = res;

        setSuggestions(data);
      });
    }
  };
  const searchLocation = (e) => {
    const value = e.target.value;
    setLocation(value);

    if (value.length > 1) {
      Axios.get(`${process.env.REACT_APP_API_URL}city/search?str=${value}`).then((res) => {
        const { data } = res;

        setLocations(data);
      });
    }
  };

  const searchListing = () => {
    var listing = suggestions.filter((item) => item.value == value);
    var city = locations.filter((item) => item.value == location);
    if (city.length > 0) {
      if (city[0].type == "City") changeCity(city[0].value);
      city = `${urlMaker(city[0].type)}_${urlMaker(city[0].value)}/`;
    } else {
      city = location ? `city_${urlMaker(location)}/` : "";
    }

    if (listing.length > 0) {
      getValue(listing[0], city);
    } else {
      listing = value ? `search_${urlMaker(value)}/` : "";
      push(`/listing/search/${listing}${city}`);
    }
  };

  const getValue = (value, city) => {
    if (value.type == "Listing") {
      push(`/listing/${urlMaker(value.category)}/${urlMaker(value.value)}-${value.id}`);
    } else if (value.type == "SubCategory") {
      push(`/listing/search/${urlMaker(value.value)}_sc-${value.id}/${city}`);
    } else {
      push(`/listing/search/${urlMaker(value.value)}_c-${value.id}/${city}`);
    }
  };

  return (
    <div id="desktop-search-bar">
      <Row
        className="no-gutters mr-xl-5 mr-lg-5 border rounded"
        style={{
          borderTopLeftRadius: "6px",
          borderBottomLeftRadius: "6px",
        }}
      >
        <Col xl={11} lg={10} xs={5}>
          <form autoComplete="off">
            <Row className="no-gutters bg-white rounded-left p-2">
              <Col xl={6} lg={6} md={6}>
                <div className="">
                  <Autocomplete
                    items={suggestions}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className="p-3 text-overflow"
                        style={{
                          background: isHighlighted ? "lightgray" : "white",
                        }}
                      >
                        {item.type == "Listing" ? <BsSearch /> : <BiCategoryAlt />} <span className="ml-2 mt-1">{item.label}</span>
                      </div>
                    )}
                    value={value}
                    inputProps={{
                      placeholder: "Search Businesses",
                      style: autoCompleteInputProps,
                    }}
                    wrapperProps={{ className: "auto-complete" }}
                    wrapperStyle={{ width: "100%" }}
                    menuStyle={autoCompleteMenuStyle}
                    // onSelect={(item) => searchStr(item)}
                    onSelect={(e) => setValue(e)}
                    onChange={search}
                    getItemValue={(e) => e.value}
                  />
                </div>
              </Col>
              <Col className="border-left" xl={6} lg={6} md={6}>
                <div>
                  <Autocomplete
                    items={locations}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className="p-3 text-overflow"
                        style={{
                          background: isHighlighted ? "lightgray" : "white",
                        }}
                      >
                        <BsSearch /> <span className="ml-2 mt-1">{item.label}</span>
                      </div>
                    )}
                    value={location}
                    inputProps={{
                      placeholder: "City or Area",
                      style: autoCompleteInputProps,
                    }}
                    wrapperProps={{ className: "auto-complete" }}
                    wrapperStyle={{ width: "100%" }}
                    menuStyle={autoCompleteMenuStyle}
                    // onSelect={(item) => searchStr(item)}
                    onSelect={(e) => setLocation(e)}
                    onChange={searchLocation}
                    getItemValue={(e) => e.value}
                  />
                </div>
              </Col>
            </Row>
          </form>
        </Col>
        <Col xl={1} lg={2} xs={2}>
          <Button onClick={searchListing} variant="danger" style={buttonStyle} className="rounded-right w-100">
            <span style={{ fontSize: "20px" }}>
              <BsSearch />
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchBar;

var buttonStyle = {
  height: "47px",
  borderRadius: "0px",
};
const autoCompleteInputProps = {
  width: "100%",
  marginRight: "10px",
  borderRadius: "3px",
  border: "0px solid #000",
  padding: "3.5px 10px",
  backgroundColor: "#fff",
  outline: "none",
};
const autoCompleteMenuStyle = {
  borderRadius: "3px",
  border: "1px solid #dfdfdf",
  background: "rgba(255, 255, 255, 1)",
  fontSize: "90%",
  overflow: "auto",
  position: "absolute",
  top: "40px",
  left: "-9px",
  cursor: "pointer",
  maxHeight: "177px",
  width: "100%",
  opacity: "1",
  zIndex: "100",
};
