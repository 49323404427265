import Axios from "axios";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import "./CSS/ErrorBoundry.css";

function ErrorFallback({ error, resetErrorBoundary }) {
  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_API_URL}error`, { url: window.location.href, error: error?.message || "NO ERROR MESSAGE" });
  }, [error]);

  return (
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1>
            5<span></span>3
          </h1>
        </div>
        <h2>Oops! Something went wrong</h2>
        <p>Sorry but the page you are looking for is either temporarily unavailable, does not exist.</p>
        <a href="/">Back to homepage</a>
      </div>
    </div>
  );
}

const ErrorBound = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBound;
