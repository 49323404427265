import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useState } from "react";
import { Auth } from "../Context/Auth.context";
import WebsiteRoutes from "./Website";
import ProtectedRoutes from "./Protected";
import ScrollToTop from "../Components/ScrollToTop";
import AuthorizedLayout from "../Layouts/Authorized.layout";
import WebsiteLayout from "../Layouts/Website.layout";
import SEO from "../SEO/SEO.js";
import OldRoutes from "./old";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-131763000-1";

ReactGA.initialize(TRACKING_ID);

const Checkout = React.lazy(() => import("../Views/Checkout/"));
const ThankYou = React.lazy(() => import("../Views/Checkout/ThankYou"));

export default function Routes() {
  const { isAuthenticated } = Auth();
  const oldRoutes = OldRoutes;
  const websiteRoutes = WebsiteRoutes();
  const protectedRoutes = ProtectedRoutes();

  const user = localStorage.getItem("user") && localStorage.getItem("user") != "undefined" ? JSON.parse(localStorage.getItem("user")) : {};
  const [size, setSize] = useState(window.innerWidth);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.addEventListener("resize", (event) => {
      setSize(window.innerWidth);
    });
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <SEO />

      <WebsiteLayout size={size}>
        <Suspense fallback={<></>}>
          <Switch>
            <Route path="/checkout" component={(props) => <Checkout {...props} />} />
            <Route path="/thank-you/:id" component={(props) => <ThankYou {...props} />} />
            {oldRoutes &&
              oldRoutes.map((oldRoute, i) => (
                <Route
                  key={i}
                  path={oldRoute.from}
                  exact={true}
                  render={() => {
                    console.log(oldRoute);
                    if (oldRoute.to) {
                      return <Redirect to={oldRoute.to} />;
                    } else {
                      window.location.href = "https://citybook.pk/blog/" + oldRoute.from;
                    }
                  }}
                />
              ))}
            {websiteRoutes &&
              websiteRoutes.map((websiteRoute, i) => (
                <Route
                  key={i}
                  path={websiteRoute.path}
                  exact={websiteRoute.exact}
                  component={size > 480 ? websiteRoute.desktopModule : websiteRoute.mobileModule ? websiteRoute.mobileModule : websiteRoute.desktopModule}
                />
              ))}
            {isAuthenticated ? (
              <AuthorizedLayout>
                <Switch>
                  {protectedRoutes &&
                    protectedRoutes.map((protectedRoute, i) => (
                      <Route
                        key={i}
                        path={protectedRoute.path}
                        exact={protectedRoute.exact}
                        component={size > 480 ? protectedRoute.desktopModule : protectedRoute.mobileModule ? protectedRoute.mobileModule : protectedRoute.desktopModule}
                      />
                    ))}
                </Switch>
              </AuthorizedLayout>
            ) : (
              <Route path="*" component={() => <Redirect to="/sign-in" />} />
            )}
          </Switch>
        </Suspense>
      </WebsiteLayout>
    </Router>
  );
}
