import { useState } from "react";
import Axios from "axios";
import { Form, Modal, ModalBody } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { FiChevronsLeft } from "react-icons/fi";
import { BiCategoryAlt } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import { urlMaker } from "../../../helpers";
import { Link } from "react-router-dom";

const SearchBar = () => {
  const { pathname } = useLocation();

  return pathname.includes("/sms") ? (
    <SMSSearch />
  ) : pathname.includes("/mobile") ? (
    <MobileSearch />
  ) : pathname.includes("/ring-tones") || pathname.includes("/ringtones") ? (
    <RingtoneSearch />
  ) : pathname.includes("/names") ? (
    <NamesSearch />
  ) : pathname.includes("/stock") ? (
    <StockSearch />
  ) : pathname.includes("/event") ? (
    <EventSearch />
  ) : (
    <ListingSearch />
  );
};

const SMSSearch = () => {
  const getValue = (value) => {
    if (value.type == "Message") {
      return `/sms/${urlMaker(value.category)}/${urlMaker(value.value.split(" ").slice(0, 3).join(" ").replaceAll(" ", "-").toLowerCase())}-${value.id}`;
    } else {
      return `/sms/${urlMaker(value.value)}-${value.id}`;
    }
  };

  return <PureComponent url="messages/search?str=" placeholder="Search SMS & Quotes" getValue={getValue} condition={"Message"} />;
};

const EventSearch = () => {
  const getValue = (value) => {
    if (value.type == "Event") {
      return `/event/${urlMaker(value.category)}/${urlMaker(value.value)}-${value.id}`;
    } else if (value.type == "SubCategory") {
      return `/event/search/${urlMaker(value.value)}_s-${value.id}/`;
    } else {
      return `/event/search/${urlMaker(value.value)}_c-${value.id}/`;
    }
  };

  return <PureComponent url="event/search?str=" placeholder="Search Events" getValue={getValue} condition={"Event"} />;
};

const ListingSearch = () => {
  const getValue = (value) => {
    if (value.type == "Listing") {
      return `/listing/${urlMaker(value.category)}/${urlMaker(value.value)}-${value.id}`;
    } else if (value.type == "SubCategory") {
      return `/listing/search/${urlMaker(value.value)}_sc-${value.id}/`;
    } else {
      return `/listing/search/${urlMaker(value.value)}_c-${value.id}/`;
    }
  };

  return <PureComponent url="listing/search?str=" placeholder="Search Listing" getValue={getValue} condition={"Listing"} />;
};

const StockSearch = () => {
  const getValue = (value) => {
    if (value.type == "Category") {
      return `/stock/${urlMaker(value.label)}_${value.id}/`;
    } else {
      return `/stock/${urlMaker(value.category)}/${urlMaker(value.label)}_${value.id}`;
    }
  };

  return <PureComponent url="stock/search?str=" placeholder="Search Stock Images & Video" getValue={getValue} condition={"Stock"} />;
};

const NamesSearch = () => {
  const getValue = (value) => {
    if (value.type == "Category") {
      return `/names/search/pcategory_${urlMaker(value.label)}_${value.id}/`;
    } else {
      return `/names/${urlMaker(value.category)}/${urlMaker(value.label)}-${value.id}`;
    }
  };

  return <PureComponent url="names/search?str=" placeholder="Search Names" getValue={getValue} condition={"Name"} />;
};

const RingtoneSearch = () => {
  const getValue = (value) => {
    if (value.type == "Ringtone") {
      return `/ringtones/${urlMaker(value.category)}/${urlMaker(value.value)}-${value.id}`;
    } else {
      return `/ringtones/${urlMaker(value.value)}_${value.id}`;
    }
  };

  return <PureComponent url="ringtones/search?str=" placeholder="Search Ringtones & Category" getValue={getValue} condition={"Ringtone"} />;
};

const MobileSearch = () => {
  const getValue = (value) => {
    if (value.type == "Mobile") {
      return `/mobile/${urlMaker(value.value)}_${value.id}`;
    } else {
      return `/mobiles/search/-/bd_${urlMaker(value.value)}_${value.id}`;
    }
  };

  return <PureComponent url="mobilelisting/search?str=" placeholder="Search Mobile Phones & Brands" getValue={getValue} condition="Mobile" />;
};

const PureComponent = ({ url, placeholder, getValue, condition }) => {
  const { push } = useHistory();

  const [value, setValue] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [modal, setModal] = useState();

  const search = (e) => {
    const value = e.target.value;
    setValue(value);

    if (value.length > 1) {
      Axios.get(`${process.env.REACT_APP_API_URL}${url}${value}`).then((res) => {
        const { data } = res;

        setSuggestions(data);
      });
    }
  };

  const goTo = (value) => {
    push(value);
  };

  return (
    // <>
    //   <Autocomplete
    //     items={suggestions}
    //     renderItem={(item, isHighlighted) => (
    //       <div
    //         className="p-3 text-overflow"
    //         style={{
    //           background: isHighlighted ? "lightgray" : "white",
    //         }}
    //       >
    //         {item.type == condition ? <BsSearch /> : <BiCategoryAlt />} <span className="ml-2 mt-1">{item.label}</span>
    //       </div>
    //     )}
    //     value={value}
    //     inputProps={{
    //       placeholder,
    //       style: autoCompleteInputProps,
    //     }}
    //     wrapperProps={{ className: "auto-complete" }}
    //     wrapperStyle={{ width: "100%" }}
    //     menuStyle={autoCompleteMenuStyle}
    //     // onSelect={(item) => searchStr(item)}
    //     onSelect={goTo}
    //     onChange={search}
    //     getItemValue={getValue}
    //   />
    //   {value ? <FaTimes onClick={() => setValue()} className="search-icon" /> : <BsSearch className="search-icon" />}
    // </>
    <>
      <div className="p-1 w-100 text-overflow text-muted" onClick={() => setModal(!modal)}>
        {placeholder}
      </div>
      <div>
        <Modal show={modal} id="search-mobile-modal">
          <ModalBody>
            <div className="custom-search d-flex flex-column">
              <div className="d-flex p-2">
                <div className="ml-n1 align-self-center mx-2">
                  <FiChevronsLeft size="30" onClick={() => setModal(!modal)} />
                </div>
                <div className="d-flex justify-content-between bg-light" style={{ borderRadius: "8px", padding: "5px 15px" }}>
                  <div className="d-flex align-self-center">
                    <img className="mr-1 align-self-center" src="../../../../city book logo.png" style={{ width: "30px", height: "30px", objectFit: "contain" }} />
                    <input className="form-control border-0 align-self-center custom-input px-1" placeholder={placeholder} value={value ? value : ""} onChange={search} />
                  </div>
                  <div className="align-self-center pl-2">
                    {value ? (
                      <span onClick={() => setValue()}>
                        <FaTimes className="search-icon" />
                      </span>
                    ) : (
                      <BsSearch className="search-icon" />
                    )}
                  </div>
                </div>
              </div>
              <div className="border " style={{ height: "100%", overflow: "scroll" }}>
                {suggestions.map((item) => (
                  <div
                    onClick={() => {
                      goTo(getValue(item));
                      setModal(!modal);
                    }}
                    className="text-dark"
                  >
                    <div className="p-2 text-overflow">
                      {item.type == condition ? <BsSearch /> : <BiCategoryAlt />} <span className="ml-2 mt-1">{item.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default SearchBar;

const autoCompleteInputProps = {
  width: "100%",
  marginRight: "10px",
  borderRadius: "3px",
  border: "0px solid #000",
  padding: "5px 10px",
  backgroundColor: "#faf9f6",
  outline: "none",
};

const autoCompleteMenuStyle = {
  borderRadius: "10px",
  border: "1px solid #dfdfdf",
  background: "rgba(255, 255, 255, 1)",
  fontSize: "90%",
  overflow: "auto",
  position: "absolute",
  top: "57px",
  left: "53px",
  cursor: "pointer",
  maxHeight: "177px",
  width: "71.5%",
  opacity: "1",
  zIndex: "100",
};
