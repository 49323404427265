import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { FaBusinessTime, FaUserCircle, FaChevronRight, FaRegComments, FaBox, FaCameraRetro, FaChevronDown, FaHouseUser, FaCogs, FaBaby, FaCarSide, FaBlog, FaYoutube } from "react-icons/fa";
import { BiNews, BiCategory, BiFoodMenu, BiBuildingHouse, BiMessageRoundedError } from "react-icons/bi";
import { BsCalendar2Event, BsCodeSlash, BsPrinter, BsShopWindow, BsBuilding, BsPinMap, BsShieldLock, BsMusicNoteBeamed, BsCalendar4Event, BsCurrencyDollar, BsBox } from "react-icons/bs";
import { GiGoldBar, GiGasPump, GiTakeMyMoney, GiClothesline, GiKitchenTap, GiCarSeat, GiOpenedFoodCan, GiFoodTruck, GiAirplane, GiPlanePilot, GiKeyring } from "react-icons/gi";
import { GrBike, GrRestaurant } from "react-icons/gr";
import { FiHelpCircle, FiMoreVertical } from "react-icons/fi";
import { RiAdvertisementLine, RiTakeawayLine, RiHotelLine, RiCarWashingLine } from "react-icons/ri";
import { SiIfood, SiYourtraveldottv, SiAltiumdesigner } from "react-icons/si";
import { TbBuildingPavilon } from "react-icons/tb";
import { AiOutlineCar, AiOutlineFire, AiOutlineMobile, AiOutlineFileDone, AiOutlineInfoCircle, AiOutlineStar } from "react-icons/ai";
import { CgSmartHomeLight, CgShutterstock } from "react-icons/cg";
import {
  MdAir,
  MdCarRepair,
  MdOutlineCarRental,
  MdOutlineFoodBank,
  MdOutlineDinnerDining,
  MdOutlinePlace,
  MdOutlineDesignServices,
  MdMessage,
  MdOutlineAddBusiness,
  MdTravelExplore,
  MdOutlinePolicy,
} from "react-icons/md";
import { Auth } from "../../../Context/Auth.context";
import "../Main.scss";
import { Accordion } from "react-bootstrap";

const Collapse = ({ id, header, body }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="mb-3">
      <Accordion.Toggle as="div" onClick={() => setOpen(!open)} className="d-flex justify-content-between" eventKey={id}>
        {header}
        {open ? <FaChevronDown /> : <FaChevronRight />}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={id}>
        <div>{body}</div>
      </Accordion.Collapse>
    </div>
  );
};

function LeftSidebar({ open, setOpen }) {
  const { isAuthenticated } = Auth();
  const user = localStorage.getItem("user") && localStorage.getItem("user") != "undefined" ? JSON.parse(localStorage.getItem("user")) : [];

  const foodDropDown = [
    { name: "Resturant", Icon: GrRestaurant, link: "/listing/search/restaurants_c-95/" },
    { name: "Takeaways", Icon: RiTakeawayLine, link: "/listing/search/take-aways_c-99/" },
    { name: "Retailers", Icon: SiIfood, link: "/listing/search/food-retailers_c-92/" },
    { name: "Manufactoring", Icon: GiOpenedFoodCan, link: "/listing/search/food-manufacturing_c-98/" },
    { name: "Cafes", Icon: MdOutlineFoodBank, link: "/listing/search/cafes-_c-96/" },
    { name: "Super Market", Icon: BsShopWindow, link: "/listing/search/supermarket_c-97/" },
    { name: "Catering", Icon: MdOutlineDinnerDining, link: "/listing/search/catering_c-101/" },
    { name: "Distributors", Icon: GiFoodTruck, link: "/listing/search/food-distributors_c-94/" },
  ];

  const travelDropDown = [
    { name: "Hotels", Icon: RiHotelLine, link: "/listing/search/hotels_c-197/" },
    { name: "Travel Agents", Icon: GiPlanePilot, link: "/listing/search/travel-agents_c-192/" },
    { name: "Tourist", Icon: GiAirplane, link: "/listing/search/tourist-information_c-187/" },
    { name: "Visa Agency", Icon: SiYourtraveldottv, link: "/listing/search/visa-agencies_c-188/" },
    { name: "Apartments", Icon: BsBuilding, link: "/listing/search/apartments_c-200/" },
    { name: "Tour Operators", Icon: MdOutlinePlace, link: "/listing/search/tour-operators_c-202/" },
    { name: "Guest House", Icon: BiBuildingHouse, link: "/listing/search/guest-houses_c-193/" },
    { name: "Places to Visit", Icon: BsPinMap, link: "/listing/search/places-to-visit_c-194/" },
  ];

  const propertyDropDown = [
    { name: "Dealers", Icon: FaHouseUser, link: "listing/search/junk-dealers_c-7/" },
    { name: "Interior Design", Icon: MdOutlineDesignServices, link: "/listing/search/interior-design_c-311/" },
    { name: "Security", Icon: BsShieldLock, link: "/listing/search/security_c-317/" },
    { name: "Consultants", Icon: CgSmartHomeLight, link: "/listing/search/consultants_c-12/" },
    { name: "Development", Icon: TbBuildingPavilon, link: "/listing/search/property-development_c-306/" },
    { name: "Renovation", Icon: SiAltiumdesigner, link: "/listing/search/renovation-_c-307/" },
    { name: "Fire Safety", Icon: AiOutlineFire, link: "/listing/search/fire-safety-consultants_c-14/" },
    { name: "Rentals", Icon: GiKeyring, link: "/listing/search/apartment-rental_c-309/" },
  ];

  const autoMotiveDropDown = [
    { name: "Car Dealer", Icon: AiOutlineCar, link: "/listing/search/car-dealer_c-331/" },
    { name: "Spare Parts", Icon: FaCogs, link: "/listing/search/car-parts-and-accessories_c-247/" },
    { name: "Services", Icon: FaCarSide, link: "/listing/search/auto-services_c-269/" },
    { name: "Car Rental", Icon: MdOutlineCarRental, link: "/listing/search/car-rental_c-257/" },
    { name: "Auto Repair", Icon: MdCarRepair, link: "/listing/search/auto-repair_c-253/" },
    { name: "Driving School", Icon: GiCarSeat, link: "/listing/search/driving-schools_c-260/" },
    { name: "Transport", Icon: RiCarWashingLine, link: "/listing/search/transport_c-238/" },
    { name: "Cargo Service", Icon: GiKeyring, link: "/listing/search/cargo-services_c-240/" },
  ];

  const moreDropDown = [
    { name: "Advertising", Icon: RiAdvertisementLine, link: "/listing/search/advertising_c-1/" },
    { name: "Printing", Icon: BsPrinter, link: "/listing/search/printing_c-22/" },
    { name: "IT Company", Icon: BsCodeSlash, link: "/listing/search/software-company_c-364/" },
    { name: "Photography", Icon: FaCameraRetro, link: "/listing/search/photography_c-57/" },
    { name: "Event Services", Icon: BsCalendar2Event, link: "/listing/search/event-services_c-75/" },
    { name: "Plumbers", Icon: GiKitchenTap, link: "/listing/search/plumbers_c-232/" },
    { name: "AC Repair", Icon: MdAir, link: "/listing/search/ac-repair-services_c-34/" },
    { name: "Laundry", Icon: GiClothesline, link: "/listing/search/laundry-and-dry-cleaning_c-6/" },
  ];

  const aboutDropDown = [
    { name: "About Us", Icon: BiMessageRoundedError, link: "/about-us" },
    { name: "Guest Post", Icon: FaBlog, link: "/guest-post" },
    { name: "Ad Post Policy", Icon: MdOutlinePolicy, link: "/ad-post-policy" },
    { name: "Review Policy", Icon: FaRegComments, link: "/comment-policy" },
    { name: "Help Support", Icon: FiHelpCircle, link: "/support" },
    // { name: "Help Support", Icon: FiHelpCircle, link: "/help-support" },
    // { name: "Newsroom", Icon: BsNewspaper, link: "/newsroom" },
    // { name: "Sales", Icon: FaRegMoneyBillAlt, link: "/sales" },
    { name: "DMCA", Icon: MdOutlinePolicy, link: "/dmca" },
    { name: "Privacy Policy", Icon: MdOutlinePolicy, link: "/privacy-policy" },
    { name: "Refund Policy", Icon: MdOutlinePolicy, link: "/refund-policy" },
    { name: "Review Policy", Icon: MdOutlinePolicy, link: "/review-policy" },
    { name: "Terms", Icon: MdOutlinePolicy, link: "/terms-condition" },
  ];

  const exploreDropDown = [
    { name: "Find Business", Icon: MdOutlineAddBusiness, link: "/listing-search" },
    { name: "Explore Categories", Icon: BsBox, link: "/categories" },
    { name: "Videos", Icon: FaYoutube, link: "/videos" },
    { name: "Find Event", Icon: BsCalendar4Event, link: "/events-search" },
    { name: "Mobiles", Icon: AiOutlineMobile, link: "/mobiles" },
    { name: "Ringtones", Icon: BsMusicNoteBeamed, link: "/ring-tones" },
    { name: "Baby Names", Icon: FaBaby, link: "/names" },
    { name: "Auction Sheet", Icon: AiOutlineFileDone, link: "/auction-sheet-verification" },
    { name: "Prices", Icon: BsCurrencyDollar, link: "/price" },
    { name: "SMS", Icon: MdMessage, link: "/sms-text-messages" },
    {
      name: "Stock Images & Videos",
      Icon: CgShutterstock,
      length: 12,
      link: "/stock",
    },
  ];

  const prices = [
    { name: "Petrol", Icon: GiGasPump, link: "/petrol-price-in-pakistan-today" },
    { name: "Gold", Icon: GiGoldBar, link: "/gold-price-in-pakistan-today-per-tola" },
  ];

  const listingId = localStorage.getItem("listingid");

  return (
    <div id="left-sidebar">
      <Menu left isOpen={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
        <div className="mobile-menu border-bottom">
          <div className="d-flex">
            <div>
              {user.image ? (
                <img className="rounded-circle mt-n1 ml-2 border border-2" style={{ height: "40px", width: "40px" }} src={`${process.env.REACT_APP_AWS_URL}${user.image}`} />
              ) : (
                <img src="../../../../city book logo.png" style={{ height: "40px", width: "40px" }} />
                // <FaUserCircle className="rounded-circle text-muted mt-n1 ml-2 border border-2" style={{ fontSize: "40px" }} />
              )}
            </div>
            <div className="ml-2 mb-2">
              <h6 className="mb-n2 text-dark">{isAuthenticated ? user.name : "CityBook"}</h6>
              <Link className="text-muted mt-1" style={{ fontSize: "0.6rem" }} onClick={() => setOpen(false)} to="/user/dashboard">
                Go To Dashboard
              </Link>
            </div>
          </div>
          {!isAuthenticated ? (
            <Link onClick={() => setOpen(false)} to={"/sign-in"}>
              <button className="btn w-100 mt-2 text-white bg-danger rounded">{"Sign Up or Log In"}</button>
            </Link>
          ) : (
            <div className="d-flex justify-content-md-end justify-content-start">
              {!(listingId && listingId != "null") ? (
                <Link style={{ backgroundColor: "#912827", color: "#fff" }} className="my-lg-0 my-1 btn rounded text-nowrap w-50 mr-1" to="/submit-listing">
                  Submit Business
                </Link>
              ) : (
                <Link to="/edit-listing" style={{ backgroundColor: "#912827", color: "#fff" }} className="my-lg-0 my-1 btn rounded text-nowrap w-50 mr-1">
                  Edit Business
                </Link>
              )}
              <Link style={{ backgroundColor: "#912827", color: "#fff" }} className="my-lg-0 my-1 btn rounded text-nowrap w-50 ml-1" to="/submit-event">
                Submit Event
              </Link>
            </div>
          )}
        </div>

        <div className="overflow-auto">
          <div className="mobile-menu border-bottom">
            <Link to="/" onClick={() => setOpen(false)} className="d-flex mb-3 text-dark text-decoration-none" style={{ fontSize: "18px", fontWeight: "500" }}>
              <p className="align-self-center mb-0">
                <BiCategory size={23} className="mr-3" />{" "}
              </p>
              <p className="align-self-end mb-0">Home</p>
            </Link>

            <Accordion>
              <Collapse
                id="0"
                header={
                  <div className="d-flex mb-1 text-dark text-decoration-none" style={{ fontSize: "18px", fontWeight: "500" }}>
                    <p className="align-self-center mb-0">
                      <MdTravelExplore size={23} className="mr-3" />
                    </p>
                    <p className="align-self-center mb-0">Explore</p>
                  </div>
                }
                body={
                  <div className="ml-3">
                    {exploreDropDown.map((item, i) => (
                      <Link key={i} to={item.link} onClick={() => setOpen(false)} className="d-flex py-1 border-bottom text-muted text-decoration-none" style={{ fontSize: "20px" }}>
                        <item.Icon size={20} className="mr-3 mt-1" /> {item.name}
                      </Link>
                    ))}
                  </div>
                }
              />
              <Link to="/sign-up" onClick={() => setOpen(false)} className="d-flex mb-3 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                <p className="mb-0 align-self-center">
                  <FaBusinessTime size={23} className="mr-3" />
                </p>
                <p className="mb-0 align-self-center">For Businesses</p>
              </Link>
              <Collapse
                id="1"
                header={
                  <div className="d-flex mb-1 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                    <p className="align-self-center mb-0">
                      <BiFoodMenu size={23} className="mr-3" />
                    </p>
                    <p className="align-self-center mb-0"> Food</p>
                  </div>
                }
                body={
                  <div className="ml-3">
                    {foodDropDown.map((item, i) => (
                      <Link key={i} to={item.link} onClick={() => setOpen(false)} className="d-flex py-1 border-bottom text-muted text-decoration-none" style={{ fontSize: "20px" }}>
                        <item.Icon size={20} className="mr-3 mt-1" /> {item.name}
                      </Link>
                    ))}
                  </div>
                }
              />
              <Collapse
                id="2"
                header={
                  <div className="d-flex mb-1 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                    <p className="align-self-center mb-0">
                      <MdTravelExplore size={23} className="mr-3" />
                    </p>
                    <p className="align-self-center mb-0">Travel</p>
                  </div>
                }
                body={
                  <div className="ml-3">
                    {travelDropDown.map((item, i) => (
                      <Link key={i} to={item.link} onClick={() => setOpen(false)} className="d-flex py-1 border-bottom text-muted text-decoration-none" style={{ fontSize: "20px" }}>
                        <item.Icon size={20} className="mr-3 mt-1" /> {item.name}
                      </Link>
                    ))}
                  </div>
                }
              />
              <Collapse
                id="3"
                header={
                  <div className="d-flex mb-1 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                    <p className="align-self-center mb-0">
                      <BiBuildingHouse size={23} className="mr-3" />
                    </p>
                    <p className="align-self-center mb-0">Property</p>
                  </div>
                }
                body={
                  <div className="ml-3">
                    {propertyDropDown.map((item, i) => (
                      <Link key={i} to={item.link} onClick={() => setOpen(false)} className="d-flex py-1 border-bottom text-muted text-decoration-none" style={{ fontSize: "20px" }}>
                        <item.Icon size={20} className="mr-3 mt-1" /> {item.name}
                      </Link>
                    ))}
                  </div>
                }
              />
              <Collapse
                id="4"
                header={
                  <div className="d-flex mb-1 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                    <p className="align-self-center mb-0">
                      <AiOutlineCar size={23} className="mr-3" />
                    </p>
                    <p className="align-self-center mb-0">Automotive</p>
                  </div>
                }
                body={
                  <div className="ml-3">
                    {autoMotiveDropDown.map((item, i) => (
                      <Link key={i} to={item.link} onClick={() => setOpen(false)} className="d-flex py-1 border-bottom text-muted text-decoration-none" style={{ fontSize: "20px" }}>
                        <item.Icon size={20} className="mr-3 mt-1" /> {item.name}
                      </Link>
                    ))}
                  </div>
                }
              />
              <Collapse
                id="5"
                header={
                  <div className="d-flex mb-1 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                    <p className="align-self-center mb-0">
                      <AiOutlineInfoCircle size={23} className="mr-3" />
                    </p>
                    <p className="align-self-center mb-0">About</p>
                  </div>
                }
                body={
                  <div className="ml-3">
                    {aboutDropDown.map((item, i) => (
                      <Link key={i} to={item.link} onClick={() => setOpen(false)} className="d-flex py-1 border-bottom text-muted text-decoration-none" style={{ fontSize: "20px" }}>
                        <item.Icon size={20} className="mr-3 mt-1" /> {item.name}
                      </Link>
                    ))}
                  </div>
                }
              />
              <a target="_blank" href="/blog" onClick={() => setOpen(false)} className="d-flex mb-3 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                <p className="mb-0 align-self-center">
                  <BiNews size={23} className="mr-3" />
                </p>
                <p className="mb-0 align-self-center">Latest News & Updates</p>
              </a>
              <Collapse
                id="6"
                header={
                  <div className="d-flex mb-1 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                    <p className="align-self-center mb-0">
                      <FiMoreVertical size={23} className="mr-3" />
                    </p>
                    <p className="align-self-center mb-0">More</p>
                  </div>
                }
                body={
                  <div className="ml-3">
                    {moreDropDown.map((item, i) => (
                      <Link to={item.link} onClick={() => setOpen(false)} className="d-flex py-1 border-bottom text-muted text-decoration-none" style={{ fontSize: "20px" }}>
                        <item.Icon size={20} className="mr-3 mt-1" /> {item.name}
                      </Link>
                    ))}
                  </div>
                }
              />
              <Collapse
                id="7"
                header={
                  <div className="d-flex mb-1 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                    <p className="align-self-center mb-0">
                      <GiTakeMyMoney size={23} className="mr-3" />
                    </p>
                    <p className="align-self-center mb-0">Price</p>
                  </div>
                }
                body={
                  <div className="ml-3">
                    {prices.map((item, i) => (
                      <Link to={item.link} onClick={() => setOpen(false)} className="d-flex py-1 border-bottom text-muted text-decoration-none" style={{ fontSize: "20px" }}>
                        <item.Icon size={20} className="mr-3 mt-1" /> {item.name}
                      </Link>
                    ))}
                  </div>
                }
              />
              <Link to="/reviews" onClick={() => setOpen(false)} className="d-flex mb-3 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
                <p className="align-self-center mb-0">
                  <AiOutlineStar size={23} className="mr-3" />
                </p>
                <p className="mb-0 align-self-center">Reviews</p>
              </Link>
            </Accordion>
          </div>

          {/* <div className="mobile-menu border-bottom">
           

            <Link to="/" onClick={() => setOpen(false)} className="d-flex mb-3 text-dark text-decoration-none" style={{ fontSize: "20px", fontWeight: "500" }}>
              <div className="d-flex justify-content-between w-100">
                <div>More</div>
                <div>
                  <FaChevronDown style={{ color: "#c3c3c3" }} />
                </div>
              </div>
            </Link>
          </div> */}
        </div>
      </Menu>
    </div>
  );
}
export default LeftSidebar;
