import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Container, Image, Dropdown } from "react-bootstrap";
import { BsCalendar2Event, BsCodeSlash, BsPrinter, BsShopWindow, BsBuilding, BsPinMap, BsShieldLock, BsMusicNoteBeamed, BsCalendar4Event, BsCurrencyDollar, BsCalendar } from "react-icons/bs";
import { BiBuildingHouse, BiMessageRoundedError } from "react-icons/bi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { GiClothesline, GiKitchenTap, GiCarSeat, GiOpenedFoodCan, GiFoodTruck, GiAirplane, GiPlanePilot, GiKeyring } from "react-icons/gi";
import { GrRestaurant } from "react-icons/gr";
import { RiAdvertisementLine, RiTakeawayLine, RiHotelLine, RiCarWashingLine } from "react-icons/ri";
import { FaCameraRetro, FaChevronDown, FaHouseUser, FaCogs, FaBaby, FaCarSide, FaBlog, FaCalendar, FaYoutube } from "react-icons/fa";
import { SiIfood, SiYourtraveldottv, SiAltiumdesigner } from "react-icons/si";
import { TbBuildingPavilon } from "react-icons/tb";
import { AiOutlineCar, AiOutlineFire, AiOutlineMobile, AiOutlineFileDone } from "react-icons/ai";
import { CgSmartHomeLight, CgShutterstock } from "react-icons/cg";
import {
  MdAir,
  MdCarRepair,
  MdOutlineCarRental,
  MdOutlineFoodBank,
  MdOutlineDinnerDining,
  MdOutlinePlace,
  MdOutlineDesignServices,
  MdTravelExplore,
  MdMessage,
  MdOutlineAddBusiness,
  MdOutlinePolicy,
} from "react-icons/md";
import { FaRegComments } from "react-icons/fa";
import { FiHelpCircle } from "react-icons/fi";
import { Auth } from "../../../Context/Auth.context";
import Sidebar from "../../Sidebar/User";
import SearchBar from "./SearchBar";

export default function Header() {
  const { isAuthenticated } = Auth();
  const user = localStorage.getItem("user") && localStorage.getItem("user") != "undefined" ? JSON.parse(localStorage.getItem("user")) : [];

  const foodDropDown = [
    { name: "Resturant", Icon: GrRestaurant, link: "/listing/search/restaurants_c-95/" },
    { name: "Takeaways", Icon: RiTakeawayLine, link: "/listing/search/take-aways_c-99/" },
    { name: "Retailers", Icon: SiIfood, link: "/listing/search/food-retailers_c-92/" },
    { name: "Manufactoring", Icon: GiOpenedFoodCan, link: "/listing/search/food-manufacturing_c-98/" },
    { name: "Cafes", Icon: MdOutlineFoodBank, link: "/listing/search/cafes-_c-96/" },
    { name: "Super Market", Icon: BsShopWindow, link: "/listing/search/supermarket_c-97/" },
    { name: "Catering", Icon: MdOutlineDinnerDining, link: "/listing/search/catering_c-101/" },
    { name: "Distributors", Icon: GiFoodTruck, link: "/listing/search/food-distributors_c-94/" },
  ];

  const travelDropDown = [
    { name: "Hotels", Icon: RiHotelLine, link: "/listing/search/hotels_c-197/" },
    { name: "Travel Agents", Icon: GiPlanePilot, link: "/listing/search/travel-agents_c-192/" },
    { name: "Tourist", Icon: GiAirplane, link: "/listing/search/tourist-information_c-187/" },
    { name: "Visa Agency", Icon: SiYourtraveldottv, link: "/listing/search/visa-agencies_c-188/" },
    { name: "Apartments", Icon: BsBuilding, link: "/listing/search/apartments_c-200/" },
    { name: "Tour Operators", Icon: MdOutlinePlace, link: "/listing/search/tour-operators_c-202/" },
    { name: "Guest House", Icon: BiBuildingHouse, link: "/listing/search/guest-houses_c-193/" },
    { name: "Places to Visit", Icon: BsPinMap, link: "/listing/search/places-to-visit_c-194/" },
  ];

  const propertyDropDown = [
    { name: "Dealers", Icon: FaHouseUser, link: "listing/search/junk-dealers_c-7/" },
    { name: "Interior Design", Icon: MdOutlineDesignServices, link: "/listing/search/interior-design_c-311/" },
    { name: "Security", Icon: BsShieldLock, link: "/listing/search/security_c-317/" },
    { name: "Consultants", Icon: CgSmartHomeLight, link: "/listing/search/consultants_c-12/" },
    { name: "Development", Icon: TbBuildingPavilon, link: "/listing/search/property-development_c-306/" },
    { name: "Renovation", Icon: SiAltiumdesigner, link: "/listing/search/renovation-_c-307/" },
    { name: "Fire Safety", Icon: AiOutlineFire, link: "/listing/search/fire-safety-consultants_c-14/" },
    { name: "Rentals", Icon: GiKeyring, link: "/listing/search/apartment-rental_c-309/" },
  ];

  const autoMotiveDropDown = [
    { name: "Car Dealer", Icon: AiOutlineCar, link: "/listing/search/car-dealer_c-331/" },
    { name: "Spare Parts", Icon: FaCogs, link: "/listing/search/car-parts-and-accessories_c-247/" },
    { name: "Services", Icon: FaCarSide, link: "/listing/search/auto-services_c-269/" },
    { name: "Car Rental", Icon: MdOutlineCarRental, link: "/listing/search/car-rental_c-257/" },
    { name: "Auto Repair", Icon: MdCarRepair, link: "/listing/search/auto-repair_c-253/" },
    { name: "Driving School", Icon: GiCarSeat, link: "/listing/search/driving-schools_c-260/" },
    { name: "Transport", Icon: RiCarWashingLine, link: "/listing/search/transport_c-238/" },
    { name: "Cargo Service", Icon: GiKeyring, link: "/listing/search/cargo-services_c-240/" },
  ];

  const moreDropDown = [
    { name: "Advertising", Icon: RiAdvertisementLine, link: "/listing/search/advertising_c-1/" },
    { name: "Printing", Icon: BsPrinter, link: "/listing/search/printing_c-22/" },
    { name: "IT Company", Icon: BsCodeSlash, link: "/listing/search/software-company_c-364/" },
    { name: "Photography", Icon: FaCameraRetro, link: "/listing/search/photography_c-57/" },
    { name: "Event Services", Icon: BsCalendar2Event, link: "/listing/search/event-services_c-75/" },
    { name: "Plumbers", Icon: GiKitchenTap, link: "/listing/search/plumbers_c-232/" },
    { name: "AC Repair", Icon: MdAir, link: "/listing/search/ac-repair-services_c-34/" },
    { name: "Laundry", Icon: GiClothesline, link: "/listing/search/laundry-and-dry-cleaning_c-6/" },
  ];

  const exploreDropDown = [
    { name: "Find Business", Icon: MdOutlineAddBusiness, link: "/listing-search" },
    { name: "Find Event", Icon: BsCalendar4Event, link: "/events-search" },
    { name: "Mobiles", Icon: AiOutlineMobile, link: "/mobiles" },
    { name: "Ringtones", Icon: BsMusicNoteBeamed, link: "/ring-tones" },
    { name: "Baby Names", Icon: FaBaby, link: "/names" },
    { name: "Auction Sheet", Icon: AiOutlineFileDone, link: "/auction-sheet-verification" },
    { name: "Prices", Icon: BsCurrencyDollar, link: "/price" },
    { name: "SMS", Icon: MdMessage, link: "/sms-text-messages" },
    {
      name: "Stock Images & Videos",
      Icon: CgShutterstock,
      length: 12,
      link: "/stock",
    },
    {
      name: "Blog",
      Icon: FaBlog,
      href: "/blog",
    },
    {
      name: "Videos",
      Icon: FaYoutube,
      href: "/videos",
    },
  ];

  const eventDropDown = [
    { name: "Lahore", icon: BsCalendar, link: "/events/search/city_lahore" },
    { name: "Karachi", icon: BsCalendar, link: "/events/search/city_karachi" },
    { name: "Islamabad", icon: BsCalendar, link: "/events/search/city_islamabad" },
    { name: "Rawalpindi", icon: BsCalendar, link: "/events/search/city_rawalpindi" },
    { name: "Peshawar", icon: BsCalendar, link: "/events/search/city_peshawar" },
    { name: "Faislabad", icon: BsCalendar, link: "/events/search/city_faislabad" },
    { name: "Other Cities", icon: BsCalendar, link: "/events/search/" },
  ];

  const [menu, setMenu] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [newOpenOne, setNewOpenOne] = useState(false);
  const [newOpenTwo, setNewOpenTwo] = useState(false);
  const [newOpenThree, setNewOpenThree] = useState(false);
  const [newOpenFour, setNewOpenFour] = useState(false);
  const [exploreOne, setExploreOne] = useState(false);
  const [newOpenEvent, setNewOpenEvent] = useState(false);

  return (
    <div className="border-bottom bg-light">
      <Container fluid id="header">
        <div className="row no-gutters pt-4 pb-2">
          <div className="col-sm-1">
            <div className="d-flex justify-content-center">
              <Link to="/">
                <Image
                  src={`/city book logo.png`}
                  style={{
                    width: "auto",
                    height: "70px",
                    objectFit: "contain",
                  }}
                  alt="Citybook"
                />
              </Link>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7">
            <SearchBar />
            <div className="d-flex flex-row mt-1">
              <div className="px-0">
                <Dropdown className="p-0" show={newOpen} onMouseEnter={() => setNewOpen(true)} onMouseLeave={() => setNewOpen(false)}>
                  <Dropdown.Toggle id="dropdown-login" className="bg-transparent pl-0 pr-3 border-0 text-dark">
                    <Link to="/listing/food" className="text-dark" style={{ fontSize: "15px" }}>
                      Food <FaChevronDown />
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menubar fade">
                    <div className="container pl-4">
                      <div className="row no-gutters ml-2">
                        {foodDropDown.map((item, idx) => (
                          <div className="col-sm-6 my-2" key={idx}>
                            <Link to={item.link} className="mb-0 text-dark">
                              <item.Icon className="mt-n1" /> {item.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="px-0">
                <Dropdown className="p-0" show={newOpenOne} onMouseEnter={() => setNewOpenOne(true)} onMouseLeave={() => setNewOpenOne(false)}>
                  <Dropdown.Toggle id="dropdown-login" className="bg-transparent px-4 border-0 text-dark">
                    <Link to="/listing/travel" className="text-dark" style={{ fontSize: "15px" }}>
                      Travel <FaChevronDown />
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menubar fade">
                    <div className="container pl-4">
                      <div className="row no-gutters ml-2">
                        {travelDropDown.map((item, idx) => (
                          <div className="col-sm-6 my-2" key={idx}>
                            <Link to={item.link} className="mb-0 text-dark">
                              <item.Icon className="mt-n1" /> {item.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="px-0">
                <Dropdown className="p-0" show={newOpenTwo} onMouseEnter={() => setNewOpenTwo(true)} onMouseLeave={() => setNewOpenTwo(false)}>
                  <Dropdown.Toggle id="dropdown-login" className="bg-transparent px-4 border-0 text-dark">
                    <Link to="/listing/property" className="text-dark" style={{ fontSize: "15px" }}>
                      Property <FaChevronDown />
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menubar fade">
                    <div className="container pl-4">
                      <div className="row no-gutters ml-2">
                        {propertyDropDown.map((item, idx) => (
                          <div className="col-sm-6 my-2" key={idx}>
                            <Link to={item.link} className="mb-0 text-dark">
                              <item.Icon className="mt-n1" /> {item.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="px-0">
                <Dropdown className="p-0" show={newOpenThree} onMouseEnter={() => setNewOpenThree(true)} onMouseLeave={() => setNewOpenThree(false)}>
                  <Dropdown.Toggle id="dropdown-login" className="bg-transparent px-4 border-0 text-dark">
                    <Link to="/listing/automotive" className="text-dark" style={{ fontSize: "15px" }}>
                      Automotive <FaChevronDown />
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menubar fade">
                    <div className="container pl-4">
                      <div className="row no-gutters ml-2">
                        {autoMotiveDropDown.map((item, idx) => (
                          <div className="col-sm-6 my-2" key={idx}>
                            <Link to={item.link} className="mb-0 text-dark">
                              <item.Icon className="mt-n1" /> {item.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="px-0 ">
                <Dropdown className="p-0" show={newOpenFour} onMouseEnter={() => setNewOpenFour(true)} onMouseLeave={() => setNewOpenFour(false)}>
                  <Dropdown.Toggle id="dropdown-login" className="bg-transparent px-4 border-0 text-dark">
                    <a className="text-dark" style={{ fontSize: "15px" }}>
                      More <FaChevronDown />
                    </a>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menubar fade">
                    <div className="container pl-4">
                      <div className="row no-gutters ml-2">
                        {moreDropDown.map((item, idx) => (
                          <div className="col-sm-6 my-2" key={idx}>
                            <Link to={item.link} className="mb-0 text-dark">
                              <item.Icon className="mt-n1" /> {item.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="px-0">
                <Dropdown className="p-0" show={newOpenEvent} onMouseEnter={() => setNewOpenEvent(true)} onMouseLeave={() => setNewOpenEvent(false)}>
                  <Dropdown.Toggle id="dropdown-login" className="bg-transparent px-4 border-0 text-dark">
                    <Link to="/events-search" className="text-dark" style={{ fontSize: "15px" }}>
                      Events <FaChevronDown />
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menubar fade">
                    <div className="container pl-4">
                      <div className="row no-gutters ml-2">
                        {eventDropDown.map((items, idx) => (
                          <div className="col-sm-6 my-2" key={idx}>
                            <Link to={items.link} className="mb-0 text-dark">
                              <items.icon className="mt-n1" /> {items.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* ---------------------------------------- Menu nav Links---------------------------------------- */}
          <div className="header-1 d-flex justify-content-end col-xl-5 col-lg-4">
            <div className="d-flex align-self-center mt-n4 mr-2">
              <Dropdown className="dropdown-explore align-self-center" show={exploreOne} onMouseEnter={() => setExploreOne(true)} onMouseLeave={() => setExploreOne(false)}>
                <Dropdown.Toggle id="dropdown-explore" className="bg-transparent p-0 border-0 mt-n1 mr-1 text-dark">
                  <a href="#" className="align-self-center for-business text-dark">
                    <MdTravelExplore /> Explore <FaChevronDown />
                  </a>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menubar fade">
                  <div className="container pl-4">
                    <div className="row no-gutters ml-2">
                      {exploreDropDown.map((item, idx) => (
                        <div className={!item.length ? `col-sm-6 my-2` : `col-sm-${item.length} my-2`} key={idx}>
                          {item.href ? (
                            <a href={item.href} className="mb-0 text-dark">
                              <item.Icon className="mt-n1" /> {item.name}
                            </a>
                          ) : (
                            <Link to={item.link} className="mb-0 text-dark">
                              <item.Icon className="mt-n1" /> {item.name}
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Link to="/sign-in" className="align-self-center text-dark for-business">
                For Businesses
              </Link>
              <Link to="/reviews" className="align-self-center text-dark for-business">
                Reviews
              </Link>
              {isAuthenticated ? (
                <>
                  <div className="d-flex mt-1 ml-5" onClick={() => setMenu(true)} style={{ cursor: "pointer" }}>
                    <div className="name mt-2 text-nowrap" style={{ fontSize: "0.9rem" }}>
                      Dashboard
                    </div>
                    <div className="image">
                      {user.image ? (
                        <img className="rounded-circle mt-n1 ml-2 border border-2" style={{ height: "40px", width: "40px" }} src={`${process.env.REACT_APP_AWS_URL}${user.image}`} />
                      ) : (
                        <FontAwesomeIcon className="rounded-circle text-muted mt-n1 ml-2 border border-2" icon={faUserCircle} style={{ fontSize: "40px" }} />
                      )}
                    </div>
                  </div>
                  <Sidebar menu={menu} setMenu={setMenu} />
                </>
              ) : (
                <>
                  <Link to="/sign-in" className="nav-link signUp-btn ml-3">
                    Log in
                  </Link>
                  <Link to="/sign-up" className="nav-link mx-3 text-white signUp-btn-1">
                    Sign Up
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
