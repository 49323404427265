import Routes from "./Routes/routes.js";
import { AuthProvider } from "./Context/Auth.context.js";
import { ToastContainer } from "react-toastify";
import ErrorBound from "./ErrorBoundry";

function App() {
  return (
    <ErrorBound>
      <AuthProvider>
        <Routes />
        <ToastContainer />
      </AuthProvider>
    </ErrorBound>
  );
}

export default App;
