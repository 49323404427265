import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaPinterestP, FaLinkedin } from "react-icons/fa";
import { config } from "../../config/social.config";

export default function Footer() {
  var listText = {
    fontSize: "12px",
    color: "#999",
  };
  var divider = {
    borderColor: "#999",
  };

  const foodDropDown = [
    { name: "Resturant", link: "/listing/search/restaurants_c-95/" },
    { name: "Takeaways", link: "/listing/search/take-aways_c-99/" },
    { name: "Retailers", link: "/listing/search/food-retailers_c-92/" },
    { name: "Manufactoring", link: "/listing/search/food-manufacturing_c-98/" },
    { name: "Cafes", link: "/listing/search/cafes-_c-96/" },
    { name: "Super Market", link: "/listing/search/supermarket_c-97/" },
    { name: "Catering", link: "/listing/search/catering_c-101/" },
    { name: "Distributors", link: "/listing/search/food-distributors_c-94/" },
  ];

  const travelDropDown = [
    { name: "Hotels", link: "/listing/search/hotels_c-197/" },
    { name: "Travel Agents", link: "/listing/search/travel-agents_c-192/" },
    { name: "Tourist", link: "/listing/search/tourist-information_c-187/" },
    { name: "Visa Agency", link: "/listing/search/visa-agencies_c-188/" },
    { name: "Apartments", link: "/listing/search/apartments_c-200/" },
    { name: "Tour Operators", link: "/listing/search/tour-operators_c-202/" },
    { name: "Guest House", link: "/listing/search/guest-houses_c-193/" },
    { name: "Places to Visit", link: "/listing/search/places-to-visit_c-194/" },
  ];

  const propertyDropDown = [
    { name: "Dealers", link: "listing/search/junk-dealers_c-7/" },
    { name: "Interior Design", link: "/listing/search/interior-design_c-311/" },
    { name: "Security", link: "/listing/search/security_c-317/" },
    { name: "Consultants", link: "/listing/search/consultants_c-12/" },
    { name: "Development", link: "/listing/search/property-development_c-306/" },
    { name: "Renovation", link: "/listing/search/renovation-_c-307/" },
    { name: "Fire Safety", link: "/listing/search/fire-safety-consultants_c-14/" },
    { name: "Rentals", link: "/listing/search/apartment-rental_c-309/" },
  ];

  const autoMotiveDropDown = [
    { name: "Car Dealer", link: "/listing/search/car-dealer_c-331/" },
    { name: "Spare Parts", link: "/listing/search/car-parts-and-accessories_c-247/" },
    { name: "Services", link: "/listing/search/auto-services_c-269/" },
    { name: "Car Rental", link: "/listing/search/car-rental_c-257/" },
    { name: "Auto Repair", link: "/listing/search/auto-repair_c-253/" },
    { name: "Driving School", link: "/listing/search/driving-schools_c-260/" },
    { name: "Transport", link: "/listing/search/transport_c-238/" },
    { name: "Cargo Service", link: "/listing/search/cargo-services_c-240/" },
  ];
  const moreDropDown = [
    { name: "Advertising", link: "/listing/search/advertising_c-1/" },
    { name: "Printing", link: "/listing/search/printing_c-22/" },
    { name: "IT Company", link: "/listing/search/software-company_c-364/" },
    { name: "Photography", link: "/listing/search/photography_c-57/" },
    { name: "Event Services", link: "/listing/search/event-services_c-75/" },
    { name: "Plumbers", link: "/listing/search/plumbers_c-232/" },
    { name: "AC Repair", link: "/listing/search/ac-repair-services_c-34/" },
    { name: "Laundry", link: "/listing/search/laundry-and-dry-cleaning_c-6/" },
  ];
  const exploreDropDown = [
    { name: "Find Business", link: "/listing" },
    { name: "Find Event", link: "/event" },
    { name: "Mobiles", link: "/mobiles" },
    { name: "Ringtones", link: "/ringtones" },
    { name: "Baby Names", link: "/names" },
    { name: "Auction Sheet", link: "/auction-sheet" },
    { name: "Prices", link: "/price" },
    { name: "SMS", link: "/sms" },
  ];
  const quickLinks = [
    { name: "Submit Business", link: "/listing" },
    { name: "Submit Event", link: "/event" },
    { name: "Submit SMS", link: "/mobiles" },
    { name: "Submit Reviews", link: "/ringtones" },
    { name: "", link: "/names" },
    {
      name: "Stock Images & Videos",
      link: "/stock",
    },
    { name: "Refund Policy", link: "/refund-policy" },
    { name: "Terms & Conditions", link: "/terms-condition" },
  ];

  const aboutDropDown = [
    { name: "About Us", link: "/about-us" },
    { name: "Ad Post Policy", link: "/ad-post-policy" },
    { name: "Review Policy", link: "/comment-policy" },
    { name: "Guest Post", link: "/guest-post" },
    { name: "Help Support", link: "/support" },
    { name: "DMCA", link: "/dmca" },
    { name: "Privacy Policy", link: "/privacy-policy" },
    { name: "Review Policy", link: "/review-policy" },
  ];
  return (
    <div className="bg-dark pt-5 px-lg-5 px-0 pb-3" style={{ position: "relative" }}>
      <Container>
        <Row>
          <Col lg={2} md={4} xs={6}>
            <h3 className="text-white font-weight-normal">Food</h3>
            <ul className="list-unstyled text-muted">
              {foodDropDown.map((item, i) => (
                <li key={i}>
                  <Link to={item.link} style={listText}>
                    <span style={{ fontSize: "15px", fontWeight: 500 }}>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <h3 className="mt-4 text-white font-weight-normal">Useful Link</h3>
            <ul className="list-unstyled text-muted">
              {moreDropDown.map((item, i) => (
                <li key={i}>
                  <Link to={item.link} style={listText}>
                    <span style={{ fontSize: "15px", fontWeight: 500 }}>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <h3 className="text-white font-weight-normal">Property</h3>
            <ul className="list-unstyled text-muted">
              {propertyDropDown.map((item, i) => (
                <li key={i}>
                  <Link to={item.link} style={listText}>
                    <span style={{ fontSize: "15px", fontWeight: 500 }}>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <h3 className="mt-4 text-white font-weight-normal">Explore</h3>
            <ul className="list-unstyled text-muted">
              {exploreDropDown.map((item, i) => (
                <li key={i}>
                  <Link to={item.link} style={listText}>
                    <span style={{ fontSize: "15px", fontWeight: 500 }}>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <h3 className=" text-white font-weight-normal">Travel</h3>
            <ul className="list-unstyled text-muted">
              {travelDropDown &&
                travelDropDown.map((item, i) => (
                  <li key={i}>
                    <Link to={item.link} style={listText}>
                      <span style={{ fontSize: "15px", fontWeight: 500 }}>{item.name}</span>
                    </Link>
                  </li>
                ))}
            </ul>
            <h3 className="mt-4 text-white font-weight-normal">About</h3>
            <ul className="list-unstyled text-muted">
              {aboutDropDown.map((item, i) => (
                <li key={i}>
                  <Link to={item.link} style={listText}>
                    <span style={{ fontSize: "15px", fontWeight: 500 }}>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <h3 className=" text-white font-weight-normal">Automotive</h3>
            <ul className="list-unstyled text-muted">
              {autoMotiveDropDown &&
                autoMotiveDropDown.map((item, i) => (
                  <li key={i}>
                    <Link to={item.link} style={listText}>
                      <span style={{ fontSize: "15px", fontWeight: 500 }}>{item.name}</span>
                    </Link>
                  </li>
                ))}
            </ul>
            <h3 className="mt-4 text-white font-weight-normal">Quick Links</h3>
            <ul className="list-unstyled text-muted">
              {quickLinks.map((item, i) => (
                <li key={i}>
                  <Link to={item.link} style={listText}>
                    <span style={{ fontSize: "15px", fontWeight: 500 }}>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col sm={4}>
            <h6 className="text-white font-weight-normal">Subscribe to our Newsletter</h6>
            <Row className="no-gutters">
              <Col xs={8}>
                <input className="form-control form-control-sm rounded-0" placeholder="name@citybook.com" />
              </Col>
              <Col xs={4}>
                <Button variant="danger" size="sm" className="rounded-0">
                  Subscribe
                </Button>
              </Col>
            </Row>

            <div className="mt-5">
              <h6 className="text-white font-weight-normal">Find us on</h6>
              <Row>
                <Col xs={6}>
                  <Image src="/download-removebg-preview.png" fluid className="my-1" />
                </Col>
                {/* <Col xs={6}>
                  <Image src="https://via.placeholder.com/250x60/000000?text=App" fluid className="my-1" />
                </Col>
                <Col xs={6}>
                  <Image src="https://via.placeholder.com/250x60/000000?text=App" fluid className="my-1" />
                </Col> */}
              </Row>
            </div>
            <div className="mt-5">
              <h6 className="text-white font-weight-normal">Follow us</h6>
              <Row className="mt-2">
                <Col xs={2}>
                  <a className="text-white" href={config.link.fb} target="_blank">
                    <FaFacebook size={25} />
                  </a>
                </Col>
                <Col xs={2}>
                  <a className="text-white" href={config.link.tt} target="_blank">
                    <FaTwitter size={25} />
                  </a>
                </Col>
                <Col xs={2}>
                  <a className="text-white" href={config.link.ig} target="_blank">
                    <FaInstagram size={25} />
                  </a>
                </Col>
                <Col xs={2}>
                  <a className="text-white" href={config.link.yt} target="_blank">
                    <FaYoutube size={25} />
                  </a>
                </Col>
                <Col xs={2}>
                  <a className="text-white" href={config.link.pt} target="_blank">
                    <FaPinterestP size={25} />
                  </a>
                </Col>
                <Col xs={2}>
                  <a className="text-white" href={config.link.in} target="_blank">
                    <FaLinkedin size={25} />
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <hr className={divider} />
        <div className="mt-3 d-flex justify-content-between">
          <div style={listText}>
            <div>Copyright © 2003 - 2022 Citybook (Pvt) Ltd. - All Rights Reserved.</div>
            <div className="d-flex">
              <Link className="text-muted" to={"/privacy-policy"}>
                Terms of Service
              </Link>{" "}
              <Link className="text-muted" to={"terms-condition"}>
                &nbsp;| Privacy Policy
              </Link>
            </div>
          </div>

          <a className="text-decoration-none" href="https://azi.solutions/" target="_blank" style={listText}>
            Designed & Developed by &nbsp;
            <img style={{ width: "auto", height: "50px" }} src="/azi solutions.png" />
          </a>
        </div>
      </Container>
    </div>
  );
}
