import { lazy } from "react";

// SMS Section
const MainSMS = lazy(() => import("../Views/SMS/Desktop/"));
const CategorySMS = lazy(() => import("../Views/SMS/Desktop/Category"));
const Message = lazy(() => import("../Views/SMS/Desktop/Message"));
const MobMainSMS = lazy(() => import("../Views/SMS/Mobile/"));
const MobCategorySMS = lazy(() => import("../Views/SMS/Mobile/Category"));
const MobMessage = lazy(() => import("../Views/SMS/Mobile/Message"));

// Price Section
const Gold = lazy(() => import("../Views/Prices/Gold"));
const Petrol = lazy(() => import("../Views/Prices/Petrol/"));
const AllPrices = lazy(() => import("../Views/Prices/AllPrices"));
const CategoryPrice = lazy(() => import("../Views/Prices/"));
// const FiltersPrice = lazy(() => import("../Views/Prices/AO/filters"));
// const DetailsPrice = lazy(() => import("../Views/Prices/AO/details"));

// Auth Section
const SignIn = lazy(() => import("../Views/Auth/Desktop/SignIn"));
const MobileAuth = lazy(() => import("../Views/Auth/Desktop/Mobile"));
const SignUp = lazy(() => import("../Views/Auth/Desktop/SignUp"));
const ForgetPassword = lazy(() => import("../Views/Auth/Desktop/ForgetPassword"));
const SignInM = lazy(() => import("../Views/Auth/Mobile/SignIn"));
const SignUpM = lazy(() => import("../Views/Auth/Mobile/SignUp"));
const ForgetPasswordM = lazy(() => import("../Views/Auth/Mobile/ForgotPassword"));

// Listing Section
const Listing = lazy(() => import("../Views/Listing/Desktop/"));
const FeaturedListing = lazy(() => import("../Views/Listing/Desktop/Featured"));
const ListingDetails = lazy(() => import("../Views/Listing/Details"));
const ListingDetails_M = lazy(() => import("../Views/Listing/Mobile/Details"));
const ListingCategory = lazy(() => import("../Views/Listing/Category"));
const ListingCategory_M = lazy(() => import("../Views/Listing/Mobile/Search"));
const ListingTravel = lazy(() => import("../Views/Listing/Travel"));
const ListingFood = lazy(() => import("../Views/Listing/Food"));
const ListingProperty = lazy(() => import("../Views/Listing/Property"));
const ListingAutomotive = lazy(() => import("../Views/Listing/Automotive"));
const ListingCategories_M = lazy(() => import("../Views/Listing/Mobile/Categories"));

// Event Section
const Event = lazy(() => import("../Views/Event/Desktop"));
const EventSearch = lazy(() => import("../Views/Event/Desktop/Search"));
const EventDetails = lazy(() => import("../Views/Event/Desktop/Details"));
const MobileEvent = lazy(() => import("../Views/Event/Mobile/Main/Index.js"));
const MobileEventSearch = lazy(() => import("../Views/Event/Mobile/Search/Search"));
const MobileEventDetails = lazy(() => import("../Views/Event/Mobile/Details/Details"));

// Reviews Section
const Reviews = lazy(() => import("../Views/Reviews/Desktop"));
const ReviewDetails = lazy(() => import("../Views/Reviews/Desktop/Details"));
const ReviewsM = lazy(() => import("../Views/Reviews/Mobile"));
const ReviewDetailsM = lazy(() => import("../Views/Reviews/Mobile/Details"));

// Ringtones Pages
const Ringtones = lazy(() => import("../Views/Ringtones/Desktop/Main/"));
const RingtonesCategory = lazy(() => import("../Views/Ringtones/Desktop/Category/"));
const RingtonesDownload = lazy(() => import("../Views/Ringtones/Desktop/Download/"));
const RingtoneRecentPopular = lazy(() => import("../Views/Ringtones/Desktop/RecentSearch"));
const MobileRingtones = lazy(() => import("../Views/Ringtones/Mobile/Main/Main"));
const MobileRingtonesCategory = lazy(() => import("../Views/Ringtones/Mobile/Category"));
const MobileRingtonesDownload = lazy(() => import("../Views/Ringtones/Mobile/Download"));
const RingtoneRecentMobile = lazy(() => import("../Views/Ringtones/Mobile/RecentSearch"));

// Videos
const Videos = lazy(() => import("../Views/Videos/Desktop"));
const VideosDetail = lazy(() => import("../Views/Videos/Desktop/Detail"));
const VideoSearch = lazy(() => import("../Views/Videos/Desktop/Search"));
const VideosM = lazy(() => import("../Views/Videos/Mobile"));
const VideosDetailM = lazy(() => import("../Views/Videos/Mobile/Detail"));
const VideosSearchM = lazy(() => import("../Views/Videos/Mobile/Search"));

// Categories Pages
const Category = lazy(() => import("../Views/Category/Desktop"));
const Category_M = lazy(() => import("../Views/Category/Mobile"));

// Cities Pages
const Cities = lazy(() => import("../Views/Cities/Desktop"));
const Cities_M = lazy(() => import("../Views/Cities/Mobile"));

// Static Pages
const AboutUs = lazy(() => import("../pages/AboutUs"));
const AdPostPolicy = lazy(() => import("../pages/AdPostPolicy"));
const CommentPolicy = lazy(() => import("../pages/CommentPolicy"));
const Contact = lazy(() => import("../pages/Contact/Contact"));
const HelpSupport = lazy(() => import("../pages/Contact/Help&support"));
const Newsroom = lazy(() => import("../pages/Contact/Newsroom"));
const Faqs = lazy(() => import("../pages/Contact/Faqs"));
const Faq = lazy(() => import("../pages/Contact/Faq"));
const Sales = lazy(() => import("../pages/Contact/Sales"));
const DMCA = lazy(() => import("../pages/DMCA"));
const GuestPost = lazy(() => import("../Views/GuestPost/GuestPost"));
const GuestPostForm = lazy(() => import("../Views/GuestPost/GuestPostForm"));
const JobInside = lazy(() => import("../Views/Jobs/JobInside"));
const Jobs = lazy(() => import("../Views/Jobs/Jobs"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("../pages/RefundPolicy"));
const ReviewPolicy = lazy(() => import("../pages/ReviewPolicy"));
const TermsCondition = lazy(() => import("../pages/TermsConditions"));

// Home pages
const Home = lazy(() => import("../Views/Home/Desktop/index.js"));
const HomeM = lazy(() => import("../Views/Home/Mobile"));

// Mobile Prices pages
const Mobiles = lazy(() => import("../Views/Mobiles/Desktop"));
const MobileDetails = lazy(() => import("../Views/Mobiles/Desktop/Details"));
const MobileCompare = lazy(() => import("../Views/Mobiles/Desktop/Compare"));
// const AllMobiles = lazy(() => import("../pages/Mobiles/AllMobile/"));
const HomeMobileView = lazy(() => import("../Views/Mobiles/Mobile/index.js"));

// Baby Names Pages
const Search = lazy(() => import("../Views/Names/Desktop/Search/Search.js"));
const Names = lazy(() => import("../Views/Names/Desktop"));
const NameDetails = lazy(() => import("../Views/Names/Desktop/Detail/insideName"));
const MobileName = lazy(() => import("../Views/Names/Mobile/index.js"));
const MobileSearch = lazy(() => import("../Views/Names/Mobile/Search"));
const MobileNameDetail = lazy(() => import("../Views/Names/Mobile/InsideName"));

// Stocks
const StockCategory = lazy(() => import("../Views/Stock/Desktop/Category"));
const StockSearch = lazy(() => import("../Views/Stock/Desktop/Search"));
const StockCategoryM = lazy(() => import("../Views/Stock/Mobile/Category"));
const StockSearchKeyword = lazy(() => import("../Views/Stock/Desktop/Keyword"));
const StockSearchM = lazy(() => import("../Views/Stock/Mobile/Search"));
const StockImage = lazy(() => import("../Views/Stock/Desktop/Image"));
const StockImageM = lazy(() => import("../Views/Stock/Mobile/Image"));
const RecentImages = lazy(() => import("../Views/Stock/Desktop/RecentPopular/Search"));
const RecentImagesMobile = lazy(() => import("../Views/Stock/Mobile/RecentPopular/RecentSearch"));

// API Routes
const VerifyAccount = lazy(() => import("../Views/API/VerifyAccount"));

// Auction Sheet
const AuctionSheet = lazy(() => import("../Views/AuctionSheet"));
const MobileAuctionSheet = lazy(() => import("../Views/AuctionSheet/Mobile"));

const WebsiteRoutes = () => [
  {
    exact: true,
    name: "Home",
    path: "/",
    desktopModule: Home,
    mobileModule: HomeM,
  },
  // SMS Routes
  {
    exact: true,
    name: "SMS",
    path: "/sms-text-messages",
    desktopModule: MainSMS,
    mobileModule: MobMainSMS,
  },
  {
    exact: true,
    name: "SmsCategory",
    path: "/sms/:param",
    desktopModule: CategorySMS,
    mobileModule: MobCategorySMS,
  },
  {
    exact: true,
    name: "Message",
    path: "/sms/:category/:param",
    desktopModule: Message,
    mobileModule: MobMessage,
  },
  // Prices
  {
    exact: true,
    name: "Price",
    path: "/price",
    desktopModule: CategoryPrice,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-pakistan-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-karachi-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-lahore-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-faisalabad-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-rawalpindi-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-gujranwala-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-peshawar-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-multan-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-hyderabad-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-islamabad-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "GoldPrices",
    path: "/gold-price-in-quetta-today-per-tola",
    desktopModule: Gold,
    mobileModule: false,
  },
  {
    exact: true,
    name: "PetrolPrices",
    path: "/petrol-price-in-pakistan-today",
    desktopModule: Petrol,
    mobileModule: false,
  },
  {
    exact: true,
    name: "PriceFilters",
    path: "/price/:param",
    desktopModule: AllPrices,
    mobileModule: false,
  },
  // {
  //   exact: true,
  //   name: "PricesDetails",
  //   path: "/price/:param/:brand",
  //   desktopModule: DetailsPrice,
  //   mobileModule: false,
  // },
  // Auth
  {
    exact: true,
    name: "SignIn",
    path: "/sign-in",
    desktopModule: SignIn,
    mobileModule: SignInM,
  },
  {
    exact: true,
    name: "SignUp",
    path: "/sign-up",
    desktopModule: SignUp,
    mobileModule: SignUpM,
  },
  {
    exact: true,
    name: "MobileAuth",
    path: "/auth/mobile",
    desktopModule: MobileAuth,
    mobileModule: false,
  },
  {
    exact: true,
    name: "ForgetPassword",
    path: "/forget-password",
    desktopModule: ForgetPassword,
    mobileModule: ForgetPasswordM,
  },
  // Listing
  {
    exact: true,
    name: "SearchListing",
    path: "/listing-search",
    desktopModule: Listing,
    mobileModule: ListingCategory_M,
  },
  {
    exact: true,
    name: "FeaturedListing",
    path: "/listing/featured",
    desktopModule: FeaturedListing,
    mobileModule: ListingCategory_M,
  },
  {
    exact: false,
    name: "ListingFood",
    path: "/listing/food",
    desktopModule: ListingFood,
    mobileModule: ListingCategories_M,
  },
  {
    exact: false,
    name: "ListingTravel",
    path: "/listing/travel",
    desktopModule: ListingTravel,
    mobileModule: ListingCategories_M,
  },
  {
    exact: false,
    name: "ListingProperty",
    path: "/listing/property",
    desktopModule: ListingProperty,
    mobileModule: ListingCategories_M,
  },
  {
    exact: false,
    name: "ListingAutomotive",
    path: "/listing/automotive",
    desktopModule: ListingAutomotive,
    mobileModule: ListingCategories_M,
  },
  {
    exact: false,
    name: "ListingCategory",
    path: "/listing/search/",
    desktopModule: ListingCategory,
    mobileModule: ListingCategory_M,
  },
  {
    exact: true,
    name: "Listing",
    path: "/listing/:category/:title",
    desktopModule: ListingDetails,
    mobileModule: ListingDetails_M,
  },

  // Videos
  {
    exact: true,
    name: "Videos",
    path: "/videos",
    desktopModule: Videos,
    mobileModule: VideosM,
  },
  {
    exact: true,
    name: "Videos-Detail",
    path: "/video/:detail",
    desktopModule: VideosDetail,
    mobileModule: VideosDetailM,
  },
  {
    exact: true,
    name: "Videos-Search",
    path: "/videos/search",
    desktopModule: VideoSearch,
    mobileModule: VideosSearchM,
  },

  // Event
  {
    exact: true,
    name: "Events",
    path: "/events-search",
    desktopModule: Event,
    mobileModule: MobileEvent,
  },
  {
    exact: false,
    name: "SearchEvent",
    path: "/event/search",
    desktopModule: EventSearch,
    mobileModule: MobileEventSearch,
  },
  {
    exact: true,
    name: "Event",
    // path: "/event/detail",
    path: "/event/:category/:title",
    desktopModule: EventDetails,
    mobileModule: MobileEventDetails,
  },
  // Reviews
  {
    exact: false,
    name: "Reviews",
    path: "/reviews",
    desktopModule: Reviews,
    mobileModule: ReviewsM,
  },
  {
    exact: true,
    name: "ReviewsDetails",
    path: "/review/:title",
    desktopModule: ReviewDetails,
    mobileModule: ReviewDetailsM,
  },
  // Ringtones
  {
    exact: true,
    name: "Ringtones",
    path: "/ring-tones",
    desktopModule: Ringtones,
    mobileModule: MobileRingtones,
  },
  {
    exact: true,
    name: "RingtonesRecent",
    path: "/ringtones/search",
    desktopModule: RingtoneRecentPopular,
    mobileModule: RingtoneRecentMobile,
  },
  {
    exact: true,
    name: "RingtonesCategory",
    path: "/ringtones/:category",
    desktopModule: RingtonesCategory,
    mobileModule: MobileRingtonesCategory,
  },
  {
    exact: true,
    name: "RingtonesDownload",
    path: "/ringtones/:category/:param",
    desktopModule: RingtonesDownload,
    mobileModule: MobileRingtonesDownload,
  },

  // Mobile Prices
  {
    exact: true,
    name: "Mobiles",
    path: "/mobiles",
    desktopModule: Mobiles,
    mobileModule: HomeMobileView,
  },
  {
    exact: false,
    name: "MobileBrand",
    path: "/mobiles/search/-/",
    desktopModule: Mobiles,
    mobileModule: HomeMobileView,
  },
  {
    exact: false,
    name: "MobileCompare",
    path: "/mobiles/compare",
    desktopModule: MobileCompare,
    mobileModule: false,
  },
  {
    exact: true,
    name: "MobileDetails",
    path: "/mobile/:title",
    desktopModule: MobileDetails,
    mobileModule: false,
  },
  // Names
  {
    exact: true,
    name: "Names",
    path: "/names",
    desktopModule: Names,
    mobileModule: MobileName,
  },
  {
    exact: false,
    name: "SearchNames",
    path: "/names/search",
    desktopModule: Search,
    mobileModule: MobileSearch,
  },
  {
    exact: true,
    name: "NameDetails",
    path: "/names/:details/:name",
    desktopModule: NameDetails,
    mobileModule: MobileNameDetail,
  },

  //STOCKS
  {
    exact: true,
    name: "StockCategory",
    path: "/stock",
    desktopModule: StockCategory,
    mobileModule: StockCategoryM,
  },
  // {
  //   exact: true,
  //   name: "ImagesRecent",
  //   path: "/stock/search",
  //   desktopModule: RecentImages,
  //   mobileModule: RecentImagesMobile,
  // },
  {
    exact: true,
    name: "StockSearch",
    path: "/stock/search",
    desktopModule: StockSearchKeyword,
    mobileModule: StockCategoryM,
  },
  {
    exact: true,
    name: "StockSearch",
    path: "/stock/:category",
    desktopModule: StockSearch,
    mobileModule: StockSearchM,
  },
  {
    exact: true,
    name: "StockSearch",
    path: "/stock/:category/:image",
    desktopModule: StockImage,
    mobileModule: StockImageM,
  },
  // API Routes
  {
    exact: true,
    name: "VerifyAccount",
    path: "/verify-account",
    desktopModule: VerifyAccount,
    mobileModule: false,
    // DEMO URL /verify-account?id=%242b%2410%24Y1Cjk9g4.4VrdIuKbC39uuu.pwD2lYxXWDFRL8L3qYgYOzinSprCC
  },
  // Category Routes
  {
    exact: false,
    name: "Categories",
    path: "/categories",
    desktopModule: Category,
    mobileModule: Category_M,
  },
  // Cities Routes
  {
    exact: false,
    name: "Cities",
    path: "/cities",
    desktopModule: Cities,
    mobileModule: Cities_M,
  },
  // Auction Sheet Routes
  {
    exact: false,
    name: "AuctionSheet",
    path: "/auction-sheet-verification",
    desktopModule: AuctionSheet,
    mobileModule: MobileAuctionSheet,
  },
  // Static Routes
  { exact: true, name: "contact", path: "/support", mobileModule: false, desktopModule: Contact },
  { exact: true, name: "sales", path: "/contact", mobileModule: false, desktopModule: Sales },
  { exact: true, name: "help-support", path: "/help-support", mobileModule: false, desktopModule: HelpSupport },
  { exact: true, name: "newsroom", path: "/chat", mobileModule: false, desktopModule: Newsroom },
  { exact: true, name: "newsroom", path: "/faqs", mobileModule: false, desktopModule: Faqs },
  { exact: true, name: "newsroom", path: "/faqs/:id", mobileModule: false, desktopModule: Faq },
  { exact: true, name: "guest-post", path: "/guest-post", mobileModule: false, desktopModule: GuestPost },
  { exact: true, name: "guest-post-form", path: "/guest-post/:id", mobileModule: false, desktopModule: GuestPostForm },
  { exact: true, name: "jobs", path: "/jobs", mobileModule: false, desktopModule: Jobs },
  { exact: true, name: "career", path: "/career/:id", mobileModule: false, desktopModule: JobInside },
  { exact: true, name: "about-us", path: "/about-us", mobileModule: false, desktopModule: AboutUs },
  { exact: true, name: "privacy-policy", path: "/privacy-policy", mobileModule: false, desktopModule: PrivacyPolicy },
  { exact: true, name: "terms-condition", path: "/terms-condition", mobileModule: false, desktopModule: TermsCondition },
  { exact: true, name: "dmca", path: "/dmca", mobileModule: false, desktopModule: DMCA },
  { exact: true, name: "refund-policy", path: "/refund-policy", mobileModule: false, desktopModule: RefundPolicy },
  { exact: true, name: "comment-policy", path: "/comment-policy", mobileModule: false, desktopModule: CommentPolicy },
  { exact: true, name: "review-policy", path: "/review-policy", mobileModule: false, desktopModule: ReviewPolicy },
  { exact: true, name: "ad-post-policy", path: "/ad-post-policy", mobileModule: false, desktopModule: AdPostPolicy },
];

export default WebsiteRoutes;
