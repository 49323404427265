import { Image, Container, Row, Col } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faStream,
  faBoxOpen,
  faUsers,
  faBriefcase,
  faGift,
  faInbox,
  faExpandAlt,
  faDollarSign,
  faBookArrowUp,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faImage,
  faCalendar,
  faComments,
  faQuestionCircle,
  faChartBar,
  faShareSquare,
  faUserCircle,
} from "@fortawesome/free-regular-svg-icons";

const Header = () => {
  const location = useLocation();
  const listingId = localStorage.getItem("listingid");

  const route = (route) => {
    return listingId && listingId != "null" ? route : "/submit-listing";
  };

  return (
    <div className="hidden-element">
      <ul className="list-unstyled user-menubar">
        <li>
          <Link
            to="/user/dashboard"
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/dashboard" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faHome} />
            <p>Dashboard</p>
          </Link>
        </li>
        <li>
          <Link
            to={route(`/user/listing/${listingId}`)}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === `/user/listing/${listingId}` && "active"
            }`}
          >
            <FontAwesomeIcon icon={faStream} />
            <p>My Business</p>
          </Link>
        </li>

        <li>
          <Link
            to={`/user/events`}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === `/user/events` && "active"
            }`}
          >
            <FontAwesomeIcon icon={faCalendar} />
            <p>My Events</p>
          </Link>
        </li>

        <li>
          <Link
            to={`/user/inbox`}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === `/user/inbox` && "active"
            }`}
          >
            <FontAwesomeIcon icon={faComments} />
            <p>My Messages</p>
          </Link>
        </li>

        <li className="hide">
          <Link
            to={route("/user/photos")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/photos" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faImage} />
            <p>Photos</p>
          </Link>
        </li>

        <li>
          <Link
            to={route("/user/products")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/products" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faBoxOpen} />
            <p>Products</p>
          </Link>
        </li>

        <li>
          <Link
            to={route("/user/reviews")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/reviews" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faComments} />
            <p>Reviews</p>
          </Link>
        </li>

        <li>
          <Link
            to={route("/user/social")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/social" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faBookmark} />
            <p>Social Media</p>
          </Link>
        </li>

        <li>
          <Link
            to={route("/user/pricing")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/pricing" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faDollarSign} />
            <p>Pricing</p>
          </Link>
        </li>

        <li>
          <Link
            to={route("/user/coupon")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/coupon" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faGift} />
            <p>Coupons</p>
          </Link>
        </li>

        <li>
          <Link
            to={route("/user/employees")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/employees" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faUsers} />
            <p>Employees</p>
          </Link>
        </li>
        <li>
          <Link
            to={route("/user/qa")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/qa" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
            <p>Q&A</p>
          </Link>
        </li>
        <li>
          <Link
            to={route("/user/jobs")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/jobs" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faBriefcase} />
            <p>Jobs</p>
          </Link>
        </li>
        <li>
          <Link
            to={route("/user/inbox")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/inbox" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faInbox} />
            <p>Inbox</p>
          </Link>
        </li>
        <li className="hide">
          <Link
            to={route("/user/statistics")}
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/statistics" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faChartBar} />
            <p>Statistics</p>
          </Link>
        </li>
        {/* <li className="hide">
          <Link to={route("/user/share")} style={{ borderBottom: "1px solid #f3f3f3" }} className={`menu-item ${location.pathname === "/user/share" && "active"}`}>
            <FontAwesomeIcon icon={faShareSquare} />
            <p>Share</p>
          </Link>
        </li> */}
        <li>
          <Link
            to="/user/account"
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/account" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faUserCircle} />
            <p>Account</p>
          </Link>
        </li>
        <li>
          <Link
            to="/user/upgrade"
            style={{ borderBottom: "1px solid #f3f3f3" }}
            className={`menu-item ${
              location.pathname === "/user/upgrade" && "active"
            }`}
          >
            <FontAwesomeIcon icon={faExpandAlt} className="text-danger" />
            <p className="text-danger">Upgrade</p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Header;
