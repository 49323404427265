import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const SEO = (props) => {
  const location = useLocation();
  const [meta, setMeta] = useState([]);
  const [old, setOld] = useState({ location: "", meta: "" });

  useEffect(() => {
    if (location != old.location || props.meta != old.meta) {
      Axios.get(`${process.env.REACT_APP_API_URL}seo/?page=${location.pathname + (location.search ? location.search : "")}`).then((res) => {
        setMeta(res.data && res.data[0] ? res.data[0] : props.meta ? props.meta : []);
      });
      setOld({ location, meta: props.meta });
    }
  }, [location, props.meta]);

  return (
    <Helmet>
      <title>{meta.title || "CityBook"}</title>
      <meta name="title" content={meta.title || "CityBook"} />
      {meta.description && <meta name="description" content={meta.description} />}
    </Helmet>
  );
};

export default SEO;
