import React, { Suspense } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserCircle, faStream, faPhotoVideo, faComments, faUsers, faChartBar, faSignOutAlt, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "../../Context/Auth.context.js";
import "./sidebar.scss";

const SideBar = ({ menu, setMenu }) => {
  const listingId = localStorage.getItem("listingid");
  const user = localStorage.getItem("user") && localStorage.getItem("user") != "undefined" ? JSON.parse(localStorage.getItem("user")) : [];

  const route = (route) => {
    return listingId && listingId != "null" ? route : "/submit-listing";
  };

  /* Auth to verify the login */
  const { isAuthenticated, activateToken, activateAuthentication } = Auth();
  const history = useHistory();

  const logout = () => {
    activateAuthentication(false);
    activateToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshCycle");
    localStorage.removeItem("listingid");
    localStorage.removeItem("user");
    history.push("/sign-in");
  };

  var Styles = {
    paddingBottom: "10px",
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div id="menu">
      <Menu fallDown styles={Styles} right className="main-sidebar" isOpen={menu} onOpen={() => setMenu(true)} onClose={() => setMenu(false)}>
        <h5 className="text-white">Account</h5>
        <Link to="/user/dashboard" onClick={() => setMenu(false)} className="d-flex menu-item pt-3 pl-0 ml-0">
          <div className="mt-2 pt-1">
            {user.image ? (
              <img className="rounded-circle mt-n1 ml-2 border border-2" style={{ height: "80px", width: "80px" }} src={`${process.env.REACT_APP_AWS_URL}${user.image}`} />
            ) : (
              <FontAwesomeIcon className="rounded-circle text-muted mt-n1 ml-2 border border-2" icon={faUserCircle} style={{ fontSize: "40px" }} />
            )}
          </div>

          <div className="d-flex align-self-center text-white">
            <div>
              <p className="ml-2 mb-0" style={{ fontSize: "0.8rem" }}>
                {user.name}
              </p>
              <small className="border rounded p-1 ml-2 text-uppercase" style={{ fontSize: "0.6rem" }}>
                <Link onClick={() => setMenu(false)} className="text-white" to={route(`/user/account`)}>
                  Edit Profile
                </Link>
              </small>
            </div>
          </div>
        </Link>
        <Link
          onClick={() => setMenu(false)}
          style={{ borderBottom: "1px solid rgba(255,255,255, 0.3)" }}
          className="py-2 text-decoration-none menu-item d-flex justify-content-start font-weight-light text-white"
          to={route(`/user/dashboard`)}
        >
          <span style={{ fontSize: "1.1rem" }}>
            <FontAwesomeIcon icon={faUserCircle} />
          </span>
          <span style={{ marginLeft: "10px", fontSize: "1.1rem" }}>Dashboard</span>
        </Link>
        <Link
          onClick={() => setMenu(false)}
          style={{ borderBottom: "1px solid rgba(255,255,255, 0.3)" }}
          className="py-2 text-decoration-none menu-item d-flex justify-content-start font-weight-light text-white"
          to={route(`/user/listing/${listingId}`)}
        >
          <span style={{ fontSize: "1.1rem" }}>
            <FontAwesomeIcon icon={faStream} />
          </span>
          <span style={{ marginLeft: "10px", fontSize: "1.1rem" }}>My Business</span>
        </Link>
        <Link
          onClick={() => setMenu(false)}
          style={{ borderBottom: "1px solid rgba(255,255,255, 0.3)" }}
          className="py-2 text-decoration-none menu-item d-flex justify-content-start font-weight-light text-white"
          to={`/user/events`}
        >
          <span style={{ fontSize: "1.1rem" }}>
            <FontAwesomeIcon icon={faCalendar} />
          </span>
          <span style={{ marginLeft: "10px", fontSize: "1.1rem" }}>My Events</span>
        </Link>
        <Link
          onClick={() => setMenu(false)}
          style={{ borderBottom: "1px solid rgba(255,255,255, 0.3)" }}
          className="py-2 text-decoration-none menu-item d-flex justify-content-start font-weight-light text-white"
          to={`/user/inbox`}
        >
          <span style={{ fontSize: "1.1rem" }}>
            <FontAwesomeIcon icon={faComments} />
          </span>
          <span style={{ marginLeft: "10px", fontSize: "1.1rem" }}>My Messages</span>
        </Link>
        <Link
          onClick={() => setMenu(false)}
          style={{ borderBottom: "1px solid rgba(255,255,255, 0.3)" }}
          className="py-2 text-decoration-none menu-item d-flex justify-content-start font-weight-light text-white"
          to="/user/account"
        >
          <span style={{ fontSize: "1.1rem" }}>
            <FontAwesomeIcon icon={faUserCircle} />
          </span>
          <span style={{ marginLeft: "10px", fontSize: "1.1rem" }}>User Profile</span>
        </Link>
        <Link
          onClick={() => {
            logout();
            setMenu(false);
          }}
          className="py-2 text-decoration-none menu-item d-flex justify-content-start font-weight-light text-white"
        >
          <span style={{ fontSize: "1.1rem" }}>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </span>
          <span style={{ marginLeft: "10px", fontSize: "1.1rem" }}>Logout</span>{" "}
        </Link>
        <Link to="/submit-listing" style={{ fontSize: "1.1rem", color: "white" }} onClick={() => setMenu(false)} className="meni-item business-btn">
          <button className="my-3 btn w-100" style={{ color: "#fff", border: "1px solid #fff" }}>
            + Add Business
          </button>
        </Link>
        <Link onClick={() => setMenu(false)} to="/submit-event" className="my-2 text-decoration-none meni-item membership-btn">
          <button className="btn w-100 " style={{ color: "#fff", border: "1px solid #fff" }}>
            + Add Event
          </button>
        </Link>
        <div className="pt-1"></div>
      </Menu>
    </div>
  );
};

export default SideBar;
