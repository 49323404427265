import { useLocation, Route } from "react-router-dom";

import Header from "../Components/Desktop/Headers/Header.component.js";
import FooterMobile from "../Components/Desktop/FooterMobile";
import Footer from "../Components/Desktop/Footer.component";

import MobileHeader from "../Components/Mobile/Header";
import MobileFooter from "../Components/Mobile/Footer";
import "./style.scss";

function WebsiteLayout({ ...props }) {
  const location = useLocation();
  const hideForForm = () => {
    const { pathname } = location;
    return !(pathname.includes("/submit-listing") || pathname.includes("/submit-event") || pathname.includes("/edit-listing") || pathname.includes("/edit-event"));
  };

  return props.size > 480 ? (
    <>
      {location.pathname !== "/" && <Header />}
      <Route {...props} />
      <Footer />
    </>
  ) : (
    <div>
      {hideForForm() && <MobileHeader />}
      <div className={hideForForm() && "child-padding"}>
        <Route {...props} />
      </div>
      {window.innerWidth < 480 ? <>{hideForForm() && <FooterMobile />}</> : ""}
      {hideForForm() && <MobileFooter />}
    </div>
  );
}

export default WebsiteLayout;
