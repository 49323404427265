const config = {
  googleC_Id: process.env.REACT_APP_gglId,
  fb_Id: process.env.REACT_APP_fbId,
  link: {
    in: "https://www.linkedin.com/company/citybookpk/",
    fb: "https://www.facebook.com/CityBook.Pk/",
    ig: "https://www.instagram.com/citybook.pk/",
    yt: "https://www.youtube.com/channel/UCtyHl-KtT8za_uCZi_wEM-w",
    pt: "https://www.pinterest.com/citybookpk/",
    tt: "https://twitter.com/CityBookpk",
    wt: "https://wa.link/g27wfg",
  },
};

export { config };
